import React, { FC } from 'react';
import Image, { ImageProps } from 'next/image';

import getImageLoader from '../../utils/helpers/imageLoader';

interface NextImageStrcutureProps {
  src?: string | undefined;
  alt?: string | null;
  width?: number | null;
  height?: number | null;
  imageType: ImageProps['layout'];
  quality: number;
  priority?: boolean;
  sizes?: string;
  tabIndex?: number;
  className?: string;
  dataHookId?: string;
  useFill: boolean
}

const NextImageStructure: FC<NextImageStrcutureProps> = ({
  src,
  alt,
  width,
  height,
  imageType,
  quality,
  priority,
  sizes,
  tabIndex,
  className,
  dataHookId,
  useFill
}) =>
  process.env.NEXT_PUBLIC_SITE && (imageType === "fill" || width && height) ? (
    <>
      {src && (
        <Image
          data-hookid={dataHookId}
          src={src}
          alt={alt || ''}
          width={width || undefined}
          height={height || undefined}
          fill={useFill}
          {...getImageLoader(src)}
          quality={quality}
          priority={priority}
          sizes={sizes}
          tabIndex={tabIndex}
          className={className}
        />
      )}

    </>
  ) : (
    // eslint-disable-next-line @next/next/no-img-element
    <img src={src} alt={alt || ''} />
  );

export default NextImageStructure;
