import { createReducer } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
import {   
  updateCheckoutCartEmail
} from './actions';

export interface CheckoutReduxState {
  email:string;
}

export const initialState: CheckoutReduxState = {
  email:''
};

export const checkoutReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateCheckoutCartEmail, (state, action) => {
      state.email = action.payload.email;
    })
});
