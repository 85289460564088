import gql from 'graphql-tag';

export const META_DATA_FRAGMENT = gql`
  fragment MetaData on Metadata {
    title
    description
    keywords
    robots
    hreflangs {
      locale
      url
    }
  }
`;

export const CTA_FRAGMENT = gql`
  fragment Cta on HtmlLink {
    text
    url
    newWindow
    linkId
  }
`;

export const MARGIN_FRAGMENT = gql`
  fragment Margin on Margin {
    topPixels
    rightPixels
    bottomPixels
    leftPixels
  }
`;

export const CUSTOM_POSITION_FRAGMENT = gql`
  fragment CustomPositionDesktop on ContentCustomPosition {
    topPercent
    leftPercent
  }
`;

export const IMAGE_DETAILS = gql`
  fragment ImageDetails on ImgDetails {
    src
    width
    height
  }
`;

export const NEXT_IMAGE_FRAGMENT = gql`
  fragment NextImage on ImageV2 {
    desktop {
      ...ImageDetails
    }
    tablet {
      ...ImageDetails
    }
    mobile {
      ...ImageDetails
    }
    alt
  }
  ${IMAGE_DETAILS}
`;

export const VIDEO_FRAGMENT = gql`
  fragment Video on Video {
    videoIdDesktop
    videoIdMobile
    videoThumbnailUrlDesktop
    videoThumbnailUrlMobile
  }
`;

export const PRODUCT_IMAGE_FRAGMENT = gql`
  fragment ProductImage on ProductImage {
    largeSrc
    mediumSrc
    smallSrc
    tinySrc
    alt
  }
`;


export const PRICE_FRAGMENT = gql`
  fragment Price on ProductPrice {
    now
    nowFormatted
    was
    wasFormatted
    discount
    discountPercentage
  }
`;

export const SIZE_FRAGMENT = gql`
  fragment Size on ProductSize {
    sku
    fit
    stockMessage
    inStock
    labels {
      original
      eu
      uk
      us
    }
    giftBox
    giftBoxCode
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment Product on ProductListItem {
    name
    lineNumber
    url
    brand
    brandCode
    productGenderCode
    colour
    material
    media {
      productImages {
        url
        shotType
        alt
        placeholder
      }
    }
    price {
      ...Price
    }
    newIn
    sale
    offer
    exclusive
    clearance
    priceDrop
    imageRoundel
  }
  ${PRICE_FRAGMENT}
`;

export const PRODUCT_PLP_FRAGMENT = gql`
  fragment ProductPlp on ProductListItem {
    name
    lineNumber
    colour
    url
    brand
    media {
      productImages {
        url
        shotType
        alt
        placeholder
      }
    }
    price {
      ...Price
    }
    newIn
    sale
    offer
    exclusive
    clearance
    priceDrop
    imageRoundel
  }
  ${PRICE_FRAGMENT}
`;

export const SUB_MENU_INDEX_LINK_FRAGMENT = gql`
  fragment SubMenuIndexLink on SubMenuIndexLink {
    letter
    enabled
    url
  }
  ${CTA_FRAGMENT}
`;

export const BRAND_LETTERS_LINKS_LIST_FRAGMENT = gql`
  fragment BrandLetterLinkList on BrandLetterLinkList {
    letter
    links {
      ...Cta
    }
  }
  ${CTA_FRAGMENT}
`;

export const IMAGE_LINK = gql`
  fragment ImageLink on ImageHtmlLink {
    image {
      ...NextImage
    }
    link {
      ...Cta
    }
    subtitle
  }
  ${CTA_FRAGMENT}
  ${NEXT_IMAGE_FRAGMENT}
`;

export const PROMO_BANNER_ITEM = gql`
  fragment PromoBannerItemFragment on PromoBannerItem {
    backgroundImage {
      ...NextImage
    }
    backgroundColourHex
    textAlignment
    fontSize
    fontColourHex
    expiryDate
    textHtml
    textLink {
      ...Cta
    }
    linkWrapsWholeItem
  }
  ${CTA_FRAGMENT}
  ${NEXT_IMAGE_FRAGMENT}
`;