import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectBagData } from '../../../features/bag';
import { selectIsCompactHeader, selectToggles } from '../../../features/static';
import { MinibagOptionsInterface } from '../../../types/custom-types';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import usePageName from '../../../utils/customHooks/usePageName';
import useSiteChecker from '../../../utils/customHooks/useSiteChecker';
import MinibagIconStructure from './MinibagIconStructure';

const MiniBagIcon: FC<MinibagOptionsInterface> = ({ toggleBag, isMobileOrTablet, showBag }) => {
  const bagData = useSelector(selectBagData);
  const { headerTheme } = useHeaderTheme();
  const toggles = useSelector(selectToggles);
  const headerCompact = useSelector(selectIsCompactHeader);
  const kgRedesign = toggles?.featureKgRedesign;
  const site = useSiteChecker();
  const isKG = site === 'kg';
  const { pageName } = usePageName();
  const isHomepage = pageName === 'HomePage';
  const finalIconMargin = isHomepage ? '2px' : '4px'

  const componentProps = {
    toggleBag,
    headerTheme,
    showBag,
    bagData,
    isMobileOrTablet,
    headerCompact,
    kgRedesign,
    isKG,
    finalIconMargin
  };
  return <>{headerTheme && <MinibagIconStructure {...componentProps} />}</>;
};

export default MiniBagIcon;
