import { SiteSnippets as CarvelaSiteSnippet } from './carvela/styles/resources/siteSnippets';
import { SiteSnippets as KgSiteSnippet } from './kg/styles/resources/siteSnippets';
import { SiteSnippets as SahSiteSnippet } from './sah/styles/resources/siteSnippets';
import getSite from '../shared/utils/customHooks/useSiteChecker';

interface Snippet {
  [key: string]: string;
}

interface SiteSiteSnippetInterface {
  [key: string]: Snippet;
}

const SiteSnippets: SiteSiteSnippetInterface = {
  carvela: CarvelaSiteSnippet,
  kg: KgSiteSnippet,
  sah: SahSiteSnippet
};

const site = getSite();

const siteSnippets = SiteSnippets[site];

export default siteSnippets;
