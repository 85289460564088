import DOMPurify from 'isomorphic-dompurify';

const cleanHtml = (codeToClean?: string | Node | null): string => {
  if (!codeToClean) {
    return '';
  }
  const cleanCode = typeof window !== 'undefined' ? DOMPurify.sanitize(codeToClean) : codeToClean;

  return cleanCode as string;
};

export default cleanHtml;
