/* Packages */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import constants from '../../../sites/constants';
import { selectFooterData, selectShowMobileAppLogo, selectToggles } from '../../features/static';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import FooterStructure from './FooterStructure';

interface FooterProps {
  mobileNewsletterAccordion: boolean;
}

const Footer: FC<FooterProps> = ({ mobileNewsletterAccordion }) => {
  const theme = useTheme();
  const [showSignUpDescription, setShowSignUpDescription] = useState(false);
  const [showAppLogos, setShowAppLogos] = useState(false);
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const currentYear = new Date().getFullYear();
  const showMobileAppLogo = useSelector(selectShowMobileAppLogo);
  const footerData = useSelector(selectFooterData);
  const toggles = useSelector(selectToggles);

  useEffect(() => {
    if (isMobile) {
      setShowSignUpDescription(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (footerData) {
      const { appStores } = footerData;

      if (appStores?.accounts.length) {
        setShowAppLogos(true);
      }
    }
  }, [footerData]);

  const newLayout = constants.newFooterLayout;

  const componentProps = {
    ...footerData,
    currentYear,
    isMobile,
    isTablet,
    showAppLogos,
    showSignUpDescription,
    showMobileAppLogo,
    mobileNewsletterAccordion: newLayout ? false : mobileNewsletterAccordion,
    newLayout,
    kgRedesignToggle: toggles?.featureKgRedesign,
    showFooterSocialLogos: toggles?.featureFooterSocialLogos,
  };

  return <FooterStructure {...componentProps} />;
};

export default Footer;
