import { useSelector } from 'react-redux';

import { selectPageName } from '../../../features/static';

interface UsePageNameReturn {
  pageName: string;
}

const usePageName = (): UsePageNameReturn => {
  const pageName = useSelector(selectPageName);

  if (pageName) {
    return {
      pageName,
    };
  }

  return {
    pageName: 'NoPageName',
  };
};

export default usePageName;
