import { fonts, vars } from './resources';

interface TypesInterface {
  [key: string]: string;
}

export const Types: TypesInterface = {
  h1Style: `
    font-size: 24px;
    line-height: 33px;
    font-family: ${fonts.fontKudryashevHeadline};
    font-weight: 400;
  `,
  h2Style: `
    font-size: 22px;
    line-height: 30px;
    font-family: ${fonts.fontKudryashevHeadline};
    margin: 0;
    text-transform: none;
    font-weight: 400;
  `,
  h3Style: `
    font-size: 14px;
    line-height: 20px;
    font-family: ${fonts.fontShapiroBase63LightHeavy};
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
  `,
  h4Style: `
    font-size: 14px;
    font-family: ${fonts.fontShapiroBase55Middle};
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  `,
  h5Style: `
    font-size: 12px;
    font-family: ${fonts.fontFamilyPrimary};
    line-height: 20px;
  `,
  h6Style: `
    font-size: 19px;
    line-height: 20px;
    font-family: ${fonts.fontKudryashevHeadline};
  `,
  ctaTextStyles: `
    font-family: ${fonts.fontFamilyPrimary};
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
  `,
  linkStyles: `
  text-decoration: underline;
  font-size: 14px;
  line-height: 10px;
  font-family: ${fonts.fontFamilyPrimary};
  cursor: pointer;
  color: ${vars.black};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  `,
  tertiaryLinkStyles: `
  text-decoration: underline;
  font-size: 14px;
  line-height: 10px;
  font-family: ${fonts.fontFamilyPrimary};
  cursor: pointer;
  font-weight: 400;
  &:hover {
    text-decoration: none;
  }
  `,
  labelStyle: `
    font-family: ${fonts.fontFamilyPrimary};
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    text-decoration: none;
  `,
  labelSmallStyle: `
    font-family: ${fonts.fontFamilyPrimary};
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
  `,
  boldStyle: `
    font-family: ${fonts.fontShapiroBase63LightHeavy};
    font-size: inherit;
  `,
};

export default Types;
