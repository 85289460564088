import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { createAction } from '@reduxjs/toolkit';

import { GoCertifyPageData } from '../../pageComponents/GocertifyPages';
import { BrandIndex, CategoryBreadcrumb, CountryPickerResponse, Footer, Header, HeaderNavigation, HeaderTrendingSearch, JobListing, ProductBreadcrumb, Sitemap } from "../../types/middleware-types";
import { SiteData } from "../../types/state-types";

export const setHeaderOnlyData = createAction<Header>('static/setHeaderOnlyData');
export const setHeaderNavigationData = createAction<HeaderNavigation>('static/setHeaderNavigationData');
export const setFooterData = createAction<Footer>('static/setFooterData');
export const setCountryPickerData = createAction<CountryPickerResponse>('static/setCountryPickerData');
export const setPageName = createAction<string>('static/setPageName');
export const setSiteData = createAction<SiteData>('static/setSiteData');
export const setBrandIndex = createAction<BrandIndex>('static/setBrandIndex');
export const setToggleData = createAction<LDFlagSet>('static/setToggleData');
export const setSitemapData = createAction<Sitemap>('static/setSitemapData');
export const setHeaderColourTheme = createAction<string>('static/setHeaderColourTheme');
export const setGoCertifyData = createAction<GoCertifyPageData>('static/setGoCertifyData');
export const setJobsData = createAction<JobListing[]>('static/setJobsData');
export const setPageId = createAction<string>('static/setPageId');
export const setCurrencyData = createAction<{ code: string, symbol: string }>('static/setCurrencyData');
export const setCookies = createAction<string | null>('static/setCookies');
export const setShowBrandNameProductCard = createAction<boolean>('static/setShowBrandNameProductCard');
export const setHeaderReverse = createAction<boolean>('static/setHeaderReverse');
export const setTrendingSearchData = createAction<HeaderTrendingSearch>('static/setTrendingSearchData');
export const setBreadCrumbSchema = createAction<Array<CategoryBreadcrumb | ProductBreadcrumb>>('static/setBreadcrumbSchema');

