import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from '../../../../features/static';
import { ImageHtmlLink } from '../../../../types/middleware-types';
import SubMenuImageLinkListStructure from './SubMenuImageLinkListStructure';

export interface SubMenuImageLinkListProps {
  data: {
    links: Array<ImageHtmlLink>;
  };
}

const SubMenuImageLinkList: FC<SubMenuImageLinkListProps> = ({ data: { links } }) => {
  const toggles = useSelector(selectToggles);
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  const componentProps = {
    data: links,
    useReactRoutingLinks,
  };
  return <SubMenuImageLinkListStructure {...componentProps} />;
};

export default SubMenuImageLinkList;
