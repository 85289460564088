/* eslint-disable react/no-danger */
import React, { ElementType, FC, KeyboardEvent, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccordionValues } from '../../types/custom-types';
import { HtmlLink, Job, Maybe } from '../../types/middleware-types';
import AccordionItem from '../AccordionItem';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledAccordionWrapper,
  StyledTitleDropdown,
  StyledTitleDropdownText,
} from './AccordionStyles';

export interface AccordionProps {
  data: {
    title: string;
    itemComponent: ElementType;
    links?: HtmlLink[];
    careersData?: {
      subDepartment: string;
      jobsByDept: Job[];
    }[]
  }[];
  toggleAccordion: (title: string) => void;
  pageName: string;
  dataHookId?: string;
  title: string;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  toggleOnHover?: boolean;
  activeAccordion: AccordionValues[];
  titleComponent?: ElementType | null;
  children: ReactNode;
  plusIcon: any;
  minusIcon: any;
  onEnterKeyPressed: (e: KeyboardEvent<HTMLButtonElement>, selectedTitle: string) => void;
  text?: Maybe<string>;
  isHeader?: boolean;
  isCompactHeader?: boolean;
  colourTriggerRed: boolean;
  showCareersPageAccordions?: boolean;
}

// Conditional parent wrapper, different styles for different Children, moved to utils if used again
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConditionalWrapper = ({ condition, children, Wrapper }: any) =>
  condition ? <Wrapper>{children}</Wrapper> : children;

const AccordionStructure: FC<AccordionProps> = ({
  data,
  titleComponent,
  toggleAccordion,
  activeAccordion,
  children,
  title,
  toggleOnHover,
  pageName,
  onMouseEnter,
  onMouseLeave,
  plusIcon,
  minusIcon,
  onEnterKeyPressed,
  text,
  isHeader,
  isCompactHeader,
  colourTriggerRed,
  showCareersPageAccordions,
  dataHookId
}) => {
  const isChildActive = activeAccordion.find((child) => child.title === title && child.isOpen);
  const TitleComponent = titleComponent || 'div';

  return (
    <ConditionalWrapper condition={text} Wrapper={StyledTitleDropdown}>
      <StyledAccordion data-hookid={`${pageName}VerticalAccordion`}>
        {data.length > 0 ? (
          <>
            {showCareersPageAccordions ? (
              <>
                {data.map(({ title: itemTitle, itemComponent: ItemComponent, careersData }) => {
                  const isActive = activeAccordion.find((acc) => acc.title === itemTitle && acc.isOpen);
                  return (
                    <StyledAccordionWrapper
                      key={itemTitle}
                      onMouseEnter={() => toggleOnHover && toggleAccordion(itemTitle)}
                      onMouseLeave={() => toggleOnHover && toggleAccordion(itemTitle)}
                      className="item"
                    >
                      <StyledAccordionTrigger
                        $layout={pageName}
                        data-hookid={`${pageName}${itemTitle?.replace(/\s+/g, '')}AccordionTrigger`}
                        className={isActive ? 'active' : 'inactive'}
                        onClick={() => toggleAccordion(itemTitle)}
                        type="button"
                        $red={colourTriggerRed}
                      >
                        <TitleComponent
                          className="trigger-title"
                          dangerouslySetInnerHTML={{ __html: itemTitle }}
                        />
                        <span>
                          <FontAwesomeIcon icon={isActive ? minusIcon as IconProp : plusIcon as IconProp} />
                        </span>
                      </StyledAccordionTrigger>
                      <StyledAccordionContent $isActive={isActive} className={isActive ? 'active' : ''}>
                        {careersData?.map((job, index) => (
                          <ItemComponent key={index} data={job} />
                        ))}
                      </StyledAccordionContent>
                    </StyledAccordionWrapper>
                  );
                })}
              </>
            ) : (
              <>
                {data.map(({ title: itemTitle, itemComponent: ItemComponent, ...rest }) => {
                  const isActive = activeAccordion.find((acc) => acc.title === itemTitle && acc.isOpen);
                  return (
                    <StyledAccordionWrapper
                      key={itemTitle}
                      onMouseEnter={() => toggleOnHover && toggleAccordion(itemTitle)}
                      onMouseLeave={() => toggleOnHover && toggleAccordion(itemTitle)}
                      className="item"
                    >
                      <StyledAccordionTrigger
                        $layout={pageName}
                        data-hookid={`${pageName}${itemTitle?.replace(/\s+/g, '')}AccordionTrigger`}
                        className={isActive ? 'active' : 'inactive'}
                        onClick={() => toggleAccordion(itemTitle)}
                        type="button"
                        $red={colourTriggerRed}
                      >
                        <TitleComponent
                          className="trigger-title"
                          dangerouslySetInnerHTML={{ __html: itemTitle }}
                        />
                        <span>
                          <FontAwesomeIcon icon={isActive ? minusIcon : plusIcon} />
                        </span>
                      </StyledAccordionTrigger>
                      <StyledAccordionContent $isActive={isActive} className={isActive ? 'active' : ''}>
                        {ItemComponent ? (
                          <ItemComponent data={rest} />
                        ) : (
                          <AccordionItem links={rest.links} isHeader={isHeader} isOpen={!!isActive} />
                        )}
                      </StyledAccordionContent>
                    </StyledAccordionWrapper>
                  );
                })}
              </>
            )}

          </>
        ) : (
          <StyledAccordionWrapper
            data-hookid={text ? `${pageName}-title-dropdown` : null}
            onMouseEnter={() => toggleOnHover && onMouseEnter()}
            onMouseLeave={() => toggleOnHover && onMouseLeave()}
          >
            <StyledAccordionTrigger
              $layout={pageName}
              className={isChildActive ? 'active' : 'inactive'}
              onKeyDown={(e) => onEnterKeyPressed(e, title)}
              onClick={() => !toggleOnHover && toggleAccordion(title)}
              type="button"
              data-hookid={`${pageName}${dataHookId || title?.replace(/\s+/g, '')}AccordionTrigger`}
              $red={!!isHeader && !isCompactHeader && ['clearance'].includes(title.toLowerCase())}
            >
              <div dangerouslySetInnerHTML={{ __html: title }} />
              <span>
                <FontAwesomeIcon icon={isChildActive ? minusIcon : plusIcon} />
              </span>
            </StyledAccordionTrigger>
            <StyledAccordionContent $isActive={isChildActive}>
              {text && text.length > 0 ? (
                <StyledTitleDropdownText>{text}</StyledTitleDropdownText>
              ) : (
                children
              )}
            </StyledAccordionContent>
          </StyledAccordionWrapper>
        )}
      </StyledAccordion>
    </ConditionalWrapper>
  );
};

export default AccordionStructure;