import React, { ChangeEvent, FC, FormEvent, KeyboardEvent, memo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextInput from '../../basic/TextInput';
import {
  ProductSearchKeywordSuggestion,
  ProductSearchProductSuggestion,
} from '../../types/middleware-types';
import SearchIcon from './SearchIcon';
import SearchResults from './SearchResults';
import {
  ClearButtonContainer,
  StyledCloseButton,
  StyledOffScreenLabel,
  StyledSearchboxContainer,
  StyledSearchControls,
} from './SearchStyles';
import TrendingSearchResults from './TrendingSearchResults';

interface SearchStructureProps {
  searchOpen: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  clearSearch: () => void;
  landingSearch: boolean;
  handleSearch: (e: FormEvent) => void;
  handleKeyPress: (e: KeyboardEvent) => void;
  searchSuggestions: ProductSearchKeywordSuggestion[] | [];
  searchResults: ProductSearchProductSuggestion[] | [];
  handleSearchClose: () => void;
  compactHeader: boolean;
  searchUrl: string;
  headerHeight: number;
  searchPlaceholder: string;
  idName?: string;
  showOpenSearchBar?: boolean;
  showTrendingData: boolean;
}

const SearchStructure: FC<SearchStructureProps> = ({
  searchOpen,
  handleChange,
  searchTerm,
  clearSearch,
  landingSearch,
  handleSearch,
  handleKeyPress,
  searchSuggestions,
  searchResults,
  handleSearchClose,
  compactHeader,
  searchUrl,
  headerHeight,
  searchPlaceholder,
  idName,
  showOpenSearchBar,
  showTrendingData
}) => (
  <>
    <SearchIcon
      data-hookid="globalHeaderSearch"
      landingSearch={landingSearch}
      clearSearch={clearSearch}
    />
    <StyledSearchboxContainer
      data-hookid="searchboxContainer"
      $searchOpen={searchOpen}
      $compactHeader={compactHeader}
      $showOpenSearchBar={showOpenSearchBar}
      $top={headerHeight}
    >
      <StyledCloseButton
        type="button"
        onClick={handleSearchClose}
        data-hookid="globalHeaderSearchCancel"
      >
        <FontAwesomeIcon icon={faTimes as IconProp} />
        <StyledOffScreenLabel>close</StyledOffScreenLabel>
      </StyledCloseButton>
      <StyledSearchControls>
        <TextInput
          placeholder={searchPlaceholder}
          value={searchTerm}
          name="search"
          id={idName}
          onChange={handleChange}
          type="text"
          onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
          focus
          data-hookid={landingSearch ? "globalHeaderLandingSearchInputBox" : "globalHeaderSearchInputBox"}
          title="search"
        />
        {compactHeader && (
          <ClearButtonContainer>
            <button
              type="button"
              onClick={clearSearch}
              data-hookid="globalHeaderSearchClearBtn"
            >
              clear
            </button>
          </ClearButtonContainer>
        )}
        <button
          className="submit"
          type="button"
          onClick={handleSearch}
          data-hookid={landingSearch ? "globalHeaderLandingSearchButtonSubmit" : "globalHeaderSearchButtonSubmit"}
        >
          <FontAwesomeIcon icon={faSearch as IconProp} />
          <StyledOffScreenLabel>submit</StyledOffScreenLabel>
        </button>
      </StyledSearchControls>
      {showTrendingData && searchTerm === '' && (
        <TrendingSearchResults />
      )}
      {searchResults.length > 0 && searchTerm !== '' &&  (
        <SearchResults
          searchTerm={searchTerm}
          searchSuggestions={searchSuggestions}
          searchResults={searchResults}
          searchUrl={searchUrl}
        />
      )}
    </StyledSearchboxContainer>
  </>
);

export default memo(SearchStructure);