import React, { FC, memo, Ref } from 'react';

import {
  HeaderNavigation,
  Maybe,
  PromoBanner as PromoBannerType,
} from '../../../types/middleware-types';
import Account from '../../Account';
import BrandLogo from '../../BrandLogo';
import ContinuityBar from '../../ContinuityBar';
import CountryPicker from '../../CountryPicker';
import MainMenu from '../../MainMenu';
import MiniBag from '../../Minibag';
import MobileMenu from '../../MobileMenu';
import PromoBanner from '../../PromoBanner';
import Search from '../../Search';
import SkipLink from '../../SkipLink';
import {
  StyledDesktopNav,
  StyledMenu,
  StyledMobileNav,
  StyledMobileUtils,
  StyledOverlay,
  StyledSecondarySection,
  StyledTransparentHeader,
  StyledUtilitiesMenu,
} from './TransparentHeaderStyles';

export interface TransparentHeaderStructureProps {
  toggleSearch: (value?: boolean) => void;
  searchOpen: boolean;
  headerNavigation?: Maybe<HeaderNavigation>;
  headerPosition: string;
  promoBanner?: Maybe<PromoBannerType>;
  headerTheme: string;
  headerBackground: string;
  getElement: Ref<HTMLElement>;
  showDesktopLayout: boolean;
  hasSmartAppBanner?: boolean;
  showContinuityBar: boolean;
}

const TransparentHeaderStructure: FC<TransparentHeaderStructureProps> = ({
  toggleSearch,
  searchOpen,
  headerNavigation,
  headerPosition,
  promoBanner,
  headerTheme,
  headerBackground,
  getElement,
  showDesktopLayout,
  hasSmartAppBanner,
  showContinuityBar
}) => (
  <>
    {!showContinuityBar && promoBanner && (
      <PromoBanner data={promoBanner} />
    )}
    {showContinuityBar && promoBanner && (
      <ContinuityBar data={promoBanner} />
    )}
    <SkipLink target="#mainnavigation" label="skip to navigation" />
    <StyledTransparentHeader
      id="header"
      ref={getElement}
      data-hookid="globalHeader"
      $headerPosition={headerPosition}
      $headerTheme={headerTheme}
      $headerBackground={headerBackground}
      $hasSmartAppBanner={hasSmartAppBanner}
      $hasPromoBannerMobile={promoBanner?.mobileItems && promoBanner?.mobileItems?.length > 0}
      $hasPromoBannerDesktop={promoBanner?.desktopItems && promoBanner?.desktopItems?.length > 0}
      $promoBannerMobileHeight={promoBanner?.mobileHeightPixels}
      $promoBannerDesktopHeight={promoBanner?.desktopHeightPixels}
    >
      {/* Desktop nav */}
      {showDesktopLayout && (
        <StyledDesktopNav>
          <StyledUtilitiesMenu $searchOpen={searchOpen} data-hookid="desktopGlobalHeader">
            <StyledMenu data-hookid="desktopGlobalHeaderAccountMenu">
              <CountryPicker />
              <Account />
            </StyledMenu>
            <BrandLogo />
            <StyledMenu data-hookid="desktopGlobalHeaderMinibagMenu">
              <MiniBag />
              <Search landingSearch={false} idName="search" />
            </StyledMenu>
          </StyledUtilitiesMenu>
          <MainMenu data={headerNavigation?.desktopMenu} />
          <StyledOverlay $searchOpen={searchOpen} onClick={() => toggleSearch(false)} />
        </StyledDesktopNav>
      )}
      {/* Mobile nav */}
      {!showDesktopLayout && (
        <StyledMobileNav>
          <StyledMobileUtils data-hookid="mobileGlobalHeader">
            <StyledSecondarySection>
              <MobileMenu data={headerNavigation?.mobileMenu} layout="MobileMenu" />
              <Search landingSearch={false} idName="mob-search" />
            </StyledSecondarySection>
            <BrandLogo />
            <StyledSecondarySection>
              <MiniBag />
            </StyledSecondarySection>
          </StyledMobileUtils>
          {headerPosition === 'absolute' && <Search landingSearch={true} idName="search-abs" />}
        </StyledMobileNav>
      )}
    </StyledTransparentHeader>
  </>
);

export default memo(TransparentHeaderStructure);
