import styled from 'styled-components';

import { StyledLink } from '../../basic/LinkItem/LinkItemStyles';

interface StyledMobileMenuLinkProps {
  $compactHeader?: boolean;
}

export const StyledMobileMenuLink = styled.div<StyledMobileMenuLinkProps>`
  display: flex;
  align-items: center;
  padding: ${({ $compactHeader }) => ($compactHeader ? '0.9rem 1.6rem' : '0.9rem 0.1rem')};
  box-sizing: border-box;
  border-top: ${({ $compactHeader, theme }) =>
  $compactHeader ? `1px solid ${theme.headerColors.mobileMenuBorder}` : 'none'};
  font-size: 1.2rem;

  &:last-child {
    padding-bottom: 3rem;
  }

  > div {
    &:first-child {
      width: ${({ theme }) => theme.vars.sizeXL};
    }
  }

  ${StyledLink} {
    ${({ theme }) => theme.types.labelStyle};
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  button {
    width: 100%;
    ${({ theme }) => theme.types.labelStyle};
    text-align: left;
    text-transform: unset;
    outline: none;
    margin-left: 1rem;
    padding-left: 0;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.vars.black};
  }
`;
