import styled from 'styled-components';

import { StyledLink } from '../../../basic/LinkItem/LinkItemStyles';
import { Maybe } from '../../../types/middleware-types';

interface StyledSearchResultsContainerProps {
  $elOffset: number;
  $compactHeader: boolean;
}

interface StyledPricesProps {
  $wasPrice: Maybe<string> | undefined;
}

export const StyledProductLink = styled.a`
  text-decoration: none;
  width: 100%;
  margin-bottom: 0.5rem;

  @media ${({ theme }) => theme.devices.large} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const StyledSearchHistoryText = styled.h4`
  ${({ theme }) => theme.types[theme.search.headings]};
  text-transform: uppercase;
  color: ${({ theme }) => theme.vars.black};
  width: 100%;
  margin-bottom: 0;

  @media ${({ theme }) => theme.devices.smallOnly} {
    margin-top: 0;
  }
`;

export const StyledSearchResultsContainer = styled.div<StyledSearchResultsContainerProps>`
  display: flex;
  margin-top: ${({ theme }) => theme.vars.sizeM};
  text-align: left;
  flex-direction: column;
  left: 15%;

  /* Extra padding bottom to account for 100vh address bar bug */
  padding: 0 5% ${({ $compactHeader }) => ($compactHeader ? 0 : '8rem')} 0;
  overflow-y: auto;
  max-height: ${({ $elOffset }) => `calc(100vh - ${$elOffset}px)`};
  box-sizing: border-box;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    width: 100%;
    padding: 0;
  }
`;

export const StyledSearchHistoryContainer = styled.div`
  display: block;
  width: 30%;

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
      margin: 1.6rem 0;
      ${StyledLink} {
        ${({ theme }) => theme.types.labelStyle};
        text-decoration: none;
        text-transform: capitalize;
        color: ${({ theme }) => theme.vars.black};
      }
    }
  }
`;

export const StyledSearchTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: ${({ theme }) => theme.vars.sizeM};
      ${StyledLink} {
        ${({ theme }) => theme.types.labelStyle};
        text-decoration: none;
        text-transform: capitalize;
      }
    }
  }
`;

export const StyledProductSearchResult = styled.div`
  display: flex;

  img {
    width: 9rem;
    height: 9rem;
  }
`;

export const StyledImageContainer = styled.div`
  width: 9rem;
  height: 9rem;
`;

export const StyledProductInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem 0;

  p {
    margin: 0.3rem 0.8rem;
    color: ${({ theme }) => theme.vars.black};

    &.name {
      ${({ theme }) => theme.types[theme.search.productName]};
    }

    &.brand {
      ${({ theme }) => theme.types[theme.search.brandName]};
    }

    &.price {
      font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ theme }) => theme.vars.sizeM};
  }
`;

export const StyledViewMoreResultsBtn = styled.div`
  margin-top: ${({ theme }) => theme.vars.sizeXS};
  width: 100%;
  text-align: center;

  @media ${({ theme }) => theme.devices.large} {
    width: 50%;
    text-align: left;
    margin-left: 50%;
  }

  a {
    ${({ theme }) => theme.types.tertiaryLinkStyles};
    color: ${({ theme }) => theme.vars.black};
    text-decoration: underline;

    &:hover {
      &::after {
        width: 0;
      }
    }
  }
`;

export const StyledPrices = styled.div<StyledPricesProps>`
  display: flex;
  ${({ $wasPrice }) =>
  $wasPrice &&
    `
    p {
      &:first-of-type {
        text-decoration: line-through
      }
    }
  `};
`;