import React, { FC } from 'react';

import SkipLinksStructure from './SkipLinksStructure';

export interface SkipLinksProps {
  target: string,
  label: string
}

const SkipLinks: FC<SkipLinksProps> = ({ target, label }) => {
  const componentProps = { target, label };

  return <SkipLinksStructure {...componentProps} />;
};

export default SkipLinks;
