/* eslint-disable no-bitwise */
import addToDataLayer from '../addToDataLayer';

interface PromoObj {
  promotions: {
    id: string;
    name?: string;
    creative: string;
    position?: number;
    page_type: string;
    promo_image?: string | null;
  }[];
}

interface PromoToAdd {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: number;
  ecommerce: { [key: string]: PromoObj };
}

function buildHash(str: string): number {
  return str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
}

const getPath = (url?: string | null): string => {
  try {
    const urlObj = new URL(url || '');
    return urlObj.pathname;
  } catch (e) {
    return url || '';
  }
};

const eventTypes: { [key: string]: { event: string; eventAction: string } } = {
  click: {
    event: 'promoClick',
    eventAction: 'promotion click',
  },
  view: {
    event: 'promoView',
    eventAction: 'promotion view',
  },
};

const formatPromoEvent = (
  description: string | null | undefined,
  position: number | undefined,
  url: string | null | undefined,
  imageSrc: string | null | undefined,
  type: string,
  eventType: string,
): void => {
  const image = imageSrc?.startsWith('//kg-static') ? imageSrc.replace('//', '') : imageSrc;
  const id = buildHash(`${description}${type}${position}${url}`);
  const promoObj: PromoObj = {
    promotions: [
      {
        id: `${description?.toLowerCase()}_${type}_${id}`,
        name: description?.toLowerCase(),
        creative: type,
        position,
        page_type: getPath(url),
        promo_image: image,
      },
    ],
  };
  const promoToAdd: PromoToAdd = {
    event: eventTypes[eventType].event,
    eventCategory: 'ecommerce',
    eventAction: eventTypes[eventType].eventAction,
    eventLabel: type === 'inline' ? 'Inline Banner' : type,
    eventValue: position,
    ecommerce: {},
  };
  promoToAdd.ecommerce[eventTypes[eventType].event] = promoObj;
  addToDataLayer(promoToAdd);
};

export default formatPromoEvent;
