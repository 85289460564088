/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';

import { selectToggles } from '../../../../../features/static';
import { HtmlLink } from '../../../../../types/middleware-types';
import { StyledShopBySize, StyledTitle } from '../ShopBySizeStyles';

export interface SubMenuShopBySizeProps {
  data: {
    sizes: Array<HtmlLink>;
  }
  title: string;
}

const SubMenuShopBySize: FC<SubMenuShopBySizeProps> = ({ data: { sizes }, title }) => {
  const toggles = useSelector(selectToggles);
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;
  return (
    <StyledShopBySize data-hookid="globalHeaderSubMenuShopBySize">
      <StyledTitle>{title}</StyledTitle>
      <ul>
        {sizes?.length > 0 &&
          sizes.map((size, index) => (
            <li key={`${size.text}-${index}`}>
              {useReactRoutingLinks ? (
                <Link href={size.url} passHref legacyBehavior>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a id={size?.linkId || undefined} {...(size.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}>
                    {size.text}
                  </a>
                </Link>
              ) : (
                <a id={size?.linkId || undefined} href={size.url} {...(size.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}>
                  {size.text}
                </a>
              )}
            </li>
          ))}
      </ul>
    </StyledShopBySize>
  )};

export default SubMenuShopBySize;
