import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectBrandsIndexData } from '../../../../features/static';
import { IndexSubMenu as IndexSubMenuTypes, SubMenuIndexLink  } from '../../../../types/middleware-types';
import IndexSubMenuStructure from './IndexSubMenuStructure';

export interface IndexSubMenuProps {
  data: IndexSubMenuTypes
}

const IndexSubMenu: FC<IndexSubMenuProps> = ({ data }) => {
  const brandIndexData = useSelector(selectBrandsIndexData);

  const finalBrandsIndexData: Array<SubMenuIndexLink> | null = brandIndexData || null;

  const componentProps = { data, brandsIndex: finalBrandsIndexData };

  return <IndexSubMenuStructure {...componentProps} />;
};

export default IndexSubMenu;
