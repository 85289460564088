import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TextInputStructure from './TextInputStructure';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: boolean;
  errorMsg?: string;
  type: string;
  required?: boolean;
  darkBg?: boolean;
  focus?: boolean;
  site?: string;
  icon?: IconProp;
  iconColour?: string;
}

const TextInput: FC<TextInputProps> = ({
  id,
  label,
  name,
  onChange = () => { },
  placeholder = '',
  error,
  errorMsg,
  type,
  required = false,
  darkBg,
  focus,
  site,
  icon,
  iconColour,
  ...otherProps
}: TextInputProps) => {
  const componentProps = {
    id,
    label,
    name,
    onChange,
    placeholder,
    error,
    errorMsg,
    type,
    required,
    darkBg,
    focus,
    site,
    icon,
    iconColour,
    ...otherProps,
  };
  return <TextInputStructure {...componentProps} />;
};

export default TextInput;
