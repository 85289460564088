import { createGlobalStyle } from 'styled-components';

import { fonts } from '../resources/resources';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${fonts.fontFamilyPrimary};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/AkzidenzGroteskBE-Ex.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/AkzidenzGroteskBE-Ex.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: ${fonts.fontFamilyPrimaryBold};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/AkzidenzGroteskBE-MdEx.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/AkzidenzGroteskBE-MdEx.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: ${fonts.fontFamilyBebasNeue};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/BebasNeue-Regular.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/BebasNeue-Regular.woff") format("woff");
    font-display: swap;
  }

  html {
    font-size: 10px;

    body {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      font-size: 10px;
      font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
      color: ${({ theme }) => theme.vars.black};
      background: ${({ theme }) => theme.vars.white};

      /* To assist mobile minibag styling */
      overflow-x: hidden;

      @media ${({ theme }) => theme.devices.large} {
        overflow-x: initial;
      }

      &.no-scroll {
        overflow: hidden !important;
        width: 100vw;
        height: 100vh;
      }
    }

    /* removes focus ring for mouse & touch interactions */
    /* stylelint-disable selector-max-universal */
    &[data-whatintent= "mouse"],
    &[data-whatintent= "touch"] {
      *:focus {
        outline: none;
      }

      /* Necessary for styled radios and checkboxes */
      input + label {
        &::before {
          outline: none !important;
        }

        span {
          outline: none !important;
        }
      }
    }
  }
`;
