import styled from 'styled-components';

interface Props {
  $compactHeader: boolean;
  $hideNav: boolean;
  $isScrolling: boolean;
  $landingSearch: boolean;
  $headerTheme: string;
  $pointer: boolean;
  $isHeaderReverse?: boolean;
}

export const StyledSearchIconContainer = styled.div<Props>`
  width: ${({ $compactHeader }) => $compactHeader && '2rem'};
  min-width: ${({ theme }) => theme.vars.sizeM};
  ${({ $hideNav, $isScrolling, $compactHeader }) =>
  $hideNav && !$isScrolling && !$compactHeader ? 'visibility: hidden' : 'display: flex'};
  align-items: center;
  cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'auto')};
  ${({ $landingSearch, $compactHeader, $headerTheme }) =>
    $landingSearch &&
    !$compactHeader &&
    `
        border-bottom:  1px solid ${$headerTheme};
        margin: 1.6rem 3.2rem;
        padding: 0.8rem 0;

        span {
          font-size: 1.4rem !important;
        }
        `};
  svg {
    cursor: pointer;
    font-size: ${({ theme }) => theme.vars.sizeL};
    max-width: ${({ $isHeaderReverse }) => ($isHeaderReverse ? `1.8rem` : `2rem`)};
    color: ${({ $headerTheme }) => $headerTheme};
    margin-left: auto;
    margin-bottom: ${({ $compactHeader }) => (!$compactHeader ? `0.4rem` : `0`)};

    path {
      stroke: ${({ $headerTheme }) => $headerTheme};
    }
  }

  span {
    font-size: ${({ theme }) => theme.vars.sizeS};
    color: ${({ $headerTheme }) => $headerTheme};
    margin-left: 0.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.vars.linkHover};

    svg path {
      stroke-width: ${({ theme }) => theme.vars.sizeXS};
    }
  }

  .search-close-icon {
    font-size: ${({ theme }) => theme.vars.sizeXL};
  }

  @media ${({ theme }) => theme.devices.large} {
    width: ${({ $compactHeader }) => $compactHeader ? `auto` : `17.6rem`};
    margin: 0 1.6rem 0 0.8rem;
    padding-top: 0;
    border-bottom: ${({ $compactHeader, $headerTheme }) =>
      $compactHeader ? `none` : `1px solid ${$headerTheme}`};
    padding-bottom: 0;
    visibility: visible;

    .search-icon {
      font-size: ${({ theme }) => theme.vars.sizeM};
      max-width: 2rem;
    }
  }
`;
