'use client'

/* eslint-disable no-console */
import React, { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Maybe } from "../../types/middleware-types";
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import WistiaVideoStructure from './WistiaVideoStructure';

interface WistiaVideoProps {
  videoIdDesktop: string;
  videoIdMobile?: string;
  videoThumbnailUrlDesktop?: Maybe<string>;
  videoThumbnailUrlMobile?: Maybe<string>;
  autoPlay?: boolean;
  muted?: boolean;
  controlsVisibleOnLoad?: boolean;
  endVideoBehaviour?: string;
  label?: string;
}

const WistiaVideo: FC<WistiaVideoProps> = ({
  videoIdDesktop,
  videoIdMobile,
  videoThumbnailUrlDesktop,
  videoThumbnailUrlMobile,
  autoPlay = true,
  muted = true,
  controlsVisibleOnLoad = true,
  endVideoBehaviour = 'loop',
  label,
}) => {
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const videoIdToggled = videoIdMobile && isDesktop === false ? videoIdMobile : videoIdDesktop;
  const thumbnailUrl = videoThumbnailUrlMobile && isDesktop === false ? videoThumbnailUrlMobile : videoThumbnailUrlDesktop;
  
  useEffect(() => {
    // Adds the aria-label to the text content of the button
    const buttons = document.getElementsByClassName('w-vulcan-v2-button');
    Array.from(buttons).forEach((button) => {
      const ariaLabel = button.getAttribute('aria-label');
      // eslint-disable-next-line no-param-reassign
      button.textContent = ariaLabel;
    });
  }, []);

  const componentProps = {
    id: videoIdToggled,
    thumbnailUrl,
    autoPlay,
    muted,
    controlsVisibleOnLoad,
    endVideoBehaviour,
    label,
  };

  return <>{isDesktop !== undefined && <WistiaVideoStructure {...componentProps} />}</>;
};

export default WistiaVideo;
