import CarvelaConstants from './carvela/constants';
import KgConstants from './kg/constants';
import SahConstants from './sah/constants';
import { ConstantsState } from '../shared/types/state-types';
import getSite from '../shared/utils/customHooks/useSiteChecker';

interface SiteConstants {
  [key: string]: ConstantsState;
}

const Constants: SiteConstants = {
  carvela: CarvelaConstants,
  kg: KgConstants,
  sah: SahConstants,
};

const site = getSite();

const constants = site ? Constants[site] : SahConstants;

export default constants;
