import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuth, updateAuthStatus } from '../../features/auth';
import { selectIsCompactHeader } from '../../features/static';
import addToDataLayer from '../../utils/DataLayer/addToDataLayer';
import AccountStructure from './AccountStructure';

interface AccountProps {
  mobileHeader?: boolean;
}

const Account: FC<AccountProps> = ({ mobileHeader }): ReactElement => {
  const authDispatch = useDispatch();
  const isAuth = useSelector(selectAuth);
  const [isHovered, setIsHovered] = useState(false);

  const compactHeaderSelected = useSelector(selectIsCompactHeader);

  const handleLogout = () => {
    // We can't use Router.push as it doesn't work in M2's wrapper.
    window.location.replace('/customer/account/logout');
    authDispatch(updateAuthStatus(false));
    addToDataLayer({
      event: 'logout',
      eventCategory: 'login|register|newsletter',
      eventAction: 'logout successful',
      eventLabel: 'logout',
    });
  };

  // These are hard coded - not from the CMS
  const accountLinks = [
    { title: 'Account Overview', href: '/customer/account' },
    { title: 'My Orders', href: '/sales/order/history' },
  ];

  useEffect(() => {
    addToDataLayer({
      'Logged In Status': isAuth,
    });
  }, [isAuth]);

  const componentProps = {
    isAuth,
    accountLinks,
    isHovered,
    setIsHovered,
    handleLogout,
    compactHeaderSelected,
    mobileHeader,
  };
  return <AccountStructure {...componentProps} />;
};

export default Account;
