import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import Cookie from 'universal-cookie';

import { selectShowSiteSwitcher, updateIsSiteSwitcherVisible, updateShowSiteSwitcher } from '../../features/nav';
import { GET_SITE_SWITCHER_DATA } from '../../graphQl/queries/siteSwitcher';
import { SiteSwitcher as ISiteSwitcher } from '../../types/middleware-types';
import SiteSwitcherStructure from './SiteSwitcherStructure';

export interface SiteSwitcherProps {
  domain: string;
}

const SiteSwitcher: FC<SiteSwitcherProps> = ({
  domain,
}) => {
  const showSiteSwitcher = useSelector(selectShowSiteSwitcher);
  const redirectDomains = ['www.kurtgeiger.com.au'];
  const dispatch = useDispatch();
  const SITE_SWITCHER_COOKIE = 'site_switcher';
  const cookie = new Cookie();

  const [getSiteSwitcherCmsData, { data }] =
    useLazyQuery<{ siteSwitcher: ISiteSwitcher }>(GET_SITE_SWITCHER_DATA);

  const hideSiteSwitcher = () => {
    cookie.set(SITE_SWITCHER_COOKIE, 1);
    dispatch(updateShowSiteSwitcher(false));
    dispatch(updateIsSiteSwitcherVisible(false));
  };

  const checkCorrectUrl = (redirect: string) => {
    if (redirect && window.location.href.includes(redirect)) {
      hideSiteSwitcher();
    } else {
      if (redirectDomains.indexOf(redirect) !== -1) {
        window.location.replace(`${redirect}`);
      }
      dispatch(updateShowSiteSwitcher(true));
    }
  };

  const handleRedirect = (url: string) => {
    window.location.replace(`${url}`);
  };

  useEffect(() => {
    if (domain) {
      getSiteSwitcherCmsData({ variables: { domain } });
    }
  }, [domain]);

  useEffect(() => {
    if (cookie.get(SITE_SWITCHER_COOKIE)) {
      dispatch(updateShowSiteSwitcher(false));
      dispatch(updateIsSiteSwitcherVisible(false));
    } else if (data?.siteSwitcher?.redirect?.url) {
      checkCorrectUrl(data?.siteSwitcher?.redirect?.url);
    }
  }, []);

  const componentProps = {
    showSiteSwitcher,
    hideSiteSwitcher,
    data: data?.siteSwitcher,
    handleRedirect,
  };

  return <>{data && <SiteSwitcherStructure {...componentProps} />}</>;
};

export default SiteSwitcher;
