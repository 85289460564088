import React, { FC, ReactElement } from 'react';

import SignUpPopupStructure from './SignUpPopupStructure';

interface SignUpPopupProps {
  linkTrigger: string | undefined;
  closeButtonClicked: () => void;
  signUpButtonClicked: () => void;
  showSignUpPopup: boolean;
}

const SignUpPopup: FC<SignUpPopupProps> = (props): ReactElement => {
  return <SignUpPopupStructure {...props} />;
};

export default SignUpPopup;
