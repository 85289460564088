import { ApolloLink } from '@apollo/client';

export type PreviewModeSettings = {
  cmsPreviewSiteHandle: string | string[];
  cmsPreviewToken: string | string[];
  cmsPreviewPageId: string | string[];
};

export const previewModeLink = ({ cmsPreviewSiteHandle, cmsPreviewToken, cmsPreviewPageId }: PreviewModeSettings) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'cmsPreviewSiteHandle': cmsPreviewSiteHandle,
        'cmsPreviewToken': cmsPreviewToken,
        'cmsPreviewPageId': cmsPreviewPageId,
      }
    }));

    return forward(operation);
  });
