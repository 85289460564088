import { get, set } from 'local-storage';

interface PreviousProductPosition {
  position: string;
  location: string;
  pageUrl: string;
  lineNum: string;
}

// For 'back to plp' line number persisting.
const removeItemLineNumberFromLocalStorage = (): void => {
  const previousInfo = get('previous_product_position') as PreviousProductPosition;
  if (previousInfo) {
    set('previous_product_position', { ...previousInfo, lineNum: '' });
  }
};

export default removeItemLineNumberFromLocalStorage;
