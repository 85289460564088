/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { PlpFilterState } from '../../types/state-types';
import {
  setPLpApplyFilterEnabled,
  setPLpCurrentSelectedFilters,
  setPLpFilterMenuOpenMobile,
  setSizeQueryData,
  updatePlpActiveFilters,
  updatePlpCurentMobileFilters,
  updatePlpCurrentFilter,
  updatePLpCurrentSelectedFilters,
  updatePlpCurrentSortBy,
  updatePlpFilterOptionsData,
  updatePlpSlideFilters,
} from './actions';

export const initialState: PlpFilterState = {
  currentSizeQueryData: null,
  productListFilterOptions: null,
  currentFilter: null,
  currentMobileOptions: null,
  currentActiveFilters: null,
  currentSortBy: null,
  slideFilters: false,
  currentSelectedFilters: {},
};

export const plpFilterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updatePlpFilterOptionsData, (state, action) => {
      state.productListFilterOptions = action.payload;
    })
    .addCase(updatePlpCurrentFilter, (state, action) => {
      state.currentFilter = action.payload;
    })
    .addCase(updatePlpCurentMobileFilters, (state, action) => {
      state.currentMobileOptions = action.payload;
    })
    .addCase(updatePlpActiveFilters, (state, action) => {
      state.currentActiveFilters = action.payload;
    })
    .addCase(updatePlpCurrentSortBy, (state, action) => {
      state.currentSortBy = action.payload;
    })
    .addCase(updatePlpSlideFilters, (state, action) => {
      state.slideFilters = action.payload;
    })
    .addCase(updatePLpCurrentSelectedFilters, (state, action) => {
      state.currentSelectedFilters = { ...state.currentSelectedFilters, ...action.payload };
    })
    .addCase(setPLpCurrentSelectedFilters, (state, action) => {
      state.currentSelectedFilters = action.payload;
    })
    .addCase(setPLpApplyFilterEnabled, (state, action) => {
      state.applyFilterEnabled = action.payload;
    })
    .addCase(setPLpFilterMenuOpenMobile, (state, action) => {
      state.filterMenuOpenMobile = action.payload;
    })
    .addCase(setSizeQueryData, (state, action) => {
      state.currentSizeQueryData = action.payload;
    });
});
