import styled from 'styled-components';

import { StyledLink } from '../../../../basic/LinkItem/LinkItemStyles';
import { StyledFooterLinkList } from '../../../AccordionItemLayout/AccordionItemFooter/AccordionItemStyles';

interface Props {
  $compactHeader: boolean;
}

export const StyledLinkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.vars.sizeS};

  @media ${({ theme }) => theme.devices.smallOnly} {
    margin-bottom: 0;
  }
  ${StyledFooterLinkList} {
    margin: 0;

    li {
      margin: 0.6rem 0;

      a {
        padding: 1rem 1rem 1rem 0;
      }
    }
  }

  h3 {
    margin-bottom: ${({ theme }) => theme.vars.sizeXS};
    color: ${({ theme }) => theme.vars.black};

    @media ${({ theme }) => theme.devices.smallOnly} {
      margin-left: 0.7rem;
    }
  }
`;

export const StyledLinkList = styled.ul<Props>`
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;

  li,
  a {
    list-style: none;
    margin: ${({ $compactHeader }) => ($compactHeader ? '0' : '1rem 1.6rem 0.1rem 0')};
    ${StyledLink} {
      ${({ theme }) => theme.types.linkStyles};
      line-height: 1.4rem;
      font-weight: ${({ $compactHeader }) => ($compactHeader ? '100' : '400')};
      text-transform: capitalize;
      color: ${({ theme }) => theme.vars.black};
    }
  }
`;
