import { createAction } from '@reduxjs/toolkit';

export const updateShowMyBag = createAction<boolean>('nav/updateShowMyBag');
export const updateMeganavOpen = createAction<boolean>('nav/updateMeganavOpen');
export const updateHideNav = createAction<boolean>('nav/updateHideNav');
export const updateMediaBannerColourTheme = createAction<string|null>('nav/updateMediaBannerColourTheme');
export const updateIsSiteSwitcherVisible = createAction<boolean>('nav/updateIsSiteSwitcherVisible');
export const updateShowMobileCountryPicker = createAction<boolean>('nav/updateShowMobileCountryPicker');
export const updateSearchOpen = createAction<boolean>('nav/updateSearchOpen');
export const updateShowSiteSwitcher = createAction<boolean>('nav/updateShowSiteSwitcher');
export const updateSubmenuCompactPosition= createAction<number>('nav/updateSubmenuCompactPosition');
export const updateIsAtTop = createAction<boolean>('nav/updateIsAtTop');
