import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsSmartAppBannerClosed } from '../../features/device';
import { HeaderLayout } from '../../types/state-types';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import TransparentHeader from './TransparentHeader';
import TransparentHeaderCompact from './TransparentHeaderCompact';
import TransparentHeaderCompactReverse from './TransparentHeaderCompactReverse';

interface HeaderProps {
  layout: HeaderLayout;
}

const Header: FC<HeaderProps> = ({ layout }) => {
  const LAYOUTS = {
    TransparentHeader,
    TransparentHeaderCompact,
    TransparentHeaderCompactReverse
  };

  const Component = LAYOUTS[layout] || null;
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const noSmartAppBanner = useSelector(selectIsSmartAppBannerClosed);

  const [showDesktopLayout, setShowDesktopLayout] = useState<boolean>(!!isDesktop);

  useEffect(() => {
    setShowDesktopLayout(!!isDesktop);
  }, [isDesktop]);

  return <>{Component && <Component showDesktopLayout={showDesktopLayout} hasSmartAppBanner={!noSmartAppBanner} />}</>;
};

export default Header;
