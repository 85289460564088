import React, { FC } from 'react';
import Head from 'next/head';
import Script from 'next/script';

interface GlobalEStructureProps {
  domain: string;
  strategy: 'afterInteractive' | 'beforeInteractive';
  useToggledCode: boolean
}

const GlobalEStructure: FC<GlobalEStructureProps> = ({ domain, strategy, useToggledCode }) => {

  if (useToggledCode) {
    return (
      <>
        <Head>
          <link id="GEPIStyles" rel="stylesheet" href={`${domain}/includes/css/${process.env.NEXT_PUBLIC_GLOBAL_E_ID}`} />
        </Head>
        <Script src={`${domain}/includes/js/${process.env.NEXT_PUBLIC_GLOBAL_E_ID}`} async type="text/javascript" strategy={strategy} />
      </>
    );
  }

  return (
    <>
      <Head>
        <link id="GEPIStyles" rel="stylesheet" href={`${domain}/includes/css/1019`} />
      </Head>
      <Script src={`${domain}/includes/js/1019`} async type="text/javascript" strategy={strategy} />
    </>
  );
};

export default GlobalEStructure;
