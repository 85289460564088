import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';

import StyledFormLabel from '../FormLabel/FormLabelStyles';
import { StyledRadioButton } from './RadioButtonStyles';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  className?: string;
  isDefault?: boolean;
  selected?: boolean;
  engs15241: boolean
}

const RadioButtonStructure: FC<RadioButtonProps> = ({
  label,
  value,
  name,
  onChange,
  id,
  className,
  isDefault,
  selected,
  engs15241,
  ...otherProps
}) => (
  <>
    {engs15241 ? (
      <StyledRadioButton
        type="radio"
        className={['radio-input', className].join(' ')}
        onChange={onChange}
        name={name}
        id={id}
        defaultChecked={isDefault}
        value={value}
        checked={selected}
        {...otherProps}
      />
    ) : (
      <StyledRadioButton
        type="radio"
        className={['radio-input', className].join(' ')}
        onChange={onChange}
        name={name}
        id={id}
        defaultChecked={isDefault}
        value={value}
        {...otherProps}
      />
    )}
    <StyledFormLabel htmlFor={id}>{label}</StyledFormLabel>
  </>
);

export default RadioButtonStructure;
