import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { StyledCountryPickerInner } from '../../CountryPicker/CountryPickerStyles';

interface StyleProps {
  $finalTopPositionMob: number,
  $headerHeight: number,
  $isMenuOpen: boolean;
  $headerOffset: number;
  $bannerSpace: number;
  $engs13232ScrollIssue: boolean;
}

export const StyledMobileMenuContainer = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledMobileMenuTrigger = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  height: 2.4rem;
  min-width: 2.1rem;
  outline: none;
  margin-right: 0.8rem;
`;

export const StyledMobileNavigationContainer = styled.div<StyleProps>`
  z-index: 20;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ $bannerSpace, $engs13232ScrollIssue }) => $engs13232ScrollIssue ? `calc(100dvh - ${$bannerSpace}px)` : `100vh`};
  width: 100%;
  left: 0;
  overflow: scroll;
  background-color: ${({ theme }) => theme.headerColors.backgroundColor};
  transform: ${({ $isMenuOpen }) => ($isMenuOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 600ms ease-in;
`;

export const StyledCountryPickerMobile = styled.div`
  ${StyledCountryPickerInner} {
    display: block !important;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
`;

export const StyledLinksContainer = styled.div`
  display: flex;
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 1rem 0;

    button {
      width: 100%;
      position: relative;
      text-align: left;
      list-style: none;
      padding: 2rem 1.4rem 2rem 2rem;
      cursor: pointer;
      display: inline-block;
      background: transparent;
      color: ${({ theme }) => theme.vars.black};
      text-transform: uppercase;
      font-size: 1.4rem;
      font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};

      &.active {
        background: ${({ theme }) => theme.vars.white};

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 0.1rem;
          background: ${({ theme }) => theme.vars.black};
          margin-top: 0.3rem;
        }
      }

      a {
        display: inline-block;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        font-weight: 400;
      }
    }
  }
`;

export const StyledArrowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 2.4rem;
  top: 32%;
  width: 2.4rem !important;
  height: 2.4rem;
`;

export const StyledSubMenuContainer = styled.div`
  display: block;
  background: ${({ theme }) => theme.headerColors.backgroundColor};
`;

export const StyledUtilLinks = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  list-style: none;
  box-sizing: border-box;
  min-height: 50vh;
  background: ${({ theme }) => theme.vars.greyLight};
  padding: 1rem 0 0 2rem;

  /* TODO: Temp fix to remove extra space globale to update */
  > li[data-ge-shippingswitcher-flag] {
    margin: 0 !important;
  }

  > li {
    text-align: left;
    margin: 2rem 0;

    > a {
      font-size: 1.4rem;
    }
  }
`;

export const StyledCountryFlag = styled.div`
  display: flex;

  img {
    height: ${({ theme }) => theme.vars.sizeM};
    width: ${({ theme }) => theme.vars.sizeL};
  }
`;

export const StyledStoresSection = styled.div`
  svg {
    width: 2.4rem;
  }
`;
