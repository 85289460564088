import gql from 'graphql-tag';

export const SIGNUP_FOR_NEWSLETTER = gql`
  mutation signupForNewsletter($input: NewsletterFormInput!) {
    newsletterFormSubmission(input: $input) {
      ... on WelcomeProgrammeSuccessResponse {
        success
      }
      ... on WelcomeProgrammeErrorResponse {
        status
        statusText
      }
    }
  }
`;
