'use client';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectMetadata } from '../../features/metadata';
import { selectIsAtTop } from '../../features/nav';
import { selectToggles } from '../../features/static';
import useHeaderTheme from '../../utils/customHooks/useHeaderTheme';
import usePageName from '../../utils/customHooks/usePageName';
import useSiteChecker from '../../utils/customHooks/useSiteChecker';
import parseCMSMediaProp from '../../utils/formatters/parseCMSMediaProp';
import BrandLogoStructure from './BrandLogoStructure';

const BrandLogo: FC<{ isHomePage?: boolean }> = ({ isHomePage }) => {
  const site = useSiteChecker();
  const isKG = site === 'kg'
  const { brandLogo, headerTheme } = useHeaderTheme();
  const toggles = useSelector(selectToggles);
  const { featureKgRedesign, engs14345ReactRoutingATagsToLinks } = toggles;
  const isAtTop = useSelector(selectIsAtTop);
  const standardWidth = isKG ? '260px' : '100%';
  const { description } = useSelector(selectMetadata);
  const pageName = usePageName()

  const brandLogoData = {
    alt: brandLogo?.alt || '',
    mediaList: {
      desktop: brandLogo?.desktop,
      mobile: brandLogo?.mobile,
      tablet: brandLogo?.tablet,
    },
    defaultImageUrl: (brandLogo?.desktop || brandLogo?.tablet || brandLogo?.mobile)?.src,
    type: 'next-image',
  };

  const componentProps = {
    logo: pageName.pageName === '404Page' ? brandLogoData : parseCMSMediaProp(brandLogo),
    isHomePage,
    isAtTop,
    featureKgRedesign,
    headerTheme: headerTheme === '#333333' && isKG ? '#000' : headerTheme,
    standardWidth,
    description: description as string,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return <>{brandLogoData && <BrandLogoStructure {...componentProps} />}</>;
};

export default BrandLogo;