import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectMediaBannerColourTheme, selectMeganavOpen, updateIsAtTop } from '../../../features/nav';
import {
  selectHeaderLogo,
  selectHeaderTheme,
  selectIsCompactHeader,
  selectPageName,
  selectToggles
} from '../../../features/static';
import { ImageV2, Logo, Maybe } from '../../../types/middleware-types';
import useMobileWatcher from '../useMobileWatcher';

interface HeaderThemeReturn {
  headerTheme: string;
  headerBackground: string;
  headerPosition: string;
  brandLogo?: ImageV2;
  isScrolling: boolean;
  scrollDirection: number;
}

/**
 * get colour from CMS | media banner | default one
 * @param reduxSiteData
 * @param themeOptions
 * @param mediaBannerColourTheme
 * @param defaultColour
 * @returns colour
 */
const getHeaderColour = (
  headerThemeData: string,
  themeOptions: {
    [key: string]: string;
  },
  mediaBannerColourTheme: Maybe<string>,
  defaultColour: string,
): string => {
  if (headerThemeData) {
    return themeOptions[headerThemeData?.toLowerCase()] || defaultColour;
  }
  if (mediaBannerColourTheme) {
    return themeOptions[mediaBannerColourTheme];
  }
  return defaultColour;
};

const useHeaderTheme = (): HeaderThemeReturn => {
  const globalTheme = useTheme();
  const toggles = useSelector(selectToggles);
  const routingToggle = toggles?.engs13825ReactRouting;
  const pageName = useSelector(selectPageName);
  const { isDesktop } = useMobileWatcher(['desktop'], globalTheme.vars);
  const compactHeader = useSelector(selectIsCompactHeader);
  const headerThemeData = useSelector(selectHeaderTheme);
  const headerLogo = useSelector(selectHeaderLogo);
  const logoData = headerLogo;
  const megaNavOpen = useSelector(selectMeganavOpen);
  const mediaBannerColourTheme = useSelector(selectMediaBannerColourTheme);
  const light = globalTheme.vars.white.toLowerCase();
  const dark = globalTheme.vars.black.toLowerCase();
  const [logo, setLogo] = useState<Maybe<Logo> | undefined>(logoData);
  const [headerBackground, setHeaderBackground] = useState('transparent');
  const [isScrolling, setIsScrolling] = useState(false);
  const [headerPosition, setHeaderPosition] = useState('absolute');
  const pageYOffset = useRef(0);
  const [scrollDirection, setScrollDirection] = useState(0);
  const TOP_OFFSET = 50;
  const dispatch = useDispatch();

  const isHomepage = pageName === 'HomePage';
  const updateSAHheader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu
  const forceCompactHeader = isHomepage && updateSAHheader;

  const themeOptions: { [key: string]: string } = {
    light,
    dark,
  };

  const [headerColour, setHeaderColour] = useState<string>(getHeaderColour(headerThemeData, themeOptions, mediaBannerColourTheme, dark));
  const [headerTheme, setHeaderTheme] = useState(headerColour);
  const [brandLogo, setBrandLogo] = useState<ImageV2 | undefined>(
    headerTheme === light ? logoData?.light : logoData?.dark,
  );

  // Use header colour from CMS, media banner or use the default one
  useEffect(() => {
    const newHeaderColour = getHeaderColour(
      headerThemeData,
      themeOptions,
      mediaBannerColourTheme,
      dark,
    );
    setHeaderColour(newHeaderColour);
  }, [headerThemeData, mediaBannerColourTheme]);

  // Set header theme for regular header
  useEffect(() => {
    if (!compactHeader) {
      setHeaderTheme(isScrolling ? dark : headerColour);
      setHeaderPosition(isScrolling ? 'fixed' : 'absolute');
      setHeaderBackground(isScrolling ? light : 'transparent');
    }
  }, [headerColour, isScrolling, compactHeader]);

  // Set header theme for compact header
  useEffect(() => {
    if (updateSAHheader && window.scrollY <= TOP_OFFSET) {
      if (megaNavOpen) {
        setHeaderTheme(megaNavOpen ? dark : headerColour);
      } else {
        setHeaderTheme(megaNavOpen ? light : headerColour);
      }
    }

    if (compactHeader || forceCompactHeader) {
      // Fix for Promobanner and Header having different positioning.
      setHeaderPosition(isScrolling ? 'fixed' : 'absolute');

      if (toggles?.featureStickyHeader && isDesktop && scrollDirection === 0 && window.scrollY === 0 && toggles?.featureKgRedesign) {
        dispatch(updateIsAtTop(true))
      }
      else if (toggles?.featureStickyHeader && (isDesktop && scrollDirection > 0 && window.scrollY > TOP_OFFSET || !isDesktop && isScrolling)) {
        setHeaderPosition('fixed');
        dispatch(updateIsAtTop(false))
        setHeaderTheme(dark);
        setHeaderBackground(light);
      }
      else if (toggles?.featureStickyHeader && (isDesktop && scrollDirection > 0 && window.scrollY <= TOP_OFFSET || !isDesktop && isScrolling)) {
        dispatch(updateIsAtTop(true))
        setHeaderTheme(megaNavOpen ? dark : headerColour);
        setHeaderBackground(megaNavOpen ? light : 'transparent');
      }
      else {
        dispatch(updateIsAtTop(false))
        setHeaderPosition('absolute');
        setHeaderTheme(megaNavOpen ? dark : headerColour);
        setHeaderBackground(megaNavOpen ? light : 'transparent');
      }
    }
  }, [megaNavOpen, compactHeader, headerColour, scrollDirection, isDesktop, isScrolling]);

  useEffect(() => {
    if (!logo && logoData) {
      setLogo(logoData);
    }
  }, [logo, logoData]);

  useEffect(() => {
    const handleNavigation = () => {
      const scrollDir = window.scrollY > TOP_OFFSET ? -1 : 0;
      setIsScrolling(window.scrollY > TOP_OFFSET);
      if (toggles?.featureStickyHeader) {
        setScrollDirection(pageYOffset.current - window.scrollY > 0 ? 1 : scrollDir);
      }
      pageYOffset.current = window.scrollY;
    }
    pageYOffset.current = window.scrollY;
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, []);

  useEffect(() => {
    if (routingToggle) {
      setHeaderTheme(headerColour);
    }
  }, [headerColour])

  // Decide upon logo based on theme
  useEffect(() => {
    if (headerTheme === light) {
      setBrandLogo(logo?.light);
    }
    else if (headerTheme === dark || isScrolling) {
      setBrandLogo(logo?.dark);
    }
  }, [headerTheme, logo, isScrolling]);

  return {
    headerTheme,
    headerBackground,
    headerPosition,
    brandLogo,
    isScrolling,
    scrollDirection,
  };
};

export default useHeaderTheme;
