import { createAction } from '@reduxjs/toolkit';

import { Maybe, NearbyStore, Product, Store } from '../../types/middleware-types';
import { NearbyStoresDataState } from '../../types/state-types';

export const updateCurrentFisProduct = createAction<Maybe<Product>>(
  'findInStore/updateCurrentFisProduct',
);
export const updateNearbyStoresData = createAction<NearbyStoresDataState>(
  'findInStore/updateNearbyStoresData',
);
export const updateFisSelectedSizeData = createAction<Maybe<string>>(
  'findInStore/updateFisSelectedSizeData',
);
export const updateSelectedStore = createAction<Maybe<NearbyStore | Store>>(
  'findInStore/updateSelectedStore',
);
