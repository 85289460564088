import { createAction } from '@reduxjs/toolkit';

import { FormattedFilters, InitialFilterDataInterface } from '../../types/custom-types';
import { CategoryActiveFilter, CategoryBanner, CategoryFilter, CategorySortByOption, ContentBlockMediaBanner, Maybe } from '../../types/middleware-types';
import { OpenFilterOptions, PlpDataWithoutItems } from '../../types/state-types';

export const setPlpDataWithoutItems = createAction<Maybe<PlpDataWithoutItems>>(
  'filters/setPlpDataWithoutItems',
);

export const setActiveCategoriesData = createAction<Array<CategoryActiveFilter>>(
  'filters/setActiveCategoriesData',
);

export const setCategoriesData = createAction<Array<CategoryFilter>>(
  'filters/setCategoriesData',
);

export const setSelectedCategory = createAction<string>(
  'filters/setSelectedCategory',
);

export const setSelectedPrice = createAction<string>(
  'filters/setSelectedPrice',
);

export const setSelectedGender = createAction<string>(
  'filters/setSelectedGender',
);

export const setCurrentPageView = createAction<string>(
  'filters/setCurrentPageView',
);

export const setSortByData = createAction<Maybe<Array<CategorySortByOption>>>(
  'filters/setSortByData',
);

export const setCurrentSortBy = createAction<string>(
  'filters/setCurrentSortBy',
);

export const setCurrentPendingFilters = createAction<Array<CategoryFilter>>(
  'filters/setCurrentPendingFilters',
);


export const setOpenFilterDropdown = createAction<OpenFilterOptions>(
  'filters/setOpenFilterDropdown',
)

export const setFormattedFilters = createAction<FormattedFilters>(
  'filters/setFormattedFilters',
)
export const setInitialFilterData = createAction<InitialFilterDataInterface>(
  'filters/setInitialFilterData',
)

export const setBrandedPlpData = createAction<CategoryBanner | ContentBlockMediaBanner | null>(
  'filters/setBrandedPlpData',
)

export const updateMobFiltersOpen = createAction<boolean>(
  'filters/updateMobFiltersOpen',
)

export const updateCloseAllFilterPopups = createAction<boolean>(
  'filters/updateCloseAllFilterPopups',
)
