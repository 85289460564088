import React, { FC, Ref } from 'react';

import { Maybe, PromoBannerItem as PromoBannerItemType } from '../../types/middleware-types';
import Carousel from '../Carousel';
import { SliderSettings } from '../Slider/Slider.types';
import PromoBannerItem from './PromoBannerItem';
import { StyledPromoBannerContainer, StyledPromoBannerListContainer } from './PromoBannerStyles';

interface PromoBannerStructureProps {
  desktopItems: Array<PromoBannerItemType>;
  mobileItems: Array<PromoBannerItemType>;
  height?: Maybe<number>;
  settings: SliderSettings;
  isDesktop: boolean;
  getElement: Ref<HTMLDivElement>;
  hasSeparator?: Maybe<boolean>;
  compactHeader: boolean;
  headerPosition: string;
  top: number;
  stickyCompactHeader:boolean;
  isReverse: boolean;
}

const PromoBannerStructure: FC<PromoBannerStructureProps> = ({
  desktopItems,
  mobileItems,
  height,
  settings,
  isDesktop,
  getElement,
  hasSeparator,
  compactHeader,
  headerPosition,
  top,
  stickyCompactHeader,
  isReverse
}) => (
  <>
    {(desktopItems?.length > 0 || mobileItems?.length > 0) && (
      <StyledPromoBannerContainer
        $height={height}
        id="promo-banner"
        data-hookid="globalPromoBanner"
        ref={getElement}
        $stickyCompactHeader={stickyCompactHeader}
        $compactHeader={compactHeader}
        $headerPosition={headerPosition}
        $top={top}
        $isReverse={isReverse}
      >
        {!isDesktop ? (
          <Carousel
            component={PromoBannerItem}
            items={mobileItems}
            settings={settings}
            showArrows={false}
            otherProps={{ height, isDesktop }}
            idValue="promo-banner-inner"
          />
        ) : (
          <StyledPromoBannerListContainer $height={height} id="promo-banner-inner">
            {desktopItems?.map((item, index) => (
              <PromoBannerItem
                // eslint-disable-next-line react/no-array-index-key
                key={`promoBannerItem${index}`}
                {...item}
                height={height}
                index={index}
                hasSeparator={hasSeparator && index + 1 !== desktopItems.length}
              />
            ))}
          </StyledPromoBannerListContainer>
        )}
      </StyledPromoBannerContainer>
    )}
  </>
);

export default PromoBannerStructure;