import gql from 'graphql-tag';

export const WELCOME_POPUP_SUBMISSION = gql`
  mutation welcomePopupSubmission($input: WelcomeProgrammeFormInput!) {
    welcomePopupFormSubmission(input: $input) {
      ... on WelcomeProgrammeSuccessResponse {
        success
      }
      ... on WelcomeProgrammeErrorResponse {
        status
        statusText
        message
      }
    }
  }
`;
