import React, { FC, ReactElement } from 'react';

const UserIcon: FC = (): ReactElement => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7566 12.2666C7.52246 12.2666 4.08887 15.6988 4.08887 19.9333H19.4222C19.4222 15.6988 15.9908 12.2666 11.7566 12.2666Z"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M11.7556 11.7557C14.2961 11.7557 16.3556 9.69617 16.3556 7.15566C16.3556 4.61515 14.2961 2.55566 11.7556 2.55566C9.21507 2.55566 7.15558 4.61515 7.15558 7.15566C7.15558 9.69617 9.21507 11.7557 11.7556 11.7557Z"
      stroke="black"
      strokeMiterlimit="10"
    />
  </svg>
);

export default UserIcon;
