/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { DataCaptureState } from '../../types/state-types';
import {
  updateHasFilledForm,
} from './actions';

export const initialState: DataCaptureState = {
  hasFilledForm: false,
};

export const dataCaptureReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateHasFilledForm, (state, action) => {
      state.hasFilledForm = action.payload;
    });
});
