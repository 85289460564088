import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { selectAuth, updateAuthStatus } from '../../../features/auth';

interface UseAuthReturnType {
  checkCookie: () => void;
  authToken: string;
}

const useAuth = (): UseAuthReturnType => {
  const authDispatch = useDispatch();
  const isAuth = useSelector(selectAuth);
  const [authToken, setAuthToken] = useState('');

  const OAUTH_COOKIE = '__Host-customer_token';

  const checkCookie = () => {
    const cookies = new Cookies();
    const authCookie = cookies.get(OAUTH_COOKIE);
    if (authCookie && authCookie !== '') {
      setAuthToken(authCookie);
      authDispatch(updateAuthStatus(true));
    } else {
      setAuthToken('');
    }
  };

  useEffect(() => {
    checkCookie();
  }, []);

  useEffect(() => {
    if (authToken) {
      if (!isAuth) {
        authDispatch(updateAuthStatus(true));
      }
    } else if (isAuth) {
      authDispatch(updateAuthStatus(false));
    }
  }, [authToken]);

  return {
    checkCookie,
    authToken: isAuth ? authToken : '',
  };
};

export default useAuth;
