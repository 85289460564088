import { Variables as CarvelaVariables } from  './carvela/styles/resources/resources';
import { Variables as KgVariables } from  './kg/styles/resources/resources';
import { Variables as SahVariables } from  './sah/styles/resources/resources';
import getSite from '../shared/utils/customHooks/useSiteChecker';

const Variables = {
  carvela: CarvelaVariables,
  kg: KgVariables,
  sah: SahVariables
};

const site = getSite();

const variables = Variables[site];

export default variables;
