'use client'

import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';

import { setIsSmartAppBannerClosed } from "../../features/device";
import { selectIsSmartAppBannerClosed } from "../../features/device/selectors";
import { selectToggles } from "../../features/static";
import SmartAppBannerStructure from "./SmartAppBannerStructure";

export const DEFAULT_SMART_BANNER_HEIGHT = 78;
export enum SmartAppBannerDevices {
  IOS = 'ios',
  ANDROID = 'android'
}

export type Theme = SmartAppBannerDevices.IOS | SmartAppBannerDevices.ANDROID

export interface SmartAppBannerProps {
  title: string
}

const SmartAppBanner: FC<SmartAppBannerProps> = ({
  title,
}) => {
  const toggles = useSelector(selectToggles);
  const [isIos, setIos] = useState(false);

  const theme: string = isIos ? SmartAppBannerDevices.IOS : SmartAppBannerDevices.ANDROID;

  const mixins = {
    ios: {
      appMeta: () => 'apple-itunes-app',
      iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon'],
      getStoreLink: () => `https://itunes.apple.com/gb/app/id${process.env.NEXT_PUBLIC_IOS_APP_ID}`,
      storeText: 'On the App Store',
      price: 'Free'
    },
    android: {
      appMeta: () => 'google-play-app',
      iconRels: [
        'android-touch-icon',
        'apple-touch-icon-precomposed',
        'apple-touch-icon',
      ],
      getStoreLink: () => `http://play.google.com/store/apps/details?id=${process.env.NEXT_PUBLIC_ANDROID_APP_ID}`,
      storeText: 'In Google Play',
      price: 'Free',
    },
  };

  const retrieveInfo = () => {
    const buttonLink = theme === SmartAppBannerDevices.IOS ? mixins.ios.getStoreLink() : mixins.android.getStoreLink();
    const description = theme === SmartAppBannerDevices.IOS ? mixins.ios.storeText : mixins.android.storeText;
    const price = theme === SmartAppBannerDevices.IOS ? mixins.ios.price : mixins.android.price;
    const buttonText = 'View';
    let icon;

    return {
      icon,
      buttonLink,
      description,
      price,
      buttonText,
    };
  }

  const { icon, buttonLink, description, price, buttonText } = retrieveInfo();

  const [finalSettings, setFinalSettings] = useState({
    icon,
    buttonLink,
    description,
    price,
    buttonText
  })

  useEffect(() => {
    const iOS = () => {
      return (
        typeof window !== 'undefined' &&
        (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          window.navigator.platform,
        ) ||
          // iPad on iOS 13 detection
          (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document))
      );
    };
    const isApple = iOS()
    setIos(isApple)
  }, [])

  const cookies = new Cookies();
  const bannerClosed = useSelector(selectIsSmartAppBannerClosed)
  const url = (theme === 'ios' ? `${process.env.NEXT_PUBLIC_S3_BUCKET}/deviceicons/iOS-180x180.png` : `${process.env.NEXT_PUBLIC_S3_BUCKET}/deviceicons/Android-192x192.png`)
  const daysHidden = 30;
  const dispatch = useDispatch();

  const expireDate = (additionalDays: number) => {
    const expiredDate = new Date();

    expiredDate.setDate(expiredDate.getDate() + additionalDays);

    return expiredDate;
  };


  const onClose = () => {
    cookies.set('smartbanner-closed', true, {
      path: '/',
      expires: expireDate(daysHidden),
    });
    dispatch(setIsSmartAppBannerClosed(true));
  };

  useEffect(() => {
    if (cookies.get('smartbanner-closed')) {
      dispatch(setIsSmartAppBannerClosed(true));
    }
  }, []);

  useEffect(() => {
    const { icon, buttonLink, description, price, buttonText } = retrieveInfo();
    setFinalSettings({
      icon,
      buttonLink,
      description,
      price,
      buttonText
    })
  }, [isIos])


  const componentProps = {
    src: finalSettings?.icon || url,
    title,
    theme,
    price: finalSettings?.price,
    description: finalSettings?.description,
    buttonLink: finalSettings?.buttonLink,
    buttonText: finalSettings?.buttonText,
    onClose,
    bannerClosed,
  };
  // @ts-ignore
  return <>{((toggles.featureSmartBannerIos && isIos) || (toggles.featureSmartBannerAndroid && !isIos)) && (<SmartAppBannerStructure {...componentProps} />)}</>
}

export default SmartAppBanner;