import styled from 'styled-components';

export const StyledSignUpPopupTrigger = styled.div<{ $showSignUpPopup: boolean }>`
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  width: 15rem;
  border: 1px solid;
  padding: 1rem;
  z-index: 100;
  text-transform: uppercase;
  box-sizing: border-box;
  background: ${({ theme }) => theme.vars.white};
  display: ${({ $showSignUpPopup }) => ($showSignUpPopup ? 'block' : 'none')};

  h3 {
    padding-right: 2rem;
  }
`;
