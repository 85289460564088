import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrencyData, selectIsCompactHeader, selectShowBrandName, selectToggles, selectTrendingHeaderData } from '../../../features/static';
import SearchResultsStructure from './TrendingSearchResultsStructure';

const TrendingSearchResults: FC = () => {
  const [elOffset, setElOffset] = useState(0);
  const currencyData = useSelector(selectCurrencyData);
  const trendingData = useSelector(selectTrendingHeaderData);


  const compactHeader = useSelector(selectIsCompactHeader);
  const toggles = useSelector(selectToggles);
  const showBrandName = useSelector(selectShowBrandName);

  useEffect(() => {
    // calc position within window
    const resultsContainer = document.getElementById('trending-search-results');
    if (resultsContainer) {
      setElOffset(resultsContainer.getBoundingClientRect().top);
    }
  }, []);

  const componentProps = {
    trendingListTitle: trendingData?.trendingMenu?.[0]?.title || 'Trending Searches',
    trendingProductTitle: trendingData?.trendingProducts?.[0]?.title || 'Trending Products',
    elOffset,
    listData: trendingData?.trendingMenu?.[0]?.links,
    trendingProductData: trendingData?.trendingProducts?.[0]?.products,
    compactHeader,
    showBrandName,
    currencySymbol: currencyData.symbol,
    toggles,
    useFormatted: toggles.featureKgRedesign,
  };

  return <SearchResultsStructure {...componentProps} />;
};

export default TrendingSearchResults;