import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { DEFAULT_PROMO_BANNER_HEIGHT } from '../../../components/PromoBanner';
import { DEFAULT_SMART_BANNER_HEIGHT } from '../../../components/SmartAppBanner';
import { selectIsSmartAppBannerClosed } from '../../../features/device';
import { selectShowSiteSwitcher } from '../../../features/nav';
import { selectPromoBanner, selectToggles } from '../../../features/static';
import useMobileWatcher from '../useMobileWatcher';

interface HeaderCompactPositionReturnType {
  smartBannerHeight: number;
  siteSwitcherHeight: number;
  headerHeight: number;
  headerTop: number;
  promoBannerHeight: number;
  promoBannerTop: number;
  totalHeaderHeight: number;
}

export const useHeaderCompactPosition = (): HeaderCompactPositionReturnType => {
  const theme = useTheme();
  const toggles = useSelector(selectToggles);
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const showSiteSwitcher = useSelector(selectShowSiteSwitcher);
  const noSmartAppBanner = useSelector(selectIsSmartAppBannerClosed);
  const promoBanner = useSelector(selectPromoBanner);

  // Hardcoded for SSR
  const headerHeightMobile = 43;
  const headerHeightDesktop = toggles?.featureKgRedesign ? 95 : 123;

  const smartBannerHeight = !noSmartAppBanner ? DEFAULT_SMART_BANNER_HEIGHT : 0
  const siteSwitcherHeight = showSiteSwitcher ? 200 : 0
  const promoBannerHeightMobile = promoBanner?.mobileHeightPixels || (promoBanner?.mobileItems && promoBanner?.mobileItems?.length > 0 ? DEFAULT_PROMO_BANNER_HEIGHT : 0);
  const promoBannerHeightDesktop = promoBanner?.desktopHeightPixels || (promoBanner?.desktopItems && promoBanner?.desktopItems?.length > 0 ? DEFAULT_PROMO_BANNER_HEIGHT : 0);

  const promoBannerTopMobile = siteSwitcherHeight + smartBannerHeight;
  const promoBannerTopDesktop = siteSwitcherHeight;
  const headerTopMobile = siteSwitcherHeight + smartBannerHeight + promoBannerHeightMobile;
  const headerTopDesktop = siteSwitcherHeight + promoBannerHeightDesktop;

  const totalHeaderHeightMobile = headerTopMobile + headerHeightMobile;
  const totalHeaderHeightDesktop = headerTopDesktop + headerHeightDesktop;

  return {
    smartBannerHeight,
    siteSwitcherHeight,
    headerHeight: isDesktop ? headerHeightDesktop : headerHeightMobile,
    headerTop: isDesktop ? headerTopDesktop : headerTopMobile,
    promoBannerHeight: isDesktop ? promoBannerHeightDesktop : promoBannerHeightMobile,
    promoBannerTop: isDesktop ? promoBannerTopDesktop : promoBannerTopMobile,
    totalHeaderHeight: isDesktop ? totalHeaderHeightDesktop : totalHeaderHeightMobile,
  }
};

