import styled from 'styled-components';

import Buttons from '../../../basic/Buttons';
import { StyledAmazonPayWrapper } from '../../AmazonPay/AmazonPayStyles';

interface DropdownProps {
  $showBag: boolean;
  $isScrolling: boolean;
  $topPosition: number;
  $compactHeader: boolean;
  $kgRedesignToggle: boolean;
  $isMobileOrTablet: boolean
}

export const StyledMinibagDropdownContainer = styled.div<DropdownProps>`
  background: ${({ theme }) => theme.vars.white};
  position: fixed;
  z-index: 3;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0 0.4rem 0.3rem 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  top: ${({ $isScrolling, $isMobileOrTablet, $topPosition, $compactHeader }) => {
  if ($isScrolling && !$compactHeader) {
    return '5rem';
  }
  if ($isMobileOrTablet) {
    return `${$topPosition}px`;
  }
  return '10rem';
}};
  left: 90%;
  transform: translateX(100%);
  transition: transform 800ms ease-in;
  ${({ $showBag }) =>
  $showBag &&
    `
    transform: translateX(-90%);
    transition: transform 800ms ease-in;
  `};

  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $showBag }) => ($showBag ? 'block' : 'none')};
    transform: none;
    top: ${({ $topPosition }) => `${$topPosition}px`};
    right: ${({ $compactHeader }) => ($compactHeader ? 0 : '24rem')};
    width: ${({ $kgRedesignToggle }) => ($kgRedesignToggle ? '35.8' : '30.4')}rem;
    left: auto;
  }
`;

export const StyledBasketTotals = styled.span`
  margin-left: 0.5rem;
`;

export const StyledBagInfo = styled.div`
  text-align: left;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.vars.black};

  .close {
    display: block;
    cursor: pointer;
  }

  h4 {
    margin: 0;

    span {
      text-transform: capitalize;
      ${({ theme }) => theme.types.h4Style};
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    .close {
      display: none;
    }
  }
`;

export const StyledActionsContainer = styled.div<{ $kgRedesignToggle: boolean }>`
  padding-top: 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.minibag.product.borderColor};

  .more-items {
    display: block;
    color: ${({ theme }) => theme.vars.black};
    margin-bottom: 1.5rem;
    font-size: ${({ theme, $kgRedesignToggle }) => $kgRedesignToggle ? '1.1rem' : theme.vars.sizeS};
    text-align: left;
    text-decoration: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? 'underline' : 'none'};
  }

  p.title {
    margin-right: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? '10rem' : '1rem'};
  }

  ${StyledAmazonPayWrapper} {
    margin: 1rem 0 0;
    width: 100% !important;
  }
`;

export const StyledDeliveryInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.vars.black};

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.vars.sizeS};
    line-height: 2rem;
  }

  .title {
    ${({ theme }) => theme.types.h4Style};
    line-height: 2rem;
    margin-right: 1rem;
  }
`;

export const StyledButtonsContainer = styled.div<{ $isEngs13172?: boolean }>`
  display: flex;
  margin-top: ${({ theme }) => theme.vars.sizeL};
  justify-content: space-between;
  z-index: 12;

  @media ${({ theme }) => theme.devices.smallOnly} {
    justify-content: center;

    a {
      &:first-of-type {
        width: ${({ $isEngs13172 }) => !$isEngs13172 && '40%'};
        margin-right: ${({ theme, $isEngs13172 }) => !$isEngs13172 && theme.vars.sizeXS};
      }

      &:last-of-type {
        width: ${({ $isEngs13172 }) => !$isEngs13172 && '55%'};
        margin-left: ${({ theme, $isEngs13172 }) => !$isEngs13172 && theme.vars.sizeXS};
      }
    }
  }

  button {
    font-size: ${({ theme }) => theme.vars.sizeS};
    padding: 0 1rem;
  }
`;

export const BagButton = styled(Buttons)`
  background: transparent;
`;

export const StyledButtonWrapper = styled.a`
  width: 100%;
`;

export const CheckoutButton = styled(Buttons)`
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.vars.black};
  }
`;

export const StyledEmptyBagContainer = styled.div<{ $kgRedesignToggle: boolean }>`
  padding-bottom: ${({ $kgRedesignToggle }) => ($kgRedesignToggle ? '1rem' : '0')};

  p {
    font-size: ${({ theme, $kgRedesignToggle }) => $kgRedesignToggle ? '1.1rem' : theme.vars.sizeS};
    color: ${({ theme }) => theme.vars.black};
  }

  .title {
    text-transform: capitalize;
    ${({ theme }) => theme.types.h4Style};
  }
`;

export const StyledNewInLink = styled.a`
  text-transform: uppercase;
  text-decoration: underline;
`;