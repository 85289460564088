import React, { FC } from 'react';

import { HtmlLink } from '../../types/middleware-types';
import removeItemLineNumberFromLocalStorage from '../../utils/helpers/removeItemLineNumberFromLocalStorage';
import AccordionItemStructure from './AccordionItemStructure';

interface Props {
  links?: HtmlLink[];
  isHeader?: boolean;
  isOpen?: boolean;
}

const AccordionItemFooter: FC<Props> = ({ links, isHeader, isOpen }) => {
  const componentProps = {
    links,
    removeItemLineNumberFromLocalStorage,
    isHeader,
    isOpen,
  };

  return <AccordionItemStructure {...componentProps} />;
};

export default AccordionItemFooter;
