import styled from 'styled-components';

import { SalesFlagType } from '../../types/custom-types';

interface Props {
  $label: SalesFlagType | string | undefined;
  $featureKgRedesign: boolean;
}

export const StyledTag = styled.p<Props>`
  display: block;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.productCard.roundelFlag.borderBottom};
  width: 100%;
  background: transparent;
  margin: 0;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  font-size: 1.2rem;
  ${({ $featureKgRedesign }) =>
  $featureKgRedesign && `
    text-align: left;
    padding: 0.5rem;
    font-size: 0.8rem;
  `}
  ${({ $label, theme }) =>
    $label &&
    ['sale', 'clearance'].includes($label) &&
    `color: ${theme.productCard.roundelFlag.color};`}
  

  @media ${({ theme }) => theme.devices.large} {
    font-size: 1.2rem;
  }
`;
