import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'universal-cookie';

import { selectHasFilledForm } from '../../../features/dataCapture';
import { selectIsAtTop, selectIsSiteSwitcherVisible, selectMeganavOpen, selectShowSiteSwitcher, updateShowSiteSwitcher } from '../../../features/nav';
import { selectHeaderNavigationData, selectPromoBanner, selectToggles } from '../../../features/static';
import useDataCaptureCookies from '../../../utils/customHooks/useDataCaptureCookies';
import useElementPush from '../../../utils/customHooks/useElementPush';
import { useHeaderCompactPosition } from '../../../utils/customHooks/useHeaderCompactPosition';
import useHeaderNavigation from '../../../utils/customHooks/useHeaderNavigation';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import useHuntDomain from '../../../utils/customHooks/useHuntDomain';
import usePageName from '../../../utils/customHooks/usePageName';
import { DEFAULT_PROMO_BANNER_HEIGHT } from '../../PromoBanner';
import { DEFAULT_SMART_BANNER_HEIGHT } from "../../SmartAppBanner";
import TransparentHeaderCompactReverseStructure from './TransparentHeaderCompactReverseStructure';

interface HeaderProps {
  showDesktopLayout: boolean;
  hasSmartAppBanner?: boolean;
}

const TransparentHeaderCompactReverse: FC<HeaderProps> = ({ showDesktopLayout, hasSmartAppBanner }) => {
  const toggles = useSelector(selectToggles);
  const dispatch = useDispatch();
  const cookie = new Cookie();
  const getElement = useElementPush<HTMLDivElement>('header');
  const { headerTheme, headerBackground, headerPosition, isScrolling } = useHeaderTheme();
  const isSiteSwitcherVisible = useSelector(selectIsSiteSwitcherVisible);
  const showSiteSwitcher = useSelector(selectShowSiteSwitcher);
  const megaNavOpen = useSelector(selectMeganavOpen);
  const siteSwitcherCookie = cookie.get('site_switcher');
  const headerNavigation = useSelector(selectHeaderNavigationData);
  const pageName = usePageName();
  const homepageShowPromobannerToggle = toggles?.featureShowKgHomepagePromobanner;
  const isHomePage = pageName.pageName === 'HomePage';

  const promoBanner = useSelector(selectPromoBanner);
  const isAtTop = useSelector(selectIsAtTop);
  const { showContiunity = false } = useDataCaptureCookies();
  const acceptedRedux = useSelector(selectHasFilledForm);
  const [showContinuityBar, setShowContinuityBar] = useState(false);

  const currentUrl = typeof window !== 'undefined' && window.location.host;
  const { searchOpen, toggleSearch } = useHeaderNavigation();
  const { headerTop, promoBannerHeight } = useHeaderCompactPosition();

  const siteSwitcherToggled = toggles &&
    Object.keys(toggles).length > 0 &&
    toggles.featureSiteSwitcher &&
    !toggles.featureGlobalECountryPickerAndSiteSwitcherOnly &&
    siteSwitcherCookie === undefined;

  const { domain } = useHuntDomain(siteSwitcherToggled);

  // Show promobanner on homepage, depending on what's returned by toggle.
  let showHomepagePromobanner = false;
  if (homepageShowPromobannerToggle === 'true') {
    showHomepagePromobanner = true;
  } else if (homepageShowPromobannerToggle === 'mobile') {
    if (!showDesktopLayout) {
      showHomepagePromobanner = true;
    }
  } else if (homepageShowPromobannerToggle === 'desktop') {
    if (showDesktopLayout) {
      showHomepagePromobanner = true;
    }
  }

  useEffect(() => {
    if (
      siteSwitcherToggled &&
      domain &&
      domain !== currentUrl
    ) {
      dispatch(updateShowSiteSwitcher(true));
    }
  }, [toggles, siteSwitcherCookie, domain]);

  // Pulling out promo banner data for SSR.
  const smartAppBannerHeaderShift = hasSmartAppBanner ? DEFAULT_SMART_BANNER_HEIGHT : 0;

  const [finalTopPositionMob, setFinalTopPositionMob] = useState((promoBanner?.mobileHeightPixels || DEFAULT_PROMO_BANNER_HEIGHT) + smartAppBannerHeaderShift);
  const [finalTopPositionDesk, setFinalTopPositionDesk] = useState(
    promoBanner?.desktopHeightPixels || DEFAULT_PROMO_BANNER_HEIGHT,
  );

  /**
   * Logic to set the top postions of header. 0 by default, if hasPromoBanner set to promoHeight or 50. If there is
   * also showSiteSwitcher then set to current value plus 200; if SmartBanner is there add an extra 75
   */
  useEffect(() => {
    if (promoBanner) {
      const hasPromoBannerMob = promoBanner?.mobileItems && promoBanner.mobileItems.length > 0;
      const hasPromoBannerDesk = promoBanner?.desktopItems && promoBanner.desktopItems.length > 0;

      const promoBannerMobileHeight = promoBanner?.mobileHeightPixels;
      const promoBannerDesktopHeight = promoBanner?.desktopHeightPixels;
      let tempTopPositionMob = 0;
      let tempTopPositionDesk = 0;

      if (hasPromoBannerMob) {
        tempTopPositionMob = promoBannerMobileHeight || DEFAULT_PROMO_BANNER_HEIGHT;
      }

      if (hasPromoBannerDesk) {
        tempTopPositionDesk = promoBannerDesktopHeight || DEFAULT_PROMO_BANNER_HEIGHT;
      }

      if (showSiteSwitcher) {
        tempTopPositionMob += 200;
        tempTopPositionDesk += 200;
      }

      if (hasSmartAppBanner) {
        tempTopPositionMob += DEFAULT_SMART_BANNER_HEIGHT;
      }

      if (toggles?.featureStickyHeader && (!isHomePage || showHomepagePromobanner)) {
        setFinalTopPositionMob(headerTop);
        setFinalTopPositionDesk(headerTop);
      }
      else if (isHomePage) {
        // Push header down on homepage if smart banner is present.
        if (hasSmartAppBanner) {
          setFinalTopPositionMob(DEFAULT_SMART_BANNER_HEIGHT);
        } else {
          setFinalTopPositionMob(0);
        }
        setFinalTopPositionDesk(0);
      }
      else {
        setFinalTopPositionMob(tempTopPositionMob);
        setFinalTopPositionDesk(tempTopPositionDesk);
      }

    }
  }, [promoBanner, showSiteSwitcher, hasSmartAppBanner, showDesktopLayout]);

  useEffect(() => {
    const hasAcceptCookie = showContiunity || acceptedRedux;
    setShowContinuityBar(toggles.featureDataCapture && hasAcceptCookie);
  }, [acceptedRedux, toggles]);

  const componentProps = {
    searchOpen,
    toggleSearch,
    headerTheme,
    headerBackground,
    showSiteSwitcher,
    isSiteSwitcherVisible,
    megaNavOpen,
    getElement,
    finalTopPositionMob: pageName.pageName === 'M2' && toggles?.engs13532RemoveM2HeaderSpacing ? 0 : finalTopPositionMob,
    finalTopPositionDesk: pageName.pageName === 'M2' && toggles?.engs13532RemoveM2HeaderSpacing ? 0 : finalTopPositionDesk,
    domain,
    promoBanner,
    showDesktopLayout,
    headerPosition: toggles?.featureStickyHeader ? headerPosition : 'absolute',
    promoBannerHeight,
    headerNavigation,
    isAtTop,
    showContinuityBar,
    isHomePage,
    showHomepagePromobanner,
    isScrolling,
    pageName: pageName.pageName
  };

  return <TransparentHeaderCompactReverseStructure {...componentProps} />;
};


export default TransparentHeaderCompactReverse;
