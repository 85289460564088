import React, { FC } from 'react';

import LinkItem from '../../../../basic/LinkItem';
import {
  ImageHtmlLink as ImageHtmlLinkType,
  IndexSubMenu,
  Maybe,
  SubMenuIndexLink,
} from '../../../../types/middleware-types';
import ImageHtmlLink from '../ImageHtmlLink';
import LinkListWithImage from '../LinkListWithImage';
import SubMenuLinkList from '../SubMenuLinkList';
import {
  StyledImagesContainer,
  StyledImagesLinksContainer,
  StyledIndexList,
  StyledIndexSubMenu,
  StyledLinkRenderer,
  StyledTextContainer,
} from './IndexSubMenuStyles';

interface IndexSubMenuStructureProps {
  data: IndexSubMenu,
  brandsIndex?: Array<SubMenuIndexLink> | null,
}


const IndexSubMenuStructure: FC<IndexSubMenuStructureProps> = ({ data, brandsIndex }) => (
  <StyledIndexSubMenu id={data?.menuId || undefined}>
    <StyledTextContainer>
      <h3>Index</h3>
      <StyledLinkRenderer type="desktop">
        <LinkItem href={data.index.allUrl}>{data.index.title}</LinkItem>
      </StyledLinkRenderer>
    </StyledTextContainer>
    <StyledIndexList>
      {brandsIndex && brandsIndex?.map((i) => (
        <li data-hookid="IndexSubMenuLink" key={i.letter}>
          <LinkItem
            id={data?.link?.linkId || undefined}
            href={i.enabled ? i.url : ''}
            className={!i.enabled ? 'disabled' : ''}
            disabled={!i.enabled}
          >
            {i.letter}
          </LinkItem>
        </li>
      ))}
    </StyledIndexList>
    <StyledLinkRenderer type="mobile">
      <LinkItem href={data.index.allUrl}>{data.index.title}</LinkItem>
    </StyledLinkRenderer>
    <StyledImagesLinksContainer>
      <SubMenuLinkList data={data.links} />
      {data.images && data.images.length > 0 && (
        <StyledImagesContainer>
          {data.images.map((image: Maybe<ImageHtmlLinkType>, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <ImageHtmlLink id={image?.link?.linkId || undefined} key={`${image?.image.desktop.src}-${index}`} data={image} linkPosition='' />
          ))}
        </StyledImagesContainer>
      )}
    </StyledImagesLinksContainer>
    <StyledLinkRenderer type="mobile">
      <LinkListWithImage data={data} />
    </StyledLinkRenderer>
  </StyledIndexSubMenu>
);

export default IndexSubMenuStructure;
