import React, { ElementType, FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { usePathname } from 'next/navigation';
import Cookies from 'universal-cookie';

import constants from '../../../sites/constants';
import { GlobalStyle, GlobalType } from '../../../sites/styles';
import { selectFooterData, selectPageName, selectToggles } from '../../features/static';
import { GET_CUSTOMER_DATA } from '../../graphQl/queries/customer';
import { Normalize } from '../../styles';
import { HeaderLayout } from '../../types/state-types';
import useAuth from '../../utils/customHooks/useAuth';
import formatUserLocationDatalayer from '../../utils/DataLayer/formatUserLocationObject';
import formatUserObjectDatalayer from '../../utils/DataLayer/formatUserObject';
import getCustomerHeaders from '../../utils/getters/getCustomerHeaders';
import PageLayoutStructure from './PageLayoutStructure';

interface Props {
  children: ReactNode
}

const PageLayout: FC<Props> = ({ children }) => {
  const toggles = useSelector(selectToggles);
  const pageName = useSelector(selectPageName);
  const footerData = useSelector(selectFooterData);
  const [userEventSent, setUserEventSent] = useState(false);
  const showWelcomePopup = toggles?.featureWelcomePopup;
  const showDataCapture = toggles?.featureDataCapture;
  const { checkCookie } = useAuth();
  const pathname = usePathname();
  const cookiesUni = new Cookies();
  const hasCookie = cookiesUni.get('page');

  if (hasCookie) {
    cookiesUni.remove('page', { path: '/' });
  }
  cookiesUni.set('page', pageName || 'no-page-name', { path: '/' });

  const customerHeaders = getCustomerHeaders(null);
  const { data: customerData } = useQuery(GET_CUSTOMER_DATA, {
    context: { headers: customerHeaders },
    skip: !customerHeaders.customer_oauth_token,
  });

  const GlobalS = GlobalStyle as ElementType;
  const GlobalT = GlobalType as ElementType;

  const { mobileNewsletterAccordion } = constants;

  let { headerLayout } = constants;
  headerLayout = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu ? HeaderLayout.TransparentHeaderCompact : headerLayout;

  const params = {
    action_type: '',
    action_location: pageName,
    action_value_id: `${process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID}-${pageName?.toLowerCase()}`,
    graphQlError: 'no error',
    graphQlData: 'no data',
  };

  const isPlp = (pageName === 'plpSearch') || (pageName === 'plp');

  useEffect(() => {
    if (customerData && !userEventSent) {
      formatUserObjectDatalayer(customerData.customerData);
      setUserEventSent(true);
    }
  }, [customerData, userEventSent]);

  if (toggles.engs14801PageChangeEvent && typeof window !== "undefined") {
    const pageChangeEvent = new Event("pageChange");
    dispatchEvent(pageChangeEvent);
  }

  useEffect(() => {
    // Set customer country selection in datalayer
    const setCustomerCountry = () => {
      const siteID = process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID;
      let userLocation;
      const hasGlobalCookie = cookiesUni.get('GlobalE_Data');

      switch (siteID) {

        case 'kurtgeigerus':
          userLocation = 'US';
          break;

        case 'kurtgeigermexico':
          userLocation = 'MEX';
          break;

        case 'kurtgeigerglobal':
          if (hasGlobalCookie) {
            userLocation = hasGlobalCookie.countryISO;
          }
          break;

        default:
          userLocation = 'GB'; // Default value for any other cases
          break;
      }

      formatUserLocationDatalayer(userLocation);
    };
    setCustomerCountry();
  }, []);

  useEffect(() => {
    checkCookie();
  }, [pathname]);

  const componentProps = {
    pageName,
    children,
    headerLayout: toggles.featureKgRedesign ? HeaderLayout.TransparentHeaderCompactReverse : headerLayout,
    showWelcomePopup,
    // If the current page is a PLP, pass through Splunk Params.
    splunkParams: isPlp ? params : {},
    mobileNewsletterAccordion,
    showDataCapture,
    welcomeData: footerData?.newsletterSignup,
  };

  return (
    <>
      {/* Styles components */}
      <Normalize />
      <GlobalS />
      <GlobalT />
      <PageLayoutStructure {...componentProps} />
    </>
  );
};

export default PageLayout;
