import React, { FC, HTMLProps, MouseEvent, ReactNode } from 'react';

import { Maybe } from '../../types/middleware-types';
import useSiteChecker from '../../utils/customHooks/useSiteChecker';
import ButtonStructure from './ButtonStructure';

export type ButtonType = 'button' | 'submit' | 'reset';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  btnType: string;
  type?: ButtonType;
  active?: boolean;
  effect?: string;
  passedFunctions?: (event?: MouseEvent<HTMLElement>) => void;
  pageName?: Maybe<string>;
  componentName?: string;
  children?: ReactNode;
  currentSite?: string;
  dataHook?: string;
}

const Button: FC<ButtonProps> = ({
  dataHook,
  children,
  btnType,
  type = 'button',
  active,
  effect,
  passedFunctions = () => { },
  pageName = '',
  componentName = '',
  ...otherProps
}: ButtonProps) => {
  const site = useSiteChecker();

  const componentProps = {
    btnType,
    children,
    type,
    active,
    effect,
    passedFunctions,
    pageName,
    componentName,
    currentSite: site,
    dataHook,
    ...otherProps,
  };
  return <ButtonStructure {...componentProps} />;
};

export default Button;
