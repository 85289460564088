import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NextImage from '../../../basic/NextImage';
import { ImageV2, NewsletterSignup } from '../../../types/middleware-types';
import NewsletterSignUp from '../../NewsletterSignUp';
import { StyledPopupXButton } from '../WelcomePopupStyles';
import {
  StyledFormPopupContent,
  StyledFormPopupFormWrapper,
  StyledFormPopupImageWrapper,
} from './FormPopupStyles';

interface FormPopupStructureProps {
  image?: ImageV2;
  newsletterSignup: NewsletterSignup;
  thanks?: string;
  setNewsletterSubmited: Dispatch<SetStateAction<boolean>>;
  newsletterSubmited: boolean;
  closeButtonClicked: () => void;
  popupFormIntroHTML?: string;
  source?: string;
}

const FormPopupStructure: FC<FormPopupStructureProps> = ({
  image,
  newsletterSignup,
  thanks,
  newsletterSubmited,
  closeButtonClicked,
  setNewsletterSubmited,
  popupFormIntroHTML,
  source,
}): ReactElement => {

  return (
    <StyledFormPopupContent
      data-hookid="WelcomePopupContent"
      className={source ? `${source}-welcome-popup-content` : ''}
    >
      <StyledFormPopupImageWrapper data-hookid="WelcomePopupImage">
        {image && <NextImage mediaList={image} />}
      </StyledFormPopupImageWrapper>
      <StyledFormPopupFormWrapper data-hookid="WelcomePopupForm">
        {popupFormIntroHTML && !newsletterSubmited && (
          <div
            data-hookid="WelcomePopupFormIntro"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: popupFormIntroHTML }}
          />
        )}
        <NewsletterSignUp
          welcomePopupForm={true}
          newsletterSignup={{ ...newsletterSignup, thanksHtml: thanks }}
          showSignUpDescription={!newsletterSubmited}
          setNewsletterSubmited={setNewsletterSubmited}
          source={source}
        />
      </StyledFormPopupFormWrapper>
      <StyledPopupXButton onClick={closeButtonClicked} data-hookid="WelcomePopupClose">
        <FontAwesomeIcon type="button" icon={faTimes as IconProp} />
      </StyledPopupXButton>
    </StyledFormPopupContent>
  );
};

export default FormPopupStructure;
