import { RootState } from '../../../reduxStore/store';
import { Maybe, NearbyStore, Product, Store } from '../../types/middleware-types';
import { NearbyStoresDataState } from '../../types/state-types';

export const selectCurrentFisProduct = (state: RootState): Maybe<Product> =>
  state.findInStore.currentFindInStoreProduct;

export const selectNearbyStoresData = (state: RootState): NearbyStoresDataState =>
  state.findInStore.nearbyStoresData;

export const selectFisSelectedSizeData = (state: RootState): Maybe<string> =>
  state.findInStore.selectedFisSize;

export const selectSelectedStore = (state: RootState): Maybe<NearbyStore | Store> =>
  state.findInStore.selectedStore;
