import { Bag } from '../../../types/middleware-types';
import addToDataLayer from '../addToDataLayer';
import mapToBasketProductSchema, { BasketProduct } from '../mapToBasketProductSchema';

interface customWindow extends Window {
  // Added ignore as any object structure can be passed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer: Array<{ [key: string]: any }>;
}

declare const window: customWindow;

interface DataLayerEventData {
  event: string;
  pageType?: string;
  pageCategory?: string | null;
  basket?: {
    products: Array<BasketProduct>;
    total: string;
    subtotal: string;
    quantity: number;
  };
}

const formatPageView = (pageName: string | null, categoryPath: string | null | undefined, bag?: Bag): void => {
  const dataLayerEvent: DataLayerEventData = {
    event: 'pageView',
  };
  switch (pageName) {
    case 'HomePage':
      dataLayerEvent.pageType = 'home';
      dataLayerEvent.pageCategory = 'Homepage';
      break;
    case 'plp':
      dataLayerEvent.pageType = 'category';
      dataLayerEvent.pageCategory = categoryPath;
      break;
    case 'plpSearch':
      dataLayerEvent.pageType = 'searchresults';
      dataLayerEvent.pageCategory = 'Other';
      break;
    case 'pdp':
      dataLayerEvent.pageType = 'product';
      dataLayerEvent.pageCategory = categoryPath;
      break;
    default:
      dataLayerEvent.pageType = 'other';
      dataLayerEvent.pageCategory = 'Other';
      break;
  }

  if (bag && bag?.items?.length > 0) {
    const products: Array<BasketProduct> = bag.items.map(({ product, quantity }) => ({
      ...mapToBasketProductSchema(product),
      quantity,
    }));
    dataLayerEvent.basket = {
      products,
      total: bag.subTotal,
      subtotal: bag.subTotal,
      quantity: bag.itemCount,
    };
  }

  window.dataLayer = window.dataLayer || [];
  addToDataLayer(dataLayerEvent);
};

export default formatPageView;
