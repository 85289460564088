import { RefObject, useCallback, useEffect, useRef } from 'react';

export const usePreventVerticalScroll = <T extends HTMLElement>(ref: RefObject<T>, dragThreshold = 5): void => {
  const touchStartX = useRef<number>(0);
  const currentTouchX = useRef<number>(0);

  const touchStart = useCallback((e: TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  }, []);

  const preventTouch = useCallback(
    (e: TouchEvent) => {
      currentTouchX.current = e.touches[0].clientX - touchStartX.current;
      if (Math.abs(currentTouchX.current) > dragThreshold && e.cancelable) {
        e.preventDefault();
        return false;
      }

      return true;
    },
    [dragThreshold],
  );

  useEffect(() => {
    const { current } = ref;
    if (current) {
      current.addEventListener('touchstart', touchStart);
      current.addEventListener('touchmove', preventTouch, { passive: false });
    }
    return () => {
      if (current) {
        current.removeEventListener('touchstart', touchStart);
        current.removeEventListener('touchmove', preventTouch);
      }
    }
  }, [preventTouch, ref, touchStart])
}