import React, { FC, ReactNode } from 'react';
// @ts-ignore missing types package
import QubitReact from 'qubit-react/wrapper';

import Canonical from '../../components/Canonical';
import DataCapture from '../../components/DataCapture';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MetaData from '../../components/MetaData';
import WelcomePopup from '../../components/WelcomePopup';
import FormPopup from '../../components/WelcomePopup/FormPopup';
import { SplunkParams } from '../../types/custom-types';
import { NewsletterSignup } from '../../types/middleware-types';
import { HeaderLayout } from '../../types/state-types';
import ErrorBoundary from '../ErrorBoundary';
import { StyledMain } from './PageLayoutStyles';

interface Props {
  headerLayout: HeaderLayout;
  pageName: string | null | undefined;
  showWelcomePopup: boolean;
  showDataCapture: boolean;
  splunkParams?: SplunkParams;
  welcomeData?: NewsletterSignup;
  mobileNewsletterAccordion: boolean;
  pagePadding?: number;
  children?: ReactNode;
}

const PageLayoutStructure: FC<Props> = ({
  children,
  headerLayout,
  pageName,
  showWelcomePopup,
  showDataCapture,
  splunkParams = {},
  welcomeData,
  mobileNewsletterAccordion,
  pagePadding
}) => (
  <>
    <MetaData />
    <Canonical />
    <div data-hookid={pageName}>
      <StyledMain
        $pagePadding={pagePadding || 0}
      >
        <Header layout={headerLayout} />
        <ErrorBoundary sendToSplunk={true} splunkParams={splunkParams}>
          {children}
        </ErrorBoundary>
      </StyledMain>
      {/* FIXED ELEMENTS */}
      <QubitReact id="QubitNewsLetterPopup" componentTest={FormPopup}>
        {null}
      </QubitReact>
      {showWelcomePopup && welcomeData && <WelcomePopup welcomeData={welcomeData} />}
      {showDataCapture && welcomeData && <DataCapture onSiteDataCapture={welcomeData} />}
      <Footer mobileNewsletterAccordion={mobileNewsletterAccordion} />
    </div>
  </>
);

export default PageLayoutStructure;