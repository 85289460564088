import styled from 'styled-components';

interface StyledFormLabelProps {
  $required?: boolean;
}

const StyledFormLabel = styled.label<StyledFormLabelProps>`
  ${({ theme }) => theme.types.labelStyle};
  ${({ $required, theme }) =>
  $required &&
    `
    &::after {
      content: "*";
      display: inline-block;
      margin-left: 0.5rem;
      color: ${theme.vars.redDark};
    }
  `};
`;

export default StyledFormLabel;
