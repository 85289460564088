import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsHeaderReverse } from '../../features/static';
import { DesktopSubMenu } from '../../types/middleware-types';
import useHeaderNavigation from '../../utils/customHooks/useHeaderNavigation';
import MainMenuStructure from './MainMenuStructure';

export interface MainMenuProps {
  data: Array<DesktopSubMenu> | undefined;
}

const MainMenu: FC<MainMenuProps> = ({ data = [] }: MainMenuProps) => {
  const [currentTab, setCurrentTab] = useState('');
  const isHeaderReverse = useSelector(selectIsHeaderReverse);
  const { toggleMegaNav } = useHeaderNavigation();

  const toggleSubNav = (title: string): void => {
    setCurrentTab(title);
    toggleMegaNav(!!title);
  };

  const componentProps = {
    data,
    toggleSubNav,
    currentTab,
    isHeaderReverse
  };

  return <MainMenuStructure {...componentProps} />;
};

export default MainMenu;
