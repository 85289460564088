import styled from 'styled-components';

export const StyledSiteSwitcher = styled.div`
  height: 20rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.vars.white};
  width: 100%;
  background: ${({ theme }) => theme.vars.black};
  position: relative;
`;

export const StyledCloseButton = styled.div`
  position: absolute;
  right: 4rem;
  top: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${({ theme }) => theme.devices.smallOnly} {
    right: 2rem;
    top: 1.5rem;
  }

  span {
    ${({ theme }) => theme.types.h2Style};
    margin-left: 0.5rem;
  }
`;

export const StyledSiteSwitcherContent = styled.div`
  box-sizing: border-box;
  max-width: 70rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5.5rem;

  .flag {
    margin-bottom: 0.5rem;

    img {
      width: 5rem !important; /* some of the SVGs have hard coded widths */
      height: 2.5rem !important; /* some of the SVGs have hard coded heights */
    }
  }

  button {
    margin: 0.5rem 0;
    width: 40%;

    @media ${({ theme }) => theme.devices.smallOnly} {
      width: 70%;
    }
  }
`;
