'use client'

import { useCallback, useEffect, useState } from 'react';

import { GlobalAnimations, GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockAnimationMediaAndText50PercentSplit,
  ContentBlockAnimationMediaBanner,
  ContentBlockAnimationMediaBasic,
  ContentBlockAnimationMediaCarousel,
  ContentBlockAnimationMediaCarouselSimple,
  ContentBlockAnimationMediaCarouselWithMediaItem,
  ContentBlockAnimationMediaCtAs,
  ContentBlockAnimationMediaFocus,
  ContentBlockAnimationMediaOverlap,
  ContentBlockAnimationMediaShopBySize,
  ContentBlockAnimationProductCarousel,
  ContentBlockAnimationProductCarouselWithMediaItem,
} from '../../../types/middleware-types';
import getResizeObserver from '../../getters/getResizeObserver/getResizeObserver';
import { isInView } from '../../helpers/isInView';

export const DEFAULT_ON_SCROLL_STYLE: GlobalAnimationsStyle = () => ({});

type ScrollAnimationType =
  | ContentBlockAnimationMediaShopBySize
  | ContentBlockAnimationMediaCarousel
  | ContentBlockAnimationMediaCtAs
  | ContentBlockAnimationMediaBasic
  | ContentBlockAnimationMediaFocus
  | ContentBlockAnimationMediaBanner
  | ContentBlockAnimationMediaAndText50PercentSplit
  | ContentBlockAnimationMediaCarouselSimple
  | ContentBlockAnimationMediaCarouselWithMediaItem
  | ContentBlockAnimationProductCarousel
  | ContentBlockAnimationProductCarouselWithMediaItem
  | ContentBlockAnimationMediaOverlap
  | ContentBlockAnimationCelebrityStyleCarousel;

const useAnimationOnScroll = (
  animationName?: ScrollAnimationType | null,
): {
  enteredView: boolean;
  animationStyle: GlobalAnimationsStyle;
  forwardedRef: (node: HTMLElement) => void;
} => {
  const [enteredView, setEnteredView] = useState(false);
  const animationStyle: GlobalAnimationsStyle | null = animationName
    ? GlobalAnimations[animationName]
    : null;
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [elementHeight, setElementHeight] = useState(0);
  const [addedListener, setAddedListener] = useState(false);
  const OFFSET = -50;

  const forwardedRef = useCallback((node: HTMLElement) => {
    setElement(node);
  }, []);

  // update element height on resize
  useEffect(() => {
    if (element && animationStyle) {
      const updateElementHeightAndTop = () => {
        const { height } = element.getBoundingClientRect();
        setElementHeight((elHeight) => (!elHeight && height ? height : elHeight));
      };
      updateElementHeightAndTop();

      const resizeObserver = getResizeObserver(updateElementHeightAndTop);
      resizeObserver.observe(element);
    }
  }, [element, animationStyle]);

  // check if element is in view
  useEffect(() => {
    const onScroll = (): void => {
      if (enteredView) {
        return;
      }
      if (isInView(element, OFFSET)) {
        setEnteredView(true);
      }
    };
    if (animationStyle && element && elementHeight > 0) {
      // If in view on load, run onScroll function only for BACKGROUNDSHRINK animation.
      if (isInView(element, OFFSET) && animationName === 'BACKGROUNDSHRINK') {
        onScroll();
      } else if (!addedListener) {
        setAddedListener(true);
        window.addEventListener('scroll', onScroll);
      }
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [enteredView, animationStyle, element, elementHeight]);

  return {
    enteredView,
    animationStyle: animationStyle || DEFAULT_ON_SCROLL_STYLE,
    forwardedRef,
  };
};

export default useAnimationOnScroll;
