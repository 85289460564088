/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { PlpContentState } from '../../types/state-types';
import {
  refreshPlpData,
  setCategoryCarousel,
  setRouteUrl,
  updatePaginationData,
  updatePlpCanonical,
  updatePlpCurrentPage,
  updatePlpData,
  updatePlpLoading,
  updateProductList,
  updateProductView,
} from './actions';

export const initialState: PlpContentState = {
  productView: 'small_view',
  plpLoading: {},
  plpData: null,
  productList: {
    items: [],
    loadDataAbove: false,
  },
  productListCurrentPage: {
    page: 1,
  },
  plpPage: 1,
  plpMetadata: null,
  initialQuery: null,
  paginationData: null,
  routeUrl: '/'
};

export const plpContentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateProductView, (state, action) => {
      state.productView = action.payload;
    })
    .addCase(refreshPlpData, (state, action) => {
      state.refreshPlpData = action.payload;
    })
    .addCase(updatePaginationData, (state, action) => {
      state.paginationData = action.payload;
    })
    .addCase(updatePlpCurrentPage, (state, action) => {
      state.productListCurrentPage = action.payload;
    })
    .addCase(updateProductList, (state, action) => {
      state.productList = action.payload;
    })
    .addCase(updatePlpData, (state, action) => {
      state.plpData = action.payload;
    })
    .addCase(updatePlpCanonical, (state, action) => {
      state.canonicalUrl = action.payload;
    })
    .addCase(setRouteUrl, (state, action) => {
      state.routeUrl = action.payload;
    })
    .addCase(updatePlpLoading, (state, action) => {
      state.plpLoading = action.payload;
    })
    .addCase(setCategoryCarousel, (state, action) => {
      state.categoryCarousel = action.payload;
    });
});
