/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { setMetadata } from './actions';
import { MetadataState } from './types';

export const initialState: MetadataState = {
  title: '',
  description: null,
  keywords: null,
  robots: null,
  hreflangs: [],
  metaImage: '',
};

export const metadataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMetadata, (state, action) => {
      state.title = action.payload?.title;
      state.description = action.payload?.description;
      state.keywords = action.payload?.keywords;
      state.robots = action.payload?.robots || null;
      state.hreflangs = action.payload?.hreflangs;
      state.metaImage = action.payload?.metaImage || null;
    })
});
