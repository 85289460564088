import React, { ComponentType, FC } from 'react';
import { useTheme } from 'styled-components';

import { SubMenuData } from '../../types/custom-types';
import { ImageHtmlLink, Maybe } from '../../types/middleware-types';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import SubMenuComponent, { SubMenuProps as SubMenuComponentProps } from './SubMenu';
import SubMenuColumn from './SubMenuColumn';
import SubMenuCompact, { SubMenuCompactProps } from './SubMenuCompact';

const LAYOUTS: {
  [key: string]: ComponentType<SubMenuCompactProps> | ComponentType<SubMenuComponentProps>;
} = {
  SubMenu: SubMenuComponent,
  SubMenuCompact,
  SubMenuColumn,
};

export interface SubMenuProps {
  data: SubMenuData;
  showSubNav?: boolean;
  parent?: Maybe<string>;
  layout: string;
  showSubMenu?: boolean;
  closeSubmenu?: () => void;
  promoBannerHeight?: number;
  finalTopPositionMob?: number;
  headerHeight?: number;
  mobileSubmenuImages?: Maybe<ImageHtmlLink>[];
}

const SubMenu: FC<SubMenuProps> = ({
  data,
  showSubNav,
  parent,
  layout,
  showSubMenu,
  closeSubmenu = () => { },
  promoBannerHeight = 0,
  finalTopPositionMob = 0,
  headerHeight = 0,
  mobileSubmenuImages,
}) => {
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const componentProps = {
    data,
    showSubNav,
    parent: parent || '',
    showSubMenu,
    closeSubmenu,
    isDesktop,
    promoBannerHeight,
    finalTopPositionMob,
    headerHeight,
    mobileSubmenuImages,
  };

  const Component = LAYOUTS[layout];

  return Component ? <Component {...componentProps} /> : null;
};

export default SubMenu;
