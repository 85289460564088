import styled from 'styled-components';

export const StyledPopupXButton = styled.button`
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  top: 0.7rem;
  right: 1rem;
  padding: 0;
  cursor: pointer;

  svg {
    width: 100% !important;
    height: 100% !important;
    color: ${({ theme }) => theme.welcomePopup.color};
  }
`;
