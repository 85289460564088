import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NextImage from "../../basic/NextImage";
import { createMediaList } from "../../utils/helpers/createMediaList";
import {
  StyledBannerWrapper,
  StyledSmartBanner,
  StyledSmartBannerCloseButton,
  StyledSmartBannerColumn,
  StyledSmartBannerInfo,
  StyledSmartBannerLink,
  StyledSmartBannerRow,
  StyledSmartBannerTitle
} from "./SmartAppBannerStyles";
import { Theme } from ".";

interface SmartAppBannerStructureProps {
  src: string,
  title: string
  theme?: Theme,
  price: string,
  description: string,
  buttonLink: string,
  buttonText: string,
  onClose?: () => void,
  bannerClosed: boolean,
}

const SmartAppBannerStructure: FC<SmartAppBannerStructureProps> = ({
  src,
  title,
  theme,
  price,
  description,
  buttonLink,
  buttonText,
  onClose,
  bannerClosed,
}) => {
  return (
    !bannerClosed && (
      <StyledBannerWrapper>
        <StyledSmartBanner $bannerTheme={theme} data-hookid='smartbanner'>
          <StyledSmartBannerRow>
            <StyledSmartBannerCloseButton
              data-hookid='smartBannerCloseButton'
              $bannerTheme={theme}
              type='button'
              onClick={onClose}
              aria-label="close"
            >
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </StyledSmartBannerCloseButton>
            <StyledSmartBannerRow>
              <NextImage
                mediaList={createMediaList({
                  src,
                  width: 64,
                  height: 64,
                })}
                alt='smartAppBannerImg'
                priority
              />
              <StyledSmartBannerColumn $bannerTheme={theme}>
                <StyledSmartBannerTitle $bannerTheme={theme}>
                  {title}
                </StyledSmartBannerTitle>
                <StyledSmartBannerInfo>
                  {price}
                </StyledSmartBannerInfo>
                <StyledSmartBannerInfo>
                  {description}
                </StyledSmartBannerInfo>
              </StyledSmartBannerColumn>
            </StyledSmartBannerRow>
          </StyledSmartBannerRow>
          <StyledSmartBannerLink
            data-hookid='bannerRedirectBtn'
            href={buttonLink}
            onClick={onClose}
            $bannerTheme={theme}
          >
            {buttonText}
          </StyledSmartBannerLink>
        </StyledSmartBanner>
      </StyledBannerWrapper>
    )
  )
}

export default SmartAppBannerStructure;