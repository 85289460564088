import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePathname, useSearchParams } from 'next/navigation';

import constants from '../../../sites/constants';
import { selectMetadata } from '../../features/metadata';
import {
  selectPlpCurrentPage,
  selectPlpCurrentSortItem,
  selectPlpDataExists,
  selectPlpShouldIndex,
} from '../../features/plpContent';
import usePageName from '../../utils/customHooks/usePageName';
import MetaDataStructure from './MetaDataStructure';

const MetaData: FC = () => {
  const { defaultMetaData } = constants;
  const { title, keywords, description, robots, hreflangs, metaImage } =
    useSelector(selectMetadata);
  const { pageName } = usePageName();
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const hasPage1 = Number(searchParams?.get('page')) === 1;
  const [finalMetaTitle, setFinalMetaTitle] = useState(title || defaultMetaData?.title)
  /**
   * First filers the current selected sortBy. Builds main title with currentPage and title
   * If there is a sortBy item selected, append to url. Else return titleWithPrefix
   *
   * @returns finalTitle | string
   */
  const buildPlpMetaTitle = (): string => {
    const defaultTitle = title || defaultMetaData?.title;
    const onPage1WithNoQuery = !hasPage1 && plpCurrentPage === 1;

    const titleWithPrefix = !plpDataExists
      ? defaultTitle
      : `Page ${plpCurrentPage} | ${defaultTitle}`;
    let finalTitle = plpCurrentSortItem
      ? `${titleWithPrefix} | ${plpCurrentSortItem}`
      : `${titleWithPrefix}`;

    if (onPage1WithNoQuery) {
      finalTitle = defaultTitle;
    }

    return finalTitle;
  };

  const plpCurrentSortItem = useSelector(selectPlpCurrentSortItem);
  const plpCurrentPage = useSelector(selectPlpCurrentPage);
  const plpDataExists = useSelector(selectPlpDataExists);
  const plpShouldIndex = useSelector(selectPlpShouldIndex);

  const [currentSite, setCurrentSite] = useState<string>('');
  const [updatedPlpMetaTitle, setUpdatedMetaTitle] = useState<string | null>(buildPlpMetaTitle());

  /**
   * Sets the site
   *
   * @returns void
   */
  useEffect(() => {
    switch (process.env.NEXT_PUBLIC_SITE) {
      case 'sah':
        setCurrentSite('Shoeaholics');
        break;
      case 'kg':
        setCurrentSite('Kurt Geiger');
        break;
      case 'kgus':
        setCurrentSite('Kurt Geiger');
        break;
      case 'carvela':
        setCurrentSite('Carvela');
        break;
      default:
        break;
    }
  }, []);

  const metaLocation = usePathname();
  const noIndex = plpShouldIndex === false;
  const finalRobots = metaLocation?.includes('out-of-stock') ? 'NOINDEX' : robots;

  useEffect(() => {
    if (pageName === 'plp') {
      const updateTitle = buildPlpMetaTitle();
      setUpdatedMetaTitle(updateTitle);
    }
  }, [plpDataExists, plpCurrentPage, plpCurrentSortItem, title]);

  useEffect(() => {
    if (pageName === 'plp' && updatedPlpMetaTitle) {
      setFinalMetaTitle(updatedPlpMetaTitle)
    }
    else {
      setFinalMetaTitle(title || defaultMetaData?.title)
    }
  }, [updatedPlpMetaTitle, pageName, pathName])

  const componentProps = {
    title: finalMetaTitle,
    keywords: keywords || undefined,
    description: description || defaultMetaData?.description,
    robots: (noIndex && 'noindex, nofollow') || finalRobots || 'INDEX,FOLLOW',
    metaImage: metaImage || undefined,
    metaLocation,
    currentSite,
    hreflangs: hreflangs || [],
  };

  return <MetaDataStructure {...componentProps} />;
};

export default MetaData;
