import { createAction } from '@reduxjs/toolkit';

import { ProductViewType } from '../../types/custom-types';
import { CategoryPagination, ImageLinkCampaign, Maybe } from '../../types/middleware-types';
import {
  CategoryWithPerPage,
  PlpLoadingType,
  ProductListCurrentPageType,
  ProductListType,
  ProductSearchWithPerPage,
} from '../../types/state-types';

export const updateProductView = createAction<ProductViewType>('plpContent/updateProductView');
export const refreshPlpData = createAction<boolean>('plpContent/refreshPlpData');
export const updatePaginationData = createAction<CategoryPagination>(
  'plpContent/updatePaginationData',
);
export const updatePlpCurrentPage = createAction<ProductListCurrentPageType>(
  'plpContent/updatePlpCurrentPage',
);
export const updateProductList = createAction<ProductListType>('plpContent/updateProductList');
export const updatePlpData = createAction<
  Maybe<CategoryWithPerPage> | Maybe<ProductSearchWithPerPage>
>('plpContent/updatePlpData');
export const updatePlpCanonical = createAction<string | undefined>('plpContent/updatePlpCanonical');
export const updatePlpLoading = createAction<PlpLoadingType>('plpContent/updatePlpLoading');
export const setRouteUrl = createAction<string>('plpContent/setRouteUrl');
export const setCategoryCarousel = createAction<ImageLinkCampaign>('plpContent/setCategoryCarousel');
