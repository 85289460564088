import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';

import { selectProductUrl } from '../../features/currentProduct';
import { selectCanonicalUrl } from '../../features/plpContent';
import { selectPageName } from '../../features/static';
import CanonicalStructure from './CanonicalStructure';

const Canonical: FC = () => {
  const fallBackUrl = usePathname();
  // Unescape base URL. This is necessary because the base URL is escaped in the environment variable.
  const unescapedBaseUrl = process.env.NEXT_PUBLIC_ROUTE_ENDPOINT ? process.env.NEXT_PUBLIC_ROUTE_ENDPOINT.replace(/\\/g, '') : '';
  const pageName = useSelector(selectPageName)
  const canonicalUrlPlp = useSelector(selectCanonicalUrl);
  const canonicalUrlPdp = useSelector(selectProductUrl);
  let canonicalUrl : string | null;
  switch (pageName) {
    case 'pdp':
      canonicalUrl = canonicalUrlPdp || ''
      break;

    case 'plp':
      canonicalUrl = canonicalUrlPlp || ''
      break;

    default:
      canonicalUrl = fallBackUrl
  }
  const [finalUrl, setFinalUrl] = useState(canonicalUrl);

  useEffect(() => {
    let canonicalUrlUpdated: string;
    switch (pageName) {
      case 'pdp':
        canonicalUrlUpdated = canonicalUrlPdp || ''
        break;

      case 'plp':
        canonicalUrlUpdated = canonicalUrlPlp || ''
        break;

      default:
        canonicalUrlUpdated = window.location.pathname
    }
    setFinalUrl(canonicalUrlUpdated)
  }, [canonicalUrl, canonicalUrlPlp, canonicalUrlPdp]);

  return <>{finalUrl ? <CanonicalStructure url={`${unescapedBaseUrl}${finalUrl}`} /> : null}</>;
};

export default Canonical;
