import styled from 'styled-components';

export const StyledSlider = styled.div`
  position: relative;
  overflow: hidden;
`;

export const StyledSliderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

interface StyledSliderContainerProps {
  $centerItems: boolean;
}

export const StyledSliderContainer = styled.ul<StyledSliderContainerProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  transform: translateX(0);
  box-sizing: border-box;
  justify-content: ${({ $centerItems }) => ($centerItems ? 'center' : 'start')};
`;

interface StyledSlideProps {
  $slideWidth: number;
}

export const StyledSlide = styled.li<StyledSlideProps>`
  width: ${({ $slideWidth }) => $slideWidth}%;
  flex-shrink: 0;
  padding: 0;
  display: block;
`;

interface StyledSliderArrowsContainerProps {
  $position?: string;
}

export const StyledSliderArrowsContainer = styled.div<StyledSliderArrowsContainerProps>`
  width: 100%;
  margin: 1rem 0;
  position: ${({ $position }) => ($position === 'INSIDE' ? 'absolute' : 'relative')};
  display: flex;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  top: ${({ $position }) => ($position === 'INSIDE' ? '50%' : '0')};
  transform: ${({ $position }) => ($position === 'INSIDE' ? 'translateY(-50%)' : 'none')};
  order: ${({ $position }) => ($position === 'ABOVE' ? -1 : 0)};
`;

interface StyledSliderArrowContainerProps {
  $position: string;
  $spaceBetween: number;
}

export const StyledSliderArrowContainer = styled.div<StyledSliderArrowContainerProps>`
  position: ${({ $position }) => ($position === 'INSIDE' ? 'absolute' : 'relative')};
  top: ${({ $position }) => ($position === 'INSIDE' ? '50%' : '0')};
  transform: ${({ $position }) => ($position === 'INSIDE' ? 'translateY(-50%)' : 'none')};

  &.left {
    ${({ $position, $spaceBetween }) =>
  $position !== 'INSIDE' && `margin-right: ${$spaceBetween || 0}px;`}
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

interface StyledSliderDotsContainerProps {
  $position: string;
}

export const StyledSliderDotsContainer = styled.ul<StyledSliderDotsContainerProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: ${({ $position }) => ($position === 'ABOVE' ? -1 : 0)};
`;

interface StyledSliderDotProps {
  $spaceBetween: number;
  $width: number;
  $height: number;
  $colour: string;
  $activeColour: string;
  $active: boolean;
}

// Button text colour changed to transparent to fix colour contrast accessibility issue.
export const StyledSliderDot = styled.li<StyledSliderDotProps>`
  width: ${({ $width, theme }) => ($width ? `${$width}px` : theme.pdpContent.sliderImageDots.width)};
  height: ${({ $height, theme }) =>
  $height ? `${$height}px` : theme.pdpContent.sliderImageDots.width};
  margin-right: ${({ $spaceBetween, theme }) =>
    $spaceBetween ? `${$spaceBetween}px` : theme.pdpContent.sliderImageDots.marginRight};
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  button {
    width: 100%;
    height: 100%;
    background: ${({ $active, $activeColour, $colour, theme }) =>
      $active
        ? $activeColour || theme.pdpContent.sliderImageDots.color
        : $colour || 'rgba(0, 0, 0, 0.25)'};
    border-radius: 50%;
    color: transparent;
    text-indent: -2rem;
    border: none;
    outline: none;
    padding: 0;
  }
`;
