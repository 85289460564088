import { ApolloLink } from '@apollo/client';

export const noRateLimitsLink = () => new ApolloLink((operation, forward) => {
  if (process.env.STELLATE_RATE_LIMITING_SHARED_SECRET) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'multisite-stellate-shared-secret': process.env.STELLATE_RATE_LIMITING_SHARED_SECRET,
      },
    }));
  }

  return forward(operation);
});