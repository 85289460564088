/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  updateRadioListShowMore,
} from './actions';

export type RadioListState = {
  showMore: boolean;
};

export const initialState: RadioListState = {
  showMore: false,
};

export const radioListReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateRadioListShowMore, (state, action) => {
      state.showMore = action.payload;
    });
});