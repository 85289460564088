import styled from 'styled-components';

import StyledFormLabel from '../../basic/FormLabel/FormLabelStyles';
import { StyledRadioButton } from '../../basic/RadioButton/RadioButtonStyles';
import { StyledInputContainer, StyledTextInput } from '../../basic/TextInput/TextInputStyles';

export const StyledPromoMessage = styled.p<{ $kgRedesignToggle: boolean }>`
  margin-bottom: ${({ theme }) => theme.vars.sizeM};
  margin-top: 0;
  text-align: left;
  text-transform: none;
  ${({ $kgRedesignToggle, theme }) =>
  $kgRedesignToggle ? `
      ${theme.types.h4Style}
      text-transform: none;
    ` : `
    ${theme.types.h3Style}
    `
  };
`;

export const Container = styled.div<{ $newLayout?: boolean }>`
  margin-bottom: ${({ $newLayout }) => $newLayout ? '3rem' : 0};

  /* Reduced specificity from StyledButton as was overriding hover styles. */
  button {
    width: 13rem;
    margin-left: ${({ theme }) => theme.vars.sizeM};
    background-color: ${({ theme }) => theme.footerColors.signUpButtonBackgroundColor};
    color: ${({ theme }) => theme.footerColors.signUpButtonTextColor};
    padding: 0.8rem;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
    margin: 0 auto;
    margin-top: ${({ theme }) => theme.vars.sizeM};
  }

  @media ${(props) => props.theme.devices.large} {
    margin-left: ${({ theme }) => theme.vars.sizeL};
    margin-bottom: 0;

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
`;

export const StyledSignUpMain = styled.div`
  display: flex;
`;

export const StyledSignUpForm = styled.form`
  ${StyledInputContainer} {
    width: 100%;

    input {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.vars.black};
      background: transparent;
      padding: 0.5rem 1rem;
      height: 2.8rem;

      /* Browser default outline removed in favour of a thicker border bottom */
      outline: none;

      &:focus {
        border-bottom-width: 2px;
      }

      &::placeholder {
        color: ${({ theme }) => theme.vars.black};
        font-family: ${({ theme }) => theme.footer.fonts.primary};
      }
    }

    @media ${(props) => props.theme.devices.large} {
      width: 80%;
    }
  }

  ${StyledTextInput} {
    color: ${({ theme }) => theme.vars.black};
  }
`;

export const StyledRadioButtonContainer = styled.div<{ $newLayout?: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.vars.sizeM};

  @media ${({ theme }) => theme.devices.large} {
    justify-content: unset;
  }
  ${StyledRadioButton} {
    &:last-of-type {
      margin-left: 3.2rem;
    }
    & + ${StyledFormLabel} {
      &::before {
        border: 1px solid ${({ theme, $newLayout }) => $newLayout ? theme.vars.greyDark : theme.footerColors.radioButtonBorderColor};
        background: ${({ theme }) => theme.vars.white};
      }
    }
    &:checked + ${StyledFormLabel} {
      &::before {
        border: 1px solid ${({ theme, $newLayout }) => $newLayout ? theme.vars.greyDark : theme.footerColors.radioButtonBorderColor};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.vars.white} inset;
        background: ${({ theme }) => theme.vars.black};
      }
    }
  }
`;

export const StyledPrivacyMessage = styled.div<{ $kgRedesignToggle: boolean }>`
  margin-top: ${({ theme }) => theme.vars.sizeM};
  text-align: center;
  font-family: ${({ theme }) => theme.footer.fonts.primary};
  font-size: 1.1rem;
  line-height: 1.8rem;

  a {
    color: ${({ theme }) => theme.vars.black};
    font-weight: 300;
    display: inline;
    text-decoration: underline;
  }

  p {
    ${({ theme }) => theme.types.labelStyle};
    ${({ $kgRedesignToggle }) =>
  $kgRedesignToggle && `
    text-align: left;
    `
  };
  }

  @media ${(props) => props.theme.devices.large} {
    text-align: left;

    a {
      text-align: left;
      justify-content: unset;
    }
  }
`;

export const StyledSignUpConfirmation = styled.div`
  background: ${({ theme }) => theme.vars.white};
  color: ${({ theme }) => theme.vars.black};
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.vars.sizeS};
  line-height: ${({ theme }) => theme.vars.sizeL};
  padding: 0.5rem 1rem;

  svg {
    margin-top: ${({ theme }) => theme.vars.sizeXS};
  }

  p {
    ${({ theme }) => theme.types.labelStyle};
    margin: 0.4rem;

    &.offer {
      ${({ theme }) => theme.types.h3Style};
      color: ${({ theme }) => theme.vars.brandPrimary};
      text-transform: uppercase;
      font-size: 1.4rem;
      margin-top: 0;
    }
  }
`;

export const StyledSignUpConfirmationHtml = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;