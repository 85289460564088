import styled from 'styled-components';

interface StyledLinkProps {
  $disabled?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
  ${({ theme }) => theme.types.linkStyles};
  display: inline-block;
  text-decoration: none;

  ${({ $disabled }) => $disabled === true &&
    `
      pointer-events: none;
    `}
  
  @media ${({ theme }) => theme.devices.large} {
    &::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: ${({ theme }) => theme.vars.black};
    transition: width 0.4s ease-in-out;
    margin-top: 3px;
   }
  }

  &:hover,
  &:focus {
    &::after {
      width: 100%;
    }
  }
`;
