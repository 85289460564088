import styled, { keyframes } from 'styled-components';

const iconDimensions = {
  width: '2.5rem',
  height: '1.8rem',
};

const loader = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 1.3rem;
    left: 1.3rem;
    font-size: ${({ theme }) => theme.vars.sizeM};
  }
`;

export const StyledInput = styled.input`
  &[name= "name"] {
    padding: 1rem 1.5rem 1rem 3.6rem;
    border: 1px solid ${(props) => props.theme.vars.greyDark};
    font-size: 1.4rem;
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.headerColors.backgroundColor};

    @media ${({ theme }) => theme.devices.large} {
      background: ${({ theme }) => theme.vars.white};
    }

    &::placeholder {
      font-family: ${(props) => props.theme.fonts.fontFamilyPrimary};
      color: ${(props) => props.theme.vars.black};
      font-size: 1.4rem;
    }
  }
`;

export const StyledCountryListWrapper = styled.div`
  display: block;
`;

export const StyledCountryList = styled.ul`
  list-style: none;
  margin: 0 0 2.5rem;
  padding: 0 0 0.1rem;
  overflow-y: auto;
  max-height: 40vh;

  @media ${(props) => props.theme.devices.medium} {
    height: auto;
  }

  > li {
    margin-bottom: 0;
    border-left: 1px solid ${(props) => props.theme.vars.greyDark};
    border-bottom: 1px solid ${(props) => props.theme.vars.greyDark};
    border-right: 1px solid ${(props) => props.theme.vars.greyDark};

    > button {
      display: flex;
      text-transform: none;
      font-weight: normal;
      font-size: 1.1rem;
      width: 100%;
      align-items: center;
      text-decoration: none;
      padding: 1.4rem 0.8rem;

      &:hover {
        background: ${({ theme }) => theme.vars.greyLightest};
      }

      > img {
        width: ${iconDimensions.width};
        height: ${iconDimensions.height};
        margin-right: 1.1rem;
      }

      > span + span {
        &::before {
          content: " ";
          display: inline-block;
          margin-left: 1.1rem;
        }
      }

      > div {
        margin-right: 1.1rem !important;
      }
    }
  }
`;

export const StyledCountryPickerButton = styled.button<{ $listVisible?: boolean }>`
  background: none;
  border: none;
  line-height: 1.2;
  padding: 0;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  text-decoration: underline;
  text-align: left;
  font-size: 1.2rem;
  display: ${({ $listVisible }) => ($listVisible ? 'none' : 'block')};
  color: ${({ theme }) => theme.vars.black};

  @media ${({ theme }) => theme.devices.large} {
    color: inherit;
  }
`;

export const StyledCountryPickerButtonMobile = styled(StyledCountryPickerButton)`
  display: block;
  text-align: left;
  margin-left: 1rem;
  margin-bottom: ${({ theme }) => theme.vars.sizeL};
  width: 100%;
  outline: none;
  text-decoration: none;

  img {
    margin-right: 1rem;
  }

  @media ${(props) => props.theme.devices.large} {
    display: none;
  }
`;

export const StyledLoader = styled.div`
  text-indent: -9999px;
  margin: auto;
  display: block;
  width: 60px;

  span {
    background: ${({ theme }) => theme.vars.greyDark};
    float: left;
    height: 10px;
    margin: 2px;
    width: 10px;
    opacity: 1;
    display: block;
    animation: ${loader} 1.1675s normal infinite;
    border-radius: 100%;

    &:nth-of-type(1) {
      animation-delay: 0.2295s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.5455s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.6985s;
    }
  }
`;
