import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'next/navigation'
import Cookies from 'universal-cookie';

import { updateHasFilledForm } from '../../../features/dataCapture';
import { selectReferrer, selectServerCookies } from '../../../features/static';
import { DataCaptureObject } from '../../../types/custom-types';

interface UseDataCaptureCookiesReturn {
  cookieValue: DataCaptureObject;
  showEntrance: boolean;
  showOrganic: boolean;
  showContiunity: boolean;
  setDataCaptureCookieValue: (parma: DataCaptureObject) => void
}

const useDataCaptureCookies = (): UseDataCaptureCookiesReturn => {
  const cookiesRedux = useSelector(selectServerCookies);
  const searchParams = useSearchParams() || null;
  const query = searchParams !== null ? Object.fromEntries(searchParams.entries()) : '';
  const finalQuery = { ...query }
  delete finalQuery?.PlpKey;
  delete finalQuery?.Pdpkey;
  const dispatch = useDispatch()
  const cookies = cookiesRedux ? new Cookies(cookiesRedux) : new Cookies();
  const dataCookieName = 'onsitedata';
  const onSiteDataCookie = cookies?.get(dataCookieName) || {};
  const { hasFilledForm = false } = onSiteDataCookie;
  const userHasPurchased = cookies?.get('customer_has_purchased') || false;
  const hasQuery = Object.keys(finalQuery).length > 0 && finalQuery.constructor === Object;
  const referrer = useSelector(selectReferrer)
  const regEx = new RegExp('search.yahoo|ecosia.org|r.search.aol.com|yell.com|search.bt.com|bing.com|duckduckgo.com|results.searchlock.com|google.co.uk|google.com|google.|int.search.tb.ask.com|search.lilo.org|search.visymo.com|search.mysearch.com|advancedsearch2.virginmedia.com|msn.com')
  const isFromSearch = referrer ? regEx?.test(referrer) : false;
  const isFromEmail = finalQuery?.utm_source ? finalQuery?.utm_source === "emarsys" : false;
  const expire24h = Date.now() + 24 * 60 * 60 * 1000;
  const expire6month = Date.now() + 6 * 30 * 24 * 60 * 60 * 1000;
  const maxCount = 5;
  const maxCountHit = onSiteDataCookie.count >= maxCount;
  const initialValue = {
    count: 1,
    maxCount,
    expire24h,
    expire6month,
    hasFilledForm: false,
  }

  const setDataCaptureCookieValue = (cookieValue: DataCaptureObject) => {
    dispatch(updateHasFilledForm(cookieValue.hasFilledForm));
    const nextYear = new Date();
    const current = new Date();
    nextYear.setFullYear(current.getFullYear() + 5);
    cookies.set(dataCookieName, JSON.stringify(cookieValue), { path: '/', expires: nextYear });
  }

  const has24hExpired = Object.keys(onSiteDataCookie).length !== 0 ? Date.now() > onSiteDataCookie.expire24h : true;
  const has6monthExpired = Object.keys(onSiteDataCookie).length !== 0 ? Date.now() > onSiteDataCookie.expire6month : true;
  const hasExpired = has24hExpired === true || has6monthExpired === true;

  useEffect(() => {
    if (Object.keys(onSiteDataCookie).length !== 0 && has6monthExpired) {
      // check for 6 month expiry: reset count = 1, and 6 month exp: now + 6 months
      setDataCaptureCookieValue({
        ...onSiteDataCookie,
        count: 1,
        expire24h,
        expire6month,
      });
    }

    else if (Object.keys(onSiteDataCookie).length !== 0 && onSiteDataCookie.count < maxCount && has24hExpired) {
      // if count below max: increment count, set expire24h to now + 24 hours, show popup
      setDataCaptureCookieValue({
        ...onSiteDataCookie,
        count: onSiteDataCookie.count + 1,
        expire24h,
      });
    }

    else if (Object.keys(onSiteDataCookie).length === 0) {
      setDataCaptureCookieValue(initialValue)
    }
  }, [])

  const hideAllPopups = isFromEmail || hasFilledForm || userHasPurchased || maxCountHit;
  const showOrganic = !hideAllPopups && hasQuery === false && isFromSearch && hasExpired;
  const showEntrance = !hideAllPopups && hasQuery === false && !isFromSearch && hasExpired;
  const showContiunity = hasFilledForm && !userHasPurchased;

  return {
    cookieValue: onSiteDataCookie,
    showEntrance,
    showOrganic,
    showContiunity,
    setDataCaptureCookieValue
  };
};

export default useDataCaptureCookies;
