import { createGlobalStyle } from 'styled-components';

import { fonts } from '../resources/resources';
import KgTypes from '../resources/typeSnippets';

export const GlobalType = createGlobalStyle`
  body {
    font-family: ${fonts.fontFamilyPrimary};
  }

  h1,
  .h1-style {
    ${KgTypes.h1Style}
  }

  h2,
  .h2-style {
    ${KgTypes.h2Style}
  }

  h3,
  .h3-style {
    ${KgTypes.h3Style}
  }

  h4,
  .h4-style {
    ${KgTypes.h4Style}
  }

  p,
  .p-style {
    ${KgTypes.labelStyle}
  }

  ul li,
  ol li {
    ${KgTypes.labelStyle}
  }

  a {
    ${KgTypes.linkStyles}
  }

  button {
    ${KgTypes.ctaTextStyles}
  }
`;
