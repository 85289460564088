import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from '../../features/static';
import RadioButtonStructure from './RadioButtonStructure';

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  className?: string;
  isDefault?: boolean;
  htmlFor?: string;
  selected?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  label,
  value = '',
  name,
  onChange,
  id,
  className = '',
  isDefault,
  selected,
  ...otherProps
}) => {
  const toggles = useSelector(selectToggles);
  const engs15241 = toggles?.engs15241SortbyNotUpdating;

  const componentProps = {
    label,
    value,
    name,
    onChange,
    id,
    className,
    isDefault,
    selected,
    engs15241,
    ...otherProps,
  };

  return <RadioButtonStructure {...componentProps} />;
};

export default RadioButton;
