import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';

import { ConstantsState, HeaderLayout } from '../../../shared/types/state-types';

const Constants: ConstantsState = {
  datalayerAddToBasketSizesType: 'eu',
  plpProductsPerPage: 36,
  brandName: 'Kurt Geiger',
  plpSearch: {
    showHeader: false,
    noResults: [
      "We couldn't find any matches for '{{searchTerm}}'. Please check your spelling or try again using a different search term.",
    ],
    searchedResults: ["We found {{results}} for '{{searchTerm}}'."],
    recommendedResults: [
      "We couldn't find any matches for '{{searchTerm}}'.",
      "But we did find some for '{{returnedSearch}}'.",
    ],
  },
  searchPlaceholder: 'Search Kurt Geiger...',
  defaultMetaData: {
    title: 'Designer Shoes & Accessories For Men & Women | Kurt Geiger',
    description:
      'Design is what we do. Kindness is what we are. Shop shoes and accessories from Kurt Geiger London, KG Kurt Geiger, Carvela and more.',
  },
  storyTypes: [
    { title: 'ALL STORIES', value: 'all' },
    { title: 'CELEBRITY STYLE', value: 'as-seen-on' },
    { title: 'TRENDS', value: 'seasonal-trend' },
    { title: 'CAMPAIGN', value: 'gallery' },
    { title: 'FILMS', value: 'video' },
  ],
  storyTitle: 'STORIES',
  pdpProductInformation: {
    accordionTitles: {
      productDetails: 'Product Details',
      productDescription: 'Description',
      delivery:
        process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus'
          ? 'Free Shipping & Returns'
          : 'Delivery & Returns',
    },
  },
  storeLocator: {
    showDistanceLabel: true,
    linkHref: 'https://stores.kurtgeiger.com',
  },
  headerImageDimensions: {
    width: 1140,
    height: 480,
  },
  headerLayout: HeaderLayout.TransparentHeaderCompact,
  showSignUpFormSuccessSvg: false,
  mobileNewsletterAccordion: true,
  searchResultsProductNameTitleCase: true,
  newFooterLayout: true,
  accordionIcons: {
    plus: faPlus as IconProp,
    minus: faMinus as IconProp,
  },
  newsletterButtonType: 'secondary',
  plpProductCardSizes: {
    small_view: '(max-width: 1024px) 50vw, 33vw',
    large_view: '(max-width: 1024px) 50vw, 25vw',
    default: '(max-width: 1024px) 50vw, 25vw',
  },
  initialsId: 'kg',
  // TODO: clean up after react router is complete
  plpRoutes: ['/new-in', '/men', '/women/shoes', '/women/accessories/accessories', '/offers'],
  loadingGif: 'KG-Load.gif',
};

export default Constants;
