import React, { FC, MouseEvent } from 'react';

import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import { StyledArrow } from './ArrowStyles';

export interface ArrowProps {
  className: string,
  onClick: (e: MouseEvent<Element>) => void,
  width?: number,
  height?: number,
  screenReaderText: string,
}

const Arrow: FC<ArrowProps> = ({
  className,
  onClick,
  width = 0,
  height = 0,
  screenReaderText,
}) => {

  return (
    <StyledArrow type="button" className={className} onClick={onClick} width={width} height={height}>
      <ScreenReaderOnlyText text={screenReaderText} />
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.37999 2.60872C5.46494 2.60872 5.54009 2.64139 5.60544 2.70674L10.1733 7.27457C10.2386 7.33992 10.2713 7.41507 10.2713 7.50002C10.2713 7.58498 10.2386 7.66013 10.1733 7.72547L5.60544 12.2933C5.54009 12.3587 5.46494 12.3913 5.37999 12.3913C5.29504 12.3913 5.21989 12.3587 5.15454 12.2933L4.66443 11.8032C4.59908 11.7378 4.56641 11.6627 4.56641 11.5777C4.56641 11.4928 4.59908 11.4176 4.66443 11.3523L8.5167 7.50002L4.66443 3.64775C4.59908 3.58241 4.56641 3.50726 4.56641 3.4223C4.56641 3.33735 4.59908 3.2622 4.66443 3.19685L5.15454 2.70674C5.21989 2.64139 5.29504 2.60872 5.37999 2.60872Z"
          fill="black"
        />
      </svg>
    </StyledArrow>
  );
}


export default Arrow;
