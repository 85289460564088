/* Packages */
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from "../../features/static";
import { NewsletterSignup } from '../../types/middleware-types';
import useDataCaptureCookies from '../../utils/customHooks/useDataCaptureCookies';
import EntrancePopUp from './EntrancePopUp';
import OrganicPopUp from './OrganicPopUp';

interface OnSiteDataCaptureProps {
  onSiteDataCapture: NewsletterSignup;
}

const DataCapture: FC<OnSiteDataCaptureProps> = ({ onSiteDataCapture }) => {
  const toggles = useSelector(selectToggles);
  const [showThanksInfo, setShowThanksInfo] = useState(false);
  const { cookieValue, showEntrance, showOrganic, setDataCaptureCookieValue } = useDataCaptureCookies()
  const delayAmount = toggles?.featureDataCapturePopupDelay ? 3000 : 0;
  const closeDelayAmount = 3000 + delayAmount;

  const signUpButtonClicked = (): void => {
    setDataCaptureCookieValue({
      ...cookieValue,
      hasFilledForm: true,
    });
    setShowThanksInfo(true);
  };

  const componentProps = {
    ...onSiteDataCapture?.popups?.welcome,
    newsletterSignup: onSiteDataCapture,
    description: onSiteDataCapture?.popups?.entrance?.description,
    signUpButton: onSiteDataCapture?.popups?.entrance?.signUpButton,
    title: onSiteDataCapture?.popups?.entrance?.organicSearch?.title,
    popUpthanks: onSiteDataCapture?.popups?.entrance?.signUpSuccess,
    signUpButtonClicked,
    showEntrancePopUp: showEntrance,
    showOrganicPopUp: showOrganic,
    showThanksInfo,
    closeDelayAmount,
    delayAmount
  };

  return (
    <>
      {toggles?.featureDataCapture && (
        <>
          {toggles?.featureEntrancePopUp && (
            <EntrancePopUp {...componentProps} />
          )}
          {toggles?.featureOrganicPopUp && (
            <OrganicPopUp {...componentProps} />
          )}
        </>
      )}
    </>
  );
};

export default DataCapture;
