import styled from 'styled-components';

import { Maybe } from "../../types/middleware-types";
import { StyledCarouselContainer } from '../Carousel/CarouselStyles';
import { StyledSlider } from '../Slider/SliderStyles';

interface PromoBannerProps {
  $height?: Maybe<number>;
  $compactHeader: boolean;
  $headerPosition: string;
  $top: number;
  $stickyCompactHeader: boolean;
  $isReverse: boolean;
}

export const StyledPromoBannerContainer = styled.div<PromoBannerProps>`
  ${({ $stickyCompactHeader, $headerPosition, $compactHeader, $top, theme, $isReverse }) => {
  return $stickyCompactHeader && !$isReverse && `
  z-index: 10;
  width: 100%;
  position: ${$compactHeader ? $headerPosition : 'static'};
  top: ${$top}px;
  background: ${theme.vars.white};
  `
}}

  ${StyledCarouselContainer} {
    height: ${({ $height }) => $height}px;
    ${StyledSlider} {
      padding: 0;
    }
  }

  s p {
    margin: 0;
  }
`;
interface StyledPromoBannerListContainerProps {
  $height?: Maybe<number>;
}


export const StyledContinuityBar = styled(StyledPromoBannerContainer)`
  text-align: center;

  strong {
    ${({ theme }) => theme.types.boldStyle};
  }
`;


export const StyledPromoBannerListContainer = styled.div<StyledPromoBannerListContainerProps>`
  height: ${({ $height }) => $height}px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
`;
