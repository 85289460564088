import { devices, vars } from './resources';

interface SiteSnippetsInterface {
  [key: string]: string;
}

export const SiteSnippets: SiteSnippetsInterface = {
  pageWrapper: `
  padding: 9rem 1rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;

  @media  ${devices.large} {
    padding: 15rem 6.4rem 4rem;
    margin: 0 auto;
  }
  `,
  boxWrapper: `
  margin: 1.5rem;

  @media ${devices.large} {
    border: 1px solid ${vars.greyDark};
    padding: 1.7rem 2.5rem;
  }
  `
};

export default SiteSnippets;
