import React, { FC, memo } from 'react';

import constants from '../../../sites/constants';
import { Footer } from '../../types/middleware-types';
import Accordion from '../Accordion';
import AppStoreLogos from '../AppStoreLogos';
import FooterLinks from '../FooterLinks';
import NewsletterSignUp from '../NewsletterSignUp';
import SocialLogos from '../SocialLogos';
import {
  StyledCopyrightMessage,
  StyledFooter,
  StyledFooterMain,
  StyledFooterMessages,
  StyledFooterSection,
  StyledManagePreferencesText,
  StyledManagePreferencesWrapper,
  StyledSocialLogos,
} from './FooterStyles';

export interface FooterStructureProps extends Partial<Footer> {
  currentYear: number;
  isMobile?: boolean;
  isTablet?: boolean;
  showSignUpDescription: boolean;
  showMobileAppLogo: boolean;
  mobileNewsletterAccordion: boolean;
  newLayout?: boolean;
  kgRedesignToggle: boolean;
  showFooterSocialLogos: boolean;
}

const FooterStructure: FC<FooterStructureProps> = ({
  copyright,
  currentYear,
  menu,
  newsletterSignup,
  appStores,
  socialMedia,
  isMobile,
  isTablet,
  showSignUpDescription,
  showMobileAppLogo,
  mobileNewsletterAccordion,
  newLayout,
  kgRedesignToggle,
  showFooterSocialLogos,
}) => {
  return (
    <StyledFooter data-hookid="globalFooter" $newLayout={newLayout} $kgRedesignToggle={kgRedesignToggle}>
      <StyledFooterMain>
        <StyledFooterSection width={newLayout ? '60%' : '50%'}>
          {menu && <FooterLinks footerNavigation={menu} />}
          {showFooterSocialLogos && (
            <StyledSocialLogos>
              {showMobileAppLogo && appStores && <AppStoreLogos links={appStores.accounts} />}
              {socialMedia && <SocialLogos data={socialMedia} />}
            </StyledSocialLogos>
          )}
        </StyledFooterSection>
        <StyledFooterSection width={newLayout ? '40%' : '50%'}>
          {newsletterSignup && (isMobile || isTablet) && mobileNewsletterAccordion ? (
            <Accordion
              title={newsletterSignup.description || ''}
              plusIcon={constants.accordionIcons.plus}
              minusIcon={constants.accordionIcons.minus}
            >
              <NewsletterSignUp
                newsletterSignup={newsletterSignup}
                showSignUpDescription={showSignUpDescription}
              />
            </Accordion>
          ) : (
            <>{newsletterSignup && <NewsletterSignUp newsletterSignup={newsletterSignup} />}</>
          )}
        </StyledFooterSection>
      </StyledFooterMain>
      <StyledFooterMessages>
        <StyledCopyrightMessage data-hookid="globalFooterCopyrightMessage">
          &copy; {currentYear} {copyright}
        </StyledCopyrightMessage>
        {process.env.NEXT_PUBLIC_ONETRUST_SCRIPT === 'true' && (
          <StyledManagePreferencesWrapper className="ot-floating-button__open">
            <StyledManagePreferencesText title="manage cookie preferences" $kgRedesignToggle={kgRedesignToggle}>
              Manage Preferences
            </StyledManagePreferencesText>
          </StyledManagePreferencesWrapper>
        )}
      </StyledFooterMessages>
    </StyledFooter>
  );
};

export default memo(FooterStructure);
