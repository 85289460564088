/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';

import { SubMenuData } from '../../../types/custom-types';
import {
  IndexSubMenu,
  MobileSubMenu,
  SubMenuColumn,
  SubMenuItem,
} from '../../../types/middleware-types';
import { isSubNavItem } from '../../../types/type-checkers';
import SubMenuStructure from './SubMenuStructure';

export interface SubMenuProps {
  data: SubMenuData | SubMenuItem[] | IndexSubMenu[];
  showSubNav?: boolean;
  parent: string;
}

const SubMenuComponent: FC<SubMenuProps> = ({ data, showSubNav, parent }) => {
  let subNavItems: Array<SubMenuColumn | MobileSubMenu | SubMenuItem>;
  if (Array.isArray(data)) {
    subNavItems = data;
  } else if (data?.__typename === 'IndexSubMenu') {
    subNavItems = [data];
  } else {
    subNavItems = isSubNavItem(data) ? data.items : [];
  }
  let imageLinkList = false;

  subNavItems.forEach((item) => {
    if (item.__typename === 'SubMenuImageLinkList') {
      imageLinkList = true;
    } else {
      imageLinkList = false;
    }
  });
  
  const componentProps = { data: subNavItems, showSubNav, imageLinkList, parent };
  return <SubMenuStructure {...componentProps} />;
};

export default SubMenuComponent;
