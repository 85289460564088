import React, { FC, ReactNode } from 'react';

import { StyledMobileMenuLink } from './MobileMenuLinkStyles';

export interface MobileMenuLinkStructureProps {
  children?: ReactNode;
  compactHeader?: boolean;
  element?: ReactNode;
}

const MobileMenuLinkStructure: FC<MobileMenuLinkStructureProps> = ({
  children,
  compactHeader,
  element,
}) => {
  return (
    <StyledMobileMenuLink data-hookid="globalHeaderMobileMenuLink" $compactHeader={compactHeader}>
      {children}
      {element}
    </StyledMobileMenuLink>
  );
};

export default MobileMenuLinkStructure;
