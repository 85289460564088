'use client'

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { updateShowMobileCountryPicker } from '../../features/nav';
import { selectCountryPicker, selectToggles } from '../../features/static';
import { isCountryPickerItem } from "../../types/type-checkers";
import CountryPickerStructure from './CountryPickerStructure';

export type CountryPickerTab = 'info' | 'list';

const CountryPicker: FC = () => {
  const cookies = new Cookies();
  const toggles = useSelector(selectToggles);
  const currentCountryData = useSelector(selectCountryPicker);

  const dispatch = useDispatch();

  const countryPickr = useSelector(selectCountryPicker);

  const showCountryPicker = toggles?.featureCountryPicker;
  const hideCountryPickerOnGlobalESite =
    toggles?.featureGlobalE || toggles?.featureGlobalECountryPickerAndSiteSwitcherOnly;

  const [currentCountry, setCurrentCountry] = useState({
    ...countryPickr,
  });
  const [isTriggered, setIsTriggered] = useState(false);
  const [selectedTab, setSelectedTab] = useState<CountryPickerTab>('info');

  /* Watches for hover/focus to keep styling for the trigger */
  const triggerHoverOrFocus = (hover: boolean) => {
    setIsTriggered(hover);
  };

  useEffect(() => {
    if (countryPickr && Object.entries(currentCountry).length === 0) {
      setCurrentCountry({ ...countryPickr })
    }
  }, [countryPickr])

  useEffect(() => {
    if (currentCountryData && isCountryPickerItem(currentCountryData)) {
      setCurrentCountry({ ...currentCountryData });

      const hasCookie = cookies.get('selectedCountry');
      const defaultCookie =
        process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus' ? 'US' : 'GB';
      if (!hasCookie || hasCookie !== currentCountryData.code) {
        cookies.set('selectedCountry', currentCountryData.code ?? defaultCookie, {
          maxAge: 7776000,
          path: '/',
        });
      }

      setSelectedTab('info');
      setIsTriggered(false);
    }
  }, [currentCountryData]);

  const showMenu = () => {
    dispatch(updateShowMobileCountryPicker(false));
  };

  const showInfo = () => {
    setSelectedTab('info');
  };

  const showList = () => {
    setSelectedTab('list');
  };

  const componentProps = {
    ...currentCountry,
    currentCountry,
    isTriggered,
    triggerHoverOrFocus,
    selectedTab,
    showMenu,
    showInfo,
    showList
  };

  return (
    <>
      {hideCountryPickerOnGlobalESite ? (
        <div data-ge-shippingswitcher-flag />
      ) : (
        <>{showCountryPicker && currentCountry && <CountryPickerStructure {...componentProps} />}</>
      )}
    </>
  );
};

export default CountryPicker;
