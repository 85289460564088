import styled from 'styled-components';

import { StyledLink } from '../../../../basic/LinkItem/LinkItemStyles';
import { StyledFooterLinkList } from '../../../AccordionItemLayout/AccordionItemFooter/AccordionItemStyles';
import { StyledImageHtmlLink } from '../ImageHtmlLink/ImageHtmlLinkStyles';
import { StyledLinkListContainer } from '../SubMenuLinkList/LinkListStyles';

export const StyledLinkListWithImage = styled.div`
  ${StyledFooterLinkList} {
    margin-left: 0;
    ${StyledLink} {
      ${({ theme }) => theme.types.labelStyle}
      font-weight: 400;
    }
  }
  ${StyledLinkListContainer} {
    h3 {
      display: none;
    }
  }
`;

export const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${StyledImageHtmlLink} {
    img {
      margin: 1rem;
    }
  }
`;
