import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { FilterItemOptionsType } from '../components/Filters/Filters.types';

import { CMSBlocks, FormattedFilters, InitialFilterDataInterface, PlpFilters, ProductViewType, UpdatedCmsBlocksPage } from './custom-types';
import {
  BrandIndex,
  Cart,
  CartPrices,
  Category,
  CategoryActiveFilter,
  CategoryBanner,
  CategoryFilter,
  CategoryFilterOption,
  CategoryPagination,
  CategorySortByOption,
  CmsBlocksPage,
  CmsPage,
  CmsStoriesListingPage,
  ColourTheme,
  ContentBlock,
  ContentBlockMediaBanner,
  CountryPickerItem,
  CountryPickerResponse,
  Cta,
  Footer,
  Header,
  HeaderConsolidated,
  HeaderNavigation,
  Image,
  ImageLinkCampaign,
  ImageV2,
  ListItem,
  Maybe,
  Metadata,
  Minibag,
  NearbyStore,
  Product,
  ProductListItem,
  ProductSearch,
  Sitemap,
  SizeLabels,
  Store,
} from './middleware-types';

export enum AddingStatus {
  Added = 'ADDED',
  Adding = 'ADDING',
  Error = 'ERROR',
}

export interface ProductSearchWithPerPage extends ProductSearch {
  perPage?: number;
}

export interface CategoryWithPerPage extends Category {
  perPage?: number;
}

export type PlpDataWithPerPage = ProductSearchWithPerPage | CategoryWithPerPage;

export type GiftCardInterface = {
  cta: Cta;
  giftCardInfo: [string, string, string];
  image: Image;
  introText: string;
};

export interface SiteData {
  __typename?: string;
  pageName?: string;
  giftCard?: GiftCardInterface;
  blocks?: Maybe<Array<CMSBlocks>> | Maybe<Array<ContentBlock>>;
  brandIndex?: Maybe<BrandIndex>;
  companyName?: Maybe<string>;
  brandedPlp?: { colourTheme?: ColourTheme };
  productListLoader?: string;
  headerColourTheme?: string;
  metadata?: Metadata;
  title?: string;
  logo?: Maybe<ImageV2>;
  background?: Maybe<ImageV2>;
  html?: string;
  referrer?: string | null
}

export interface StaticState {
  siteData: Maybe<SiteData>;
  cmsData: Maybe<CmsPage & { CMSPageBuilder?: CmsBlocksPage }>;
  headerData: Maybe<HeaderConsolidated>;
  headerOnlyData: Maybe<Header>;
  headerNavigationData: Maybe<HeaderNavigation>;
  countryPickerData: Maybe<CountryPickerResponse>;
  footerData: Maybe<{ footer: Footer }>;
  compactHeader: boolean;
  findInStore: boolean;
  showBrandName: boolean;
  isBrandPage: boolean;
  showMobileAppLogo: boolean;
  isSvgVisible: boolean;
  sitemapData: Maybe<Sitemap>;
  removeXFromFirstElement: boolean;
  trackMyOrderData: Maybe<CmsPage>;
  features?: { [key: string]: boolean };
  toggles: LDFlagSet;
  initCookies?: string;
}

export interface NearbyStoresDataState {
  stores: Maybe<{ nearbyStores: Array<NearbyStore> }>;
  loading: boolean;
  fetchError: boolean;
  locationDecodingError: string;
  searchLocation: string;
  coords: { lat: Maybe<number>; lng: Maybe<number> };
}

export interface FindInStoreState {
  currentFindInStoreProduct: Maybe<Product>;
  nearbyStoresData: NearbyStoresDataState;
  selectedStore: Maybe<NearbyStore | Store>;
  selectedFisSize: Maybe<string>;
}

export type PlpState = {
  productView: ProductViewType;
  plpLoading: {
    loading: boolean;
  };
  plpData: Category;
  productList: {
    items: ProductListItem[];
    loadDataAbove: boolean;
  };
  productListCurrentPage: {
    page: number;
  };
  plpPage: number;
  plpMetadata: Metadata;
  initialQuery: {
    PlpKey: Maybe<string>;
  };
};

export interface AccordionState {
  currentOpenFilter?: Maybe<string>;
}

export interface AuthState {
  isAuth: boolean;
}

export interface DataCaptureState {
  hasFilledForm: boolean;
}

export interface BagState {
  bagData: Maybe<Minibag>;
  addingStatus: AddingStatus | null;
  currentLineNumber: string;
}

export enum HeaderLayout {
  TransparentHeader = 'TransparentHeader',
  TransparentHeaderCompact = 'TransparentHeaderCompact',
  TransparentHeaderCompactReverse = 'TransparentHeaderCompactReverse'
}

export interface ConstantsState {
  datalayerAddToBasketSizesType: keyof SizeLabels;
  brandName: string;
  plpProductsPerPage: number;
  plpSearch: {
    showHeader: boolean;
    noResults: Array<string>;
    searchedResults: Array<string>;
    recommendedResults: Array<string>;
  };
  searchPlaceholder: string;
  defaultMetaData: Record<string, string>;
  storyTypes: {
    title: string;
    value: string;
  }[];
  storyTitle: string;
  pdpProductInformation: Record<string, Record<string, string>>;
  storeLocator: {
    showDistanceLabel: boolean;
    linkHref: string;
  };
  headerImageDimensions: {
    width: number;
    height: number;
  };
  headerLayout: HeaderLayout;
  showSignUpFormSuccessSvg: boolean;
  mobileNewsletterAccordion: boolean;
  newsletterButtonType: string;
  searchResultsProductNameTitleCase: boolean;
  newFooterLayout?: boolean;
  accordionIcons: {
    plus: any;
    minus: any;
  };
  plpProductCardSizes: {
    small_view: string;
    large_view: string;
    default: string;
  };
  initialsId: string;
  plpRoutes: string[];
  loadingGif: string;
}

export interface CurrentCountryState {
  currentCountryData: Maybe<CountryPickerItem>;
  showCountryPicker: boolean;
  showMobileCountryPicker: boolean;
}

export interface StoriesState {
  id: string;
  storiesById: Record<string, CmsStoriesListingPage> | { [x: string]: UpdatedCmsBlocksPage };
  loading: boolean;
  refetch?: boolean;
}

export interface ProductListCurrentPageType {
  page: number;
  loadDataAbove?: boolean;
}

export interface ProductListType {
  items: Array<ListItem>;
  loadDataAbove: boolean;
}

export interface PlpLoadingType {
  loading?: boolean;
  productLoading?: boolean;
}

export interface PlpContentState {
  productView: ProductViewType;
  plpLoading?: PlpLoadingType;
  plpData?: Maybe<CategoryWithPerPage> | Maybe<ProductSearchWithPerPage>;
  productList?: Maybe<ProductListType>;
  productListCurrentPage: ProductListCurrentPageType;
  plpPage: number;
  refreshPlpData?: boolean;
  plpMetadata?: Maybe<Metadata>;
  initialQuery?: Maybe<string>;
  canonicalUrl?: string;
  paginationData?: Maybe<CategoryPagination>;
  routeUrl: string;
  categoryCarousel?: Maybe<ImageLinkCampaign>
}

export interface CurrentFilterType {
  mob?: boolean;
  add: boolean;
  value: string;
  url?: Maybe<string>;
  options?: Array<FilterItemOptionsType>;
  currentFilterId: string;
  label?: string;
  hasMultipleValues?: boolean;
}

export interface CurrentMobileFiltersType {
  label?: string;
  options?: Array<CategoryFilterOption>;
  currentFilterId?: string;
  type?: string;
  facetId: string;
}

export interface ProductListFilterOptionsType {
  categoryPath?: string | string[] | null;
  sortBy?: string | null;
  filters: FormattedFilters;
}

export interface PlpFilterState {
  currentSizeQueryData: string | null | undefined;
  productListFilterOptions: Maybe<ProductListFilterOptionsType>;
  currentFilter: Maybe<CurrentFilterType>;
  currentMobileOptions: Maybe<CurrentMobileFiltersType>;
  currentActiveFilters?: Maybe<Array<CategoryActiveFilter>>;
  currentSortBy: Maybe<string>;
  slideFilters: boolean;
  currentSelectedFilters: Record<string, Array<string>>;
  applyFilterEnabled?: boolean;
  filterMenuOpenMobile?: boolean;
}

export interface AllStates {
  accordionState?: AccordionState;
  authState: AuthState;
  currentCountryState: CurrentCountryState;
  findInStoreState: FindInStoreState;
  storiesState: StoriesState;
  constantsState: ConstantsState;
}

export interface AllReducers {
  authReducer: () => AuthState;
  constantsReducer: () => ConstantsState;
  currentCountryReducer: () => CurrentCountryState;
  findInStoreReducer: () => FindInStoreState;
  plpContentReducer: () => PlpContentState;
  plpFilterReducer: () => PlpFilterState;
  staticReducer: () => StaticState;
  storiesReducer: () => StoriesState;
}

export type PlpDataWithoutItems = Omit<Category | ProductSearch, 'items'>;

export type OpenFilterOptions = '' | 'Category' | 'Filters' | 'Sort By' | 'Page View' | 'FilterPersist';

export interface FiltersState {
  plpData: Maybe<PlpDataWithoutItems>;
  productListItems: Maybe<Array<ListItem>>;
  currentSelectedFilters: Record<string, Array<string>>;
  categories: Maybe<Array<CategoryFilter>>;
  pageView: string;
  sortBy: Maybe<Array<CategorySortByOption>>;
  currentSortBy: Maybe<string>;
  currentPendingFilters: Array<CategoryFilter>;
  selectedCategory: Maybe<string>;
  triggerDataFetch: boolean;
  activeCategory: Maybe<Array<CategoryActiveFilter>>;
  openFilterDropdown: OpenFilterOptions;
  formattedFilters: PlpFilters | {};
  mobFiltersOpen: boolean;
  closeAllFilterPopups: boolean;
  brandedPlpData: CategoryBanner | ContentBlockMediaBanner | null;
}

export interface FiltersReduxState {
  plpData: Maybe<PlpDataWithoutItems>;
  categories: Maybe<Array<CategoryFilter>>;
  pageView: string;
  sortBy: Maybe<Array<CategorySortByOption>>;
  currentSortBy: Maybe<string>;
  currentPendingFilters: Array<CategoryFilter>;
  selectedCategory: Maybe<string>;
  selectedPrice: Maybe<string>;
  selectedGender: Maybe<string>;
  activeCategory: Maybe<Array<CategoryActiveFilter>>;
  openFilterDropdown: OpenFilterOptions;
  formattedFilters: PlpFilters | {};
  mobFiltersOpen: boolean;
  closeAllFilterPopups: boolean;
  initialFilterData: InitialFilterDataInterface;
  brandedPlpData: CategoryBanner | ContentBlockMediaBanner | null;
}

export interface CartState {
  cartTotals: CartPrices | null;
  currentCart: Cart | null;
  productCount: number;
  checkForGiftBox: boolean;
}
