import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompactHeader, selectToggles } from '../../../../features/static';
import { ImageHtmlLink as ImageHtmlLinkType, Maybe } from '../../../../types/middleware-types';
import formatPromoEvent from '../../../../utils/DataLayer/formatPromoEvent';
import ImageHtmlLinkStructure from './ImageHtmlLinkStructure';

export interface ImageHtmlLinkProps {
  data: Maybe<ImageHtmlLinkType>;
  linkPosition?: string;
  isLast?: boolean;
  id?: string;
}

const ImageHtmlLink: FC<ImageHtmlLinkProps> = ({ data, linkPosition, isLast, id }) => {
  const compactHeader = useSelector(selectIsCompactHeader);
  const [hasBeenSeen, setHasBeenSeen] = useState(false);
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const handlePromoView = (isVisible: boolean, item: Maybe<ImageHtmlLinkType>) => {
    if (isVisible && !hasBeenSeen && item?.link) {
      formatPromoEvent(
        item?.link?.text,
        1,
        item?.link?.url,
        item?.image.desktop.src,
        'meganav',
        'view',
      );
      setHasBeenSeen(true);
    }
  };

  const componentProps = {
    content: data,
    linkPosition,
    compactHeader,
    handlePromoView,
    isLast,
    id,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };
  return <ImageHtmlLinkStructure {...componentProps} />;
};

export default ImageHtmlLink;
