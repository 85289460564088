import styled from 'styled-components';

import { StyledProductCardContainerTop } from '../../Product/ProductCard/ProductCardStyles';

export const StyledMobileCarousel = styled.div`
  display: none;
  padding: 0 0 3rem;
  overflow: auto;
  align-items: center;
  white-space: nowrap;
  ${StyledProductCardContainerTop} {
    flex: 1 0 64%;
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    height: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.vars.brandPrimary};
    height: 0.5rem;
    border-radius: 4rem;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.vars.greyLightest};
    margin: 0 11.2rem;
  }
`;
