import { createReducer } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
import { BagState } from '../../types/state-types';
import {   
  updateAddingStatus ,
  updateAddProductToBag,
  updateCurrentLineNumber
} from './actions';

export const initialState: BagState = {
  bagData: null,
  addingStatus: null,
  currentLineNumber: '',
};

export const bagReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateAddProductToBag, (state, action) => {
      state.bagData = { ...state.bagData, ...action.payload };
    })
    .addCase(updateAddingStatus, (state, action) => {
      state.addingStatus = action.payload;
    })
    .addCase(updateCurrentLineNumber, (state, action) => {
      state.currentLineNumber = action.payload;
    });
});
