
import React, { FC } from 'react';

import { ImageHtmlLink, IndexSubMenu, Maybe, SubMenuItem } from '../../../types/middleware-types';
import SubNavColumnStructure from './SubNavColumnStructure';

export interface SubNavColumnProps {
  data: SubMenuItem | Maybe<ImageHtmlLink> | SubMenuItem[] | IndexSubMenu;
  parent: string;
  linkPosition?: string;
}

const SubNavColumn: FC<SubNavColumnProps> = ({ data, parent, linkPosition }) => {
  const componentProps = { data, parent, linkPosition };
  return <SubNavColumnStructure {...componentProps} />;
};

export default SubNavColumn;
