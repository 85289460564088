import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../basic/Buttons';
import NextImage from '../../basic/NextImage';
import { SiteSwitcher } from '../../types/middleware-types';
import { getCountryFlag } from '../../utils/getters/getCountryFlag';
import { createMediaList } from '../../utils/helpers/createMediaList';
import {
  StyledCloseButton,
  StyledSiteSwitcher,
  StyledSiteSwitcherContent,
} from './SiteSwitcherStyles';

export interface SiteSwitcherStructureProps {
  showSiteSwitcher: boolean;
  hideSiteSwitcher: () => void;
  handleRedirect: (url: string) => void;
  data?: SiteSwitcher;
}

const SiteSwitcherStructure: FC<SiteSwitcherStructureProps> = ({
  showSiteSwitcher,
  hideSiteSwitcher,
  data,
  handleRedirect,
}) => (
  <>
    {showSiteSwitcher && data && (
      <StyledSiteSwitcher data-hookid="siteSwitcher">
        <StyledCloseButton role="presentation" onClick={hideSiteSwitcher}>
          <h2>Close</h2>
          <span>
            <FontAwesomeIcon icon={faTimes as IconProp} />
          </span>
        </StyledCloseButton>
        <StyledSiteSwitcherContent>
          <span className="flag">
            {data?.country && (
              <NextImage
                mediaList={createMediaList({
                  src: getCountryFlag(data.country),
                  width: 50,
                  height: 25,
                })}
                alt={data.country}
                imageType="fixed"
              />
            )}
          </span>
          <h2>{data?.title}</h2>
          <Button
            className="redirect"
            onClick={() => handleRedirect(data.redirect.url)}
            type="button"
            btnType="secondary"
          >
            {data?.redirect?.ctaText}
          </Button>
        </StyledSiteSwitcherContent>
      </StyledSiteSwitcher>
    )}
  </>
);

export default SiteSwitcherStructure;
