import React, { FC, Fragment, MouseEvent, ReactElement, TouchEvent } from 'react';

import Arrow from './Arrow';
import { SliderElement } from './Slider.types';
import {
  StyledSlide,
  StyledSlider,
  StyledSliderArrowContainer,
  StyledSliderArrowsContainer,
  StyledSliderContainer,
  StyledSliderDot,
  StyledSliderDotsContainer,
  StyledSliderWrapper,
} from './SliderStyles';

export interface SliderStructureProps {
  items: Array<SliderElement>;
  slidesNum: number;
  showArrows: boolean;
  nextArrow?: ReactElement;
  prevArrow?: ReactElement;
  arrowsPosition: string;
  arrowsSpaceBetween: number;
  arrowWidth: number;
  arrowHeight: number;
  dots: boolean;
  dotsPosition: string;
  dotsSpaceBetween: number;
  dotWidth: number;
  dotHeight: number;
  dotColour: string;
  dotActiveColour: string;
  sliderStyle: {
    transition: string;
    transform: string;
  };
  slideWidth: number;
  currentSlide: number;
  slidesToShow: number;
  handleSliderTransitionEnd: () => void;
  handleSetAutoplay: (value: boolean) => void;
  handleSwipeEnd: () => void;
  handleSwipeStart: (e: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>) => void;
  handleSwipeMove: (e: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>) => void;
  handleOnClick: (e: MouseEvent<HTMLDivElement>) => void;
  handleNextSlide: (e: MouseEvent<Element>) => void;
  handlePrevSlide: (e: MouseEvent<Element>) => void;
  scrollToSlide: (num: number) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  showSlidesRange: {
    start: number;
    end: number;
  };
  lazyLoad: boolean;
}

const SliderStructure: FC<SliderStructureProps> = ({
  items,
  slidesNum,
  showArrows,
  nextArrow = null,
  prevArrow = null,
  arrowsPosition,
  arrowsSpaceBetween,
  arrowWidth,
  arrowHeight,
  dots,
  dotsPosition,
  dotsSpaceBetween,
  dotWidth,
  dotHeight,
  dotColour,
  dotActiveColour,
  sliderStyle,
  slideWidth,
  currentSlide,
  slidesToShow,
  handleSliderTransitionEnd,
  handleSetAutoplay,
  handleSwipeStart,
  handleSwipeMove,
  handleSwipeEnd,
  handleOnClick,
  handleNextSlide,
  handlePrevSlide,
  scrollToSlide,
  onMouseEnter,
  onMouseLeave,
  showSlidesRange,
  lazyLoad,
}) => {
  const ArrowsContainer = arrowsPosition === 'INSIDE' ? Fragment : StyledSliderArrowsContainer;
  const arrowsContainerProps: { $spaceBetween?: number; position?: string } =
    arrowsPosition === 'INSIDE'
      ? {}
      : { $spaceBetween: arrowsSpaceBetween, position: arrowsPosition };

  return (
    <StyledSlider
      onMouseDown={handleSwipeStart}
      onMouseMove={handleSwipeMove}
      onMouseUp={handleSwipeEnd}
      onClick={handleOnClick}
      onTouchStart={handleSwipeStart}
      onTouchMove={handleSwipeMove}
      onTouchEnd={handleSwipeEnd}
      onTouchCancel={handleSwipeEnd}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledSliderWrapper>
        <StyledSliderContainer
          style={sliderStyle}
          onTransitionEnd={handleSliderTransitionEnd}
          $centerItems={slidesNum < slidesToShow}
        >
          {items && items.map((item, i) => (
            <StyledSlide key={`sliderItem${i}`} $slideWidth={slideWidth} data-hookid="slide">
              {/* TODO: fix type issues */}
              {/* @ts-ignore */}
              {!lazyLoad || (i > showSlidesRange.start && i <= showSlidesRange.end) ? item : null}
            </StyledSlide>
          ))}
        </StyledSliderContainer>
        {dots && (
          <StyledSliderDotsContainer $position={dotsPosition}>
            {Array(slidesNum)
              .fill(null)
              .map((_, i) => (
                <StyledSliderDot
                  key={`sliderDot${i}`}
                  $active={i === currentSlide}
                  $width={dotWidth}
                  $height={dotHeight}
                  $colour={dotColour}
                  $activeColour={dotActiveColour}
                  $spaceBetween={dotsSpaceBetween}
                >
                  <button
                    type="button"
                    data-hookid="dot"
                    onClick={() => {
                      scrollToSlide(i);
                    }}
                    onMouseEnter={() => handleSetAutoplay(true)}
                    onMouseLeave={() => handleSetAutoplay(false)}
                  >
                    {i + 1}
                  </button>
                </StyledSliderDot>
              ))}
          </StyledSliderDotsContainer>
        )}
        {showArrows &&
          (!!nextArrow && !!prevArrow ? (
            <ArrowsContainer {...arrowsContainerProps}>
              <StyledSliderArrowContainer
                className="left"
                $spaceBetween={arrowsSpaceBetween}
                $position={arrowsPosition}
              >
                {React.cloneElement(prevArrow, {
                  onClick: handlePrevSlide,
                })}
              </StyledSliderArrowContainer>
              <StyledSliderArrowContainer
                className="right"
                $spaceBetween={arrowsSpaceBetween}
                $position={arrowsPosition}
              >
                {React.cloneElement(nextArrow, {
                  onClick: handleNextSlide,
                })}
              </StyledSliderArrowContainer>
            </ArrowsContainer>
          ) : (
            <ArrowsContainer {...arrowsContainerProps}>
              <StyledSliderArrowContainer
                className="left"
                $spaceBetween={arrowsSpaceBetween}
                $position={arrowsPosition}
              >
                <Arrow
                  onClick={handlePrevSlide}
                  className="left"
                  width={arrowWidth}
                  height={arrowHeight}
                  screenReaderText="Previous arrow"
                />
              </StyledSliderArrowContainer>
              <StyledSliderArrowContainer
                className="right"
                $spaceBetween={arrowsSpaceBetween}
                $position={arrowsPosition}
              >
                <Arrow
                  onClick={handleNextSlide}
                  className="right"
                  width={arrowWidth}
                  height={arrowHeight}
                  screenReaderText="Next arrow"
                />
              </StyledSliderArrowContainer>
            </ArrowsContainer>
          ))}
      </StyledSliderWrapper>
    </StyledSlider>
  );
};

export default SliderStructure;
