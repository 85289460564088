import styled from 'styled-components';

import { StyledPromoBannerContainer } from '../PromoBanner/PromoBannerStyles'


export const StyledContinuityBar = styled(StyledPromoBannerContainer)`
  text-align: center;

  p {
    line-height: 1.3rem;
  }

  strong {
    ${({ theme }) => theme.types.boldStyle};
  }
`;

