/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import LinkItem from '../../../basic/LinkItem';
import { CustomMedia } from '../../../types/custom-types';
import { Maybe, PromoBannerItem } from '../../../types/middleware-types';
import { isNextMediaList } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import Countdown from '../../Countdown';
import { DEFAULT_PROMO_BANNER_HEIGHT } from '..';
import {
  PromoBannerItemContainer,
  PromoBannerItemContainerLink,
  StyledPromoBannerBackgroundImage,
  StyledPromoBannerItemSeparator,
} from './PromoBannerItemStyles';

interface PromoBannerItemStructureProps
  extends Omit<PromoBannerItem, 'backgroundImage' | 'textAlignment'> {
  height?: Maybe<number>;
  index?: number;
  hasSeparator?: Maybe<boolean>;
  backgroundImage: CustomMedia;
  textAlignment?: string;
}

const PromoBannerItemStructure: FC<PromoBannerItemStructureProps> = ({
  backgroundImage,
  backgroundColourHex,
  textAlignment,
  fontSize,
  fontColourHex,
  expiryDate,
  textHtml,
  textLink,
  height,
  index,
  linkWrapsWholeItem,
  hasSeparator,
}) => {

  return (
    <>
      {
        linkWrapsWholeItem ? (
          <PromoBannerItemContainerLink
            onClick={() =>
              formatPromoEvent(
                textLink?.text,
                index,
                textLink?.url || '',
                isNextMediaList(backgroundImage?.mediaList) ? backgroundImage?.mediaList?.desktop?.src : '',
                'Site stripe Banner',
                'click',
              )}
            href={textLink?.url || ''}
            target={textLink?.newWindow ? '_blank' : ''}
            $height={height ?? DEFAULT_PROMO_BANNER_HEIGHT}
            $backgroundColourHex={backgroundColourHex}
            $textAlignment={textAlignment}
            $fontSize={fontSize}
            $fontColourHex={fontColourHex}
            data-hookid="PromoBannerItem"
          >
            {backgroundImage && <StyledPromoBannerBackgroundImage {...backgroundImage} />}
            {textHtml && <span className="text" dangerouslySetInnerHTML={{ __html: textHtml }} />}
            {expiryDate && <Countdown endDate={expiryDate} layout="" />}
            {textLink?.text && (
              <div className="cta-container">
                <span className="cta">{textLink.text}</span>
              </div>
            )}
            {hasSeparator && <StyledPromoBannerItemSeparator $fontColourHex={fontColourHex} />}
          </PromoBannerItemContainerLink>
        )
          :
          (
            <PromoBannerItemContainer
              onClick={() =>
                formatPromoEvent(
                  textLink?.text,
                  index,
                  textLink?.url || '',
                  isNextMediaList(backgroundImage?.mediaList) ? backgroundImage?.mediaList?.desktop?.src : '',
                  'Site stripe Banner',
                  'click',
                )}
              $height={height ?? DEFAULT_PROMO_BANNER_HEIGHT}
              $backgroundColourHex={backgroundColourHex}
              $textAlignment={textAlignment}
              $fontSize={fontSize}
              $fontColourHex={fontColourHex}
              data-hookid="PromoBannerItem"
            >
              {backgroundImage && <StyledPromoBannerBackgroundImage {...backgroundImage} />}
              {textHtml && <div className="text" dangerouslySetInnerHTML={{ __html: textHtml }} />}
              {expiryDate && <Countdown endDate={expiryDate} layout="" />}
              {textLink?.text && (
                <div className="cta-container">
                  <LinkItem className="cta" newWindow={textLink.newWindow} href={textLink.url}>
                    {textLink.text}
                  </LinkItem>
                </div>
              )}
              {hasSeparator && <StyledPromoBannerItemSeparator $fontColourHex={fontColourHex} />}
            </PromoBannerItemContainer>
          )
      }
    </>
  );
};

export default PromoBannerItemStructure;
