import TagManager from 'react-gtm-module';

/* Function checks the data is an object and is not null, if both are true it pushes to dataLayer */
// Added ignore as any object structure can be passed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addToDataLayer = (dataLayerDetails: { [key: string]: any }): void => {
  if (typeof dataLayerDetails === 'object' && dataLayerDetails !== null) {
    TagManager.dataLayer({
      dataLayer: dataLayerDetails,
    });
  }
};

export default addToDataLayer;
