import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../basic/Buttons';
import { ImageV2, NewsletterSignup } from '../../../types/middleware-types';
import NewsletterSignUp from '../../NewsletterSignUp';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import {
  StyledCloseBtn,
  StyledImageWrapper,
  StyledNoThanksButton,
  StyledPopupBtnWrapper,
  StyledPopupCover,
  StyledPopupDescription,
  StyledPopupInner,
  StyledPopupWrapper
} from './EntrancePopUpStyles';

export interface EntrancePopUpStructureProps {
  popUpthanks?: string | null;
  title?: string | null;
  image?: ImageV2;
  closeButtonClicked: () => void;
  signUpButtonClicked: () => void;
  newsletterSignup: NewsletterSignup;
  description?: string | null;
  signUpButton?: string | null;
  showCloseBtn: boolean;
  showThanksInfo: boolean;
}

const EntrancePopUpStructure: FC<EntrancePopUpStructureProps> = ({
  popUpthanks,
  title,
  image,
  closeButtonClicked,
  signUpButtonClicked,
  newsletterSignup,
  description,
  signUpButton,
  showCloseBtn,
  showThanksInfo,
}) => {
  return (
    <>
      <StyledPopupCover
        data-hookid="entrancePopUpPopupCover"
      />
      <StyledPopupWrapper data-hookid="entrancePopUp">
        <StyledPopupInner>
          {showCloseBtn && (
            <StyledCloseBtn
              type="button"
              btnType="unstyled"
              onClick={() => closeButtonClicked()}
              data-hookid="entrancePopUpCloseBtn"
            >
              <FontAwesomeIcon icon={faTimes as IconProp} />
              <ScreenReaderOnlyText text="close" />
            </StyledCloseBtn>
          )}
          <StyledImageWrapper>
            {image && (
              // eslint-disable-next-line @next/next/no-img-element
              <img alt={`${image?.alt}}`} src={image?.mobile?.src ? image?.mobile?.src : image?.desktop?.src} />
            )}
          </StyledImageWrapper>
          {title && !showThanksInfo && <h3>{title}</h3>}
          {description && !showThanksInfo && <StyledPopupDescription>{description}</StyledPopupDescription>}
          <NewsletterSignUp
            onSiteDataPopupForm={true}
            showSignUpDescription={false}
            newsletterSignup={{ ...newsletterSignup, thanksHtml: popUpthanks }}
            setSignUpButton={signUpButton}
            additionalSignUpEvents={signUpButtonClicked}
            labelTxt="Email"
          />
          <StyledPopupBtnWrapper>
            {!showThanksInfo && (
              <StyledNoThanksButton
                dataHook="entrancePopUpNoThanks"
                passedFunctions={closeButtonClicked}
                aria-live="assertive"
                type="button"
                btnType="tertiary"
              >
                No Thanks
              </StyledNoThanksButton>
            )}
            {showThanksInfo && (
              <Button
                data-hookid="entrancePopUpContinueShopping"
                passedFunctions={closeButtonClicked}
                aria-live="assertive"
                type="button"
                btnType="primary"
                className='continue-shopping'
              >
                Continue Shopping
              </Button>
            )}
          </StyledPopupBtnWrapper>
        </StyledPopupInner>
      </StyledPopupWrapper>
    </>
  );
};

export default EntrancePopUpStructure;
