import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import Head from 'next/head';

import { SEND_TOKEN_TO_FORTER } from '../../graphQl/mutations/forter';
import { forterCode } from './importCode';

const siteId = process.env.NEXT_PUBLIC_FORTER_SITE_ID;

const Forter: FC = () => {
  const [sendtoken, { data, error }] = useMutation(SEND_TOKEN_TO_FORTER);
  const [sentSuccessfully, setSentSuccessfully] = useState<null | boolean>(null);

  const mockEvent = () => {
    const event = new CustomEvent('ftr:tokenReady', { detail: { 'forterTokenCookie': '12345' } });
    document.dispatchEvent(event)
  }

  // Type not know as its part of 3rd party code
  const sendToken = (evt: any) => {
    const token = evt?.detail.forterTokenCookie;
    if (token) {
      sendtoken({
        variables: { input: { forterToken: token } },
      });
    }
  }
  useEffect(() => {
    document.addEventListener('ftr:tokenReady', sendToken);

    // This is for local testing only. Forter code is not on local environment so event will never fire
    if (process.env.NEXT_PUBLIC_IS_LOCAL === 'true') {
      setTimeout(() => {
        mockEvent()
      }, 500)
    }

    return () => window.removeEventListener('ftr:tokenReady', sendToken);
  }, []);

  useEffect(() => {
    if (sentSuccessfully === null && data?.assignForterTokenToCart?.success) {
      setSentSuccessfully(true)
    }
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`The following error occurred when trying to send ForterToken : ${error}`)
    }
  }, [data, error, sentSuccessfully]);

  if (sentSuccessfully) {
    return (
      <Head>
        <script
          async
          id={siteId}
          dangerouslySetInnerHTML={
            { __html: forterCode }
          }
        />
      </Head>
    );
  }

  return null;
}

export default Forter;