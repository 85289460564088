import React, { FC } from 'react'

const TwitterSvg: FC = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7915 2.3448C15.2396 2.59308 14.6465 2.76028 14.024 2.83565C14.6596 2.44994 15.1478 1.83875 15.3771 1.11039C14.7828 1.4676 14.124 1.72728 13.4228 1.86725C12.8621 1.26113 12.0615 0.882385 11.1765 0.882385C9.18963 0.882385 7.72963 2.76091 8.17838 4.71101C5.6215 4.58117 3.354 3.3398 1.83588 1.45304C1.02963 2.85465 1.41775 4.68821 2.78775 5.61671C2.284 5.60024 1.809 5.46027 1.39463 5.22656C1.36088 6.67124 2.38275 8.02282 3.86275 8.32366C3.42963 8.44273 2.95525 8.4706 2.47275 8.37687C2.864 9.61571 4.00025 10.517 5.34775 10.5423C4.054 11.5702 2.424 12.0294 0.791504 11.8343C2.15338 12.7191 3.7715 13.2353 5.509 13.2353C11.2228 13.2353 14.4509 8.3452 14.2559 3.95922C14.8571 3.51904 15.379 2.96992 15.7915 2.3448Z" fill="black" />
  </svg>

)

export default TwitterSvg
