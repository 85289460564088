'use client'

// Carvela
import { GlobalStyle as CarvelahGlobalStyle } from './carvela/styles/global/global';
import { GlobalType as CarvelahGlobalType } from './carvela/styles/global/type';
// Kurt Geiger
import { GlobalStyle as KgGlobalStyle } from './kg/styles/global/global';
import { GlobalType as KgGlobalType } from './kg/styles/global/type';
// Shoeaholics
import { GlobalStyle as SahGlobalStyle } from './sah/styles/global/global';
import { GlobalType as SahGlobalType } from './sah/styles/global/type';
import getSite from '../shared/utils/customHooks/useSiteChecker';

// TODO: fix type
interface Styles {
  [key: string]: any
}

const GlobalStyles: Styles = {
  carvela: CarvelahGlobalStyle,
  kg: KgGlobalStyle,
  sah: SahGlobalStyle
};

const GlobalTypes: Styles = {
  carvela: CarvelahGlobalType,
  kg: KgGlobalType,
  sah: SahGlobalType
};

const site = getSite();

export const GlobalStyle = GlobalStyles[site];
export const GlobalType = GlobalTypes[site];
