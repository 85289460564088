import React, { FC, MouseEvent, ReactNode } from 'react';
import Link from 'next/link';

import { StyledLink } from './LinkItemStyles';

interface LinkItemStructureProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  href: string;
  label: string;
  className: string;
  newWindow: boolean;
  disabled: boolean;
  children?: ReactNode;
  id?: string;
  dataHook?: string;
  routingToggle: boolean;
}

const LinkItemStructure: FC<LinkItemStructureProps> = ({
  href,
  label,
  children,
  className,
  newWindow,
  onClick,
  disabled,
  id,
  dataHook,
  routingToggle,
}: LinkItemStructureProps) => (
  <>
    {routingToggle ? (
      <Link
        href={href}
        passHref={true}
        legacyBehavior
      >
        <StyledLink
          id={id}
          className={className}
          // onClick={onClick}
          data-hookid={dataHook}
          tabIndex={disabled ? -1 : undefined}
          {...(newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
        >
          {label || children}
        </StyledLink>
      </Link>
    ) : (
      <StyledLink
        id={id}
        href={href}
        className={className}
        onClick={onClick}
        $disabled={disabled}
        data-hookid={dataHook}
        tabIndex={disabled ? -1 : undefined}
        {...(newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
      >
        {label || children}
      </StyledLink>
    )}
  </>
);

export default LinkItemStructure;
