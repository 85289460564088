import React, { ChangeEvent, FC, memo, Ref } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NextImage from '../../../basic/NextImage';
import { CountryPickerListItem, HtmlLink } from '../../../types/middleware-types';
import { getCountryFlag } from '../../../utils/getters/getCountryFlag';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import {
  StyledCountryList,
  StyledCountryListWrapper,
  StyledCountryPickerButton,
  StyledCountryPickerButtonMobile,
  StyledInput,
  StyledInputWrapper,
} from './CountryPickerListStyles';

export interface CountryPickerListStructureProps {
  cantFindAction?: HtmlLink;
  searchLabel?: string;
  switchCountry: (newCountry: string) => void;
  filteredCountryList: CountryPickerListItem[] | null;
  filterList: (input: string) => void;
  inputEl: Ref<HTMLInputElement>;
  showInfo: () => void;
  blurHandler: () => void;
}

const CountryPickerListStructure: FC<CountryPickerListStructureProps> = ({
  cantFindAction,
  searchLabel,
  switchCountry,
  filteredCountryList,
  filterList,
  inputEl,
  showInfo,
  blurHandler,
}) => (
  <div data-hookid="CountryPickerList">
    <StyledCountryPickerButtonMobile type="button" onClick={() => showInfo()} data-hookid="CountryPickerButtonMobile">
      <FontAwesomeIcon icon={faChevronLeft as IconProp} />
      &nbsp; Back to delivery options
    </StyledCountryPickerButtonMobile>
    <StyledCountryListWrapper>
      <StyledInputWrapper>
        <StyledInput
          placeholder={searchLabel}
          name="name"
          data-hookid="CountryPickerListInput"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            filterList(e.target.value);
          }}
          type="text"
          ref={inputEl}
        />
        <FontAwesomeIcon icon={faSearch as IconProp} />
      </StyledInputWrapper>
      <StyledCountryList>
        {filteredCountryList &&
          filteredCountryList?.map((aCountry) => {
            return (
              <li key={`${aCountry.code}-${aCountry?.name}`} data-hookid="CountryPickerListItem">
                <StyledCountryPickerButton
                  type="button"
                  data-hookid="CountryPickerListItemButton"
                  onClick={() => switchCountry(aCountry.code)}
                >
                  {aCountry?.name && (
                    <NextImage
                      mediaList={createMediaList({
                        src: getCountryFlag(aCountry.name),
                        width: 25,
                        height: 18,
                      })}
                      alt={aCountry.name}
                      imageType="fixed"
                    />
                  )}
                  <span data-hookid="CountryPickerListName">{aCountry?.name}</span>
                </StyledCountryPickerButton>
              </li>
            );
          })}
      </StyledCountryList>
      {cantFindAction && (
        <StyledCountryPickerButton
          as="a"
          href={cantFindAction.url}
          {...(cantFindAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
          onBlur={blurHandler}
        >
          {cantFindAction.text}
        </StyledCountryPickerButton>
      )}
    </StyledCountryListWrapper>
  </div>
);

export default memo(CountryPickerListStructure);
