import gql from 'graphql-tag';

import { CTA_FRAGMENT, IMAGE_LINK, NEXT_IMAGE_FRAGMENT, PROMO_BANNER_ITEM } from './basicFragments';

export const HEADER_NAVIGATION_DATA = gql`
  fragment HeaderNavigationData on HeaderNavigation {
    desktopMenu {
      ... on DesktopMultiColumnSubMenu {
        menuId
        link {
          ...Cta
        }
        columns {
          items {
            ... on SubMenuLinkList {
              menuId
              title
              links {
                ...Cta
              }
            }
            ... on SubMenuImageLinkList {
              menuId
              links {
                ...ImageLink
              }
            }
            ... on ImageHtmlLink {
              ...ImageLink
            }
            ... on SubMenuShopBySize {
              title
              sizes {
                ...Cta
              }
            }
          }
        }
      }
      ... on DesktopSingleColumnSubMenu {
        menuId
        link {
          ...Cta
        }
        column {
          items {
            ... on SubMenuLinkList {
              menuId
              title
              links {
                ...Cta
              }
            }
            ... on SubMenuImageLinkList {
              menuId
              links {
                ...ImageLink
              }
            }
            ... on ImageHtmlLink {
              ...ImageLink
            }
            ... on SubMenuShopBySize {
              title
              sizes {
                ...Cta
              }
            }
          }
        }
      }
      ... on IndexSubMenu {
        menuId
        link {
          ...Cta
        }
        index {
          title
          allUrl
        }
        links {
          title
          links {
            ...Cta
          }
        }
        images {
          image {
            ...NextImage
          }
          link {
            ...Cta
          }
        }
      }
      ... on SubMenu {
        menuId
        link {
          ...Cta
        }
      }
    }
    mobileMenu {
      ... on MobileMultiItemSubMenu {
        menuId
        link {
          ...Cta
        }
        items {
          ... on SubMenuLinkList {
            menuId
            title
            links {
              ...Cta
            }
            openByDefaultOnMobile
          }
          ... on SubMenuImageLinkList {
            menuId
            links {
              ...ImageLink
            }
          }
          ... on ImageHtmlLink {
            ...ImageLink
          }
          ... on SubMenuShopBySize {
            title
            sizes {
              ...Cta
            }
          }
        }
        images {
          ...ImageLink
        }
      }
      ... on IndexSubMenu {
        menuId
        link {
          ...Cta
        }
        images {
          image {
            ...NextImage
          }
          link {
            ...Cta
          }
        }
        links {
          title
          links {
            ...Cta
          }
        }
        index {
          title
          allUrl
        }
      }
    }
  }
  ${CTA_FRAGMENT}
  ${NEXT_IMAGE_FRAGMENT}
  ${IMAGE_LINK}
`;

export const HEADER_DATA = gql`
  fragment HeaderData on Header {
    logo {
      dark {
        ...NextImage
      }
      light {
        ...NextImage
      }
    }
    promoBanner {
      desktopHeightPixels
      mobileHeightPixels
      desktopItems {
        ...PromoBannerItemFragment
      }
      mobileItems {
        ...PromoBannerItemFragment
      }
      hasSeparator
      continuityBar
    }
  }
  ${NEXT_IMAGE_FRAGMENT}
  ${PROMO_BANNER_ITEM}
`;

export const FOOTER_DATA = gql`
  fragment FooterData on Footer {
    menu {
      title
      links {
        ...Cta
      }
    }
    socialMedia {
      title
      accounts {
        platform
        url
      }
    }
    appStores {
      accounts {
        platform
        url
      }
    }
    cookiePolicy
    copyright
  }
  ${CTA_FRAGMENT}
  ${NEXT_IMAGE_FRAGMENT}
`;