import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useTheme } from 'styled-components';

import { selectBagData, updateAddProductToBag } from '../../features/bag';
import { selectPageName, selectToggles } from '../../features/static';
import { GET_MINIBAG } from '../../graphQl/queries/minibag';
import { Minibag } from '../../types/middleware-types';
import useHeaderNavigation from '../../utils/customHooks/useHeaderNavigation';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import useSiteChecker from '../../utils/customHooks/useSiteChecker';
import formatPageViewDatalayer from '../../utils/DataLayer/formatPageView';
import MinibagStructure from './MinibagStructure';

interface customWindow extends Window {
  // Global E Module object - Unknown so any is set
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GEM_Components: Record<string, any>;
}

declare const window: customWindow;

interface MiniBagHeadersInterface {
  ip: string | string[],
  url: string
  isMobile: boolean | undefined,
  isTablet: boolean | undefined,
}

const MiniBag: FC = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const site = useSiteChecker();
  const isKG = site === 'kg'

  const bagData = useSelector(selectBagData);
  const bagDispatch = useDispatch();

  const toggles = useSelector(selectToggles);
  const pageName = useSelector(selectPageName);

  const featureGlobalE = toggles?.featureGlobalE;
  const featureAmazonPay = toggles?.featureAmazonPay;

  const { showBag, toggleMiniBag } = useHeaderNavigation();
  const isMobileOrTablet = isMobile || isTablet || false;

  const getGlobalECartData = () => {
    const productList = (bagData?.bag?.items || []).map((item) => {
      const { lineNumber, name, colour, price, sizeLabels, brand, image } = item.product;

      return {
        CartItemId: item.id,
        ProductCode: lineNumber,
        Name: name,
        OrderedQuantity: item.quantity,
        IsFixedPrice: false,
        IsBlockedForGlobalE: false,
        OriginalSalePrice: price.now,
        OriginalListPrice: price.was,
        SalePrice: 0,
        ListPrice: 0,
        Attributes: [
          {
            AttributeTypeCode: 'colour',
            Name: colour,
          },
          {
            AttributeTypeCode: 'size',
            Name: `${sizeLabels.uk}/${sizeLabels.eu}`,
          },
        ],
        Brand: {
          Name: brand,
        },
        ImageURL: image?.url,
      };
    });
    return { productList };
  };

  const toggleBag = (): void => {
    toggleMiniBag();
  };

  const bagShow = (): void => {
    if (!isMobileOrTablet) {
      toggleMiniBag(true);
    }
  };

  const bagHide = (): void => {
    if (!isMobileOrTablet) {
      toggleMiniBag(false);
    }
  };

  let miniBagHeaders: MiniBagHeadersInterface;

  const [getMinibagData, { data, loading }] = useLazyQuery<{ minibag: Minibag }>(GET_MINIBAG);
  useEffect(() => {
    getMinibagData({
      variables: {
        includeAmazonPay: !!featureAmazonPay,
      },
      context: {
        headers: { ...miniBagHeaders }
      }
    });
  }, []);


  useEffect(() => {
    if (bagData && featureGlobalE && window?.GEM_Components?.ExternalMethodsComponent?.UpdateCart) {
      window.GEM_Components.ExternalMethodsComponent.UpdateCart(
        JSON.stringify(getGlobalECartData()),
      );
    }
  }, [bagData]);

  useEffect(() => {
    if (data) {
      bagDispatch(updateAddProductToBag(data.minibag));
    }
  }, [data]);

  // Format and send DL events for different pages
  useEffect(() => {
    if (pageName) {
      if (pageName === 'M2') {
        return;
      }
      if (data?.minibag?.bag) {
        if (pageName !== 'plp' && pageName !== 'pdp') {
          formatPageViewDatalayer(pageName, null, data.minibag.bag);
        } else if (pageName === 'pdp') {
          formatPageViewDatalayer(
            pageName,
            window.location.pathname
              .split('/')
              .slice(1, -1)
              .map((x) => `${x[0]?.toUpperCase()}${x.slice(1)}`)
              .join(' > '),
            data.minibag.bag,
          );
        }
      }
    }
  }, [data]);

  const componentProps = {
    toggleBag,
    showBag,
    isMobile,
    isTablet,
    isMobileOrTablet,
    bagShow,
    bagHide,
    loading,
    isKG,
  };
  return <MinibagStructure {...componentProps} />;
};

export default MiniBag;