import styled from 'styled-components';

interface SaleProps {
  $sale?: boolean,
  $was?: boolean,
  $isRedesign?: boolean,
}

export const StyledPriceValues = styled.div`
  display: flex;
  justify-content: start;
  text-transform: uppercase;
  flex-direction: column;
`;

export const StyledRRP = styled.p`
  ${({ theme }) => theme.types.labelStyle}
  position: relative;
  margin: 0;
  font-weight: 400;

  &::before {
    content: " ";
    width: 100%;
    position: absolute;
    top: ${({ theme }) => `calc( 50% - ${theme.productPrice.strikethrough.height})`};
    border-bottom: 1px solid #000000;
  }
`;

export const StyledPrice = styled.p<SaleProps>`
  ${({ theme }) => theme.types.labelStyle};
  font-size: 1.4rem;
  color: ${({ theme, $sale }) => $sale ? theme.productPrice.sale.colour : theme.vars.black};
  margin: 0;
  font-weight: 400;
  ${({ $isRedesign }) => 
  $isRedesign &&
    `
    font-size: 1.8rem;
    `
  }
`;

export const StyledPrices = styled.div<SaleProps>`
  display: flex;
  justify-content: center;
  ${({ $was }) =>
  $was &&
    `
  p {
    &:last-of-type {
      margin-left: 5px;
    }
  }
  `};
`;
