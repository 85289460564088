/* eslint-disable react/forbid-prop-types */
import React, { FC } from 'react';

import { ImageHtmlLink as ImageHtmlLinkType, IndexSubMenu as IndexSubMenuType, Maybe, MobileMultiItemSubMenu as MobileMultiItemSubMenuType, SubMenuItem as SubMenuItemTypes } from '../../../types/middleware-types';
import HtmlLink from './HtmlLink';
import ImageHtmlLink from './ImageHtmlLink';
import IndexSubMenu from './IndexSubMenu';
import LinkListWithImage from './LinkListWithImage';
import MobileMultiItemSubMenu from './MobileMultiItemSubMenu';
import SubMenuImageLinkList from './SubMenuImageLinkList';
import SubMenuLinkList from './SubMenuLinkList';
import SubMenuShopBySize from './SubMenuShopBySize';

export interface SubMenuItemProps {
  data: SubMenuItemTypes | MobileMultiItemSubMenuType | Maybe<ImageHtmlLinkType> | IndexSubMenuType;
  layout?: string;
  linkPosition?: string;
  isLast?: boolean;
}


const LAYOUTS: {
  // TODO: Investigate the type for a dynamic return
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
} = {
  ImageHtmlLink,
  SubMenuLinkList,
  SubMenuShopBySize,
  IndexSubMenu,
  SubMenuImageLinkList,
  HtmlLink,
  LinkListWithImage,
  MobileMultiItemSubMenu
};

const SubMenuItem: FC<SubMenuItemProps> = ({ data, layout, linkPosition, isLast }) => {
  const componentProps = {
    data,
    type: 'Layout',
    componentName: 'SubMenuItem',
    layout,
    linkPosition,
    isLast,
  };

  const Component = LAYOUTS[layout || ''] || null;
  return Component && <Component {...componentProps} />;
};

export default SubMenuItem;
