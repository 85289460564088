import React, { ChangeEvent, Dispatch, FC, FormEvent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import DOMPurify from 'isomorphic-dompurify';

import constants from '../../../sites/constants';
import { selectToggles } from '../../features/static';
import { COMPETITION_FORM_SUBMISSION } from '../../graphQl/mutations/competitionFormSubmission';
import { SIGNUP_FOR_NEWSLETTER } from '../../graphQl/mutations/signupNewsletter';
import { WELCOME_POPUP_SUBMISSION } from '../../graphQl/mutations/welcomePopup';
import {
  MutationCompetitionFormSubmissionArgs,
  NewsletterSignup,
} from '../../types/middleware-types';
import addToDataLayer from '../../utils/DataLayer/addToDataLayer';
import isEmailValid from '../../utils/helpers/isEmailValid/isEmailValid';
import NewsletterSignUpStructure from './NewsletterSignUpStructure';

export interface NewsletterSignupProps {
  newsletterSignup: NewsletterSignup & {
    thanksHtml?: string | null;
  };
  showSignUpDescription?: boolean;
  welcomePopupForm?: boolean;
  onSiteDataPopupForm?: boolean;
  setNewsletterSubmited?: Dispatch<SetStateAction<boolean>>;
  source?: string;
  setSignUpButton?: string | null;
  additionalSignUpEvents?: () => void;
  labelTxt?: string | null;
}

export interface SignUpFormData {
  email: string;
  option: string;
}

export interface SignUpFormError {
  error: boolean;
  errorMsg: string;
}

const NewsletterSignUp: FC<NewsletterSignupProps> = ({
  newsletterSignup,
  showSignUpDescription,
  welcomePopupForm,
  onSiteDataPopupForm,
  setNewsletterSubmited,
  source,
  setSignUpButton,
  additionalSignUpEvents,
  labelTxt,
}) => {
  const {
    description,
    emailPlaceholder,
    formTitle,
    genderCodes,
    thanks,
    legal,
    title,
    thanksHtml,
  } = newsletterSignup;
  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    option: genderCodes?.[0].code === '02' ? 'm' : 'f',
  });
  const [error, setError] = useState<SignUpFormError>({
    error: false,
    errorMsg: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const { showSignUpFormSuccessSvg } = constants;
  const mutation =
    (source && COMPETITION_FORM_SUBMISSION) ||
    (welcomePopupForm && WELCOME_POPUP_SUBMISSION) ||
    (onSiteDataPopupForm && WELCOME_POPUP_SUBMISSION) ||
    SIGNUP_FOR_NEWSLETTER;
  const toggles = useSelector(selectToggles);
  const [signupForNewsLetter] = useMutation(mutation);

  const sendDataLayerEvent = (errorMessage?: string): void => {
    addToDataLayer({
      event: 'newsletterSignup',
      eventCategory: 'login|register|newsletter',
      eventAction: errorMessage ? 'newsletter unsuccessful' : 'newsletter successful',
      eventLabel: errorMessage ?? '',
      newsletterGender: formData.option,
    });
  };

  // set ID prefix 
  let idPrefix = 'newsletter';
  if (onSiteDataPopupForm) {
    idPrefix = 'onsitedata';
  } else if (welcomePopupForm) {
    idPrefix = 'welcome';
  }

  // check if all fields are valid and submit the form
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = isEmailValid(formData.email);
    const isEmailEmpty = formData.email === '';
    if (!isValid) {
      setError({
        error: true,
        errorMsg: isEmailEmpty ?
          'An email address is required to continue' :
          'You have entered an invalid email address. Please try again',
      });
    } else {
      const variables: MutationCompetitionFormSubmissionArgs = {
        input: {
          emailAddress: formData.email,
          gender: formData.option,
        },
        source: source ?? '',
      }
      const {
        data: { newsletterFormSubmission, welcomePopupFormSubmission, competitionFormSubmission },
      } = await signupForNewsLetter({
        variables,
      });
      if (
        newsletterFormSubmission?.success ||
        welcomePopupFormSubmission?.success ||
        competitionFormSubmission?.success
      ) {
        sendDataLayerEvent();
        setSubmitted(true);
        if (typeof setNewsletterSubmited === 'function') {
          setNewsletterSubmited(true);
        }
        // load additional SignUp Events logic to live 
        if (additionalSignUpEvents) {
          additionalSignUpEvents();
        }
      } else {
        sendDataLayerEvent(
          newsletterFormSubmission?.message ||
          welcomePopupFormSubmission?.message ||
          competitionFormSubmission?.message,
        );
        setError({
          error: true,
          errorMsg:
            newsletterFormSubmission?.message ||
            welcomePopupFormSubmission?.message ||
            competitionFormSubmission?.message,
        });
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const componentProps = {
    handleSubmit,
    handleChange,
    error,
    formData,
    submitted,
    description,
    emailPlaceholder,
    formTitle,
    legal: DOMPurify ? DOMPurify.sanitize(legal) : legal,
    title,
    thanks,
    genderCodes,
    showSignUpDescription,
    showSignUpFormSuccessSvg,
    thanksHtml,
    newLayout: constants?.newFooterLayout,
    idPrefix,
    setSignUpButton,
    labelTxt: labelTxt || 'Newsletter sign up',
    kgRedesignToggle: toggles?.featureKgRedesign,
  };

  return <NewsletterSignUpStructure {...componentProps} />;
};

export default NewsletterSignUp;
