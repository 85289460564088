import styled, { keyframes } from 'styled-components';

import StyledFormLabel from '../../../basic/FormLabel/FormLabelStyles';
import { StyledInputContainer } from '../../../basic/TextInput/TextInputStyles';
import {
  Container,
  StyledPrivacyMessage,
  StyledPromoMessage,
  StyledSignUpForm,
} from '../../NewsletterSignUp/NewsletterSignUpStyles';

const popup = keyframes`
  0% {
    top: 200vh;
    left: -200vh;
  }

  100% {
    top: 50%;
    left: 50%;
  }
`;

export const StyledFormPopupContent = styled.div`
  position: fixed;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 26rem;
  min-height: 42rem;
  display: flex;
  animation: ${popup} 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    width: 60rem;
    min-height: 40rem;
  }
`;

export const StyledFormPopupImageWrapper = styled.div`
  flex: 0 0 50%;
  background: ${({ theme }) => theme.vars.white};

  
  img {
    vertical-align: bottom;
    width: 100%;

    @media ${({ theme }) => theme.devices.large} {
      height: 100%;
      width: auto;
    }
  }
`;

export const StyledFormPopupFormWrapper = styled.div`
  flex: 0 0 50%;
  background: ${({ theme }) => theme.vars.white};
  display: flex;
  align-items: center;

  ${Container} {
    margin: 2rem;
  }

  ${StyledSignUpForm} {
    button {
      background-color: ${({ theme }) => theme.welcomePopup.color};
      color: ${({ theme }) => theme.vars.white};
      border: none !important;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &.desktop {
        display: none !important;
      }

      &.mobile {
        display: block !important;
      }
    }

    ${StyledFormLabel} {
      color: ${({ theme }) => theme.welcomePopup.color};

      &::before {
        border-color: ${({ theme }) => theme.vars.greyDark};
      }
    }

    ${StyledPromoMessage} {
      ${({ theme }) => theme.types.h3Style};
    }
    ${StyledInputContainer} {
      width: 100% !important;

      input {
        ${({ theme }) => theme.types.labelStyle};
        color: ${({ theme }) => theme.welcomePopup.color};
        border-color: ${({ theme }) => theme.welcomePopup.color};

        &::placeholder {
          ${({ theme }) => theme.types.labelStyle};
          color: ${({ theme }) => theme.welcomePopup.color};
        }
      }
    }

    ${StyledPrivacyMessage} {
      text-align: center;
      color: ${({ theme }) => theme.welcomePopup.color};

      p {
        ${({ theme }) => theme.types.labelSmallStyle};
      }

      a {
        color: ${({ theme }) => theme.welcomePopup.color};
      }
    }
  }
`;
