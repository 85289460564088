import ResizeObserver from 'resize-observer-polyfill';

const getResizeObserver = (cb: () => void): ResizeObserver => {
  return new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      cb();
    });
  });
};

export default getResizeObserver;
