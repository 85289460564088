import React, { FC, useRef } from 'react';
import SlickSlider, { Settings } from 'react-slick';

import { usePreventVerticalScroll } from '../../utils/customHooks/usePreventVerticalScroll';

const SlickCarousel: FC<Settings> = ({ children, ...settings }) => {

  const slider = useRef(null);
  usePreventVerticalScroll(slider);

  return (
    <div ref={slider} data-hookid="SlickSliderCarousel">
      <SlickSlider {...settings}>
        {children}
      </SlickSlider>
    </div>
  );
};

export default SlickCarousel;
