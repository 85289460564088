/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const sendLCPSubparts = (label, page) => {
  const LCP_SUB_PARTS = [
    'Time to first byte',
    'Resource load delay',
    'Resource load time',
    'Element render delay',
  ];

  new PerformanceObserver((list) => {
    if(!('at' in list?.getEntries())){
      return
    }

    if (list !== undefined && list?.getEntries !== undefined && typeof list?.getEntries === 'function') {
      const lcpEntry = list?.getEntries()?.at(-1);
      const navEntry = performance.getEntriesByType('navigation')[0];
      const lcpResEntry = performance
        .getEntriesByType('resource')
        .filter((e) => e.name === lcpEntry?.url)[0];

      // Compute the start and end times of each LCP sub-part.
      const ttfb = navEntry.responseStart;

      const lcpRequestStart = Math.max(
        ttfb,
        lcpResEntry ? lcpResEntry.requestStart || lcpResEntry.startTime : 0,
      );

      const lcpResponseEnd = Math.max(lcpRequestStart, lcpResEntry ? lcpResEntry.responseEnd : 0);

      const lcpRenderTime = Math.max(
        lcpResponseEnd,
        lcpEntry ? lcpEntry.renderTime || lcpEntry.loadTime : 0,
      );

      // Clear previous measures before making new ones.
      LCP_SUB_PARTS.forEach((part) => performance.clearMeasures(part));

      const lcpSubPartMeasures = [
        performance.measure(LCP_SUB_PARTS[0], {
          start: 0,
          end: ttfb,
        }),
        performance.measure(LCP_SUB_PARTS[1], {
          start: ttfb,
          end: lcpRequestStart,
        }),
        performance.measure(LCP_SUB_PARTS[2], {
          start: lcpRequestStart,
          end: lcpResponseEnd,
        }),
        performance.measure(LCP_SUB_PARTS[3], {
          start: lcpResponseEnd,
          end: lcpRenderTime,
        }),
      ];

      lcpSubPartMeasures.forEach((measure) => {
        const data = {
          category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
          page: page || 'no-page-name',
          duration: Math.round(measure?.duration),
          label: measure?.name,
          startTime: Math.round(measure?.startTime),
          percentage: Math.round((1000 * measure?.duration) / lcpRenderTime) / 10,
        };

        const getFirstLetters = (str) => {
          const firstLetters = str
            .split(' ')
            .map((word) => word[0])
            .join('');

          return firstLetters;
        };

        const catName = measure?.name
          ? `LCP - ${getFirstLetters(measure?.name).toLowerCase()}`
          : 'LCP - no-name';
        if (typeof window !== 'undefined' && typeof newrelic === 'object') {
          // eslint-disable-next-line no-undef
          newrelic.addPageAction(catName, { ...data });
        }
      });
    }
  }).observe({ type: 'largest-contentful-paint', buffered: true });
};

export default sendLCPSubparts;
