import { Component, ErrorInfo, ReactNode } from 'react';

import { SplunkParams } from '../../types/custom-types';
import SendSplunkReport from '../../utils/senders/SendSplunkReport';

interface ErrorBoundaryProps {
  sendToSplunk?: boolean;
  splunkParams: SplunkParams;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidUpdate() {
    const { hasError, error } = this.state;
    const { sendToSplunk, splunkParams } = this.props;
    if (
      hasError &&
      process.env.NODE_ENV !== 'development' &&
      process.env.NEXT_PUBLIC_IS_LOCAL === 'false'
    ) {
      if (error && typeof newrelic === 'object' && typeof window !== 'undefined') {
        newrelic.noticeError(error, {
          errorType: 'ErrorBoundary Error',
        });
      }
      if (sendToSplunk) {
        splunkParams.siteError = error;
        SendSplunkReport(splunkParams);
      }
      if (window) {
        window.location.replace('/404');
      }
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { sendToSplunk, splunkParams } = this.props;
    if (
      error &&
      process.env.NODE_ENV !== 'development' &&
      process.env.NEXT_PUBLIC_IS_LOCAL === 'false'
    ) {
      if (error && typeof newrelic === 'object' && typeof window !== 'undefined') {
        newrelic.noticeError(error, {
          errorType: 'ErrorBoundary Error',
          errorInfo: info || '',
        });
      }
      if (sendToSplunk) {
        splunkParams.siteError = error;
        splunkParams.errorInfo = info;
        SendSplunkReport(splunkParams);
      }
      if (window) {
        window.location.replace('/404');
      }
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { children, sendToSplunk, splunkParams } = this.props;

    if (
      sendToSplunk &&
      hasError &&
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_IS_LOCAL === 'false'
    ) {
      if (error && typeof newrelic === 'object' && typeof window !== 'undefined') {
        newrelic.noticeError(error, {
          errorType: 'ErrorBoundary Error',
        });
      }
      splunkParams.siteError = error;
      SendSplunkReport(splunkParams);
    }

    return !hasError ? children : null;
  }
}

export default ErrorBoundary;
