import React, { FC } from 'react';
import { ImageProps } from 'next/image';

// Do not dynamic import Image or Video as it makes the tests flaky
import WistiaVideo from '../../components/WistiaVideo';
import { MediaList, NextMediaList } from '../../types/custom-types';
import { Video } from '../../types/middleware-types';
import { isNextMediaList } from '../../types/type-checkers';
import NextImage from '../NextImage';

interface MediaElementStructureProps {
  mediaList?: MediaList | NextMediaList;
  alt?: string;
  type?: string;
  video?: Video;
  label?: string;
  imageType: ImageProps['layout'];
  imgPriority?: boolean;
  sizes?: string;
}

const MediaElementStructure: FC<MediaElementStructureProps> = ({
  alt,
  mediaList,
  type,
  video,
  label,
  imageType,
  imgPriority,
  sizes,
}: MediaElementStructureProps) => (
  <>
    {type === 'next-image' && isNextMediaList(mediaList) && (
      <NextImage
        mediaList={mediaList}
        alt={alt}
        imageType={imageType}
        priority={imgPriority}
        sizes={sizes}
      />
    )}
    {type === 'video' && video && <WistiaVideo {...video} label={label} />}
  </>
);

export default MediaElementStructure;
