import React, { FC, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsCompactHeader, selectIsHeaderReverse, selectToggles } from '../../../features/static';
import useHeaderNavigation from '../../../utils/customHooks/useHeaderNavigation';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import SearchIconStructure from './SearchIconStructure';

interface SearchIconProps {
  landingSearch: boolean;
  clearSearch: () => void;
}

const SearchIcon: FC<SearchIconProps> = ({ landingSearch, clearSearch }) => {
  const theme = useTheme();
  const isHeaderReverse = useSelector(selectIsHeaderReverse);
  const toggles = useSelector(selectToggles)
  const { featureKgRedesign } = toggles;
  const { headerTheme, headerPosition, isScrolling } = useHeaderTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const compactHeader = useSelector(selectIsCompactHeader);
  const { hideNav, searchOpen, toggleSearch } = useHeaderNavigation();

  const handleOpenSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      toggleSearch(true);
    }
  };

  const closeNavMenuAndMinibag = () => {
    toggleSearch();
  };

  // Needed for SSR of the search icon.
  const SSRDesktopCheck = isDesktop ?? true;

  const componentProps = {
    searchOpen,
    closeNavMenuAndMinibag,
    isScrolling,
    headerTheme,
    headerPosition,
    isDesktop: SSRDesktopCheck,
    handleOpenSearch,
    landingSearch,
    clearSearch,
    compactHeader,
    hideNav,
    isHeaderReverse : featureKgRedesign && isHeaderReverse
  };

  return <>{headerTheme && <SearchIconStructure {...componentProps} />}</>;
};

export default SearchIcon;
