import styled, { createGlobalStyle } from 'styled-components';

import { StyledAccountContainer, StyledAccountDropdown } from '../../Account/AccountStyles';
import { StyledMinibagDropdownContainer } from '../../Minibag/MinibagDropdown/MinibagDropdownStyles';
import { StyledSearchIconContainer } from '../../Search/SearchIcon/SearchIconStyles';

interface GlobalMainStyleProps {
  paddingTop: number;
}

export const GlobalMainStyle = createGlobalStyle<GlobalMainStyleProps>`
  main {
    padding-top: ${({ paddingTop }) => paddingTop}px !important;
  }
`;
interface StyledTransparentHeaderProps {
  $headerTheme: string;
  $headerBackground: string;
  $megaNavOpen: boolean;
  $finalTopPositionMob: number;
  $finalTopPositionDesk: number;
  $headerPosition: string;
  $isAtTop: boolean;
}

interface StyledOpenSearchBarProps {
  $showOpenSearchBar: boolean
}

export const StyledTransparentHeader = styled.header<StyledTransparentHeaderProps>`
  z-index: 10;
  width: 100%;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: ${({ $headerTheme }) => $headerTheme};
  font-weight: unset;
  background: ${({ $headerBackground, $megaNavOpen, theme }) => ($megaNavOpen ? theme.headerColors.backgroundColor : $headerBackground)
  };
  position: ${({ $headerPosition }) => $headerPosition};
  top: ${({ $headerPosition, $finalTopPositionMob }) => ($headerPosition === 'fixed') ? '0px !important' : `${$finalTopPositionMob}px`};

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    top: ${({ $headerPosition, $finalTopPositionDesk }) => ($headerPosition === 'fixed') ? '0px !important' : `${$finalTopPositionDesk}px`};
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    background-color: ${({ theme, $megaNavOpen, $headerPosition, $isAtTop }) => {
    if ($headerPosition === 'fixed' && !$isAtTop) return theme.vars.white
    return $megaNavOpen ? theme.headerColors.backgroundColor : 'transparent';
  }}
  }
`;

export const StyledUtilitiesMenu = styled.div<{ $searchOpen: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  flex-direction: row-reverse;
  align-items: center;
  pointer-events: all;

  @media ${({ theme }) => theme.devices.large} {
    pointer-events: ${({ $searchOpen }) => $searchOpen && 'none'};
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const StyledOverlay = styled.div<{ $searchOpen: boolean }>`
  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $searchOpen }) => ($searchOpen ? 'block' : 'none')};
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 20;
    background-color: rgb(0 0 0 / 50%);
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    margin-left: 2rem;
    justify-content: flex-start;
  }

  &:last-of-type {
    margin-right: 1rem;
    justify-content: flex-end;
  }

  @media ${({ theme }) => theme.devices.large} {
    ${StyledMinibagDropdownContainer} {
      z-index: 12;
    }
    ${StyledAccountDropdown} {
      left: auto;
      right: 0;
    }
  }
`;

export const StyledSecondarySection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  ${StyledSearchIconContainer} {
    margin-left: 1rem;
  }
  ${StyledAccountContainer} {
    margin-right: 1rem;
  }
`;

export const StyledDesktopNav = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
    width: 100%;
    padding: 0.8rem 0;
  }
`;

export const StyledMobileNav = styled.div<StyledOpenSearchBarProps>`
  display: block;
  width: 100%;
  padding: 0 ${({ $showOpenSearchBar }) => $showOpenSearchBar ? '0' : '1.6rem'};
  overflow: hidden;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledMobileUtils = styled.div<StyledOpenSearchBarProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $showOpenSearchBar }) =>
  $showOpenSearchBar &&
    `
    padding: 0 3.2rem;
  `};
`;

export const StyledStoresContainer = styled.div<{ $headerTheme: string }>`
  display: flex;
  align-items: center;

  span {
    color: ${({ $headerTheme }) => $headerTheme};
    margin: 0.5rem;
  }

  path {
    stroke: ${({ $headerTheme }) => $headerTheme};
  }
`;

export const StyledStoresLink = styled.div<{ $headerTheme: string }>`
  @media ${({ theme }) => theme.devices.large} {
    a::after {
      background: ${({ $headerTheme }) => $headerTheme};
    }
  }
`;
