import React, { FC, memo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NextImage from '../../../basic/NextImage';
import { CountryPickerItem, HtmlLink } from '../../../types/middleware-types';
import { getCountryFlag } from '../../../utils/getters/getCountryFlag';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import { StyledChangeLocation } from '../CountryPickerStyles';
import {
  StyledCountryPickerButton,
  StyledCountryPickerButtonMobile,
  StyledCurrentLocation,
  StyledShippingOptions,
  StyledShippingWrapper,
} from './CountryPickerInfoStyles';

interface CountryPickerInfoStructureProps {
  currentCountry: CountryPickerItem;
  moreInfoAction?: HtmlLink;
  showMenu: () => void;
  showList: () => void;
  blurHandler: () => void;
}

const CountryPickerInfoStructure: FC<CountryPickerInfoStructureProps> = ({
  currentCountry,
  moreInfoAction,
  showMenu,
  showList,
  blurHandler,
}) => (
  <div data-hookid="CountryPickerInfo">
    <StyledCountryPickerButtonMobile type="button" onClick={() => showMenu()} data-hookid='CountryPickerBackToMenuButton'>
      <FontAwesomeIcon icon={faChevronLeft as IconProp} />
      &nbsp; Back to menu
    </StyledCountryPickerButtonMobile>
    <StyledChangeLocation>
      {currentCountry && (
        <StyledCurrentLocation data-hookid="CountryPickerInfoCurrent">
          {currentCountry?.name && (
            <NextImage
              mediaList={createMediaList({
                src: getCountryFlag(currentCountry.name),
                width: 25,
                height: 18,
              })}
              alt={currentCountry.name}
              imageType="fixed"
            />
          )}
          <span data-hookid="CountryPickerCurrentName">{currentCountry.name}</span>
        </StyledCurrentLocation>
      )}
      <StyledCountryPickerButton
        data-hookid="CountryPickerInfoShowList"
        type="button"
        onClick={() => showList()}
      >
        Change
      </StyledCountryPickerButton>
    </StyledChangeLocation>
    {currentCountry.deliveryPrices && currentCountry?.deliveryPrices?.length > 0 && (
      <StyledShippingWrapper>
        {currentCountry.deliveryPrices.map((shipping) => (
          <StyledShippingOptions data-hookid="CountryPickerInfoDeliveryPrices" key={shipping.label}>
            <p>{shipping.label}</p>
            <p>{shipping.value}</p>
          </StyledShippingOptions>
        ))}
      </StyledShippingWrapper>
    )}
    {moreInfoAction && (
      <StyledCountryPickerButton
        data-hookid="CountryPickerInfoMoreInfo"
        target={moreInfoAction.newWindow ? '_blank' : ''}
        as="a"
        href={moreInfoAction.url}
        onBlur={blurHandler}
      >
        {moreInfoAction.text}
      </StyledCountryPickerButton>
    )}
  </div>
);

export default memo(CountryPickerInfoStructure);
