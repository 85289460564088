import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

interface UseHuntDomainReturn {
  domain: string;
}

const useHuntDomain = (callHuntService: boolean): UseHuntDomainReturn => {
  const [domain, setDomain] = useState<string>('');

  // request hunt domain and set it in state
  useEffect(() => {
    const getDomain = async (): Promise<void> => {
      try {
        if (process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal') {
          setDomain('');
          return;
        }
        const url =
          (process.env.NEXT_PUBLIC_HUNT_URL as string) ??
          (process.env.REACT_APP_HUNT_URL as string);
        const response: AxiosResponse<{ domain: string }> = await axios.post(url, undefined, {
          withCredentials: true,
        });
        setDomain(response.data.domain);
      } catch (e) {
        setDomain('');
      }
    };

    if (callHuntService) {
      getDomain();
    }
  }, [callHuntService]);

  return { domain };
};

export default useHuntDomain;
