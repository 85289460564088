import styled from 'styled-components';

export const StyledAppStoreLogos = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    margin-right: 0.7rem;
  }

  @media ${({ theme }) => theme.devices.smallOnly} {
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-start;
    width: unset;
    margin-right: 1rem;

    a {
      margin-right: 3.8rem;
    }
  }
`;

export const StyledAppLogoWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;

  &:first-child {
    justify-content: flex-end;

    @media ${({ theme }) => theme.devices.large} {
      justify-content: flex-start;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-start;
  }

  margin-right: 0;
`;
