import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;

  > li {
    margin: 0 0 1rem;
  }
`;

export const StyledSkeletonBackground = styled.div`
  display: inline-block;
  background: linear-gradient(-90deg, ${({ theme }) => theme.plp.skeleton.startColor} 0%, ${({ theme }) => theme.plp.skeleton.endColor} 50%, ${({ theme }) => theme.plp.skeleton.startColor} 100%);
  background-size: 400% 400%;
  animation: ${pulse} 1.2s ease-in-out infinite;
`;

interface StyledSkeletonProps {
  $type?: string;
  $options: { width?: number; height?: number; margin?: string; unit?: string };
}

export const StyledSkeleton = styled(StyledSkeletonBackground)<StyledSkeletonProps>`
  height: ${({ $options }) => ($options.height ? `${$options.height}px` : '20px')};
  width: ${({ $options }) => ($options.width ? `${$options.width}px` : '100%')};
  ${({ $options }) =>
  $options.margin &&
    `
    margin: ${$options.margin};
  `}
  ${({ $type, $options }) =>
      $type === 'image' &&
    `
    height: ${$options.height ? `${$options.height}${$options.unit}` : '100px'};
    width: ${$options.width ? `${$options.width}${$options.unit}` : '100px'};
  `}
`;
