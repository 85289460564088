import styled from 'styled-components';

import { StyledLink } from '../../basic/LinkItem/LinkItemStyles';

export const StyledAccordionItemContainer = styled.div`
  width: 50%;

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ theme }) => theme.vars.sizeL};
    padding-top: 0;
  }

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: 100%;
  }
  ${StyledLink} {
    font-family: ${({ theme }) => theme.footer.fonts.primary};
  }
`;

type StyledFooterLinkListProps = {
  $itemOpen?: boolean;
};

export const StyledFooterLinkList = styled.ul<StyledFooterLinkListProps>`
  display: ${({ $itemOpen }) => ($itemOpen ? 'block' : 'none')};
  list-style: none;
  padding: 0;
  margin-left: 3.2rem;

  li {
    padding: 0.8rem 0;

    a {
      font-weight: 400;

      @media ${({ theme }) => theme.devices.smallOnly} {
        width: 100%;
      }
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;

    li {
      padding: 0;
    }
  }
`;
