import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../reduxStore/store';
import { ProductViewType } from '../../types/custom-types';
import { CategoryPagination, ImageLinkCampaign, Maybe, Metadata } from '../../types/middleware-types';
import {
  PlpDataWithPerPage,
  PlpLoadingType,
  ProductListCurrentPageType,
  ProductListType,
} from '../../types/state-types';
import { dataIsPlpSearch } from '../../types/type-checkers';

export const selectCanonicalUrl = (state: RootState): string | undefined =>
  state.plpContent.canonicalUrl;
export const selectPlpData = (state: RootState): Maybe<PlpDataWithPerPage> | undefined => {
  return state.plpContent.plpData;
};
export const selectSearchData = (state: RootState): {
  searchTerm?: string | null;
  returnedSearch?: string | null;
  numItems: number;
} | null => {
  const { plpData } = state.plpContent;

  return dataIsPlpSearch(plpData) ? {
    searchTerm: plpData.query,
    returnedSearch: plpData.returnedSearch,
    numItems: plpData.pagination?.totalItems || 0,
  } : null;
};

export const selectPlpLoading = (state: RootState): PlpLoadingType | undefined =>
  state.plpContent.plpLoading;

export const selectPlpMetadata = (state: RootState): Maybe<Metadata> | undefined =>
  state.plpContent.plpMetadata;
export const selectProductView = (state: RootState): ProductViewType =>
  state.plpContent.productView;
export const selectProductList = (state: RootState): Maybe<ProductListType> | undefined =>
  state.plpContent.productList;

export const selectProductListCurrentPage = (state: RootState): ProductListCurrentPageType =>
  state.plpContent.productListCurrentPage;

export const selectRefreshPlpData = (state: RootState): boolean | undefined =>
  state.plpContent.refreshPlpData;

export const selectPaginationData = (state: RootState): Maybe<CategoryPagination> | undefined =>
  state.plpContent.paginationData;

export const selectPlpTotalItems = createSelector(
  selectPlpData,
  (state) => state?.pagination?.totalItems,
);

export const selectPlpShouldIndex = createSelector(selectPlpData, (state) => state?.shouldIndex);
export const selectPlpCurrentPage = createSelector(
  selectPlpData,
  (state) => state?.pagination?.currentPage,
);
export const selectPlpCurrentSortItem = createSelector(selectPlpData, (state) => {
  const currentSortItem = state?.sortBy?.filter((item) => item.selected);
  return currentSortItem?.[0]?.label;
});
export const selectPlpDataExists = createSelector(selectPlpData, (state) => !!state);

export const selectRouteUrl = (state: RootState): string => state.plpContent.routeUrl;


export const selectCategoryCarousel = (state: RootState): Maybe<ImageLinkCampaign> | undefined => state.plpContent.categoryCarousel;
