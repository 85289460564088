import React, { FC, useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import { Maybe, PromoBannerItem as PromoBannerItemType } from '../../../types/middleware-types';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import PromoBannerItemStructure from './PromoBannerItemStructure';

interface PromoBannerItemProps extends PromoBannerItemType {
  height?: Maybe<number>;
  index?: number;
  hasSeparator?: Maybe<boolean>;
}

const PromoBannerItem: FC<PromoBannerItemProps> = ({
  backgroundImage,
  backgroundColourHex,
  textAlignment,
  fontSize,
  fontColourHex,
  expiryDate,
  textHtml,
  textLink,
  height,
  index,
  linkWrapsWholeItem,
  hasSeparator,
}) => {
  const [finalText, setFinalText] = useState<string | null>(null);

  /* Text has to be set using useEffect because its used in dangerouslySetInnerHTML.
  dangerouslySetInnerHTML can not be set on the server as it causes hydration issues as
  the DOM is altered
  TODO: investigate ways to get around this that allow us to render promoBanners on the server
  */
  useEffect(() => {
    const text = DOMPurify.sanitize(textHtml || '');
    setFinalText(text)
  }, [])

  const componentProps = {
    backgroundImage: parseCMSMediaProp(backgroundImage),
    backgroundColourHex,
    textAlignment:
      textAlignment?.toLowerCase() !== 'centre' ? textAlignment?.toLowerCase() : 'center',
    fontSize,
    fontColourHex,
    expiryDate,
    textHtml: finalText,
    textLink,
    height,
    index,
    linkWrapsWholeItem,
    hasSeparator,
  };

  return <>{finalText && <PromoBannerItemStructure {...componentProps} />}</>;
};

export default PromoBannerItem;
