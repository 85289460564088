import styled from 'styled-components';

import { StyledLink } from '../../../../basic/LinkItem/LinkItemStyles';
import { StyledLinkList, StyledLinkListContainer } from '../SubMenuLinkList/LinkListStyles';

interface Props {
  type: string;
}

export const StyledIndexSubMenu = styled.div`
  margin-top: ${({ theme }) => theme.vars.sizeM};
`;

export const StyledIndexList = styled.ul`
  @media ${({ theme }) => theme.devices.smallOnly} {
    overflow-y: scroll;
    padding: 1.5rem 0;
    justify-content: unset;
    margin-bottom: 1rem;

    li {
      width: 0;
      margin: 0 2.5rem;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 3rem;

  li {
    width: ${({ theme }) => theme.vars.sizeM};
    list-style: none;
    margin-bottom: 0;
  }
  ${StyledLink} {
    ${({ theme }) => theme.types.linkStyles};
    font-weight: 400;
    line-height: 1.6rem;

    &.disabled {
      color: ${({ theme }) => theme.vars.greyDark};
      cursor: default;

      &:hover {
        &::after {
          width: 0;
        }
      }
    }
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: ${({ theme }) => theme.vars.black};
    margin: 0;
  }
`;

export const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImagesLinksContainer = styled.div`
  @media ${({ theme }) => theme.devices.smallOnly} {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  ${StyledLinkListContainer} {
    width: 50%;
    ${StyledLinkList} {
      height: 25rem;
      flex-wrap: wrap;

      li {
        margin-right: 5rem;
      }
    }
  }
`;

export const StyledLinkRenderer = styled.div<Props>`
  @media ${({ theme }) => theme.devices.smallOnly} {
    display: ${({ type }) => (type === 'mobile' ? 'block' : 'none')};
    text-align: left;
  }
  ${StyledLink} {
    ${({ theme }) => theme.types.tertiaryLinkStyles};
    text-transform: initial;
    line-height: 2rem;
    font-weight: 400;

    &::after {
      background: transparent;
    }
  }

  display: ${({ type }) => (type === 'mobile' ? 'none' : 'block')};
  margin-right: 0.75rem;
`;

export const StyledMobileContainer = styled.div`
  @media ${({ theme }) => theme.devices.smallOnly} {
    display: block;
  }
`;
