import React, { Dispatch, FC, memo, SetStateAction } from 'react';

import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import AccountIcon from './AccountIcon';
import {
  StyledAccountContainer,
  StyledAccountDropdown,
  StyledSignOutButton,
  StyledSvgContainer,
} from './AccountStyles';

export interface AccountStructureProps {
  isAuth: boolean;
  accountLinks: {
    title: string;
    href: string;
  }[];
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
  compactHeaderSelected: boolean;
  mobileHeader?: boolean;
}

const AccountStructure: FC<AccountStructureProps> = ({
  isAuth,
  accountLinks,
  isHovered,
  setIsHovered,
  handleLogout,
  compactHeaderSelected,
  mobileHeader,
}: AccountStructureProps) => (
  <StyledAccountContainer
    onFocus={() => setIsHovered(isHovered)}
    onBlur={() => setIsHovered(!isHovered)}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    data-hookid="globalHeaderAccount"
    className={isAuth ? 'is-auth' : ''}
    $compactHeaderSelected={compactHeaderSelected}
  >
    <StyledSvgContainer>
      <span>Account</span>
      <a href="/customer/account">
        <ScreenReaderOnlyText text="My account" />
        <AccountIcon
          isAuth={isAuth}
          isHovered={isHovered && !mobileHeader}
          mobileHeader={mobileHeader}
        />
      </a>
    </StyledSvgContainer>
    {isAuth && !mobileHeader && (
      <StyledAccountDropdown
        className={isHovered ? 'hovered' : ''}
        $isHovered={isHovered}
        $isAuth={isAuth}
      >
        <ul>
          {accountLinks &&
            accountLinks.map((link) => (
              <li key={link.title}>
                <a href={link.href}>
                  {link.title}
                </a>
              </li>
            ))}
          <StyledSignOutButton onClick={handleLogout}>Sign Out</StyledSignOutButton>
        </ul>
      </StyledAccountDropdown>
    )}
  </StyledAccountContainer>
);

export default memo(AccountStructure);
