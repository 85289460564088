import { createAction } from '@reduxjs/toolkit';

import { Maybe } from '../../types/middleware-types';

export type PopUpActionData = Maybe<{
  target: string;
  open: boolean;
  position?: number;
  imageId?: number;
}>;

export const updateOpenPopUp = createAction<PopUpActionData>('popUp/updateOpenPopUp');
export const clearAllPopups = createAction('popUp/clearAllPopups');
