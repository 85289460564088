import React, { FC } from 'react';

import { HtmlLink } from '../../../../types/middleware-types';
import Accordion from '../../../Accordion';
import SubMenuShopBySizeList from './ShopBySizeList';
import { StyledAccordion } from './ShopBySizeStyles';

interface ShopBySizeStructureProps {
  sizes: Array<HtmlLink>;
  title: string;
  isMobile?: boolean;
}

const ShopBySizeStructure: FC<ShopBySizeStructureProps> = ({ sizes, isMobile, title }) => (
  <>
    {!isMobile ? (
      <>{sizes.length > 0 && (
        <SubMenuShopBySizeList data={{ sizes }} title={title} />
      )}
      </>
    ) : (
      <StyledAccordion>
        <Accordion
          allowMultipleOpen={false}
          data={[{ sizes, title }]}
          title={title}
          component={SubMenuShopBySizeList}
        />
      </StyledAccordion>
    )}
  </>
);

export default ShopBySizeStructure;
