import React, { FC } from 'react';

import { BasicCarouselProps } from '../../types/custom-types';
import CarouselItemList from './CarouselItemList';
import { StyledCarouselContainer } from './CarouselStyles';
import MobileCarousel from './MobileCarousel';

interface CarouselStructureProps extends BasicCarouselProps {
  dataHookid?: string;
  mobileOnly?: boolean;
  lsIgnore?: boolean;
  isDesktop?: boolean;
}

const CarouselStructure: FC<CarouselStructureProps> = ({
  items,
  component,
  settings,
  pageName,
  scrollableOnMobile,
  scrollableOnDesktop,
  animationType,
  animate,
  animationStyle,
  otherProps,
  getCarouselInstance,
  slick,
  campaign,
  setCarouselTitle,
  dataHookid,
  mobileOnly,
  lsIgnore,
  isDesktop,
  idValue
}) => (
  <StyledCarouselContainer
    data-hookid={dataHookid || `${pageName}Carousel`}
    $scrollableOnMobile={scrollableOnMobile}
    $scrollableOnDesktop={scrollableOnDesktop}
    id={idValue}
  >
    {!isDesktop && (
      <MobileCarousel
        items={items}
        component={component}
        otherProps={{ ...otherProps, animationType, animate, animationStyle }}
        setCarouselTitle={setCarouselTitle}
        campaign={campaign}
        lsIgnore={lsIgnore}
      />
    )}
    {!mobileOnly && (
      <CarouselItemList
        component={component}
        items={items}
        settings={settings}
        otherProps={{ ...otherProps, animationType, animate, animationStyle }}
        scrollableOnDesktop={scrollableOnDesktop}
        getCarouselInstance={getCarouselInstance}
        slick={slick}
        setCarouselTitle={setCarouselTitle}
        campaign={campaign}
      />
    )}
  </StyledCarouselContainer>
);

export default CarouselStructure;
