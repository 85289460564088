/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { Query } from '../../types/middleware-types';
import {
  fetchMoreData,
  updateAllStoriesData,
  updateStoriesId,
  updateStoriesLoading,
} from './actions';

export type StoriesState = {
  id: string;
  storiesById?: Pick<Query, 'CMSPageBuilder'>[] | any;
  loading: boolean;
  refetch?: boolean;
};

export const initialState: StoriesState = {
  id: '/stories',
  storiesById: null,
  loading: false,
};

export const storiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateStoriesId, (state, action) => {
      state.id = action.payload;
    })
    .addCase(updateAllStoriesData, (state, action) => {
      if (action.payload?.[0]?.CMSPageBuilder?.id) {
        state.id = action.payload[0].CMSPageBuilder.id;
        state.storiesById = {
          [action.payload[0].CMSPageBuilder.id]: action.payload[0].CMSPageBuilder,
        };
      }
    })
    .addCase(updateStoriesLoading, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(fetchMoreData, (state, action) => {
      state.refetch = action.payload;
    });
});
