import React, { FC } from 'react'

const MyBag: FC = () => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.98701 6.43994H18.6429C18.7662 6.43994 18.8896 6.56424 18.8896 6.68855L20 17.7515C20 17.8758 19.8766 18.0001 19.7532 18.0001H1.24675C1.12338 18.0001 1 17.8758 1 17.7515L1.74026 6.68855C1.74026 6.56424 1.86364 6.43994 1.98701 6.43994Z" stroke="black" strokeMiterlimit="10" />
    <path d="M6.24133 10.5202C6.24133 10.5202 6.61572 1 10.3596 1C14.1034 1 14.1034 10.5202 14.1034 10.5202" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default MyBag
