import axios from 'axios';

import { SplunkParams } from '../../../types/custom-types';

// TODO - Check global url
export const getSiteURl = (site: string): string => {
  switch (site) {
    case 'shoeaholics':
      return 'https://www.shoeaholics.com';
    case 'carvela':
      return 'https://www.carvela.com';
    case 'kurtgeigerus':
      return 'https://www.kurtgeiger.us';
    case 'kurtgeigerglobal':
      return 'https://global.kurtgeiger.com';
    case 'kurtgeigermexico':
      return 'https://www.kurtgeiger.mx';
    default:
      return 'https://www.kurtgeiger.com';
  }
};

const SendSplunkReport = async (params: SplunkParams): Promise<void> => {
  await axios.get(`${getSiteURl(process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID as string)}/blank.gif`, {
    params,
  });
};

export default SendSplunkReport;
