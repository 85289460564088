const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 3500
}

export default settings;
