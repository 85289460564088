import { devices, fonts, vars } from './resources';

interface TypesInterface {
  [key: string]: string;
}

export const Types: TypesInterface = {
  h1Style: `
    font-size: 24px;
    line-height: 30px;
    font-family: ${fonts.fontFamilyFuturaNowTextBold};
  `,
  h2Style: `
    font-size: 18px;
    line-height: 24px;
    font-family: ${fonts.fontFamilyFuturaNowTextBold};
    margin: 0;
  `,
  h3Style: `
    font-size: 14px;
    font-family: ${fonts.fontFamilyFuturaNowTextBold};
    line-height: 20px;
    margin: 0;
  `,
  h4Style: `
    font-size: 11px;
    font-family: ${fonts.fontFamilyFuturaNowTextBold};
    line-height: 10px;
  `,
  h5Style: `
    font-size: 18px;
    font-family: ${fonts.fontFamilyFuturaNowText};
    line-height: 10px;
  `,
  h6Style: `
    font-size: 35px;
    line-height: 10px;
    font-family: ${fonts.fontFamilyFuturaNowText};
  `,
  ctaTextStyles: `
    font-family: ${fonts.fontFamilyFuturaNowTextBold};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
  `,
  linkStyles: `
  text-decoration: none;
  font-size: 11px;
  line-height: 10px;
  font-family: ${fonts.fontFamilyFuturaNowTextBold};
  font-weight: 100;
  cursor: pointer;
  color: ${vars.black};
  background-color: transparent;
  @media ${devices.medium} {
    font-size: 12px;
  }
  &:hover {
    text-decoration: none;
  }
  `,
  tertiaryLinkStyles: `
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 11px;
  line-height: 10px;
  font-family: ${fonts.fontFamilyFuturaNowText};
  font-weight: 400;
  cursor: pointer;
  color: ${vars.black};
  background-color: transparent;
  @media ${devices.medium} {
    font-size: 12px;
  }
  &:hover {
    text-decoration: none;
  }
  `,
  labelStyle: `
    font-family: ${fonts.fontFamilyFuturaNowTextRegular};
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    @media ${devices.medium} {
      font-size: 14px;
    }
  `,
  labelSmallStyle: `
    font-family: ${fonts.fontFamilyFuturaNowTextRegular};
    font-size: 11px;
    line-height: 20px;
    font-weight: normal;
  `,
  boldStyle: `
  font-family: ${fonts.fontFamilyPrimaryBold};
  font-size: inherit;
`,
};

export default Types;
