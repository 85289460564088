import React, { FC, MouseEvent, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from '../../features/static';
import LinkItemStructure from './LinkItemStructure';

export interface LinkItemProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  href?: string;
  label?: string;
  className?: string;
  newWindow?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  id?: string;
  formateUrl?: boolean
  dataHook?: string
}

const LinkItem: FC<LinkItemProps> = ({
  children,
  label = '',
  className = '',
  newWindow = false,
  onClick = () => { },
  href = '',
  disabled = false,
  id,
  formateUrl = false,
  dataHook
}: LinkItemProps) => {
  const toggles = useSelector(selectToggles)
  const routingToggle = toggles?.engs13825ReactRouting;
  let localHref = '';

  const getPrefix = () => {
    if (
      process.env.REACT_APP_SITE === 'sah' ||
      process.env.NEXT_PUBLIC_SITE === 'sah'
    ) {
      return 'sa';
    }
    if (
      process.env.REACT_APP_SITE === 'carvela' ||
      process.env.NEXT_PUBLIC_SITE === 'carvela'
    ) {
      return 'cv';
    }
    return 'kg';
  }

  const convertToLocalUrl = (hrefToConvert: string) => {
    const splitUrl = hrefToConvert?.split('/');
    let previousPrefix = '';
    const formatedUrl: string[] = []
    splitUrl.forEach((thisItem: string) => {
      if (thisItem.length > 0) {
        const prefix = getPrefix();
        const formatedItem = thisItem.replace(/-/g, '');
        previousPrefix = `${previousPrefix}${formatedItem}`;
        const newUrl = `${prefix}${previousPrefix}`
        formatedUrl.push(`${newUrl}`);
      }
    })
    const formedUrl = formatedUrl.join('_');
    return `/plp/${getPrefix()}_${formedUrl}`;
  }

  if (formateUrl) {
    localHref = convertToLocalUrl(href);
  }

  const finalHref = localHref.length > 0 && process.env.NEXT_PUBLIC_IS_LOCAL === "true" && !routingToggle ? localHref : href;

  const componentProps = {
    href: finalHref,
    label,
    children,
    className,
    newWindow,
    onClick,
    disabled,
    id,
    dataHook,
    routingToggle,
  };
  
  return <LinkItemStructure {...componentProps} />;
};

export default LinkItem;
