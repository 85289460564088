import React, { FC } from 'react';

import { ScreenReaderOnlyTextProps } from './ScreenReaderOnlyText.types';
import ScreenReaderOnlyTextStructure from './ScreenReaderOnlyTextStructure';

const ScreenReaderOnlyText: FC<ScreenReaderOnlyTextProps> = ({ text, element, attrs }) => {
  const componentProps = {
    text,
    element,
    attrs,
  };

  return <ScreenReaderOnlyTextStructure {...componentProps} />;
};

export default ScreenReaderOnlyText;
