import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import Cookies from 'universal-cookie';

import { selectToggles, setCountryPickerData } from '../../../features/static';
import { GET_HEADER_CONSOLIDATED_DATA } from '../../../graphQl/queries/headerConsolidated';
import {
  CountryPickerList as ICountryPickerList,
  CountryPickerListItem,
  HeaderConsolidated,
  HtmlLink,
} from '../../../types/middleware-types';
import filterArray from '../../../utils/formatters/FilterArray';
import CountryPickerListStructure from './CountryPickerListStructure';
import { StyledLoader } from './CountryPickerListStyles';

interface CountryPickerListProps {
  showInfo: () => void;
  blurHandler: () => void;
}

interface CountryPickerListData {
  cantFindAction: HtmlLink;
  searchLabel: string;
}

interface HeaderConsolidatedOverride extends Omit<HeaderConsolidated, 'countryPicker'> {
  countryPicker: ICountryPickerList;
}

const CountryPickerList: FC<CountryPickerListProps> = ({ showInfo, blurHandler }) => {
  const toggles = useSelector(selectToggles);
  const inputEl = useRef<HTMLInputElement>(null);
  const cookies = new Cookies();
  const includeWelcomePopup = !!toggles?.featureWelcomePopup;

  const [getUpdatedCountryDetails, { data }] = useLazyQuery<{
    headerConsolidated: HeaderConsolidatedOverride;
  }>(GET_HEADER_CONSOLIDATED_DATA);

  const currentCountryDispatch = useDispatch();

  const [countryList, setCountryList] = useState<CountryPickerListItem[] | null>(null);
  const [filteredCountryList, setFilteredCountryList] = useState<CountryPickerListItem[] | null>(
    null,
  );
  const [countryPickerListData, setCountryPickerListData] = useState<CountryPickerListData | null>(
    null,
  );

  const {
    data: countryListData,
    loading,
    error,
  } = useQuery<{ headerConsolidated: HeaderConsolidatedOverride }>(GET_HEADER_CONSOLIDATED_DATA, {
    variables: {
      code: '',
      includeCountryPicker: true,
      includeWelcomePopup,
    },
  });

  useEffect(() => {
    if (data) {
      currentCountryDispatch(setCountryPickerData(data.headerConsolidated.countryPicker));
    }
  }, [data]);

  useEffect(() => {
    if (countryListData) {
      setCountryList(countryListData.headerConsolidated?.countryPicker?.items);
      setFilteredCountryList(countryListData.headerConsolidated?.countryPicker?.items);
      setCountryPickerListData({
        cantFindAction: countryListData.headerConsolidated?.countryPicker?.cantFindAction,
        searchLabel: countryListData.headerConsolidated?.countryPicker?.searchLabel,
      });
    }
  }, [countryListData]);

  const filterList = (inputValue: string) => {
    const newCountryList = filterArray<CountryPickerListItem>(countryList, ['name'], inputValue);
    if (newCountryList) {
      setFilteredCountryList(newCountryList);
    }
  };

  const switchCountry = (newCountry: string) => {
    getUpdatedCountryDetails({
      variables: {
        code: newCountry,
        includeCountryPicker: true,
        includeWelcomePopup,
      },
    });

    setFilteredCountryList(countryList);
    cookies.set('selectedCountry', newCountry, { maxAge: 7776000, path: '/' });
    if (inputEl.current) inputEl.current.value = '';
  };

  const componentProps = {
    ...countryPickerListData,
    switchCountry,
    filteredCountryList,
    filterList,
    inputEl,
    showInfo,
    blurHandler,
  };

  if (loading) {
    return (
      <div
        style={{
          padding: '30px 0',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <StyledLoader>
          <span />
          <span />
          <span />
        </StyledLoader>
      </div>
    );
  }

  if (error) {
    return (
      <span
        style={{
          border: '2px solid red',
          padding: '10px',
        }}
      >
        {error.message}
      </span>
    );
  }

  return <>{countryList !== null && <CountryPickerListStructure {...componentProps} />}</>;
};

export default CountryPickerList;
