import gql from 'graphql-tag';

export const AMAZON_PAY_FRAGMENT = gql`
  fragment AmazonPay on AmazonPayButton {
    merchantId
    publicKeyId
    ledgerCurrency
    checkoutLanguage
    productType
    placement
    buttonColor
    createCheckoutSessionConfig {
      payloadJSON
      signature
    }
  }
`;
