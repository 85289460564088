import React, { FC, memo } from 'react';
import Head from 'next/head';

interface MetaDataStructureProps {
  title: string;
  keywords?: string;
  description?: string;
  robots?: string;
  metaImage?: string;
  metaLocation?: string | null;
  currentSite: string;
  hreflangs: Array<Record<string, string>>;
}

const MetaDataStructure: FC<MetaDataStructureProps> = ({
  title,
  keywords,
  description,
  robots,
  metaImage,
  metaLocation,
  currentSite,
  hreflangs,
}) => (
  <>
    <Head>
      <title>{title}</title>
      <meta name="p:domain_verify" content={`${process.env.NEXT_PUBLIC_MAGENTO_VERIFICATION}`} />
      <meta property="fb:admins" content="100004673258883" />
      <meta name="robots" content={robots} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {hreflangs.map((hreflang) => (
        <link key={hreflang.url} rel="alternate" href={hreflang.url} hrefLang={hreflang.locale} data-kg="true" />
      ))}
      {metaImage && currentSite && (
        <>
          <meta property="og:type" content="product" />
          <meta property="og:image" content={metaImage} />
          {metaLocation && <meta property="og:url" content={metaLocation} />}
          <meta property="og:site_name" content={currentSite} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:description" content={description} />
        </>
      )}
    </Head>
  </>
);

export default memo(MetaDataStructure);
