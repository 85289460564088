import React, { Dispatch, FC, KeyboardEvent, SetStateAction } from 'react';

import { MobileMenuBasicProps } from '../MobileMenu.types';
import MobileMenuStructure from './MobileMenuStructure';

export interface MobileMenuProps extends MobileMenuBasicProps {
  setCurrentTab: Dispatch<SetStateAction<string>>;
  openSubNavOnKeyPress: (e: KeyboardEvent<HTMLLIElement>, title?: string) => void;
  headerBackground: string;
  showCountryPickerButton?: boolean;
  hideCountryPickerOnGlobalESite?: boolean;
}

const MobileMenuComponent: FC<MobileMenuProps> = ({
  isMenuOpen,
  isScrolling,
  headerBackground,
  headerTheme,
  toggleMobileMenu,
  data,
  setCurrentTab,
  currentTab,
  openSubNavOnKeyPress,
  handleDoubleClick,
  isAuth,
  accountLabel,
  accountHref,
  currentCountry,
  showCountryPicker,
  toggleCountryPicker,
  compactHeader,
  showCountryPickerButton,
  hideCountryPickerOnGlobalESite,
}) => {
  const componentProps = {
    isMenuOpen,
    isScrolling,
    headerBackground,
    headerTheme,
    toggleMobileMenu,
    data,
    setCurrentTab,
    currentTab,
    openSubNavOnKeyPress,
    handleDoubleClick,
    isAuth,
    accountLabel,
    accountHref,
    currentCountry,
    showCountryPicker,
    toggleCountryPicker,
    compactHeader,
    showCountryPickerButton,
    hideCountryPickerOnGlobalESite
  };

  return <MobileMenuStructure {...componentProps} />;
};

export default MobileMenuComponent;
