import styled from 'styled-components';

interface StyledArrowProps {
  width: number;
  height: number;
}

export const StyledArrow = styled.button<StyledArrowProps>`
  width: ${({ width }) => width || 30}px;
  height: ${({ height }) => height || 30}px;
  background-color: transparent;
  z-index: 3;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.left {
    transform: rotate(180deg);
  }
`;
