import React, { FC } from 'react';
import Link from 'next/link';

import { ThirdPartyAccount } from '../../types/middleware-types';
import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import AppleStore from '../SVG/appStore/AppleStore';
import PlayStore from '../SVG/appStore/PlayStore';
import { StyledAppLogoWrapper, StyledAppStoreLogos } from './AppStoreLogoStyles';

interface AppStoreProps {
  links: ThirdPartyAccount[] | null;
  useReactRoutingLinks: boolean;
}
const AppStoreLogosStructure: FC<AppStoreProps> = ({ links, useReactRoutingLinks }) => (
  <>
    {links ? (
      <StyledAppStoreLogos data-hookid="globalAppStoreLogos">
        {links.map((item) => (
          <StyledAppLogoWrapper key={`AppStoreLogo-${item.platform}`}>
            {useReactRoutingLinks ? (
              <Link href={item.url} passHref legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <ScreenReaderOnlyText text={item.platform} />
                  <div aria-hidden="true">
                    {item.platform === 'googlePlay' ? <PlayStore /> : <AppleStore />}
                  </div>
                </a>
              </Link>
            ) : (
              <a href={item.url}>
                <ScreenReaderOnlyText text={item.platform} />
                <div aria-hidden="true">
                  {item.platform === 'googlePlay' ? <PlayStore /> : <AppleStore />}
                </div>
              </a>
            )}
          </StyledAppLogoWrapper>
        ))}
      </StyledAppStoreLogos>
    ) : null}
  </>
);
export default AppStoreLogosStructure;
