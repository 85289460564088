import styled from 'styled-components';

import { StyledLinkListContainer } from '../SubMenuItem/SubMenuLinkList/LinkListStyles';

interface StyledSubMenuContainerProps {
  $showSubNav?: boolean;
}

export const StyledSubMenuContainer = styled.div<StyledSubMenuContainerProps>`
  display: ${({ $showSubNav }) => ($showSubNav ? 'flex' : 'none')};
  background: ${({ theme }) => theme.vars.white};
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
  cursor: default;
  height: 38.4rem;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 0.7rem 1.5rem -1.5rem rgb(0 0 0 / 47%);
`;

interface StyledSubNavContentProps {
  $imageLinkList?: boolean;
}

export const StyledSubNavContent = styled.div<StyledSubNavContentProps>`
  width: 100%;
  max-width: 95rem;
  margin: 1.6rem 11.2rem;
  display: flex;
  flex-direction: ${({ $imageLinkList }) => ($imageLinkList ? 'column' : 'row')};
  justify-content: ${({ $imageLinkList }) => ($imageLinkList ? 'start' : 'center')};

  ${StyledLinkListContainer} {
    h3 {
      margin-bottom: ".8rem";
    }
  }
`;
