import gql from 'graphql-tag';

import { AMAZON_PAY_FRAGMENT } from '../fragments/amazonPay';
import { MINIBAG_BUTTON_FRAGMENT, MINIBAG_PRODUCT_FRAGMENT } from '../fragments/minibagFragments';

export const GET_MINIBAG = gql`
  query Minibag($includeAmazonPay: Boolean!) {
    minibag {
      title
      deliveryOptions
      bag {
        _id
        id
        itemCount
        items {
          id
          product {
            ...MiniBagProduct
          }
          quantity
        }
        remainingItems
        subTotal
        subTotalFormatted
      }
      content
      bagButton {
        ...MiniBagButton
      }
      checkoutButton {
        ...MiniBagButton
      }
      amazonPayButton @include(if: $includeAmazonPay) {
        ...AmazonPay
      }
    }
  }
  ${MINIBAG_PRODUCT_FRAGMENT}
  ${MINIBAG_BUTTON_FRAGMENT}
  ${AMAZON_PAY_FRAGMENT}
`;