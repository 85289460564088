/* Packages */
import React, { FC } from 'react';

import { CountryPickerItem, HtmlLink } from '../../../types/middleware-types';
import CountryPickerInfoStructure from './CountryPickerInfoStructure';

interface CountryPickerInfoProps {
  currentCountry: CountryPickerItem;
  moreInfoAction?: HtmlLink;
  showMenu: () => void;
  showList: () => void;
  blurHandler: () => void;
}

const CountryPickerInfo: FC<CountryPickerInfoProps> = ({
  currentCountry,
  moreInfoAction,
  showMenu,
  showList,
  blurHandler,
}) => {
  const componentProps = {
    currentCountry,
    moreInfoAction,
    showMenu,
    showList,
    blurHandler,
  };

  return <CountryPickerInfoStructure {...componentProps} />;
};

export default CountryPickerInfo;
