import React, { FC, MouseEvent, ReactNode } from 'react';

import { Maybe } from '../../types/middleware-types';
import { StyledButton } from './ButtonStyles';
import { ButtonType } from '.';

interface ButtonStructureProps {
  btnType: string;
  type: ButtonType;
  passedFunctions: (event?: MouseEvent<HTMLElement>) => void;
  pageName: Maybe<string>;
  currentSite: string;
  componentName: string;
  children?: ReactNode;
  dataHook?: string;
}

const ButtonStructure: FC<ButtonStructureProps> = ({
  children,
  btnType,
  type,
  passedFunctions,
  pageName,
  componentName,
  currentSite,
  dataHook,
  ...otherProps
}) => (
  <StyledButton
    data-hookid={dataHook || `${pageName}${componentName}Button`}
    onClick={passedFunctions}
    type={type}
    $currentSite={currentSite}
    {...otherProps}
    $btnType={btnType}
  >
    {children}
  </StyledButton>
);

export default ButtonStructure;
