import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from '../../features/static';
import { ThirdPartyAccount } from "../../types/middleware-types";
import AppStoreLogosStructure from './AppStoreLogosStructure';

export interface AppStoreProps {
  links : ThirdPartyAccount[] | null
}

const AppStoreLogos: FC<AppStoreProps> = ({ links }) => {
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const componentProps = {
    links: links ? [...links].sort((a, b) => (a.platform > b.platform ? 1 : -1)) : null,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return <AppStoreLogosStructure {...componentProps} />;
};

export default AppStoreLogos;