// Subnav data could be array or object
import React, { FC, memo } from 'react';

import LinkItem from '../../../basic/LinkItem';
import { SubMenuData } from '../../../types/custom-types';
import { HtmlLink } from '../../../types/middleware-types';
import SubMenu from '../../SubMenu';
import { StyledMainMenuItem } from './MainMenuItemStyles';

export interface MainMenuItemStructureProps {
  link: HtmlLink;
  currentTab: string;
  toggleSubNav: (value: string) => void;
  subNavData: SubMenuData;
  compactHeader: boolean;
  headerTheme: string;
  isCurrentTab: boolean;
  removeItemLineNumberFromLocalStorage: () => void;
  id?: string;
  itemRef: (node: HTMLLIElement) => void;
  formateUrl: boolean;
  isHeaderReverse: boolean;
}

const MainMenuItemStructure: FC<MainMenuItemStructureProps> = ({
  link,
  subNavData,
  isCurrentTab,
  currentTab,
  toggleSubNav,
  headerTheme,
  compactHeader,
  removeItemLineNumberFromLocalStorage,
  id,
  itemRef,
  formateUrl,
  isHeaderReverse
}) => (
  <StyledMainMenuItem
    $isHeaderReverse={isHeaderReverse}
    $headerTheme={headerTheme}
    $currentTab={currentTab}
    $isCurrentTab={isCurrentTab}
    key={link.text}
    onFocus={() => toggleSubNav(link.text)}
    onBlur={() => toggleSubNav('')}
    onMouseEnter={() => toggleSubNav(link.text)}
    onMouseLeave={() => toggleSubNav('')}
    data-hookid={`globalHeaderMainMenuItem${link.text.split(' ').join('')}`}
    $compactHeader={compactHeader}
    onClick={() => removeItemLineNumberFromLocalStorage()}
    $red={!compactHeader && ['sale', 'clearance'].includes(link.text.toLowerCase())}
    ref={itemRef}
  >
    <LinkItem
      formateUrl={formateUrl}
      id={id}
      className={isCurrentTab ? 'active' : ''}
      href={link.url}
      newWindow={link.newWindow}
    >
      {link.text}
    </LinkItem>
    <SubMenu
      data={subNavData}
      showSubNav={isCurrentTab}
      parent={link.text}
      layout={compactHeader ? 'SubMenuCompact' : 'SubMenu'}
    />
  </StyledMainMenuItem>
);

export default memo(MainMenuItemStructure);
