/* eslint-disable no-param-reassign */
import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { createReducer } from '@reduxjs/toolkit';

import { GoCertifyPageData } from '../../types/custom-types';
import {
  BrandIndex,
  CategoryBreadcrumb,
  CountryPickerResponse,
  Footer,
  Header,
  HeaderNavigation,
  HeaderTrendingSearch,
  JobListing,
  Maybe,
  ProductBreadcrumb,
  Sitemap,
} from '../../types/middleware-types';
import { SiteData } from '../../types/state-types';
import getSite from '../../utils/customHooks/useSiteChecker';
import {
  setBrandIndex,
  setBreadCrumbSchema,
  setCookies,
  setCountryPickerData,
  setCurrencyData,
  setFooterData,
  setGoCertifyData,
  setHeaderColourTheme,
  setHeaderNavigationData,
  setHeaderOnlyData,
  setHeaderReverse,
  setJobsData,
  setPageId,
  setPageName,
  setShowBrandNameProductCard,
  setSiteData,
  setSitemapData,
  setToggleData,
  setTrendingSearchData,
} from './actions';

interface localStates {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface StaticStateRedux {
  siteData: Maybe<SiteData>;
  headerOnlyData: Maybe<Header>;
  headerNavigationData: Maybe<HeaderNavigation>;
  countryPickerData: Maybe<CountryPickerResponse>;
  footerData: Maybe<Footer>;
  compactHeader: boolean;
  headerReverse: boolean;
  showBrandName: boolean;
  showBrandNameProductCard: boolean;
  isBrandPage: boolean;
  showMobileAppLogo: boolean;
  brandsIndexData: Maybe<BrandIndex>;
  removeXFromFirstElement: boolean;
  toggles: LDFlagSet;
  sitemapData: Maybe<Sitemap>;
  headerColourTheme: string;
  gocertify?: GoCertifyPageData;
  jobs: Maybe<JobListing[]>;
  pageId?: string
  currency: { code: string, symbol: string }
  cookies: string | null;
  headerTrendingSearch?: HeaderTrendingSearch
  breadCrumbSchema: Array<CategoryBreadcrumb | ProductBreadcrumb> | null
}

const localStaticState: localStates = {
  carvela: {
    compactHeader: true,
    headerReverse: false,
    showBrandName: false,
    isBrandPage: true,
    showMobileAppLogo: false,
    removeXFromFirstElement: false,
  },
  kg: {
    compactHeader: true,
    headerReverse: false,
    showBrandName: true,
    isBrandPage: true,
    showMobileAppLogo: true,
    removeXFromFirstElement: true,
  },
  kgus: {
    compactHeader: true,
    headerReverse: false,
    showBrandName: true,
    isBrandPage: true,
    showMobileAppLogo: true,
    removeXFromFirstElement: true,
  },
  kgglobal: {
    compactHeader: true,
    headerReverse: false,
    showBrandName: true,
    isBrandPage: true,
    showMobileAppLogo: true,
    removeXFromFirstElement: true,
  },
  sah: {
    compactHeader: false,
    headerReverse: false,
    showBrandName: true,
    isBrandPage: false,
    showMobileAppLogo: false,
    removeXFromFirstElement: true,
  },
};

const getLocalSite = getSite();

export const initialState: StaticStateRedux = {
  siteData: null,
  headerOnlyData: null,
  headerNavigationData: null,
  countryPickerData: null,
  footerData: null,
  compactHeader: localStaticState[getLocalSite].compactHeader,
  headerReverse: localStaticState[getLocalSite].headerReverse,
  showBrandName: localStaticState[getLocalSite].showBrandName,
  showBrandNameProductCard: false,
  isBrandPage: localStaticState[getLocalSite].isBrandPage,
  showMobileAppLogo: localStaticState[getLocalSite].showMobileAppLogo,
  brandsIndexData: null,
  removeXFromFirstElement: localStaticState[getLocalSite].removeXFromFirstElement,
  toggles: {},
  sitemapData: null,
  headerColourTheme: '',
  jobs: null,
  currency: {
    symbol: '',
    code: ''
  },
  cookies: '',
  breadCrumbSchema: null
};

export const staticReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setHeaderOnlyData, (state, action) => {
      state.headerOnlyData = action.payload;
    })
    .addCase(setHeaderNavigationData, (state, action) => {
      state.headerNavigationData = action.payload;
    })
    .addCase(setCountryPickerData, (state, action) => {
      if (action.payload !== undefined) {
        state.countryPickerData = action.payload;
      }
    })
    .addCase(setFooterData, (state, action) => {
      state.footerData = action.payload;
    })
    .addCase(setSiteData, (state, action) => {
      state.siteData = action.payload;
    })
    .addCase(setBrandIndex, (state, action) => {
      state.brandsIndexData = action.payload;
    })
    .addCase(setToggleData, (state, action) => {
      state.toggles = action.payload;
    })
    .addCase(setSitemapData, (state, action) => {
      state.sitemapData = action.payload;
    })
    .addCase(setHeaderColourTheme, (state, action) => {
      state.headerColourTheme = action.payload;
    })
    .addCase(setGoCertifyData, (state, action) => {
      state.gocertify = action.payload;
    })
    .addCase(setJobsData, (state, action) => {
      state.jobs = action.payload;
    })
    .addCase(setPageId, (state, action) => {
      state.pageId = action.payload;
    })
    .addCase(setPageName, (state, action) => {
      if (state.siteData) {
        state.siteData.pageName = action.payload;
      } else {
        state.siteData = { pageName: action.payload };
      }
    })
    .addCase(setCurrencyData, (state, action) => {
      state.currency = action.payload;
    })
    .addCase(setCookies, (state, action) => { 
      state.cookies = action.payload;
    })
    .addCase(setShowBrandNameProductCard, (state, action) => {
      state.showBrandNameProductCard = action.payload;
    })
    .addCase(setHeaderReverse, (state, action) => {
      state.headerReverse = action.payload;
    })
    .addCase(setBreadCrumbSchema, (state, action) => {
      state.breadCrumbSchema = action.payload;
    })
    .addCase(setTrendingSearchData, (state, action) => {
      state.headerTrendingSearch = action.payload;
    });
});
