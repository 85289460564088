import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsCompactHeader, selectToggles } from '../../features/static';
import { PromoBanner as PromoBannerType } from '../../types/middleware-types';
import useElementPush from '../../utils/customHooks/useElementPush';
import { useHeaderCompactPosition } from '../../utils/customHooks/useHeaderCompactPosition';
import useHeaderTheme from '../../utils/customHooks/useHeaderTheme';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import ContinuityBarStructure from './ContinuityBarStructure';

export const DEFAULT_PROMO_BANNER_HEIGHT = 50;

export interface PromoBannerProps {
  data: PromoBannerType;
  isReverse?: boolean;
}

const ContinuityBar: FC<PromoBannerProps> = ({ data, isReverse = false }) => {
  const theme = useTheme();
  const { featureStickyHeader } = useSelector(selectToggles);
  const compactHeader = useSelector(selectIsCompactHeader);
  const { headerPosition } = useHeaderTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const getElement = useElementPush<HTMLDivElement>('promoBanner');
  const continuityBarData = data?.continuityBar;


  const { promoBannerTop } = useHeaderCompactPosition();

  const height = !isDesktop ? data?.mobileHeightPixels : data?.desktopHeightPixels;

  const componentProps = {
    height,
    getElement,
    stickyCompactHeader: featureStickyHeader,
    compactHeader,
    headerPosition,
    top: promoBannerTop,
    continuityBarData,
    isReverse
  };

  return <>{continuityBarData && <ContinuityBarStructure {...componentProps} />}</>;
};

export default ContinuityBar;
