import React, { FC } from 'react';

import { ImageHtmlLink, Maybe, SubMenuLinkList } from '../../../../types/middleware-types';
import LinkListWithImageStructure from './LinkListWithImageStructure';

export interface LinkListWithImageProps {
  data: {
    links: SubMenuLinkList;
    images?: Maybe<Array<Maybe<ImageHtmlLink>>>;
  }
}

const LinkListWithImage: FC<LinkListWithImageProps> = ({ data: { links, images }}) => {
  const componentProps = { links, images };
  return (
    <LinkListWithImageStructure {...componentProps} />
  )
}

export default LinkListWithImage
