import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import usePageName from '../../utils/customHooks/usePageName';
import CountdownStructure from './CountdownStructure';

export interface CountdownValues {
  days: number;
  hours: number;
  mins: number;
  secs: number;
}

export interface CountdownProps {
  endDate: string;
  layout: string;
}

const initialState: CountdownValues = {
  days: 0,
  hours: 0,
  mins: 0,
  secs: 0,
};

const Countdown: FC<CountdownProps> = ({ endDate, layout }: CountdownProps) => {
  const endDayJS = dayjs(endDate);

  const [countdownValues, setCountdownValues] = useState<CountdownValues>(initialState);

  const { pageName } = usePageName();

  const componentProps = {
    countdownValues,
    layout,
    pageName,
  };

  // check on every second how much time has left until the end and set that value
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    const timer = () => {
      const nowDayJS = dayjs(Date.now());
      const timeToEnd = endDayJS.diff(nowDayJS)
      const isNowBeforeEnd = nowDayJS.isBefore(endDayJS)

      if (isNowBeforeEnd) {
        const days = Math.floor(timeToEnd / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeToEnd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((timeToEnd % (1000 * 60 * 60)) / (1000 * 60));
        const secs = Math.floor((timeToEnd % (1000 * 60)) / 1000);
        setCountdownValues({
          days,
          hours,
          mins,
          secs,
        });
      } else {
        return clearInterval(interval);
      }
      return null;
    };
    interval = setInterval(timer, 1000);
    return () => clearInterval(interval);
  }, [endDayJS]);

  return <CountdownStructure {...componentProps} />;
};

export default Countdown;
