import styled from 'styled-components';

interface Props {
  $layout: string;
}

export const StyledCountdownItemContainer = styled.div<Props>`
  display: flex;
  padding: 0.5rem;
  text-align: center;
  width: ${({ $layout }) => ($layout === 'page' ? '4.8rem' : 'auto')};

  @media ${({ theme }) => theme.devices.medium} {
    width: ${({ $layout }) => ($layout === 'page' ? '8rem' : 'auto')};
  }

  flex-direction: ${({ $layout }) => ($layout === 'page' ? 'column' : 'row')};

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

export const StyledCountdownItemValue = styled.p<Props>`
  margin: 0;
  font-size: ${({ theme, $layout }) => ($layout === 'page' ? '1.8rem' : theme.vars.sizeM)};
`;

export const StyledCountdownItemType = styled.span<Props>`
  text-transform: lowercase;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: ${({ theme }) => theme.vars.sizeS};
  margin-top: ${({ $layout }) => ($layout === 'page' ? '1rem' : '0')};
`;
