import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledFormLabel from '../FormLabel/FormLabelStyles';
import {
  StyledErrorMsg,
  StyledIconContainer,
  StyledInputContainer,
  StyledTextInput,
} from './TextInputStyles';

interface TextInputStructureProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: boolean;
  errorMsg?: string;
  type: string;
  required?: boolean;
  darkBg?: boolean;
  focus?: boolean;
  icon?: IconProp;
  iconColour?: string;
}

const TextInputStructure: FC<TextInputStructureProps> = ({
  id,
  label,
  name,
  placeholder,
  onChange,
  error,
  errorMsg,
  type,
  required,
  darkBg,
  focus,
  icon,
  iconColour,
  ...otherProps
}) => (
  <StyledInputContainer>
    {label && id && (
      <StyledFormLabel $required={required} htmlFor={id}>
        {label}
      </StyledFormLabel>
    )}
    <StyledTextInput
      {...otherProps}
      {...(id ? { id } : {})}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      type={type}
      required={required}
      aria-required={required}
      $error={error}
      ref={(input: HTMLInputElement) => input && focus && input.focus()}
      $hasIcon={!!icon}
    />
    {icon && (
      <StyledIconContainer data-hookid="iconContainer">
        <FontAwesomeIcon color={iconColour} icon={icon} />
      </StyledIconContainer>
    )}
    {error && <StyledErrorMsg $darkBg={darkBg}>{errorMsg}</StyledErrorMsg>}
  </StyledInputContainer>
);

export default TextInputStructure;
