import styled from 'styled-components';

import { Maybe} from '../../../types/middleware-types';
import { StyledSearchIconContainer } from "../../Search/SearchIcon/SearchIconStyles";
import { DEFAULT_SMART_BANNER_HEIGHT } from "../../SmartAppBanner";

interface HeaderStyleProps {
  $headerTheme: string,
  $headerBackground: string,
  $headerPosition: string,
  $hasPromoBannerMobile: boolean | undefined,
  $promoBannerMobileHeight: Maybe<number> | undefined,
  $hasPromoBannerDesktop: boolean | undefined,
  $promoBannerDesktopHeight: Maybe<number> | undefined
  $hasSmartAppBanner?: boolean;
}

export const StyledTransparentHeader = styled.header<HeaderStyleProps>`
  z-index: 10;
  width: 100%;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: ${({ $headerTheme }) => $headerTheme};
  font-weight: unset;
  background: ${({ $headerBackground }) => $headerBackground};
  padding-bottom: 0.2rem;
  position: ${({ $headerPosition }) => $headerPosition};
  ${({ $headerPosition }) =>
  $headerPosition === 'fixed' &&
    `
    top: 0;
  `}
  ${({ $headerPosition, $hasPromoBannerMobile, $promoBannerMobileHeight, $hasSmartAppBanner }) => {
      if($headerPosition === 'fixed' && $hasSmartAppBanner) {
        return `top: ${DEFAULT_SMART_BANNER_HEIGHT}px;`
      }
      if($headerPosition === 'absolute' && !$hasSmartAppBanner) {
        return `top: ${($hasPromoBannerMobile && ($promoBannerMobileHeight ?? 50)) || 0}px;`;
      }
      if($headerPosition === 'absolute' && $hasSmartAppBanner) {
        return `top: ${($hasPromoBannerMobile && (($promoBannerMobileHeight ?? 50) + DEFAULT_SMART_BANNER_HEIGHT ?? 128)) || 0}px;`;
      }
      return null;
    }}

  @media ${({ theme }) => theme.devices.large} {
    padding-bottom: 0;
    ${({ $headerPosition, $hasPromoBannerDesktop, $promoBannerDesktopHeight }) => {
      if($headerPosition === 'fixed') {
        return `top: 0;`
      }
      if($headerPosition === 'absolute') {
        return `top: ${($hasPromoBannerDesktop && ($promoBannerDesktopHeight ?? 50)) || 0}px;`;
      }
      return null;
    }}
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledUtilitiesMenu = styled.div<{$searchOpen: boolean}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  flex-direction: row-reverse;
  align-items: center;
  pointer-events: all;

  @media ${({ theme }) => theme.devices.large} {
    pointer-events: ${({ $searchOpen }) => $searchOpen && 'none'};
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const StyledOverlay = styled.div<{$searchOpen: boolean}>`
  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $searchOpen }) => ($searchOpen ? 'block' : 'none')};
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 20;
    background-color: rgb(0 0 0 / 50%);
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    margin-left: 4rem;
    justify-content: flex-start;
  }

  &:last-of-type {
    margin-right: 4rem;
    justify-content: flex-end;
  }
`;

export const StyledSecondarySection = styled.div`
  width: 24rem;
  display: flex;
  justify-content: space-around;
`;

export const StyledDesktopNav = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
    width: 100%;
  }
`;

export const StyledMobileNav = styled.div`
  display: block;
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
  ${StyledSearchIconContainer} {
    svg {
      width: 1em;
    }
  }
`;

export const StyledMobileUtils = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.vars.sizeXS};
  min-height: 4rem;
  overflow: hidden;
`;
