const getCustomerServicesUrl = (): string | null => {
  if (
    process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus' ||
    (typeof window !== 'undefined' && window.location.host.includes('kurtgeiger.us'))
  ) {
    return 'https://help.kurtgeiger.us/hc/en-gb';
  }
  if (
    process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal' ||
    (typeof window !== 'undefined' && window.location.host.includes('global.kurtgeiger'))
  ) {
    return 'https://service.global-e.com/?id=113f4b39-0ec7-4959-9e51-10f8ef32a27f';
  }
  if (
    process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeiger' ||
    (typeof window !== 'undefined' && window.location.host.includes('kurtgeiger.com'))
  ) {
    return 'https://help.kurtgeiger.com/hc/en-gb';
  }
  if (
    process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'carvela' ||
    (typeof window !== 'undefined' && window.location.host.includes('carvela.com'))
  ) {
    return 'http://help.carvela.com/hc/en-gb';
  }

  return null;
};

export default getCustomerServicesUrl;
