import { ElementType, ErrorInfo } from 'react';
import { IncomingMessage } from 'http';
import { QueryResult, DocumentNode, ApolloError } from '@apollo/client';
import {
  Category,
  CmsBlocksPage,
  CmsBrandIndexPage,
  CmsFlashSalePage,
  CmsTrackMyOrderPage,
  ContentBlock,
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockAnimationMediaAndText50PercentSplit,
  ContentBlockAnimationMediaBanner,
  ContentBlockAnimationMediaBasic,
  ContentBlockAnimationMediaCarousel,
  ContentBlockAnimationMediaCarouselSimple,
  ContentBlockAnimationMediaCarouselWithMediaItem,
  ContentBlockAnimationMediaCtAs,
  ContentBlockAnimationMediaFocus,
  ContentBlockAnimationMediaOverlap,
  ContentBlockAnimationMediaShopBySize,
  ContentBlockAnimationProductCarousel,
  ContentBlockAnimationProductCarouselWithMediaItem,
  ContentBlockCelebrityStyleCarousel,
  ContentBlockMediaAndText50PercentSplit,
  ContentBlockMediaBanner,
  ContentBlockMediaBasic,
  ContentBlockMediaCarousel,
  ContentBlockMediaCarouselSimple,
  ContentBlockMediaCarouselWithMediaItem,
  ContentBlockMediaCtAs,
  ContentBlockMediaDoubleHeightImageOnLeftSide,
  ContentBlockMediaFocus,
  ContentBlockMediaOverlap,
  ContentBlockMediaParallax,
  ContentBlockMediaShopBySize,
  ContentBlockProductCarousel,
  ContentBlockProductCarouselWithMediaItem,
  ContentBlockRawHtml,
  ContentBlockTabs,
  ContentBlockText,
  DesktopSubMenu,
  Maybe,
  MobileSubMenu,
  ProductListItem,
  ProductSize,
  Sitemap,
  SubMenuColumn,
  SubMenuSingleColumn,
  Video,
  Product,
  ProductImage,
  ProductSearch,
  CartItemProduct,
  Job,
} from './middleware-types';

import { SliderSettings } from '../components/Slider/Slider.types';
import { SliderRef } from '../components/Slider';
import { GlobalAnimationsStyle } from '../../sites/animations';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';

export type CMSBlocks =
  | ContentBlockCelebrityStyleCarousel
  | ContentBlockMediaAndText50PercentSplit
  | ContentBlockMediaBanner
  | ContentBlockMediaBasic
  | ContentBlockMediaCarousel
  | ContentBlockMediaCarouselSimple
  | ContentBlockMediaCarouselWithMediaItem
  | ContentBlockMediaCtAs
  | ContentBlockMediaDoubleHeightImageOnLeftSide
  | ContentBlockMediaFocus
  | ContentBlockMediaOverlap
  | ContentBlockMediaParallax
  | ContentBlockMediaShopBySize
  | ContentBlockProductCarousel
  | ContentBlockProductCarouselWithMediaItem
  | ContentBlockRawHtml
  | ContentBlockTabs
  | ContentBlockText;

export interface EmarsysFormFields {
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
  consent?: string;
  storeNumber?: string;
  source?: { input?: string; value?: string };
  address?: string;
  city?: string;
  postcode?: string;
  dateOfBirth?: string;
  mobileNumber?: string;
}

export interface EmarsysFormOptions {
  formAction: string;
  formId: string;
  commentId: string;
  fields: EmarsysFormFields;
}

export interface TestMock<T> {
  request: {
    query: DocumentNode;
    variables?: {
      [key: string]: any;
    };
  };
  result: QueryResult<T>;
}

export interface QuickBuySize {
  sku: string;
  stockMessage?: Maybe<string>;
  inStock: boolean;
  label: Maybe<string> | undefined;
}

export type ProductViewType = 'small_view' | 'large_view';

export type ProductViewTypeOption = {
  id: ProductViewType;
  label: string;
  selected: boolean;
  shouldFollow: boolean;
};

export const SalesFlagTypeArray = [
  'newIn',
  'sale',
  'offer',
  'exclusive',
  'clearance',
  'priceDrop',
] as const;
export type SalesFlagType = typeof SalesFlagTypeArray[number];
export interface AccordionValues {
  title: string;
  isOpen?: boolean;
  openByDefaultOnMobile?: boolean;
}

export interface MediaList {
  mobile?: Maybe<string>;
  thumb?: Maybe<string>;
  fullsize?: Maybe<string>;
}

export interface MediaImage {
  mediaList: MediaList;
  alt?: string;
  defaultImageUrl?: string;
  type: string;
}

export interface NextImage {
  src: string;
  width?: number | null;
  height?: number | null;
}

export type NextMediaList = {
  desktop?: NextImage | null;
  mobile?: NextImage | null;
  tablet?: NextImage | null;
}

export type CustomMedia =
  | {
    video: Video;
    type: string;
    mediaList?: MediaList;
    alt?: string;
    defaultImageUrl?: string;
  }
  | {
    mediaList: MediaList;
    alt: string;
    defaultImageUrl: string;
    type: string;
    video?: Video;
  }
  | {
    mediaList: NextMediaList;
    alt: string;
    defaultImageUrl?: string;
    type: string;
  }
  | null;

export interface MinibagOptionsInterface {
  toggleBag?: () => void;
  isMobileOrTablet?: boolean;
  showBag?: boolean;
}

export interface SizesInterface {
  eu?: string | null;
  uk?: string | null;
  us?: string | null;
}

export interface ProductInterface {
  name: string;
  lineNumber?: string;
  brand: string;
  colour?: string | null;
  sale: boolean;
  sizeLabels: SizesInterface;
  image?: {
    alt?: string | null;
    url: string;
  } | null;
  productImage?: {
    alt?: string | null;
    url: string;
  } | null;
  price: {
    now: string;
    nowFormatted: string;
    was?: string | null;
    wasFormatted?: Maybe<string> | undefined;
    rrp?: string | null;
    discount?: string | null;
    discountPercentage?: string | null;
  };
  sku?: string;
}

export interface ProductExpandedInterface {
  id: string;
  product: CartItemProduct;
  quantity: number;
}

export interface BagStateInterface {
  id: string;
  title: string;
  deliveryOptions: string[];
  bag: {
    id: string;
    items: [] | ProductExpandedInterface[];
    itemCount: number;
    remainingItems: number;
    subTotal: string | null;
  };
  content: string;
  bagButton: {
    ctaText: string;
    url: string;
    openInNewTab: boolean;
  };
  checkoutButton: {
    ctaText: string;
    url: string;
    openInNewTab: boolean;
  };
}

export interface BagStateWrappedInterface {
  bagData: BagStateInterface;
}
export type DropdownOption = { label: string; value: string; id?: string };

export type SubMenuData =
  | Array<SubMenuColumn>
  | SubMenuSingleColumn
  | DesktopSubMenu
  | Array<MobileSubMenu>
  | undefined;

export interface MinibagHeaders {
  customer_oauth_token?: string;
  shopping_cart_id?: string;
  kg_session?: string;
}

export interface GoCertifyPageData {
  name: string;
  page: string;
}

export type CMSPageType = 'BlocksPage' | 'BrandIndexPage' | 'FlashSalePage' | undefined;

export interface BrandIndexPage extends CmsBrandIndexPage {
  brandIndexTitle: string;
  brandIndexSubtitle: string;
}

export interface UpdatedCmsFlashSalePage extends CmsFlashSalePage {
  flashSalesTitle: string;
  flashSalesSubtitle: string;
}

export type ScrollAnimationType =
  | ContentBlockAnimationMediaShopBySize
  | ContentBlockAnimationMediaCarousel
  | ContentBlockAnimationMediaCtAs
  | ContentBlockAnimationMediaBasic
  | ContentBlockAnimationMediaFocus
  | ContentBlockAnimationMediaBanner
  | ContentBlockAnimationMediaAndText50PercentSplit
  | ContentBlockAnimationMediaCarouselSimple
  | ContentBlockAnimationMediaCarouselWithMediaItem
  | ContentBlockAnimationProductCarousel
  | ContentBlockAnimationProductCarouselWithMediaItem
  | ContentBlockAnimationMediaOverlap
  | ContentBlockAnimationCelebrityStyleCarousel;

export type DatalayerProduct =
  | (Product & { variants: Product[] })
  | (ProductListItem & { sizes: ProductSize[] });

export interface FormattedDatalayerProduct {
  brand: string;
  category: string;
  variant: string;
  colour: string;
  previousUrl: string;
  id: any;
  sku: string;
  inStock: string;
  name: string;
  price: string;
  quantity: number;
  saleProduct: string;
  newIn: string;
  size: string;
  sizesInStock: string;
  sizesNoStock: string;
  stockLocation: string;
  totalSizes: number;
  sizesAvailable: number;
  sizesNotAvailable: number;
  url: string;
  productImageUrl: Array<ProductImage>;
  wasPrice?: string;
  markdown?: string;
  discountPercentage?: string;
  variants?: string;
  variantsAvailable?: string;
  variantsUnavailable?: string;
  variantsNoStock?: string;
}

export interface ProductListItemExtended extends ProductListItem {
  productPosition?: number;
}

export interface UpdatedCmsBlocksPage extends Omit<CmsBlocksPage, 'blocks'> {
  blocks: Maybe<Array<CMSBlocks>> | undefined;
}

export interface SplunkParams {
  action_type?: string;
  action_location?: string;
  action_value_id?: string;
  graphQlError?: string | ApolloError;
  siteError?: Error | null;
  graphQlData?: any;
  errorInfo?: ErrorInfo;
}

export type PlpFilters = Record<
  string,
  | {
    in: string[] | string;
  }
  | {
    from?: string;
    to?: string;
  }
>;
export interface CMSReturnedData {
  CMSPageBuilder: UpdatedCmsBlocksPage;
}

export interface CategoryReturedData {
  category: Category;
}

export interface CategorySearchReturedData {
  productSearch: ProductSearch;
}
export interface ProductReturedData {
  product: Maybe<Product>;
}

export interface SitemapReturedData {
  sitemap: Sitemap;
}

export interface TrackMyOrderReturedData {
  CMSPageBuilder: CmsTrackMyOrderPage;
}

export type AllReturnTypes =
  | CMSReturnedData
  | CategoryReturedData
  | ProductReturedData
  | SitemapReturedData
  | TrackMyOrderReturedData
  | CategorySearchReturedData;

export type DataCheckType =
  | 'CMSPageBuilder'
  | 'product'
  | 'category'
  | 'productSearch'
  | 'storeList'
  | 'sitemap';

export type CatchAllContent =
  | BrandIndexPage
  | UpdatedCmsBlocksPage
  | UpdatedCmsFlashSalePage
  | Maybe<Array<CMSBlocks>>
  | Maybe<Array<ContentBlock>>
  | undefined;

export interface CatchAllQueryResponse {
  CMSPageBuilder: BrandIndexPage | UpdatedCmsFlashSalePage | UpdatedCmsBlocksPage | undefined;
}
export interface BasicCarouselProps {
  // Added ignore, can be any item
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<{ [key: string]: any }>;
  component: ElementType;
  settings?: SliderSettings;
  // Added ignore,unknown props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherProps?: { [key: string]: any };
  scrollableOnDesktop?: boolean;
  animationType?: string | null;
  animate?: boolean;
  getCarouselInstance?: (slider: SliderRef | null) => void;
  animationStyle?: GlobalAnimationsStyle;
  setCarouselTitle?: string;
  campaign?: string;
  arrowImageUrl?: string;
  scrollableOnMobile?: boolean;
  pageName?: string;
  showArrows?: boolean;
  slick?: boolean;
  idValue?: string;
}

export interface IncomingMessageUpdated extends IncomingMessage {
  headers: {
    'proxy-is-mobile'?: string,
    'proxy-is-tablet'?: string,
    cookie?: string
  }
}

export type FormattedFilters = {
  [key: string]: any;
}

export interface DataCaptureObject {
  count: number;
  maxCount: number;
  expire24h: number;
  expire6month: number
  hasFilledForm: boolean,
}

export interface InitialFilterDataInterface {
  categoryPath: string | string[] | undefined | null,
  filterOptions: PlpFilters | undefined | null,
  sortBy: string | string[] | null
}

export interface BackgroundImageItemInterface {
  desk: string;
  mob: string;
}

export interface GlobalInitialDataInterface {
  togglesList: LDFlagSet;
  isMobileSSR: boolean;
  pageName: string;
}
export interface RequestOptionsInterface {
  method: string;
  headers: HeadersInit | undefined;
  body: string;
  redirect: RequestRedirect | undefined;
}
export interface JobListingByDepartment {
  __typename?: string | undefined;
  label: string;
  jobs: {
    subDepartment: string;
    careersData: Array<Job>;
  }
}

export declare enum PrefetchKind {
  AUTO = "auto",
  FULL = "full",
  TEMPORARY = "temporary"
}

export interface LinkListInteface {
  title: string,
  url: string,
  icon: React.JSX.Element
}