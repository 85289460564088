import { createAction } from '@reduxjs/toolkit';

import {
  Query
} from '../../types/middleware-types';

export const fetchMoreData = createAction<boolean>('stories/fetchMoreData');
export const updateAllStoriesData = createAction<Pick<Query, "CMSPageBuilder">[] | undefined>('stories/updateAllStoriesData');
export const updateStoriesId = createAction<string>('stories/updateStoriesId');
export const updateStoriesLoading = createAction<boolean>('stories/updateStoriesLoading');
