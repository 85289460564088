import { LDFlagSet } from '@launchdarkly/node-server-sdk';

import { HeaderNavigation } from "../../../../m2HeaderFooter/src/types/middleware-types";
import { RootState } from '../../../reduxStore/store';
import { GoCertifyPageData } from '../../pageComponents/GocertifyPages'
import { BrandIndexPage, CMSBlocks, UpdatedCmsFlashSalePage } from '../../types/custom-types';
import { BrandIndex, BrandLetterLinkList, CategoryBreadcrumb, CmsTrackMyOrderPage, ContentBlock, CountryPickerResponse, Footer, Header, HeaderTrendingSearch, ImageV2, JobListing, Logo, Maybe, ProductBreadcrumb, PromoBanner, Sitemap, SocialMedia, SubMenuIndexLink } from '../../types/middleware-types';
import { SiteData } from '../../types/state-types';

export const selectSiteData = (state: RootState): Maybe<SiteData | BrandIndexPage | UpdatedCmsFlashSalePage | CmsTrackMyOrderPage> => state.static?.siteData;
export const selectHeaderTheme = (state: RootState): string => state.static?.headerColourTheme;
export const selectPageName = (state: RootState): string | undefined => state.static?.siteData?.pageName;
export const selectPageId = (state: RootState): string | undefined => state.static?.pageId;
export const selectGoCertify = (state: RootState): GoCertifyPageData | undefined => state.static?.gocertify;
export const selectCompanyName = (state: RootState): string | null | undefined => state.static?.siteData?.companyName;
export const selectLogo = (state: RootState): Maybe<ImageV2> | undefined => state.static?.siteData?.logo;
export const selectBackground = (state: RootState): Maybe<ImageV2> | undefined => state.static?.siteData?.background;
export const selectBrandIndex = (state: RootState): Maybe<BrandIndex> | undefined => state.static?.siteData?.brandIndex;
export const selectPageBlocks = (state: RootState): Maybe<Array<CMSBlocks>> | Maybe<Array<ContentBlock>> | undefined => state.static?.siteData?.blocks;
export const selectProductListLoader = (state: RootState): string | null | undefined => state.static?.siteData?.productListLoader;
export const selectHeaderOnlyData = (state: RootState): Maybe<Header> | undefined => state.static?.headerOnlyData;
export const selectHeaderNavigationData = (state: RootState): Maybe<HeaderNavigation> | undefined => state.static?.headerNavigationData;
export const selectHeaderLogo = (state: RootState): Logo | undefined => state.static?.headerOnlyData?.logo;
export const selectPromoBanner = (state: RootState): Maybe<PromoBanner> | undefined => state.static?.headerOnlyData?.promoBanner;
export const selectCountryPicker = (state: RootState): Maybe<CountryPickerResponse> | undefined => state.static?.countryPickerData;
export const selectFooterData = (state: RootState): Maybe<Footer> => state.static?.footerData;
export const selectIsCompactHeader = (state: RootState): boolean => state.static.compactHeader;
export const selectIsHeaderReverse = (state: RootState): boolean => state.static.headerReverse;
export const selectShowBrandName = (state: RootState): boolean => state.static.showBrandName;
export const selectShowBrandNameProductCard = (state: RootState): boolean => state.static.showBrandNameProductCard;
export const selectIsBrandPage = (state: RootState): boolean => state.static.isBrandPage;
export const selectShowMobileAppLogo = (state: RootState): boolean => state.static.showMobileAppLogo;
export const selectBrandsIndexData = (state: RootState): Array<SubMenuIndexLink> | null => { if (state.static.brandsIndexData !== null) { return state.static.brandsIndexData.index } return null };
export const selectBrandsIndexListLinks = (state: RootState): Array<BrandLetterLinkList> | undefined => state.static.brandsIndexData?.brandListLinks;
export const selectToggles = (state: RootState): LDFlagSet => state.static.toggles;
export const selectRemoveXFromFirstElement = (state: RootState): boolean => state.static.removeXFromFirstElement;
export const selectSitemapData = (state: RootState): Maybe<Sitemap> => state.static.sitemapData;
export const selectJobsData = (state: RootState): Maybe<JobListing[]> => state.static.jobs;
export const selectCurrencyData = (state: RootState): { code: string, symbol: string } => state.static?.currency;
export const selectReferrer = (state: RootState): string | undefined | null => state.static?.siteData?.referrer;
export const selectServerCookies = (state: RootState): string | null => state.static?.cookies;
export const selectTrendingHeaderData = (state: RootState): HeaderTrendingSearch | undefined => state.static?.headerTrendingSearch;
export const selectSocialMediaData = (state: RootState): SocialMedia | undefined => state.static?.footerData?.socialMedia;
export const selectBreadCrumbSchema = (state: RootState): Array<CategoryBreadcrumb | ProductBreadcrumb> | null => state.static?.breadCrumbSchema;
