/* eslint-disable no-nested-ternary */
/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react';
import Head from 'next/head';

import NextImage from '../../basic/NextImage';
import { Maybe } from "../../types/middleware-types";
import { createMediaList } from '../../utils/helpers/createMediaList';
import { StyledWistiaContainer, StyledWistiaVideo } from './WistiaVideoStyles';

interface WistiaVideoStructureProps {
  id: string;
  autoPlay: boolean;
  muted: boolean;
  controlsVisibleOnLoad: boolean;
  endVideoBehaviour: string;
  label?: string;
  thumbnailUrl?: Maybe<string>;
}

const WistiaVideoStructure: FC<WistiaVideoStructureProps> = ({
  id,
  autoPlay,
  muted,
  controlsVisibleOnLoad,
  endVideoBehaviour,
  label,
  thumbnailUrl,
}) => (
  <>
    <Head>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" key="wistia-E-v1" async />
      <script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} async />
    </Head>
    {thumbnailUrl ? (
      <>
        <StyledWistiaContainer>
          <NextImage mediaList={createMediaList({ src: thumbnailUrl })} />
          <StyledWistiaVideo>
            <div
              id='wistia-video-container'
              aria-label={label}
              className={`wistia_embed wistia_async_${id} autoPlay=${autoPlay} muted=${muted} controlsVisibleOnLoad=${controlsVisibleOnLoad} endVideoBehavior=${endVideoBehaviour} wmode=transparent videoFoam=true`}
            />
          </StyledWistiaVideo>
        </StyledWistiaContainer>
      </>
    ) : (
      <div>
        <div
          id='wistia-video-container'
          aria-label={label}
          className={`wistia_embed wistia_async_${id} autoPlay=${autoPlay} muted=${muted} controlsVisibleOnLoad=${controlsVisibleOnLoad} endVideoBehavior=${endVideoBehaviour} wmode=transparent videoFoam=true`}
        />
      </div>
    )}
  </>
);

export default WistiaVideoStructure;

