import styled from 'styled-components';

import { AccordionValues } from '../../types/custom-types';

export const StyledAccordion = styled.div`
  width: 100%;

  .trigger-title {
    margin: 0;
  }
`;

export const StyledAccordionTrigger = styled.button<{ $layout: string; $red: boolean }>`
  background: transparent;
  border: none;
  text-transform: ${({ theme }) => theme.plp.filters.textTransform};
  ${({ theme }) => theme.types.h3Style};
  ${({ $layout }) => ($layout === 'flashSales' ? 'font-size: 1.8rem' : '')};
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, $red }) => ($red ? theme.vars.red : theme.vars.black)};

  > span {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`;

export const StyledAccordionContent = styled.div<{ $isActive?: boolean | AccordionValues }>`
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
`;

export const StyledTitleDropdownText = styled.p`
  ${({ theme }) => theme.types.labelStyle};
  text-align: center;
  margin: ${({ theme }) => theme.vars.sizeM};
`;

export const StyledTitleDropdown = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    max-width: 80rem;
    margin: 0 auto;
  }
  ${StyledAccordionTrigger} {
    color: ${({ theme }) => theme.vars.black};
    justify-content: center;
    text-align: center;

    span {
      margin: 0 1rem ${({ theme }) => theme.plp.dropdownTitleSvgMargin} 1.5rem;
    }
  }
`;

export const StyledAccordionWrapper = styled.div``; /* stylelint-disable-line */
