import styled from "styled-components";

export const StyledSubNavColumn = styled.div`
  text-align: left;
  width: 100%;
  margin: 0 0.8rem;

  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
    flex-direction: column;
  }
`;
