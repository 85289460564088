'use client'

import React, { FC } from 'react';
import { set } from 'local-storage';
import { usePathname } from 'next/navigation';

import { BasicCarouselProps } from '../../../types/custom-types';
import MobileCarouselStructure from './MobileCarouselStructure';

export type MobileCarouselProps = Omit<BasicCarouselProps, "getCarouselInstance"> & {
  lsIgnore?: boolean;
};

const MobileCarousel: FC<MobileCarouselProps> = ({
  items,
  component,
  otherProps = {},
  setCarouselTitle,
  campaign,
  lsIgnore,
}) => {
  const path = usePathname()

  // For GTM PDP datalayer.
  const addProductPositionToLocalStorage = (productPosition: number, lineNumber: string): void => {
    if (lsIgnore) return;

    set('previous_product_position', {
      position: productPosition.toString(),
      location: `product - ${setCarouselTitle || 'recommended products'}`,
      pageUrl: path,
      lineNum: lineNumber,
      campaignId: campaign,
    });
  };

  const componentProps = {
    items,
    component,
    otherProps,
    addProductPositionToLocalStorage,
  };

  return <MobileCarouselStructure {...componentProps} />;
};

export default MobileCarousel;
