import React, { FC, memo,ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import { AmazonPayButton, CartItem, Maybe, Minibag } from '../../../types/middleware-types';
import AmazonPay from '../../AmazonPay';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import MinibagItem from '../MinibagItem';
import {
  BagButton,
  CheckoutButton,
  StyledActionsContainer,
  StyledBagInfo,
  StyledBasketTotals,
  StyledButtonsContainer,
  StyledButtonWrapper,
  StyledDeliveryInfo,
  StyledEmptyBagContainer,
  StyledMinibagDropdownContainer,
  StyledNewInLink,
} from './MinibagDropdownStyles';
import { MinibagDropdownProps } from '.';

interface MinibagDropdownStructureProps extends MinibagDropdownProps {
  bagData: Maybe<Minibag>;
  compactHeader: boolean;
  currencySymbol: string;
  formatTotalPrice: (price: string) => ReactNode;
  isScrolling: boolean;
  itemsToShow: CartItem[];
  remainingItems: number;
  topPosition: number;
  amazonPayData: Maybe<AmazonPayButton>;
  linkAttr: {
    target?: string,
    rel?: string,
  };
  checkoutButtonLinkAttr: {
    target?: string,
    rel?: string,
  };
  isRedesign: boolean;
  engs13172CheckoutButton: boolean;
  useReactRoutingLinks: boolean;
  showReactCheckout: boolean;
}

const MinibagDropdownStructure: FC<MinibagDropdownStructureProps> = ({
  itemsToShow,
  toggleBag,
  showBag,
  bagData,
  formatTotalPrice,
  isScrolling,
  compactHeader,
  isMobileOrTablet,
  topPosition,
  remainingItems,
  currencySymbol,
  amazonPayData,
  linkAttr,
  checkoutButtonLinkAttr,
  isRedesign,
  engs13172CheckoutButton,
  useReactRoutingLinks,
  showReactCheckout,
}) => (
  <StyledMinibagDropdownContainer
    $isScrolling={isScrolling}
    data-hookid="globalHeaderMinibagDropdown"
    $showBag={showBag || false}
    $compactHeader={compactHeader}
    $isMobileOrTablet={isMobileOrTablet || false}
    $topPosition={topPosition}
    aria-hidden={!showBag}
    $kgRedesignToggle={isRedesign}
  >
    {bagData && (
      <>
        {bagData?.bag && bagData?.bag?.items.length > 0 ? (
          <>
            <StyledBagInfo>
              <h4 className="bag-info">
                <span>{bagData.title}</span> | {bagData.bag.itemCount} item
                {bagData.bag.itemCount > 1 ? 's' : ''}
                {!isRedesign && (
                  <StyledBasketTotals data-ge-basket-totals>
                    (
                    {currencySymbol}
                    {formatTotalPrice(bagData?.bag?.subTotal)}
                    )
                  </StyledBasketTotals>
                )}
              </h4>
              <div
                className="close"
                role="button"
                onKeyDown={toggleBag}
                tabIndex={0}
                onClick={toggleBag}
              >
                <ScreenReaderOnlyText text="Close minibag" />
                <FontAwesomeIcon size="2x" icon={faTimes as IconProp} />
              </div>
            </StyledBagInfo>
            {itemsToShow.length > 0 &&
              itemsToShow.map((item) => (
                // temporary key
                <MinibagItem key={`${item.id}`} {...item.product} quantity={item.quantity} />
              ))}
            <StyledActionsContainer $kgRedesignToggle={isRedesign}>
              {remainingItems > 0 && (
                <>
                  {showReactCheckout ? (
                    <Link href={bagData?.bagButton?.url || '/checkout/cart'} passHref legacyBehavior>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className="more-items">
                        View all items
                      </a>
                    </Link>
                  ) : (
                    <a className="more-items" href={bagData?.bagButton?.url || '/checkout/cart'}>
                      View all items
                    </a>
                  )}
                </>
              )}
              <StyledDeliveryInfo>
                <p className="title">{isRedesign ? 'Subtotal' : 'Subtotal Before Delivery'}</p>
                {isRedesign && bagData?.bag?.subTotalFormatted ? (
                  <p data-ge-basket-totals>
                    {bagData.bag.subTotalFormatted}
                  </p>
                ) : (
                  <p data-ge-basket-totals>
                    {currencySymbol}
                    {formatTotalPrice(bagData.bag.subTotal)}
                  </p>
                )}
              </StyledDeliveryInfo>
              {engs13172CheckoutButton ? (
                <StyledButtonsContainer data-hookid="globalHeaderMinibagDropdownButtons" $isEngs13172={true}>
                  {showReactCheckout ? (
                    <Link href={bagData?.bagButton?.url || '/checkout/cart'} passHref legacyBehavior>
                      <StyledButtonWrapper
                        {...checkoutButtonLinkAttr}
                      >
                        <CheckoutButton
                          data-hookid="viewAndCheckoutSecurelyButton"
                          btnType="primary"
                          type="button"
                        >
                          <>{bagData?.bagButton?.ctaText} & {bagData?.checkoutButton?.ctaText}</>
                        </CheckoutButton>
                      </StyledButtonWrapper>
                    </Link>
                  ) : (
                    <StyledButtonWrapper
                      href={bagData?.bagButton?.url || '/checkout/cart'}
                      {...checkoutButtonLinkAttr}
                    >
                      <CheckoutButton
                        data-hookid="viewAndCheckoutSecurelyButton"
                        btnType="primary"
                        type="button"
                      >
                        <>{bagData?.bagButton?.ctaText} & {bagData?.checkoutButton?.ctaText}</>
                      </CheckoutButton>
                    </StyledButtonWrapper>
                  )}
                </StyledButtonsContainer>
              ) : (
                <StyledButtonsContainer data-hookid="globalHeaderMinibagDropdownButtons">
                  {showReactCheckout ? (
                    <Link href={bagData?.bagButton?.url || '/checkout/cart'} passHref legacyBehavior>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        {...linkAttr}
                      >
                        <BagButton data-hookid="viewMyBagButton" btnType="secondary" type="button">
                          {bagData?.bagButton?.ctaText}
                        </BagButton>
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={bagData?.bagButton?.url || '/checkout/cart'}
                      {...linkAttr}
                    >
                      <BagButton data-hookid="viewMyBagButton" btnType="secondary" type="button">
                        {bagData?.bagButton?.ctaText}
                      </BagButton>
                    </a>
                  )}

                  {showReactCheckout ? (
                    <Link href={bagData?.checkoutButton?.url || '/checkout'} passHref legacyBehavior>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        {...checkoutButtonLinkAttr}
                      >
                        <CheckoutButton
                          data-hookid="checkoutSecurelyButton"
                          btnType="primary"
                          type="button"
                        >
                          {bagData?.checkoutButton?.ctaText}
                        </CheckoutButton>
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={bagData?.checkoutButton?.url || '/checkout'}
                      {...checkoutButtonLinkAttr}
                    >
                      <CheckoutButton
                        data-hookid="checkoutSecurelyButton"
                        btnType="primary"
                        type="button"
                      >
                        {bagData?.checkoutButton?.ctaText}
                      </CheckoutButton>
                    </a>
                  )}
                </StyledButtonsContainer>
              )}
              <AmazonPay location="minibag" parentId="MiniBag" data={amazonPayData} />
            </StyledActionsContainer>
          </>
        ) : (
          <StyledEmptyBagContainer data-hookid="globalHeaderMinibagDropdownEmptyCart" $kgRedesignToggle={isRedesign}>
            <p className="title">{bagData.title}</p>
            <p>{bagData.content}</p>
            {// eslint-disable-next-line no-nested-ternary
              isRedesign ? (
                useReactRoutingLinks ? (
                  <Link href="/new-in" passHref legacyBehavior>
                    <StyledNewInLink>Shop new in</StyledNewInLink>
                  </Link>
                ) : (
                  <StyledNewInLink href="/new-in">Shop new in</StyledNewInLink>
                )
              ) : null
            }
            {bagData.deliveryOptions.length > 0 && bagData.deliveryOptions.map((option) => <p key={option}>{option}</p>)}
          </StyledEmptyBagContainer>
        )}
      </>
    )}
  </StyledMinibagDropdownContainer>
);

export default memo(MinibagDropdownStructure);
