import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsCompactHeader, selectToggles } from '../../../../features/static';
import { SubMenuLinkList as LinkListTypes } from '../../../../types/middleware-types';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import LinkListStructure from './LinkListStructure';

export interface LinkListProps {
  data: LinkListTypes;
}

const LinkList: FC<LinkListProps> = ({ data }) => {
  const theme = useTheme();
  const { isMobile } = useMobileWatcher(['mobile'], theme.vars);
  const compactHeader = useSelector(selectIsCompactHeader);
  const toggles = useSelector(selectToggles);
  const forceCompactHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu ? true : compactHeader;

  const componentProps = {
    data,
    compactHeader: forceCompactHeader,
    isMobile
  };
  return <LinkListStructure {...componentProps} />;
};

export default LinkList;
