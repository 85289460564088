import styled from 'styled-components';

import { StyledLink } from '../../basic/LinkItem/LinkItemStyles';
import { StyledAccordion,StyledAccordionWrapper  } from "../Accordion/AccordionStyles";

export const StyledFooterLinksContainer = styled.div<{ $newLayout?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  position: relative;

  ${StyledAccordion} {
    @media ${({ theme }) => theme.devices.large} {
      display: flex;
      gap: 1rem;
    }
  }
  ${StyledAccordionWrapper} {
    padding: 1.2rem 0;

    @media ${({ theme }) => theme.devices.large} {
      padding: 0;
      width: 100%;
      margin-right: 1rem;
    }
  }

  ul {
    ${({ $newLayout }) =>
  $newLayout &&
      `
      margin-left: 0;
    `}
    li {
      margin: 1.6rem 0 1.6rem 1.6rem;
      ${({ $newLayout }) =>
        $newLayout &&
        `
        margin-left: 0;
      `}

      @media ${(props) => props.theme.devices.large} {
        margin: 1.6rem 0;
      }
    }

    a {
      text-decoration: none;

      ${(props) => props.theme.types.labelStyle};
      &:hover {
        color: ${({ theme }) => theme.vars.linkHover};
      }
    }
  }
`;

export const StyledLinksListsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledLinksList = styled.div`
  width: 33.33%;
`;

export const StyledLinksTitle = styled.p<{$kgRedesignToggle: boolean}>`
  text-transform: uppercase;
  margin: 0;
  font-family: ${({ $kgRedesignToggle, theme }) => $kgRedesignToggle ? theme.fonts.fontFamilyPrimaryBold : theme.fonts.fontFamilyPrimary};
`;

export const StyledFooterLinkList = styled.ul<{$kgRedesignToggle: boolean}>`
  padding: 0;
  list-style: none;

  ${StyledLink} {
    font-size: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? '1.1rem' : '1.4rem'};
  }
`;
