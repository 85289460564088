import styled from 'styled-components';

export const StyledSRText = styled.span`
  position: absolute;
  left: -1000rem;
  top: auto;
  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;
`;
