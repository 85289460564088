import React, { FC, Fragment, memo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NextImage from '../../../basic/NextImage';
import { getCountryFlag } from '../../../utils/getters/getCountryFlag';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import AccountIcon from '../../Account/AccountIcon';
import CountryPicker from '../../CountryPicker';
import MobileMenuLink from '../../MobileMenuLink';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import SubMenuItem from '../../SubMenu/SubMenuItem';
import {
  StyledCountryFlag,
  StyledCountryPickerMobile,
  StyledLinksContainer,
  StyledMobileMenuContainer,
  StyledMobileMenuTrigger,
  StyledMobileNavigationContainer,
  StyledSubMenuContainer,
  StyledUtilLinks,
} from './MobileMenuStyles';
import { MobileMenuProps } from '.';

const MobileMenuStructure: FC<MobileMenuProps> = ({
  compactHeader,
  isMenuOpen,
  isScrolling,
  headerBackground,
  headerTheme,
  toggleMobileMenu,
  data,
  setCurrentTab,
  currentTab,
  openSubNavOnKeyPress,
  handleDoubleClick,
  isAuth,
  accountLabel,
  accountHref,
  currentCountry,
  toggleCountryPicker,
  showCountryPicker,
  showCountryPickerButton,
  hideCountryPickerOnGlobalESite,
}) => (
  <StyledMobileMenuContainer data-hookid="globalHeaderMobileMenu">
    <StyledMobileMenuTrigger
      type="button"
      data-hookid="globalHeaderMobileMenuButton"
      onClick={toggleMobileMenu}
    >
      <FontAwesomeIcon color={headerTheme} icon={isMenuOpen ? faTimes as IconProp : faBars as IconProp} size="2x" />
      <ScreenReaderOnlyText text="Mobile Menu" />
    </StyledMobileMenuTrigger>
    <StyledMobileNavigationContainer $isScrolling={isScrolling} $isMenuOpen={isMenuOpen}>
      {!showCountryPicker ? (
        <>
          <StyledLinksContainer
            $headerBackground={headerBackground}
            $headerTheme={headerTheme}
            data-hookid="globalHeaderMobileMenuLinks"
          >
            <ul>
              {data &&
                data.map((subnav) => {
                  const isCurrentTab = subnav.link && currentTab === subnav.link.text.toLowerCase();
                  return (
                    subnav.link && (
                      <li
                        id={subnav?.menuId || undefined}
                        onDoubleClick={() => handleDoubleClick(subnav?.link?.url)}
                        role="presentation"
                        key={subnav.link.text}
                        className={isCurrentTab ? 'active' : ''}
                        onClick={() => setCurrentTab(subnav?.link?.text.toLowerCase() || '')}
                        onKeyDown={(e) =>
                          openSubNavOnKeyPress(e, subnav?.link?.text?.toLowerCase())}
                      >
                        {subnav.link.text}
                      </li>
                    )
                  );
                })}
            </ul>
          </StyledLinksContainer>
          <StyledSubMenuContainer>
            {data?.map((subNavData, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {subNavData?.link?.text?.toLowerCase() === currentTab && (
                  <SubMenuItem
                    // eslint-disable-next-line no-underscore-dangle
                    layout={subNavData?.__typename}
                    data={subNavData}
                  />
                )}
              </Fragment>
            ))}
            <StyledUtilLinks>
              <MobileMenuLink label={accountLabel} href={accountHref} compactHeader={compactHeader}>
                <AccountIcon isAuth={isAuth} />
              </MobileMenuLink>
              {!hideCountryPickerOnGlobalESite &&
                showCountryPickerButton &&
                currentCountry &&
                currentCountry?.name && (
                <MobileMenuLink label="Delivery Information" onClick={toggleCountryPicker}>
                  <StyledCountryFlag>
                    <NextImage
                      mediaList={createMediaList({
                        src: getCountryFlag(currentCountry.name),
                        width: 20,
                        height: 16,
                      })}
                      alt={currentCountry.name}
                      imageType="fixed"
                    />
                  </StyledCountryFlag>
                </MobileMenuLink>
              )}
              {hideCountryPickerOnGlobalESite && <div data-ge-shippingswitcher-flag />}
            </StyledUtilLinks>
          </StyledSubMenuContainer>
        </>
      ) : (
        <StyledCountryPickerMobile>
          <CountryPicker />
        </StyledCountryPickerMobile>
      )}
    </StyledMobileNavigationContainer>
  </StyledMobileMenuContainer>
);

export default memo(MobileMenuStructure);
