import styled from 'styled-components';

import { StyledNextImageWrapper } from '../../basic/NextImage/NextImageStyles';

interface LogoProps {
  $isHomePage?: boolean;
  $isAtTop: boolean;
  $featureKgRedesign: boolean;
  $standardWidth: string;
}

export const StyledBrandLogoContainer = styled.div<LogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  transition: width 0.5s linear;
  max-width: ${({ $featureKgRedesign }) => ($featureKgRedesign ? '200px' : 'none')};
  width: ${({ $isHomePage, $isAtTop, $standardWidth }) => ($isHomePage && $isAtTop ? '99vw' : $standardWidth)};
  margin: ${({ $featureKgRedesign }) => ($featureKgRedesign ? '0 1rem' : 'auto')};

  @media ${({ theme }) => theme.devices.large} {
    margin: ${({ $featureKgRedesign }) => ($featureKgRedesign ? '2rem auto 1rem' : 'auto')};
    max-width: ${({ $featureKgRedesign }) => ($featureKgRedesign ? '100%' : 'none')};
  }

  a {
    display: block;
    width: 100%;
  }

  img {
    width: 100%;
    max-width: ${({ theme }) => theme.header.mobileLogoMaxW};

    @media ${({ theme }) => theme.devices.large} {
      padding-top: ${({ theme }) => theme.vars.sizeXS};
      max-width: ${({ theme }) => theme.header.desktopLogoMaxW};
      height: auto;
    }
  }
  ${StyledNextImageWrapper} {
    margin: 0 auto;
    width: 100%;
    max-width: ${({ theme }) => theme.header.mobileLogoMaxW};

    @media ${({ theme }) => theme.devices.large} {
      padding-top: ${({ theme }) => theme.vars.sizeXS};
      max-width: ${({ theme, $isHomePage }) => ($isHomePage ? 'none' : theme.header.desktopLogoMaxW)};
    }
  }
`;