// @ts-nocheck
/* eslint-disable no-param-reassign, no-unused-expressions, no-sequences */
import React, { FC, useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { config } from '@fortawesome/fontawesome-svg-core';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/navigation';
import Script from 'next/script';
import { ThemeProvider } from 'styled-components';

import { useStore } from '../reduxStore/store';
import Forter from '../shared/components/Forter';
import GlobalE from '../shared/components/GlobalE';
import NewRelicSnippet from '../shared/components/NewRelicSnippet';
import SmartAppBanner from '../shared/components/SmartAppBanner';
import { getApolloClientConfig, useApollo } from '../shared/configs/apolloClient';
import PageLayout from '../shared/pageComponents/PageLayout';
import sendVitals from '../shared/utils/senders/sendVitals';
import Snippets from '../sites/globalSnippets';
import Types from '../sites/types';
import Variables from '../sites/variables';

import 'what-input';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Tell Font Awesome to skip adding the CSS automatically since it's being imported above
config.autoAddCss = false;

const tagManagerArgs: TagManagerArgs = {
  gtmId: `${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`,
};

interface TitlesInterface {
  [id: string]: string;
}

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const finalStore = useStore(pageProps?.initialReduxState);
  const router = useRouter();
  let tempPageName = router?.asPath?.split('/').pop();

  if (tempPageName?.indexOf('-') !== -1) {
    tempPageName = tempPageName?.replace(/-/g, ' ');
  }

  if (tempPageName?.indexOf('_') !== -1) {
    tempPageName = tempPageName?.replace(/_/g, ' ');
  }

  const basicTitles: TitlesInterface = {
    carvela: `Carvela | ${tempPageName}`,
    kg: `Kurt Geiger | ${tempPageName}`,
    sah: `Shoeaholics | ${tempPageName}`,
  };

  const toggles = pageProps.toggles || {};
  const loadForter = toggles?.featureForterIntegration;
  const { apolloClientUrl, apolloPersistedQueries } = getApolloClientConfig(
    toggles?.featureGraphCdn,
  );

  const apolloClient = useApollo(
    {
      ...pageProps.initialApolloState,
      query: router.query,
    },
    apolloClientUrl,
    apolloPersistedQueries,
  );

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const theme = {
    ...Variables,
    types: Types,
    snippets: Snippets,
    toggles,
  };

  const bannerTitle =
    process.env.NEXT_PUBLIC_SITE === 'sah' || process.env.REACT_APP_SITE === 'sah'
      ? 'Shoeaholics'
      : 'Kurt Geiger';

  return (
    <>
      {process.env.NEXT_PUBLIC_ONETRUST_SCRIPT === 'true' && (
        <>
          <Script
            type="text/javascript"
            src={`https://cdn-ukwest.onetrust.com/consent/${process.env.NEXT_PUBLIC_ONETRUST_ID}/OtAutoBlock.js`}
            strategy="beforeInteractive"
          />
          <Script
            src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
            strategy="beforeInteractive"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={`${process.env.NEXT_PUBLIC_ONETRUST_ID}`}
          />
          <Script type="text/javascript" id="onetrust">{`function OptanonWrapper(){}`}</Script>
        </>
      )}
      {process.env.NEXT_PUBLIC_QUBIT_SCRIPT === 'true' && (
        <Script
          src={`https://static.goqubit.com/${process.env.NEXT_PUBLIC_QUBIT_FILE}.js`}
          async
          defer
        />
      )}
      <NewRelicSnippet toggles={toggles} />
      {process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus' ? (
        <Script
          strategy="beforeInteractive"
          src="https://static-na.payments-amazon.com/checkout.js"
        />
      ) : (
        <Script
          strategy="beforeInteractive"
          src="https://static-eu.payments-amazon.com/checkout.js"
        />
      )}
      {process.env.NEXT_PUBLIC_GLOBAL_E_TRANSLATIONS === 'true' && (
        // Global E language Translations.
        <Script
          type="text/javascript"
          src={process.env.NEXT_PUBLIC_GLOBAL_E_TRANSLATIONS_SRC}
          strategy="beforeInteractive"
          async
          defer
        />
      )}
      <Head>
        <title>{process.env.NEXT_PUBLIC_SITE && basicTitles[process.env.NEXT_PUBLIC_SITE]}</title>
      </Head>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={finalStore}>
          <GlobalE />
          {loadForter && <Forter />}
          <ThemeProvider theme={theme}>
            <SmartAppBanner title={bannerTitle} />
            {pageProps?.statusCode === 500 && toggles.engs12096500Page ? (
              <Component {...pageProps} />
            ) : (
              <PageLayout>
                <Component {...pageProps} />
              </PageLayout>
            )}
          </ThemeProvider>
        </ReduxProvider>
      </ApolloProvider>
    </>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  sendVitals(metric);
}

export default App;
