import styled from 'styled-components';

import { StyledLink } from '../../basic/LinkItem/LinkItemStyles';
import { StyledErrorMsg } from '../../basic/TextInput/TextInputStyles';
import {   StyledAccordion,
  StyledAccordionTrigger ,
  StyledAccordionTrigger as StyledVerticalAccordionTrigger,
  StyledAccordionWrapper} from '../Accordion/AccordionStyles';
import {
  StyledAccordionItemContainer,
  StyledAccordionTrigger as StyledAccordionTriggerAccordionItem,
} from '../AccordionItemLayout/AccordionItemFooter/AccordionItemStyles';
import { StyledAppStoreLogos } from '../AppStoreLogos/AppStoreLogoStyles';
import { IconListStyles } from '../SocialLogos/SocialLogosStyles';

export const StyledFooter = styled.footer<{ $newLayout?: boolean, $kgRedesignToggle: boolean }>`
  background: ${({ theme }) => theme.footerColors.backgroundColor};
  color: ${({ theme }) => theme.vars.black};
  padding: ${({ theme }) => theme.footer.padding};
  position: relative;
  z-index: 1;
  height: 100%;
  border-top: ${({ theme }) => theme.footer.borderTop};

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ theme, $newLayout }) => ($newLayout ? '4rem 6rem' : theme.footer.padding)};
  }

  ${StyledLink} {
    color: ${({ theme }) => theme.vars.black};
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? '1.1rem' : '1.4rem'};

    &::after {
      color: ${({ theme }) => theme.vars.black};
    }

    @media ${({ theme }) => theme.devices.large} {
      font-size: 1.4rem;
    }
  }

  /* stylelint-disable selector-class-pattern */
  ${StyledAccordionTrigger} {
    color: ${({ theme }) => theme.vars.black};
    text-transform: capitalize;
    font-size: 1.4rem;

    .svg-inline--fa {
      font-size: ${({ theme }) => theme.footer.accordionTriggerFontSize};
    }
  }

  ${StyledVerticalAccordionTrigger} {
    font-size: 1.4rem;
  }

  ${StyledAccordionTriggerAccordionItem} {
    color: ${({ theme }) => theme.vars.black};
    font-size: 1.4rem;

    .svg-inline--fa {
      font-size: ${({ theme }) => theme.footer.accordionTriggerFontSize};
    }
  }

  ${StyledAccordionWrapper} {
    color: ${({ theme }) => theme.vars.black};
    text-transform: capitalize;
    font-size: 1.4rem;

    .svg-inline--fa {
      font-size: ${({ theme }) => theme.footer.accordionTriggerFontSize};
    }
  }

  /* stylelint-enable selector-class-pattern */

  ${StyledAccordionItemContainer} {
    @media ${({ theme }) => theme.devices.large} {
      padding: 0 1rem;
    }
  }

  ${StyledErrorMsg} {
    &::before {
      content: "";
      display: flex;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: -0.8rem;
      border-left: 1px solid ${({ theme }) => theme.vars.black};
      border-top: 1px solid ${({ theme }) => theme.vars.black};
      transform: rotate(45deg);
      background-color: ${({ theme }) => theme.footerColors.backgroundColor};
    }

    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.vars.black};
    color: ${({ theme }) => theme.vars.black};
    position: relative;
  }
`;

export const StyledFooterSection = styled.div<{ width: string }>`
  width: 100%;
  position: relative;

  @media ${({ theme }) => theme.devices.large} {
    width: ${({ width }) => width};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  > ${StyledAccordion} {
    padding: 2rem 0;
  }
`;

export const StyledFooterMain = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
  }
`;

export const StyledCopyrightMessage = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.footer.fonts.primary};
  margin-top: 1.6rem;
  order: 2;

  @media ${({ theme }) => theme.devices.large} {
    order: 1;

    span {
      margin-left: 1.25rem;
    }
  }
`;

export const StyledSocialLogos = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 0 2rem 1rem;
    ${IconListStyles} {
      margin-left: 1.25rem;
    }

    ${StyledAppStoreLogos} {
      width: 50%;
      margin-right: 0;
    }
  }
`;

export const StyledFooterMessages = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
  }
`;

export const StyledManagePreferencesText = styled.button<{ $kgRedesignToggle: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-family: ${({ theme }) => theme.footer.fonts.primary};
  padding: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? '2rem' : 0};
  margin: 1.6rem 3rem 0;
  text-decoration: underline;
  background: transparent;
  border: none;
  color: inherit;
  text-transform: ${({ $kgRedesignToggle }) => $kgRedesignToggle ? 'uppercase' : 'none'};
`;

export const StyledManagePreferencesWrapper = styled.div`
  justify-content: center;
  text-align: center;
  display: flex;
  order: 1;

  @media ${({ theme }) => theme.devices.large} {
    order: 2;
  }
`;
