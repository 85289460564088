import React, { FC, memo } from 'react';

import {
  DesktopSubMenu,
} from '../../types/middleware-types';
import { isMultiColumnSubMenu, isSingleColumnSubMenu } from '../../types/type-checkers';
import MainMenuItem from './MainMenuItem';
import {
  StyledLinks,
  StyledMainMenuContainer,
  StyledMainMenuLinksContainer,
} from './MainMenuStyles';

export interface MainMenuStructureProps {
  data: Array<DesktopSubMenu>;
  toggleSubNav: (value: string) => void;
  currentTab: string;
  isHeaderReverse: boolean
}

const MainMenuStructure: FC<MainMenuStructureProps> = ({
  data,
  toggleSubNav,
  currentTab,
  isHeaderReverse
}: MainMenuStructureProps) => (
  <StyledMainMenuContainer data-hookid="globalHeaderMainMenu">
    <StyledMainMenuLinksContainer $isHeaderReverse={isHeaderReverse}>
      <StyledLinks id="mainnavigation" $isHeaderReverse={isHeaderReverse}>
        {data?.map((subnav,index) => (
          <MainMenuItem
            key={subnav.link?.text}
            subNavData={
              isMultiColumnSubMenu(subnav)?.columns ||
              isSingleColumnSubMenu(subnav)?.column || { ...subnav }
            }
            link={subnav.link}
            currentTab={currentTab}
            toggleSubNav={toggleSubNav}
            id={subnav?.menuId || undefined}
            index={index}
          />
        ))}
      </StyledLinks>
    </StyledMainMenuLinksContainer>
  </StyledMainMenuContainer>
);

export default memo(MainMenuStructure);
