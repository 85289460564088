import React, { FC, ReactElement } from 'react';
import Link from 'next/link';

import MediaElement from '../../basic/MediaElement';
import { CustomMedia } from '../../types/custom-types';
import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import { StyledBrandLogoContainer } from './BrandLogoStyles';

export interface BrandLogoStructureProps {
  logo?: CustomMedia;
  isHomePage?: boolean;
  isAtTop: boolean,
  featureKgRedesign: boolean
  headerTheme: string;
  standardWidth: string;
  description: string;
  useReactRoutingLinks: boolean;
}

const BrandLogoStructure: FC<BrandLogoStructureProps> = ({
  logo,
  isHomePage,
  isAtTop,
  featureKgRedesign,
  headerTheme,
  standardWidth,
  description,
  useReactRoutingLinks,
}: BrandLogoStructureProps): ReactElement => (
  <>
    {logo && (
      <StyledBrandLogoContainer $isHomePage={isHomePage} $isAtTop={isAtTop} $featureKgRedesign={featureKgRedesign} $standardWidth={standardWidth}>
        {useReactRoutingLinks ? (
          <Link href="/" passHref legacyBehavior>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a data-hookid="globalHeaderBrandLogo">
              {featureKgRedesign ? (
                <>
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.58 29.14">
                    <path style={{fill: headerTheme}} d="M30.73 27.32h-5.64L12.41 13.41l-8.02 5.88v8.03H0V.84h4.39v13.72L22.94.84h6.33L15.73 10.87l15 16.45z" />
                    <path style={{fill: headerTheme}} d="M98.65 21.55q0 4.13-4 6.68t-10.97 2.69q-6.84 0-11-2.69c-2.68-1.71-4-3.95-4-6.68V3.63H73v16.85a5.25 5.25 0 0 0 2.72 4.76c1.83 1.1 4.46 1.65 7.92 1.65s6.09-.55 7.88-1.65a5.27 5.27 0 0 0 2.69-4.76V3.63h4.4ZM130.76 16.05c3.38 0 5.08-1.36 5.08-4.09 0-2.92-1.83-4.39-5.49-4.39h-15.43v8.48Zm9.8 14.05h-5.11L128.28 20h-13.36v10.1h-4.4V3.62h20.9A9.15 9.15 0 0 1 138 5.87a8 8 0 0 1 2.33 6.13 7.55 7.55 0 0 1-1.93 5.41 8.32 8.32 0 0 1-4.93 2.42Z" transform="translate(-30.45 -2.78)" />
                    <path style={{fill: headerTheme}} d="M147.01 4.8h-12.66v22.53h-4.39V4.8h-12.65V.85h29.7V4.8z" />
                    <path style={{fill: headerTheme}} d="M228.4 16.46a13.11 13.11 0 0 1-4.63 10.44q-4.64 4-12.27 4a18.74 18.74 0 0 1-11.67-3.56 12.59 12.59 0 0 1-5.31-10.49 12.64 12.64 0 0 1 5.38-10.49C203 4 207 2.78 211.87 2.78a18.16 18.16 0 0 1 14.33 6.4l-4.09 2.05A15.24 15.24 0 0 0 203 9.29a8.93 8.93 0 0 0-3.94 7.54 8.9 8.9 0 0 0 3.4 7.24 13.79 13.79 0 0 0 9 2.78q8.77 0 11.41-6.85h-12.29v-3.95h17.82Z" transform="translate(-30.45 -2.78)" />
                    <path style={{fill: headerTheme}} d="M235.96 27.32h-27.73V.84h26.7V4.8h-22.31v6.8h15.5v3.95h-15.5v7.82h23.34v3.95zM245.32.85h4.38v26.48h-4.38z" />
                    <path style={{fill: headerTheme}} d="M324.62 16.46A13.07 13.07 0 0 1 320 26.9q-4.63 4-12.27 4a18.74 18.74 0 0 1-11.67-3.56 12.56 12.56 0 0 1-5.31-10.49 12.66 12.66 0 0 1 5.37-10.47 19.34 19.34 0 0 1 12-3.58 18.16 18.16 0 0 1 14.34 6.4l-4.1 2.05a15.22 15.22 0 0 0-19.05-1.94 8.94 8.94 0 0 0-3.95 7.54 8.9 8.9 0 0 0 3.4 7.24 13.79 13.79 0 0 0 9 2.78q8.77 0 11.41-6.85h-12.36v-3.97h17.81Z" transform="translate(-30.45 -2.78)" />
                    <path style={{fill: headerTheme}} d="M332.18 27.32h-27.72V.84h26.69V4.8h-22.31v6.8h15.52v3.95h-15.52v7.82h23.34v3.95z" />
                    <path style={{fill: headerTheme}} d="M392.23 16.05q5.08 0 5.09-4.09c0-2.92-1.83-4.39-5.5-4.39h-15.43v8.48ZM402 30.1h-5.1L389.76 20h-13.37v10.1H372V3.62h20.89a9.17 9.17 0 0 1 6.55 2.25 8 8 0 0 1 2.38 6.13 7.56 7.56 0 0 1-1.95 5.41 8.29 8.29 0 0 1-4.93 2.42Z" transform="translate(-30.45 -2.78)" />
                  </svg>
                  <ScreenReaderOnlyText text={description} />
                </>
              ) : (
                <MediaElement {...logo} imageType="intrinsic" />
              )}
            </a>
          </Link>
        ) : (
          <a href="/" data-hookid="globalHeaderBrandLogo">
            {featureKgRedesign ? (
              <>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.58 29.14">
                  <path style={{fill: headerTheme}} d="M30.73 27.32h-5.64L12.41 13.41l-8.02 5.88v8.03H0V.84h4.39v13.72L22.94.84h6.33L15.73 10.87l15 16.45z" />
                  <path style={{fill: headerTheme}} d="M98.65 21.55q0 4.13-4 6.68t-10.97 2.69q-6.84 0-11-2.69c-2.68-1.71-4-3.95-4-6.68V3.63H73v16.85a5.25 5.25 0 0 0 2.72 4.76c1.83 1.1 4.46 1.65 7.92 1.65s6.09-.55 7.88-1.65a5.27 5.27 0 0 0 2.69-4.76V3.63h4.4ZM130.76 16.05c3.38 0 5.08-1.36 5.08-4.09 0-2.92-1.83-4.39-5.49-4.39h-15.43v8.48Zm9.8 14.05h-5.11L128.28 20h-13.36v10.1h-4.4V3.62h20.9A9.15 9.15 0 0 1 138 5.87a8 8 0 0 1 2.33 6.13 7.55 7.55 0 0 1-1.93 5.41 8.32 8.32 0 0 1-4.93 2.42Z" transform="translate(-30.45 -2.78)" />
                  <path style={{fill: headerTheme}} d="M147.01 4.8h-12.66v22.53h-4.39V4.8h-12.65V.85h29.7V4.8z" />
                  <path style={{fill: headerTheme}} d="M228.4 16.46a13.11 13.11 0 0 1-4.63 10.44q-4.64 4-12.27 4a18.74 18.74 0 0 1-11.67-3.56 12.59 12.59 0 0 1-5.31-10.49 12.64 12.64 0 0 1 5.38-10.49C203 4 207 2.78 211.87 2.78a18.16 18.16 0 0 1 14.33 6.4l-4.09 2.05A15.24 15.24 0 0 0 203 9.29a8.93 8.93 0 0 0-3.94 7.54 8.9 8.9 0 0 0 3.4 7.24 13.79 13.79 0 0 0 9 2.78q8.77 0 11.41-6.85h-12.29v-3.95h17.82Z" transform="translate(-30.45 -2.78)" />
                  <path style={{fill: headerTheme}} d="M235.96 27.32h-27.73V.84h26.7V4.8h-22.31v6.8h15.5v3.95h-15.5v7.82h23.34v3.95zM245.32.85h4.38v26.48h-4.38z" />
                  <path style={{fill: headerTheme}} d="M324.62 16.46A13.07 13.07 0 0 1 320 26.9q-4.63 4-12.27 4a18.74 18.74 0 0 1-11.67-3.56 12.56 12.56 0 0 1-5.31-10.49 12.66 12.66 0 0 1 5.37-10.47 19.34 19.34 0 0 1 12-3.58 18.16 18.16 0 0 1 14.34 6.4l-4.1 2.05a15.22 15.22 0 0 0-19.05-1.94 8.94 8.94 0 0 0-3.95 7.54 8.9 8.9 0 0 0 3.4 7.24 13.79 13.79 0 0 0 9 2.78q8.77 0 11.41-6.85h-12.36v-3.97h17.81Z" transform="translate(-30.45 -2.78)" />
                  <path style={{fill: headerTheme}} d="M332.18 27.32h-27.72V.84h26.69V4.8h-22.31v6.8h15.52v3.95h-15.52v7.82h23.34v3.95z" />
                  <path style={{fill: headerTheme}} d="M392.23 16.05q5.08 0 5.09-4.09c0-2.92-1.83-4.39-5.5-4.39h-15.43v8.48ZM402 30.1h-5.1L389.76 20h-13.37v10.1H372V3.62h20.89a9.17 9.17 0 0 1 6.55 2.25 8 8 0 0 1 2.38 6.13 7.56 7.56 0 0 1-1.95 5.41 8.29 8.29 0 0 1-4.93 2.42Z" transform="translate(-30.45 -2.78)" />
                </svg>
                <ScreenReaderOnlyText text={description} />
              </>
            ) : (
              <MediaElement {...logo} imageType="intrinsic" />
            )}
          </a>
        )}
      </StyledBrandLogoContainer>
    )}
  </>
);

export default BrandLogoStructure;