import gql from 'graphql-tag';

import { PRICE_FRAGMENT, PRODUCT_FRAGMENT } from '../fragments/basicFragments';

export const GET_SEARCH_RESULTS = gql`
  query ProductSearchSuggestions($query: String!) {
    productSearchSuggestions(query: $query) {
      query
      keywords {
        query
        totalItems
      }
      products {
        name
        lineNumber
        url
        brand
        thumbnail
        price {
          ...Price
        }
      }
    }
  }
  ${PRICE_FRAGMENT}
`;

export const GET_SEARCH_PAG = gql`
  query ProductSearchPagination(
    $query: NonEmptyString!
    $filters: ProductListFilterInput
    $currentPage: ProductListCurrentPage
    $perPage: ProductListPageSize
  ) {
    productSearch(query: $query, filters: $filters, currentPage: $currentPage, perPage: $perPage) {
      pagination {
        hasPreviousPage
        previousPage {
          pageNumber
          label
          selected
          shouldFollow
        }
        hasNextPage
        nextPage {
          pageNumber
          label
          selected
          shouldFollow
        }
        pages {
          pageNumber
          label
          selected
          shouldFollow
        }
        currentPage
        lastPage
        totalItems
        ads
        perPage
      }
    }
  }
`;
export const GET_SEARCH_PLP = gql`
  query ProductSearch(
    $query: NonEmptyString!
    $filters: ProductListFilterInput
    $currentPage: ProductListCurrentPage
    $sortBy: NonEmptyString
    $perPage: ProductListPageSize
  ) {
    productSearch(
      query: $query
      filters: $filters
      currentPage: $currentPage
      sortItemsBy: $sortBy
      perPage: $perPage
    ) {
      redirectLocation
      query
      returnedSearch
      items {
        ... on ProductListItem {
          ...Product
        }
      }
      pagination {
        hasPreviousPage
        previousPage {
          pageNumber
          label
          selected
          shouldFollow
        }
        hasNextPage
        nextPage {
          pageNumber
          label
          selected
          shouldFollow
        }
        pages {
          pageNumber
          label
          selected
          shouldFollow
        }
        currentPage
        lastPage
        totalItems
        ads
        perPage
      }
      filters {
        filterId
        label
        type
        expanded
        facetId
        options {
          id
          optionId
          label
          selected
          url
          itemCount
          shouldFollow
        }
      }
      sortBy {
        id
        label
        selected
        shouldFollow
      }
      activeFilters {
        id
        optionId
        label
        filterId
        url
        isRootCategory
        shouldFollow
      }
      sourceId
      shouldIndex
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query ProductSearchRecommendations($query: NonEmptyString!) {
    productSearch(query: $query) {
      recommendations {
        title
        products {
          ...Product
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
