import React, { FC, memo } from 'react';

import LinkItem from '../../../basic/LinkItem';
import NextImage from '../../../basic/NextImage';
import {
  HtmlLink,
  Maybe,
  ProductListItem,
} from '../../../types/middleware-types';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import { normaliseImageSrc } from '../../../utils/helpers/imageLoader';
import {
  StyledImageContainer,
  StyledPrices,
  StyledProductInfo,
  StyledProductLink,
  StyledProductSearchResult,
  StyledSearchHistoryContainer,
  StyledSearchHistoryText,
  StyledSearchResultsContainer,
  StyledSearchTermsContainer,
} from './TrendingSearchResultsStyles';


interface SearchResultsStructureProps {
  elOffset: number;
  listData: Maybe<HtmlLink[]> | undefined;
  trendingProductData: Maybe<ProductListItem[]> | undefined;
  compactHeader: boolean;
  showBrandName: boolean;
  currencySymbol: string;
  useFormatted: boolean;
  trendingListTitle: string;
  trendingProductTitle: string;
}

const SearchResultsStructure: FC<SearchResultsStructureProps> = ({
  elOffset,
  trendingProductData,
  listData,
  compactHeader,
  showBrandName,
  currencySymbol,
  useFormatted,
  trendingListTitle,
  trendingProductTitle
}) => (
  <StyledSearchResultsContainer
    id="trending-search-results"
    data-hookid="globalHeaderTrendingSearchResults"
    $elOffset={elOffset}
    $compactHeader={compactHeader}
  >
    {listData && listData.length > 0 && (
      <StyledSearchHistoryContainer data-hookid="trendingSearchResultsList">
        <ul>
          <StyledSearchHistoryText data-hookid="trendingSearchResultsListTitle">{trendingListTitle}</StyledSearchHistoryText>
          {
            listData.map(({ text, url }) => (
              <li className="suggestedSearchTerms" key={`${text}`} data-hookid="trendingSearchResultsListItem">
                <LinkItem
                  href={url}
                  label={text}
                />
              </li>
            ))
          }
        </ul>
      </StyledSearchHistoryContainer>
    )}
    <StyledSearchTermsContainer data-hookid="trendingSearchResultsProducts">
      <StyledSearchHistoryText data-hookid="trendingSearchResultsProductsTitle">{trendingProductTitle}</StyledSearchHistoryText>
      {trendingProductData && trendingProductData.length > 0 &&
        trendingProductData.map(({ url, media, name, brand, price, lineNumber }) => (
          <StyledProductLink
            key={lineNumber}
            href={url}
            data-hookid="trendingSearchResultsProductsItem"
          >
            <StyledProductSearchResult>
              <StyledImageContainer>
                <NextImage
                  mediaList={createMediaList({
                    src: normaliseImageSrc(media?.productImages?.[0]?.url)
                  })}
                  alt={name}
                />
              </StyledImageContainer>
              <StyledProductInfo>
                {showBrandName && <p className="brand">{brand}</p>}
                <p className="name">{name}</p>
                <StyledPrices $wasPrice={price?.was}>
                  <p className="price" data-bp={price?.now} data-hookid="globalHeaderSearchResultsPriceNow">
                    {useFormatted ? (
                      <>{price?.nowFormatted}</>
                    ) : (
                      <>
                        {currencySymbol}
                        {price?.now}
                      </>
                    )}
                  </p>
                  {price?.was && (
                    <p className="price" data-hookid="globalHeaderSearchResultsPriceWas">
                      {useFormatted ? (
                        <>{price?.wasFormatted}</>
                      ) : (
                        <>
                          {currencySymbol}
                          {price?.was}
                        </>
                      )}
                    </p>
                  )}
                </StyledPrices>
              </StyledProductInfo>
            </StyledProductSearchResult>
          </StyledProductLink>
        ))}
    </StyledSearchTermsContainer>
  </StyledSearchResultsContainer>
);

export default memo(SearchResultsStructure);