import styled from 'styled-components';

interface Props {
  $isHovered?: boolean;
  $isAuth: boolean;
  $headerTheme: string;
  $mobileHeader?: boolean;
}

export const StyledAccountIcon = styled.div<Props>`
  background: ${({ $isHovered, theme, $isAuth }) =>
  $isHovered && $isAuth ? theme.vars.white : 'transparent'};

  svg {
    width: ${({ theme }) => theme.vars.sizeXL};

    path {
      stroke: ${({ $isHovered, theme, $isAuth, $headerTheme }) =>
    $isHovered && $isAuth ? theme.vars.black : $headerTheme};
      fill: ${({ $isHovered, theme, $isAuth, $headerTheme }) =>
      $isHovered && $isAuth ? theme.vars.black : $isAuth && $headerTheme};

      @media ${({ theme }) => theme.devices.smallOnly} {
        stroke: ${({ theme, $mobileHeader }) => !$mobileHeader && theme.vars.black};
      }
    }
  }
`;
