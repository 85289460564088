import styled from 'styled-components';

import { StyledInputContainer, StyledTextInput } from '../../basic/TextInput/TextInputStyles';

interface Props {
  $compactHeader: boolean;
  $top: number;
  $searchOpen: boolean;
  $showOpenSearchBar?: boolean;
}

export const StyledSearchboxContainer = styled.div<Props>`
  pointer-events: all;
  position: absolute;
  top: ${({ $compactHeader, $top, $showOpenSearchBar }) => {
  if ($compactHeader) return $top;
  if ($showOpenSearchBar) return 43;
  return 53;
}}px;
  left: 0;
  padding: ${({ $compactHeader, $showOpenSearchBar }) => (($compactHeader || $showOpenSearchBar)  ? '1.2rem' : '0.8rem')} 3.2rem 2.6rem;
  width: 100%;
  background: ${({ theme }) => theme.vars.white};
  display: ${({ $searchOpen }) => ($searchOpen ? 'block' : 'none')};
  box-shadow: 1px 7px 9px -3px hsl(0deg 0% 54.1% / 50%);
  box-sizing: border-box;
  z-index: 21;

  @media ${({ theme }) => theme.devices.large} {
    left: 50%;
    width: 70%;
    padding: 3.2rem;
    top: 12.8rem;
    transform: translate(-50%, 0);
  }
`;

export const StyledSearchControls = styled.div`
  display: flex;
  position: relative;
  ${StyledInputContainer} {
    align-items: center;
    width: 100%;
  }
  ${StyledTextInput} {
    padding: 1.2rem 0;
    border: none;
    outline: none;
    border-bottom: 1.5px solid ${({ theme }) => theme.vars.black};
    border-radius: 0;
    width: 100%;
    color: ${({ theme }) => theme.vars.black};
    background: ${({ theme }) => theme.vars.white};

    &:focus {
      box-shadow: none;
      border: none;
      border-bottom: 1.5px solid ${({ theme }) => theme.vars.black};
    }
  }

  svg {
    font-size: ${({ theme }) => theme.vars.sizeL};

    path {
      fill: ${({ theme }) => theme.vars.black};
    }
  }

  button {
    cursor: pointer;
    border: none;
    background: transparent;
    position: absolute;
    right: 0;
    bottom: ${({ theme }) => theme.vars.sizeXS};
  }
`;

export const StyledCloseButton = styled.button`
  display: none;
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  top: 0.5rem;
  left: unset;
  right: 0.5rem;
  font-size: ${({ theme }) => theme.vars.sizeXL};
  width: 3rem;
  z-index: 10;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.vars.linkHover};
    }
  }

  svg path {
    fill: ${({ theme }) => theme.vars.black};
  }

  @media ${(props) => props.theme.devices.large} {
    display: block;
  }
`;

export const StyledOffScreenLabel = styled.span`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

export const ClearButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 32px;
  bottom: 10%;

  button {
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    color: ${({ theme }) => theme.vars.black};
  }
`;