import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { HtmlLink } from '../../../../types/middleware-types';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import ShopBySizeStructure from './ShopBySizeStructure';

export interface ShopBySizeProps {
  data: {
    sizes: Array<HtmlLink>;
    title: string;
  }
}

const ShopBySize: FC<ShopBySizeProps> = ({ data: { sizes, title } }) => {
  const theme = useTheme();
  const { isMobile } = useMobileWatcher(['mobile'], theme.vars);
  const componentProps = { sizes, isMobile, title };

  return <ShopBySizeStructure {...componentProps} />;
};

export default ShopBySize;
