import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import constants from '../../../sites/constants';
import { selectToggles } from '../../features/static';
import { SubMenuLinkList } from '../../types/middleware-types';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import FooterLinkStructure from './FooterLinkStructure';

interface FooterLinksProps {
  footerNavigation: SubMenuLinkList[];
}

const FooterLinks = ({ footerNavigation }: FooterLinksProps) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const toggles = useSelector(selectToggles);

  const componentProps = {
    footerNavigation,
    isMobile,
    isTablet,
    newLayout: constants.newFooterLayout,
    kgRedesignToggle: toggles?.featureKgRedesign,
  };

  return <FooterLinkStructure {...componentProps} />;
};

export default FooterLinks;
