import type { NextWebVitalsMetric } from 'next/app'
import Cookies from 'universal-cookie';

import sendLCPSubparts from '../sendLCPSubparts';

const sendVitals = (metric: NextWebVitalsMetric): void => {
  const { id, name, label, value, startTime } = metric;
  const cookiesUni = new Cookies();
  const page = cookiesUni.get('page');
  const disableInfiniteScroll = cookiesUni.get('disableInfiniteScroll');

  const data = {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    startTime,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    page: page || 'no-page-name',
    reducePlpElements: '',
    disableInfiniteScroll
  };

  if (name === 'LCP') {
    sendLCPSubparts(label, page);
  }

  if (typeof window !== 'undefined' && typeof newrelic === 'object') {
    // eslint-disable-next-line no-undef
    newrelic.addPageAction(name, { ...data });
  }
};

export default sendVitals;
