/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { addNewElement } from './actions';

export interface ElementsState {
  [key: string]: any;
}

export const initialState: ElementsState = {};

export const elementsReducer = createReducer(initialState, builder => {
  builder
    .addCase(addNewElement, (state, { payload }) => {
      state[payload.nodeName] = payload.node;
    });
});
