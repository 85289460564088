import React, { FC } from 'react';

import { MobileMultiItemSubMenu as MobileMultiItemSubMenuType } from '../../../../types/middleware-types';
import MobileMultiItemSubMenuStructure from './MobileMultiItemSubMenuStructure';

export interface MobileMultiItemSubMenuProps {
  data: MobileMultiItemSubMenuType;
}

const MobileMultiItemSubMenu: FC<MobileMultiItemSubMenuProps> = ({ data }) => {
  const componentProps = { data };
  return <MobileMultiItemSubMenuStructure {...componentProps} />;
};

export default MobileMultiItemSubMenu;
