import React, { FC } from 'react';
import Head from 'next/head';

interface Props {
  url: string,
}

const CanonicalStructure: FC<Props> = ({
  url,
}) => {
  return (
    <Head>
      <link rel="canonical" href={url} data-kg="true" />
    </Head>
  );
};

export default CanonicalStructure;
