import React, { FC, memo } from 'react';

import { MinibagOptionsInterface } from '../../../types/custom-types';
import { Maybe, Minibag } from '../../../types/middleware-types';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import MyBag from '../svg/MyBag';
import MyBagCompact from '../svg/MyBagCompact';
import MyBagCompactFilled from '../svg/MyBagCompactFilled';
import MyBagFilled from '../svg/MyBagFilled';
import {
  StyledMinibagCount,
  StyledMinibagIconContainer,
  StyledMinibagPlus,
} from './MinibagIconStyles';

interface BagContentInterface {
  headerCompact: boolean;
  bagData: Maybe<Minibag>;
}

export const EmptyBagComponent: FC<{ headerCompact: boolean }> = ({ headerCompact }) => {
  return headerCompact ? <MyBagCompact /> : <MyBag />;
};

export const FilledBagComponent: FC<BagContentInterface> = ({ headerCompact, bagData }) => {
  return headerCompact ? <MyBagCompactFilled /> : <MyBagFilled bagData={bagData} />;
};

interface MinibagIconStructureProps extends MinibagOptionsInterface, BagContentInterface {
  headerTheme: string;
  kgRedesign: boolean;
  isKG: boolean;
  finalIconMargin: string;
}

const MinibagIconStructure: FC<MinibagIconStructureProps> = ({
  toggleBag,
  headerTheme,
  showBag,
  bagData,
  isMobileOrTablet,
  headerCompact,
  kgRedesign,
  isKG,
  finalIconMargin
}) => {
  return (
    <StyledMinibagIconContainer
      $showBag={showBag || false}
      $headerTheme={headerTheme}
      role="button"
      tabIndex={0}
      onKeyDown={toggleBag}
      onClick={isMobileOrTablet ? toggleBag : undefined}
      data-hookid="globalHeaderMinibagIcon"
      $headerCompact={headerCompact}
      $bagEmpty={!bagData?.bag?.itemCount}
      $kgRedesign={kgRedesign}
      $isKG={isKG}
      $finalIconMargin={finalIconMargin}
    >
      <ScreenReaderOnlyText text="Minibag" />
      {bagData && bagData.bag && bagData.bag.itemCount > 0 ? (
        <>
          <FilledBagComponent headerCompact={headerCompact} bagData={bagData} />
          <StyledMinibagCount
            data-hookid="globalHeaderMinibagIconCount"
            $headerTheme={headerTheme}
            $headerCompact={headerCompact}
          >
            {bagData.bag.itemCount <= 9 ? bagData.bag.itemCount : 9}
          </StyledMinibagCount>
          {bagData.bag.itemCount > 9 && (
            <StyledMinibagPlus data-hookid="globalHeaderMinibagIconPlus">+</StyledMinibagPlus>
          )}
        </>
      ) : (
        <EmptyBagComponent headerCompact={headerCompact} />
      )}
    </StyledMinibagIconContainer>
  );
};

export default memo(MinibagIconStructure);
