import { css, keyframes } from 'styled-components';

export const animMoveUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const animFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const animZoomIn = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

export const animShrink = keyframes`
  from {
    width: 120%;
  }
  to {
    width: 100%;
  }
`;

export const animScrollDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
`;

// TODO: fix types
export type GlobalAnimationStyleObject = {
  moveUp?: any;
  fadeIn?: any;
  zoomIn?: any;
  scrollDown?: any;
};

export type GlobalAnimationsStyle = (delay?: number) => GlobalAnimationStyleObject;

export interface GlobalAnimationsType {
  [key: string]: GlobalAnimationsStyle;
}

export const GlobalAnimations: GlobalAnimationsType = {
  BACKGROUNDPARALLAX: () => ({
    moveUp: css`
      @media ${({ theme }) => theme.devices.smallOnly} {
        animation: ${animMoveUp} 0.5s linear 0.5s both;
      }
    `,
    fadeIn: css`
      @media ${({ theme }) => theme.devices.smallOnly} {
        animation: ${animFadeIn} 1s ease 1.5s both;
      }
    `,
    zoomIn: css`
      @media ${({ theme }) => theme.devices.smallOnly} {
        animation: ${animZoomIn} 3s ease both;
      }
    `,
  }),
  MOVEFADE: () => ({
    scrollDown: css`
      animation: ${animScrollDown} 0.5s ease-in both;
    `,
    fadeIn: css`
      animation: ${animFadeIn} 1s cubic-bezier(0.6, 0, 0.1, 1) 0.5s both;
    `,
  }),
  BLOCKMOVEFADE: (
    delay = 0,
  ): {
    fadeIn?: any;
    scrollDown?: any;
  } => {
    const BASE_FADE_IN_DELAY = 1000;

    return {
      scrollDown: css`
        animation: ${animScrollDown} 1s ease-in ${delay}ms both;
      `,
      fadeIn: css`
        animation: ${animFadeIn} 1s cubic-bezier(0.6, 0, 0.1, 1) ${BASE_FADE_IN_DELAY + delay}ms
          both;
      `,
    };
  },
  BACKGROUNDSHRINK: () => ({
    zoomIn: css`
      animation: ${animShrink} 3s ease both 1s;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    `,
  }),
};
