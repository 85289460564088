import styled from 'styled-components';

import { StyledNextImageWrapper } from '../../../../basic/NextImage/NextImageStyles';

interface Props {
  $compactHeader: boolean;
}

interface WrapperProps {
  $isLast?: boolean;
}

export const StyledImageHtmlLink = styled.div<Props>`
  max-width: ${({ $compactHeader }) => ($compactHeader ? '100%' : '27.2rem')};

  img {
    width: 100%;
    height: auto;
  }

  h4 {
    margin: 0.7rem 0 0;
  }

  a,
  p {
    ${({ theme }) => theme.types.linkStyles};
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    text-decoration: underline;
    margin: 2.5rem 0;
    display: block;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-bottom: ${({ theme }) => theme.vars.sizeM};
  }
`;

export const StyledImageHtmlLinkClickableAdWrapper = styled.div`
  a {
    margin: 0;
    ${StyledNextImageWrapper} {
      @media ${({ theme }) => theme.devices.large} {
        span {
          > img {
            max-height: 25rem !important;
          }
        }
      }
    }
  }
`;

export const StyledImageHtmlLinkWrapper = styled.div<WrapperProps>`
  ${({ $isLast }) =>
  $isLast === true &&
    `
    margin-top: auto;
  `}
`;
