/* eslint-disable no-useless-escape */
const isEmailValid = (email: string): boolean => {
  let isValid;
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email.trim())) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
};

export default isEmailValid;
