import { CartItemProduct } from '../../../types/middleware-types';

export interface BasketProduct {
  brand: string;
  id: string;
  sku: string;
  name: string;
  price: string;
  saleProduct: string;
  newIn: string;
  unitNowPrice: string;
  wasPrice?: string | null;
  markdown?: string | number | null;
  discountAmount: string | number;
  discountPercentage?: string | null;
  colour?: string;
  variant?: string;
  quantity?: number;
}

const mapToBasketProductSchema = (product: CartItemProduct): BasketProduct => {
  return {
    brand: product.brand.toLowerCase(),
    id: product.lineNumber,
    sku: product.lineNumber,
    name: product.name.toLowerCase(),
    price: product.price.now,
    saleProduct: product.sale === true ? 'yes' : 'no',
    newIn: product.newIn === true ? 'yes' : 'no',
    unitNowPrice: product.price.now,
    wasPrice: product.price.was,
    markdown: Number(product.price.discount) > 0 ? product.price.discount : 0,
    discountAmount:
      Number(product.price.discount) > 0 ? parseFloat(product.price.discount || '').toFixed(2) : 0,
    discountPercentage: product.price.discountPercentage,
    colour: product.colour?.toLowerCase(),
    variant: product.colour?.toLowerCase(),
  };
};

export default mapToBasketProductSchema;
