import styled from 'styled-components';

import { StyledSkeletonBackground } from '../../../basic/Skeleton/SkeletonStyles';
import { StyledLoading } from '../../Loading/LoadingStyles';
import { StyledPrice, StyledPrices, StyledRRP } from '../../Price/PriceStyles';
import { StyledTag } from '../../SalesFlag/SalesFlagStyles';
import {
  StyledSlide,
  StyledSlider,
  StyledSliderContainer,
  StyledSliderWrapper,
} from '../../Slider/SliderStyles';





export const StyledQuickBuyButton = styled.button<{
  $btnStylePrimary?: boolean;
}>`
  display: block;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.vars.white};
  color: ${({ theme }) => theme.vars.black};
  border: 1px solid ${({ theme }) => theme.vars.greyDark};
  padding: 1rem 0.7rem;
  transform: translateY(100%);
  transition: all 400ms cubic-bezier(0.6, 0, 0.1, 1) 1.0354ms;
  visibility: hidden;

  ${StyledLoading} {
    height: 2rem;
  }

  &:hover {
    background: ${({ theme }) => theme.productCard.quickBuy.hoverBackground};
    color: ${({ theme }) => theme.productCard.quickBuy.hoverColor};
  }

  ${({ $btnStylePrimary, theme }) =>
  $btnStylePrimary &&
      `
      background: ${theme.vars.black};
      color: ${theme.vars.white};
      border: 1px solid ${theme.vars.black};
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: ${theme.buttons.primaryHover.textDecoration};
      }
  `};
`;

export const StyledProductCardContainerTop = styled.div<{
  $showQuickBuy?: boolean;
  $addStyleForShowingQuickBuy?: boolean;
}>`
  position: relative;

  @media ${({ theme }) => theme.devices.large} {
    &:hover,
    &:focus {
      ${({ $showQuickBuy, $addStyleForShowingQuickBuy }) =>
  $showQuickBuy &&
        $addStyleForShowingQuickBuy &&
        `
    ${StyledQuickBuyButton} {
      visibility: visible;
      transform: none;
    }
    ${StyledTag} {
      visibility: hidden;
    }
    `};
    }
  }
`;

export const StyledProductCardContainer = styled.a<{
  $quickAdd?: boolean;
  $focusQuickBuy?: boolean;
  $showCheckoutQuickBuy?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.vars.black};
  text-decoration: none;
  ${StyledTag} {
    visibility: ${({ $quickAdd, $focusQuickBuy }) =>
  $quickAdd || $focusQuickBuy ? 'hidden' : 'visible'};
    padding-bottom: 0;
    margin-bottom: 0;
  }

  picture {
    display: block;

    > img {
      width: 100%;
      height: auto;
    }
  }

  ${({ $showCheckoutQuickBuy }) =>
    $showCheckoutQuickBuy &&
      `
        padding-bottom: 4.5rem;
      `};
`;

export const StyledRoundel = styled.div<{ $productView?: string }>`
  position: absolute;
  top: -2%;
  right: -2%;
  width: 30%;
  height: 30%;

  @media ${({ theme }) => theme.devices.large} {
    width: ${({ $productView }) => ($productView === 'small_view' ? '20%' : '30%')};
    height: ${({ $productView }) => ($productView === 'small_view' ? '20%' : '30%')};
  }

  z-index: 2;

  > img {
    width: 100%;
  }
`;

export const StyledBrand = styled.p`
  ${({ theme }) => theme.types[theme.productCard.brandName]};
  margin: 0;
`;

export const StyledProductName = styled.p<{
  $featureKgRedesign?: boolean;
}>`
  ${({ theme }) => theme.types.labelStyle};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.productCard.name.fontWeight};
  text-transform: ${({ $featureKgRedesign, theme }) => $featureKgRedesign ? 'uppercase !important' : theme.productCard.name.textTransform};
  margin: ${({ theme }) => theme.productCard.textMargin} 0;
  margin-bottom: ${({ $featureKgRedesign }) => $featureKgRedesign ? '0.7rem' : null};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCarousel = styled.div<{ $quickAdd?: boolean }>`
  display: flex;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */

  img {
    position: absolute;
    inset: 0;
  }

  > div {
    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ${StyledSkeletonBackground} {
    position: absolute;
    inset: 0;
  }

  ${StyledSlide} {
    height: 100%;
    width: 100%;

    > div {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        position: relative;
      }
    }
  }

  ${StyledSlider} {
    height: 100%;
    width: 100%;
    ${StyledSliderWrapper} {
      height: 100%;
      ${StyledSliderContainer} {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

export const StyledImageContainer = styled.div`
  height: 100%;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;
    position: relative;
  }
`;

export const StyledCarouselContainer = styled.div`
  position: absolute;
  inset: 0;
`;

export const StyledQuickBuyContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 6;
`;

export const StyledContentContainer = styled.div`
  width: 100%;
  position: relative;

  ${StyledTag} {
    position: absolute;
    bottom: 0;
    border: none;
    z-index: 5;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
`;

export const StyledContent = styled.div<{$featureKgRedesign?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  &.content {
    z-index: 0;
    background: ${({ theme }) => theme.vars.white};
    margin-top: ${({ $featureKgRedesign, theme }) => $featureKgRedesign ? '0.5rem' : theme.vars.sizeM};

    ${StyledProductName} {
      ${({ $featureKgRedesign }) => 
  $featureKgRedesign &&
        `
        text-align: left;
        font-size: 0.9rem;
        text-transform: uppercase;
        `
      }
    }

    ${StyledPrice} {
      ${({ $featureKgRedesign }) => 
        $featureKgRedesign &&
        `
        font-size: 0.9rem;
        text-transform: uppercase;
        `
      }
    }

    ${StyledRRP} {
      ${({ $featureKgRedesign }) => 
        $featureKgRedesign &&
        `
        font-size: 0.9rem;
        text-transform: uppercase;
        `
      }
    }

    ${StyledPrices} {
      ${({ $featureKgRedesign }) => $featureKgRedesign && `justify-content: left;`}
    }

    @media ${({ theme }) => theme.devices.large} {
      ${({ $featureKgRedesign }) => 
        $featureKgRedesign &&
          `
            margin-top: 0.7rem;
            margin-bottom: 1rem;
          `
      }
      // Had to seperate these out as our linter didnt like stacked selectors
      ${StyledProductName} {
        ${({ $featureKgRedesign }) => $featureKgRedesign && `font-size: 1.2rem;`}
      }
      ${StyledPrice} {
        ${({ $featureKgRedesign }) => $featureKgRedesign && `font-size: 1.2rem;`}
      }
      ${StyledRRP} {
        ${({ $featureKgRedesign }) => $featureKgRedesign && `font-size: 1.2rem;`}
      }
    }
  }
`;

interface QuickBuyProps {
  $focused: boolean;
  $showQuickBuy: boolean;
  $featureKgRedesign: boolean;
  $showCheckoutQuickBuy?: boolean;
}

export const StyledQuickBuyWrapper = styled.div<QuickBuyProps>`
  position: relative;
  bottom: ${({ theme, $featureKgRedesign }) => $featureKgRedesign ? '5.5rem' : theme.productCard.quickBuy.position};
  z-index: 6;

  @media ${({ theme }) => theme.devices.large} {
    ${({ $showQuickBuy, $focused }) =>
  $showQuickBuy &&
      $focused &&
      `
        ${StyledQuickBuyButton} { 
          visibility: visible;
          transform: none;
        }
      `};
  }

  ${({ $showCheckoutQuickBuy }) =>
        $showCheckoutQuickBuy &&
      `
        bottom: 0;
        ${StyledQuickBuyButton} { 
          visibility: visible;
          transform: none;
        }
      `};
`;
