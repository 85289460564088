import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ConstantsState, HeaderLayout } from '../../../shared/types/state-types';

const Constants: ConstantsState = {
  datalayerAddToBasketSizesType: 'eu',
  brandName: 'Shoeaholics',
  plpProductsPerPage: 36,
  plpSearch: {
    showHeader: true,
    noResults: [
      "Sorry Shoeaholic! No matches could be found for '{{searchTerm}}'. Try checking the spelling, use fewer words or using more general keywords",
    ],
    searchedResults: ['We found {{results}} that matched your search'],
    recommendedResults: [
      "Sorry Shoeaholic! We didn’t find any results for '{{searchTerm}}', but we found {{number}} for '{{returnedSearch}}'...",
    ],
  },
  searchPlaceholder: 'Search Shoeaholics...',
  defaultMetaData: {
    title: 'Shoeaholics | Shop Over 100 Great Footwear & Accessory Brands',
    description:
      'Shop discount designer shoes, boots, bags & accessories for women and men at shoeaholics.com - choose over 3000 styles from 100+ designer brands',
  },
  storyTypes: [
    { title: 'All Stories', value: 'all' },
    { title: 'Pop Culture', value: 'as-seen-on' },
    { title: 'Trending', value: 'seasonal-trend' },
    { title: 'Health & Wellness', value: 'gallery' },
    { title: 'In Conversation With', value: 'video' },
  ],
  storyTitle: 'Shoe Stories',
  pdpProductInformation: {
    accordionTitles: {
      productDetails: 'Product Details',
      productDescription: 'Product Description',
      delivery: 'Delivery & Returns',
    },
  },
  storeLocator: {
    showDistanceLabel: true,
    linkHref: '/storelocator',
  },
  headerImageDimensions: {
    width: 255,
    height: 175,
  },
  headerLayout: HeaderLayout.TransparentHeader,
  showSignUpFormSuccessSvg: true,
  mobileNewsletterAccordion: false,
  searchResultsProductNameTitleCase: true,
  newFooterLayout: true,
  accordionIcons: {
    plus: faPlus as IconProp,
    minus: faMinus as IconProp,
  },
  newsletterButtonType: 'primary',
  plpProductCardSizes: {
    small_view: '(max-width: 1024px) 50vw, 25vw',
    large_view: '(max-width: 1024px) 50vw, 20vw',
    default: '(max-width: 1024px) 50vw, 25vw',
  },
  initialsId: 'sa',
  // TODO: clean up after react router is complete
  plpRoutes: [
    '/new-in',
    '/men',
    '/women',
    '/bags-accessories/bags',
    '/bags-accessories/accessories',
    '/clearance',
  ],
  loadingGif: 'SAH-Load.gif',
};

export default Constants;
