import {
  CarouselDBlockItemExtension,
  CarouselDProductItemExtension,
} from '@components/CMSModules/ContentBlockCarousel/CarouselD/CarouselDStructure';
import {
  CMSBlocks,
  CMSReturnedData,
  CategoryReturedData,
  DataCaptureObject,
  MediaList,
  NextMediaList,
  ProductListItemExtended,
  ProductReturedData,
  SalesFlagTypeArray,
  SalesFlagType,
  SubMenuData,
  UpdatedCmsBlocksPage,
  BrandIndexPage,
  UpdatedCmsFlashSalePage,
  PlpFilters,
} from './custom-types';
import {
  AdvertListItem,
  CmsStoriesListItem,
  Category,
  CategoryBanner,
  ContentBlock,
  ContentBlockMediaAndText50PercentSplit,
  CountryPickerResponse,
  ImageV2,
  Media,
  ContentBlockMediaBanner,
  CountryPickerItem,
  ListItem,
  ProductSearch,
  ProductListItem,
  StoriesGeneralListItem,
  Maybe,
  ProductMedia,
  SizeLabels,
  Product,
  DesktopMultiColumnSubMenu,
  DesktopSingleColumnSubMenu,
  DesktopSubMenu,
  IndexSubMenu,
  MobileMultiItemSubMenu,
  MobileSubMenu,
  SubMenuColumn,
  SubMenuItem,
  SubMenuSingleColumn,
  CmsStoriesListingPage,
  CmsTrackMyOrderPage,
  ContentBlockMediaCarousel,
  ContentBlockMediaCarouselSimple,
  ContentBlockMediaCarouselWithMediaItem,
  ContentBlockProductCarousel,
  ContentBlockProductCarouselWithMediaItem,
  CmsBlocksPage,
  ProductImage,
  ProductImageInfo,
  ProductSearchKeywordSuggestion,
  HtmlLink,
} from './middleware-types';
import {
  QuickFilters,
  PlpBanner,
  QueryParams,
  UpdatedOptions,
  FinalFirstSettings,
} from '../pageComponents/PlpContent/PlpContent.types';
import {
  CategoryWithPerPage,
  PlpDataWithPerPage,
  ProductSearchWithPerPage,
  SiteData,
} from './state-types';

type SubNavItem =
  | MobileMultiItemSubMenu
  | MobileSubMenu
  | SubMenuItem
  | SubMenuColumn
  | SubMenuSingleColumn
  | DesktopSubMenu;

type SubMenuArray = Array<SubMenuColumn | MobileMultiItemSubMenu>;

type CMSBlocksCarousel =
  | ContentBlockMediaCarousel
  | ContentBlockMediaCarouselSimple
  | ContentBlockMediaCarouselWithMediaItem
  | ContentBlockProductCarousel
  | ContentBlockProductCarouselWithMediaItem;

export const isProductListItemType = (aItem: ListItem): ProductListItem | null => {
  if ('lineNumber' in aItem) {
    return aItem;
  }
  return null;
};

export const isAdType = (aItem: ListItem): AdvertListItem | null => {
  if ('cellSpan' in aItem) {
    return aItem;
  }
  return null;
};

export const isProduct = (item: ListItem | ProductListItem): item is ProductListItem => {
  if ('lineNumber' in item) {
    return true;
  }
  return false;
};

export const isProductItem = (item: Maybe<Product>): item is Product => {
  return item?.__typename === 'Product';
};

export const isProductItemArray = (item: Maybe<Product[]> | undefined): item is Product[] => {
  return item?.[0].__typename === 'Product';
};

export const isProductWithPosition = (aItem: ListItem): ProductListItemExtended | null => {
  if ('lineNumber' in aItem) {
    return aItem;
  }
  return null;
};

export const isCMSReturnedData = (
  someData: CMSReturnedData | CategoryReturedData | ProductReturedData | undefined,
): boolean => {
  if (someData !== undefined && 'CMSPageBuilder' in someData) {
    return true;
  }
  return false;
};

export const isGeneralStoryType = (item: CmsStoriesListItem): item is StoriesGeneralListItem => {
  return item.__typename === 'StoriesGeneralListItem';
};

export const isImageV2 = (data?: Media | Record<string, never> | null): data is ImageV2 => {
  return data?.__typename === 'ImageV2';
};

export const isMediaBannerType = (
  data: CMSBlocks | ContentBlock | undefined,
): data is ContentBlockMediaBanner => {
  if (data !== undefined && 'colourTheme' in data) {
    return true;
  }
  return false;
};

export const isPlpMediaBannerType = (
  data: ContentBlockMediaBanner | PlpBanner | CategoryBanner | null | undefined,
): data is ContentBlockMediaBanner => {
  return data?.__typename === 'CategoryBanner';
};

export const isMedia50Type = (
  data: CMSBlocks[] | undefined | null,
): data is ContentBlockMediaAndText50PercentSplit[] => {
  return data?.[0]?.__typename === 'ContentBlockMediaAndText50PercentSplit';
};

export const isNextMediaList = (data?: NextMediaList | MediaList): data is NextMediaList => {
  return !!data?.mobile || !!(data as NextMediaList)?.desktop;
};

export const isArrayOfCMSBlocks = (
  data: Maybe<Array<CMSBlocks>> | Maybe<Array<ContentBlock>> | undefined,
): data is Array<CMSBlocks> => {
  const firstElement = data && data[0] !== null ? data[0] : null;
  const isCMSBlock = isCMSBlocks(firstElement);
  return isCMSBlock;
};

export const isCMSBlocks = (
  data: ContentBlock | undefined | null | CMSBlocks,
): data is CMSBlocks => {
  if ((data as CMSBlocks)?.__typename?.includes('ContentBlock')) {
    return true;
  }
  return false;
};

export const isCMSBlocksCarousel = (
  data: ContentBlock | undefined | null | CMSBlocks,
): data is CMSBlocksCarousel => {
  if (isCMSBlocks(data) && (data as CMSBlocks)?.__typename?.includes('Carousel')) {
    return true;
  }
  return false;
};

export const isCountryPickerItem = (
  data: CountryPickerItem | Record<string, unknown> | Maybe<CountryPickerResponse> | undefined,
): data is CountryPickerItem => {
  return data?.__typename === 'CountryPickerItem';
};

export const isSalesFlagType = (item: string): item is SalesFlagType => {
  return SalesFlagTypeArray.includes(item as SalesFlagType);
};

export const isContentBlockMediaBannerType = (
  data: ContentBlock,
): data is ContentBlockMediaBanner => {
  return (
    'colourTheme' in data && 'backgroundImage' in data && 'fontSize' in data && 'subtitle' in data
  );
};

export const isSearchPLPData = (data?: Category | ProductSearch | null): data is ProductSearch => {
  return data?.__typename === 'ProductSearch';
};

export const isCategoryPLPData = (data?: Category | ProductSearch | null): data is Category => {
  return data?.__typename === 'Category';
};

export const isCarouselDProductItem = (
  item: CarouselDBlockItemExtension | CarouselDProductItemExtension,
): item is CarouselDProductItemExtension => {
  return item?.__typename === 'ProductListItem';
};

export const isProductMedia = (
  media: ProductMedia | Maybe<Media> | undefined,
): media is ProductMedia => {
  return media?.__typename === 'ProductMedia';
};

export const isQuickFilters = (
  item: Category | ProductSearch | Maybe<QuickFilters> | undefined,
): item is QuickFilters => {
  return item !== null;
};

export const isCategoryData = (
  item: Category | ProductSearch | Maybe<QuickFilters> | undefined,
): item is Category => {
  return item !== null;
};

export const isCurrentProdList = (
  item: Category | ProductSearch | ListItem[] | null,
): item is Category | ProductSearch => {
  return item !== null;
};

export const isPlpDataDescription = (item?: Maybe<Category | ProductSearch>): item is Category => {
  return item !== null;
};

export const isPlpData = (
  item: Maybe<PlpDataWithPerPage> | undefined,
): item is PlpDataWithPerPage => {
  return item !== null;
};

export const isString = (item: Array<string | number>): item is string[] => {
  if (item) {
    return typeof item[0] === 'string';
  }
  return false;
};

export const isNumber = (item: number | undefined): item is number => {
  return typeof item === 'number';
};

export const isCategoryPath = (item: string | string[] | undefined | null): item is string => {
  return typeof item === 'string';
};

export const isNode = (item: EventTarget | Node | null): item is Node => {
  if (item as Node) {
    return true;
  }
  return false;
};

export const isQueryParams = (item: QueryParams): item is QueryParams => {
  return item !== null;
};

export const isUpdatedOptions = (
  item: PlpFilters | UpdatedOptions | FinalFirstSettings | null,
): item is UpdatedOptions => {
  return item !== null;
};

export const isSize = (size: Maybe<string> | undefined): size is keyof SizeLabels => {
  return size !== undefined && typeof size === 'string';
};

export const isPromiseRejected = (
  promiseResult: PromiseSettledResult<any> | PromiseRejectedResult,
): promiseResult is PromiseRejectedResult => {
  return promiseResult?.status === 'rejected';
};

export const isMultiColumnSubMenu = (item: DesktopSubMenu): DesktopMultiColumnSubMenu | null => {
  if (item?.__typename === 'DesktopMultiColumnSubMenu' && item?.columns) {
    return item;
  }
  return null;
};

export const isSingleColumnSubMenu = (item: DesktopSubMenu): DesktopSingleColumnSubMenu | null => {
  if (item?.__typename === 'DesktopSingleColumnSubMenu' && item?.column) {
    return item;
  }
  return null;
};

export const isMobileMultiItemSubMenu = (
  data: MobileSubMenu[] | undefined,
): data is MobileMultiItemSubMenu[] => {
  return (
    data !== undefined &&
    data[0]?.__typename === 'MobileMultiItemSubMenu' &&
    data[0].items.length > 0
  );
};

export const isSubNavItem = (
  data: SubNavItem | undefined,
): data is SubMenuColumn | MobileMultiItemSubMenu => {
  return (
    data?.__typename === 'SubMenuSingleColumn' ||
    data?.__typename === 'MobileMultiItemSubMenu' ||
    data?.__typename === 'SubMenuColumn'
  );
};

export const isSubMenuItem = (
  data: SubMenuColumn | MobileSubMenu | SubMenuItem,
): IndexSubMenu | SubMenuItem | null => {
  if (
    data?.__typename === 'IndexSubMenu' ||
    data?.__typename === 'SubMenuLinkList' ||
    data?.__typename === 'SubMenuImageLinkList' ||
    data?.__typename === 'ImageHtmlLink' ||
    data?.__typename === 'SubMenuShopBySize'
  ) {
    return data;
  }
  return null;
};

export const isSubMenuArray = (data: SubMenuData | SubMenuArray): data is SubMenuArray => {
  if (Array.isArray(data)) {
    return (
      data.length > 0 &&
      (data[0]?.__typename === 'SubMenuColumn' || data[0]?.__typename === 'MobileMultiItemSubMenu')
    );
  }
  return false;
};

export const isIP = (ip: string | string[] | undefined) => {
  if (Array.isArray(ip)) {
    return ip[0];
  } else if (ip !== undefined && typeof ip === 'string') {
    return ip;
  }
  return false;
};
export const isCmsStoriesListingPage = (
  data?: Record<string, CmsStoriesListingPage> | { [x: string]: UpdatedCmsBlocksPage },
): data is Record<string, CmsStoriesListingPage> => {
  if (data) {
    const firstKeyValue = data[Object.keys(data)[0]];
    return firstKeyValue?.__typename === 'CMSStoriesListingPage';
  }
  return false;
};

export const dataIsPlpSearch = (
  data?: Maybe<PlpDataWithPerPage>,
): data is ProductSearchWithPerPage => data?.__typename === 'ProductSearch';
export const dataIsPlpCategory = (data?: Maybe<PlpDataWithPerPage>): data is CategoryWithPerPage =>
  data?.__typename === 'Category';

export const isBrandIndex = (
  data: Maybe<SiteData | UpdatedCmsFlashSalePage | BrandIndexPage | CmsTrackMyOrderPage>,
): data is BrandIndexPage => {
  return data?.__typename === 'CMSBrandIndexPage';
};

export const isFlashSale = (
  data: Maybe<SiteData | UpdatedCmsFlashSalePage | BrandIndexPage | CmsTrackMyOrderPage>,
): data is UpdatedCmsFlashSalePage => {
  return data?.__typename === 'CMSFlashSalePage';
};

export const isTrackMyOrder = (
  data: Maybe<SiteData | UpdatedCmsFlashSalePage | BrandIndexPage | CmsTrackMyOrderPage>,
): data is CmsTrackMyOrderPage => {
  return data?.__typename === 'CMSTrackMyOrderPage';
};

export const isCMSBlocksPage = (
  data: Maybe<SiteData | UpdatedCmsFlashSalePage | BrandIndexPage | CmsTrackMyOrderPage | { CMSPageBuilder: CmsBlocksPage }>,
): data is SiteData => {
  if (data && 'CMSPageBuilder' in data) {
    data?.CMSPageBuilder.__typename === 'CMSBlocksPage';
  }
  else if (data) {
    return data?.__typename === 'CMSBlocksPage';
  }

  return false
};

export const isCMSPageBuilder = (
  data: Maybe<SiteData | UpdatedCmsFlashSalePage | BrandIndexPage | CmsTrackMyOrderPage | { CMSPageBuilder: CmsBlocksPage }>,
): data is { CMSPageBuilder: CmsBlocksPage } => {
  if (data && 'CMSPageBuilder' in data) {
    data?.CMSPageBuilder.__typename === 'CMSBlocksPage';
  }

  return false
};

export const isPlpKey = (item: string | string[] | undefined | null): item is string => {
  return typeof item === 'string';
};