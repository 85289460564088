import styled from 'styled-components';

export const StyledCountryPickerInner = styled.div<{ $isTriggered?: boolean }>`
  color: ${({ theme }) => theme.vars.black};
  z-index: 1;
  display: none;
  width: 32rem;
  box-sizing: border-box;
  padding: 1.1rem 1.2rem 2rem 1.5rem;
  background: ${({ theme }) => theme.headerColors.backgroundColor};
  box-shadow: 0 0.4rem 0.3rem 0 rgb(0 0 0 / 20%);

  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $isTriggered }) => ($isTriggered ? 'block' : 'none')};
    position: absolute;
    text-align: left;
    padding: 1.6rem 2.4rem;
    background: ${({ theme }) => theme.vars.white};
  }
`;

export const StyledCountryPicker = styled.div`
  position: relative;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.vars.white};
    z-index: 999;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-right: ${({ theme }) => theme.vars.sizeXS};
  }
`;

export const StyledCountryPickerTrigger = styled.div<{ $isTriggered: boolean }>`
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  display: none;

  > img {
    width: 2.4rem;
    height: 100%;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
  }

  @media ${({ theme }) => theme.devices.medium} {
    padding: 0.4rem 0.5rem 0.8rem;
    padding-top: 0.6rem;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.vars.white};
    box-shadow: 0 0.4rem 0.3rem 0 rgb(0 0 0 / 20%);
  }
  ${({ $isTriggered }) =>
  $isTriggered &&
    `
    box-shadow: 0 0.4rem 0.3rem 0 rgba(0, 0, 0, 0.2);
  `}
`;

export const StyledIntroText = styled.p`
  margin-bottom: 1.3rem;
  line-height: 2;
  display: none;

  @media ${({ theme }) => theme.devices.medium} {
    display: block;
    line-height: 1.5rem;
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const StyledCountryPickerButton = styled.button<{ $listVisible?: boolean }>`
  background: none;
  display: none;
  border: none;
  line-height: 1.2;
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;

  @media ${({ theme }) => theme.devices.medium} {
    display: ${({ $listVisible }) => ($listVisible ? 'none' : 'block')};
  }
`;

export const StyledCountryPickerButtonMobile = styled(StyledCountryPickerButton)`
  display: block;

  @media ${({ theme }) => theme.devices.medium} {
    display: none;
  }
`;

export const StyledChangeLocation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.3rem;
`;
