import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { destroyMessage, errorMessage, infoMessage, successMessage } from './actions';
import { NotificationState } from './notification.types';

export const initialState: NotificationState = {
  message: '',
  timeout: 5000,
  icon: null,
  trigger: false,
  backgroundColor: '',
  uuid: null,
};

/* eslint-disable no-param-reassign */
const setNotificationState = (state: NotificationState, action: any, icon: IconDefinition, defaultBackgroundColor: string) => {
  state.message = action.payload.message;
  state.trigger = true;
  state.icon = action.payload.icon ?? icon;
  state.timeout = action.payload.timeout ?? 5000;
  state.backgroundColor = action.payload.backgroundColor ?? defaultBackgroundColor;
  state.uuid = uuidv4();
};
/* eslint-enable no-param-reassign */


export const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(successMessage, (state, action) => {
      setNotificationState(state, action, faCheck as IconDefinition, '#f5fcf6');
    })
    .addCase(errorMessage, (state, action) => {
      setNotificationState(state, action, faExclamationTriangle as IconDefinition, '#fae5e5');
    })
    .addCase(infoMessage, (state, action) => {
      setNotificationState(state, action, faInfoCircle as IconDefinition, '#fdf0d5');
    })
    .addCase(destroyMessage, (state) => {
      /* eslint-disable no-param-reassign */
      state.message = '';
      state.trigger = false;
      state.icon = null;
      state.backgroundColor = '';
      state.uuid = null;
      /* eslint-enable no-param-reassign */
    });
});