/* eslint-disable no-nested-ternary */
import React, { FC, memo } from 'react';

import NextImage from '../../basic/NextImage';
import { CountryPickerItem, HtmlLink } from '../../types/middleware-types';
import { isCountryPickerItem } from '../../types/type-checkers';
import { getCountryFlag } from '../../utils/getters/getCountryFlag';
import { createMediaList } from "../../utils/helpers/createMediaList";
import CountryPickerInfo from './CountryPickerInfo';
import CountryPickerList from './CountryPickerList';
import {
  StyledCountryPicker,
  StyledCountryPickerButtonMobile,
  StyledCountryPickerInner,
  StyledCountryPickerTrigger,
  StyledIntroText,
} from './CountryPickerStyles';
import { CountryPickerTab } from '.';

export interface CountryPickerStructureProps {
  instructions?: string;
  moreInfoAction?: HtmlLink;
  currentCountry: CountryPickerItem | Record<string, unknown>;
  isTriggered: boolean;
  triggerHoverOrFocus: (hovered: boolean) => void;
  selectedTab: CountryPickerTab;
  showMenu: () => void;
  showInfo: () => void;
  showList: () => void;
}

const CountryPickerStructure: FC<CountryPickerStructureProps> = ({
  instructions,
  moreInfoAction,
  currentCountry,
  isTriggered,
  triggerHoverOrFocus,
  selectedTab,
  showMenu,
  showInfo,
  showList,
}) => (
  <StyledCountryPicker
    tabIndex={0}
    data-hookid="globalHeaderCountryPicker"
    onMouseLeave={() => triggerHoverOrFocus(false)}
    onMouseEnter={() => triggerHoverOrFocus(true)}
    onFocus={() => triggerHoverOrFocus(true)}
  >
    {isCountryPickerItem(currentCountry) && (
      <>
        <StyledCountryPickerTrigger $isTriggered={isTriggered}>
          {currentCountry?.name && (
            <NextImage
              mediaList={createMediaList({
                src: getCountryFlag(currentCountry.name),
                width: 24,
                height: 16,
              })}
              alt={currentCountry.name}
              imageType="fixed"
            />
          )}
          <StyledCountryPickerButtonMobile>Change location</StyledCountryPickerButtonMobile>
        </StyledCountryPickerTrigger>
        <StyledCountryPickerInner data-testid="CountryPickerInner" $isTriggered={isTriggered}>
          {instructions && <StyledIntroText>{instructions}</StyledIntroText>}
          {selectedTab === 'info' ? (
            <CountryPickerInfo
              moreInfoAction={moreInfoAction}
              currentCountry={currentCountry}
              showMenu={showMenu}
              showList={showList}
              blurHandler={() => triggerHoverOrFocus(false)}
            />
          ) : (
            <CountryPickerList showInfo={showInfo} blurHandler={() => triggerHoverOrFocus(false)} />
          )}
        </StyledCountryPickerInner>
      </>
    )}
  </StyledCountryPicker>
);

export default memo(CountryPickerStructure);
