import React, { FC } from 'react';

const MyBagCompactFilled: FC = () => (
  <svg width="18" height="22" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.501901 29.193L0.502739 29.1785V29.1641V8.86475H22.8123H24.4972V29.1641V29.1786L24.4981 29.1931C24.5164 29.5085 24.4022 29.824 24.1691 30.0709C23.9381 30.3155 23.6082 30.4727 23.2462 30.5001H1.75724C1.39448 30.4735 1.06363 30.3165 0.83194 30.0717C0.598236 29.8247 0.483595 29.5088 0.501901 29.193Z"
      fill="none"
      stroke="black"
      id="filled-minibag-main-path"
    />
    <path
      d="M20 8.5H20.5V8V7.60914C20.5 3.65628 17.1168 0.5 13.0018 0.5C8.88722 0.5 5.5 3.65595 5.5 7.60914V8V8.5H6H20Z"
      fill="none"
      stroke="black"
      strokeMiterlimit="10"
    />
  </svg>
);

export default MyBagCompactFilled;
