import React, { FC, memo } from 'react';

import { MinibagOptionsInterface } from '../../types/custom-types';
import MinibagDropdown from './MinibagDropdown';
import MiniBagIcon from './MinibagIcon';
import { StyledMinibagContainer } from './MinibagStyles';

export interface MinibagStructureProps extends MinibagOptionsInterface {
  bagShow: () => void,
  bagHide: () => void,
  loading: boolean,
  isKG: boolean,
}

const MinibagStructure: FC<MinibagStructureProps> = ({
  toggleBag,
  showBag,
  isMobileOrTablet,
  bagShow,
  bagHide,
  loading,
  isKG,
}) => (
  <>
    <StyledMinibagContainer
      onMouseEnter={bagShow}
      onMouseLeave={bagHide}
      $showBag={showBag || false}
      data-hookid="minibagContainer"
      $isKG={isKG}
    >
      {toggleBag && (
        <>
          {isMobileOrTablet ? (
            <MiniBagIcon showBag={showBag} toggleBag={toggleBag} isMobileOrTablet={isMobileOrTablet} />
          ) : (
            <a href="/checkout/cart">
              <MiniBagIcon showBag={showBag} toggleBag={toggleBag} isMobileOrTablet={isMobileOrTablet} />
            </a>
          )}
          <MinibagDropdown
            showBag={showBag}
            toggleBag={toggleBag}
            loading={loading}
            isMobileOrTablet={isMobileOrTablet}
          />
        </>
      )}
    </StyledMinibagContainer>
  </>
);

export default memo(MinibagStructure);