/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { 
  ContentBlockCelebrityStyleCarousel,
  Maybe,
  Product,
  ProductMedia,
  ProductRecommendations,
} from '../../types/middleware-types';
import {
  updateCelebrityStyleCarouselData,
  updateCurrentProduct,
  updateProductColours,
  updateProductMedia,
} from './actions';

export interface CurrentProductState {
  selectedSize?: Maybe<string>;
  currentProduct: Maybe<Product>;
  celebrityStyleCarousel: Maybe<ContentBlockCelebrityStyleCarousel>;
  productColours?: Maybe<Array<Product>>;
  productRecommendations?: Maybe<Array<ProductRecommendations>>;
  productMedia?: Maybe<ProductMedia>;
}

export const initialState: CurrentProductState = {
  celebrityStyleCarousel: null,
  productColours: null,
  currentProduct: null,
  productMedia: null,
};

export const currentProductReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateCelebrityStyleCarouselData, (state, action) => {
      state.celebrityStyleCarousel = action.payload;
    })
    .addCase(updateProductColours, (state, action) => {
      state.productColours = action.payload;
    })
    .addCase(updateCurrentProduct, (state, action) => {
      state.currentProduct = action.payload;
    })
    .addCase(updateProductMedia, (state, action) => {
      state.productMedia = action.payload;
    })
});
