import styled from 'styled-components';

export const StyledWistiaContainer = styled.div`
  position: relative;

  img {
    width: 100%;
  }
`;

export const StyledWistiaVideo = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  #wistia-video-container {
    width: 100%;
  }
`;
