import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../reduxStore/store';
import { CategoryActiveFilter, Maybe } from '../../types/middleware-types';
import {
  CurrentFilterType,
  CurrentMobileFiltersType,
  ProductListFilterOptionsType,
} from '../../types/state-types';

export const selectPlpCurrentSortBy = (state: RootState): Maybe<string | null> =>
  state.plpFilter.currentSortBy;
export const selectPlpCurrentActiveFilters = (
  state: RootState,
): Maybe<Array<CategoryActiveFilter>> | undefined => state.plpFilter.currentActiveFilters;
export const selectselectPlpCurrentActiveFiltersLength = createSelector(
  selectPlpCurrentActiveFilters,
  (state) => state?.length,
);
export const selectPlpCurrentFilter = (state: RootState): Maybe<CurrentFilterType> =>
  state.plpFilter.currentFilter;
export const selectPlpCurrentMobileOptions = (state: RootState): Maybe<CurrentMobileFiltersType> =>
  state.plpFilter.currentMobileOptions;
export const selectPlpSlideFilters = (state: RootState): boolean => state.plpFilter.slideFilters;
export const selectPlpCurrentSelectedFilters = (state: RootState): Record<string, Array<string>> =>
  state.plpFilter.currentSelectedFilters;
export const selectPlpApplyFiltersEnabled = (state: RootState): boolean | undefined =>
  state.plpFilter.applyFilterEnabled;
export const selectPlpCurrentSizeQueryData = (
  state: RootState,
): string | null | undefined => state.plpFilter.currentSizeQueryData;
export const selectPlpProductListFilterOptions = (
  state: RootState,
): Maybe<ProductListFilterOptionsType> => state.plpFilter.productListFilterOptions;
export const selectPlpFilterMenuOpenMobile = (state: RootState): boolean | undefined =>
  state.plpFilter.filterMenuOpenMobile;
