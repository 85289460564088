import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import constants from '../../../../sites/constants';
import { selectElements } from '../../../features/elements';
import { selectPageName, selectToggles } from '../../../features/static';
import { SubMenuData } from '../../../types/custom-types';
import { ImageHtmlLink, Maybe, MobileMultiItemSubMenu } from '../../../types/middleware-types';
import getCustomerServicesUrl from '../../../utils/getters/getCustomerServicesUrl';
import { MobileMenuBasicProps } from '../MobileMenu.types';
import MobileMenuCompactReverseStructure from './MobileMenuCompactReverseStructure';

export interface MobileMenuCompactReverseProps extends MobileMenuBasicProps {
  showSubMenu: boolean;
  setShowSubMenu: (arg0: boolean) => void;
  promoBannerHeight: number;
  finalTopPositionMob?: number;
  showCountryPickerButton?: boolean;
  hideCountryPickerOnGlobalESite?: boolean;
}

const MobileMenuCompactReverse: FC<MobileMenuCompactReverseProps> = ({
  isMenuOpen,
  isScrolling,
  headerTheme,
  toggleMobileMenu,
  data,
  handleDoubleClick,
  isAuth,
  accountLabel,
  accountHref,
  currentCountry,
  showCountryPicker,
  toggleCountryPicker,
  showSubMenu,
  setShowSubMenu,
  compactHeader,
  promoBannerHeight,
  finalTopPositionMob = 0,
  showCountryPickerButton,
  hideCountryPickerOnGlobalESite,
}) => {
  const [mobileSubmenuData, setMobileSubmenuData] = useState<SubMenuData>();
  const [mobileSubmenuImages, setMobileSubmenuImages] = useState<Maybe<ImageHtmlLink>[]>();
  const toggles = useSelector(selectToggles);
  const engs13232ScrollIssue = toggles?.engs13232ScrollIssue;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;
  const { header } = useSelector(selectElements);
  const [headerOffset, setHeaderOffset] = useState(0)
  const storesIcon = toggles?.featureStoresIcon;
  const pageName = useSelector(selectPageName);
  const isHomepage = pageName === 'HomePage';
  const { storeLocator } = constants;

  const openSubmenu = (mobileData: MobileMultiItemSubMenu) => {
    setShowSubMenu(true);
    setMobileSubmenuData([{ ...mobileData }]);
    if (mobileData.images !== null && mobileData.images !== undefined) {
      setMobileSubmenuImages(mobileData?.images);
    }
  };

  const closeSubmenu = () => {
    setShowSubMenu(false);
  };
  const buffer = 43;
  const updatedTop =
    typeof window !== 'undefined'
      ? finalTopPositionMob + header?.clientHeight - window?.scrollY - buffer
      : 0;

  useEffect(() => {
    const elementTop = document.querySelector('[data-hookid="globalHeaderMobileNavigationContainer"]')?.getBoundingClientRect()?.top;
    if (elementTop) {
      setHeaderOffset(elementTop)
    }
  }, []);

  // This is needed for mobile to allow extra space to scroll because of the promoBanner
  const hpMobilePromoBanner = toggles?.featureShowKgHomepagePromobanner === 'mobile' || toggles?.featureShowKgHomepagePromobanner === 'true';
  const bannerSpace = (!isHomepage || hpMobilePromoBanner) ? 60 : 20;
  const lastLink = process.env.NEXT_PUBLIC_LD_SITE !== 'kgglobal' && process.env.NEXT_PUBLIC_LD_SITE !== 'kgmx' ? { link: storeLocator.linkHref, text: 'Store Locator' } : null;

  const mobileLinkList = [
    {
      url: '/kurt-geiger-kindness-foundation',
      name: 'The kindness foundation'
    },
    {
      url: process.env.NEXT_PUBLIC_LD_SITE !== 'kgglobal' && process.env.NEXT_PUBLIC_LD_SITE !== 'kgmx' ? '/kurt-geiger-kindness-foundation' : null,
      name: 'Loyalty'
    },
    {
      url: accountHref,
      name: accountLabel
    },
    {
      url: process.env.NEXT_PUBLIC_LD_SITE !== 'kgus' ? lastLink?.link : '/track-my-order',
      name: process.env.NEXT_PUBLIC_LD_SITE !== 'kgus' ? lastLink?.text : 'Track My Order'
    }
  ]

  const componentProps = {
    isMenuOpen,
    isScrolling,
    headerTheme,
    toggleMobileMenu,
    data,
    mobileSubmenuImages,
    handleDoubleClick,
    isAuth,
    accountLabel,
    accountHref,
    currentCountry,
    showCountryPicker,
    showCountryPickerButton,
    toggleCountryPicker,
    showSubMenu,
    openSubmenu,
    closeSubmenu,
    mobileSubmenuData,
    compactHeader,
    promoBannerHeight,
    headerHeight: header?.clientHeight,
    customerServicesUrl: getCustomerServicesUrl(),
    storesIcon,
    finalTopPositionMob: updatedTop,
    hideCountryPickerOnGlobalESite,
    headerOffset,
    bannerSpace,
    engs13232ScrollIssue,
    mobileLinkList,
    storeLocator,
    useReactRoutingLinks
  };

  return <MobileMenuCompactReverseStructure {...componentProps} />;
};

export default MobileMenuCompactReverse;
