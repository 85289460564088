import styled from 'styled-components';

import { StyledAccordionTrigger as StyledAccordionTriggerVertical } from '../../Accordion/AccordionStyles';
import { StyledAccordionTrigger } from '../../AccordionItemLayout/AccordionItemFooter/AccordionItemStyles';
import { StyledCountryPickerInner } from '../../CountryPicker/CountryPickerStyles';

interface StyleProps {
  $isMenuOpen: boolean,
  $isScrolling: boolean
}
interface BackgroundProps {
  $headerBackground: string,
  $headerTheme: string
}

export const StyledMobileMenuContainer = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledMobileMenuTrigger = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1.4rem;
  left: 3rem;
  padding: 0;
  height: 2.4rem;
  min-width: 2.1rem;
  outline: none;
  margin-right: 8px;
`;

export const StyledMobileNavigationContainer = styled.div<StyleProps>`
  display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: calc(100vh - 15rem);
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  left: 0;
  top: ${({ $isScrolling }) => ($isScrolling ? '5.5rem' : '7.2rem')};
  z-index: 15;
`;

export const StyledCountryPickerMobile = styled.div`
  ${StyledCountryPickerInner} {
    display: block !important;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
`;

export const StyledLinksContainer = styled.div<BackgroundProps>`
  background: ${({ $headerBackground }) => $headerBackground};
  width: 100%;

  ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      ${({ theme }) => theme.types.ctaTextStyles}
      list-style: none;
      padding: 0.9rem 2rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: transparent;
      color: ${({ theme, $headerTheme }) => $headerTheme || theme.vars.black};

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.vars.black};
        margin-top: 3px;
        visibility: hidden;
      }

      &.active {
        background: ${({ theme }) => theme.vars.white};
        color: ${({ theme }) => theme.vars.black};

        &::after {
          visibility: visible;
        }
      }
    }
  }
`;

export const StyledSubMenuContainer = styled.div`
  padding: ${({ theme }) => theme.vars.sizeL};
  padding-top: 0;
  height: 100%;
  overflow-y: scroll;
  background: ${({ theme }) => theme.vars.white};
  ${StyledAccordionTrigger} {
    ${({ theme }) => theme.types.h3Style};
  }
  ${StyledAccordionTriggerVertical} {
    ${({ theme }) => theme.types.h3Style};
    .trigger-title {
      ${({ theme }) => theme.types.h3Style};
    }

    font-size: 1.4rem !important;
    padding: 1.2rem 0 !important;
  }
`;

export const StyledUtilLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCountryFlag = styled.div`
  display: flex;

  img {
    height: ${({ theme }) => theme.vars.sizeM};
    width: ${({ theme }) => theme.vars.sizeL};
  }
`;
