export const vars = {
  brandPrimary: '#b08a7d',
  greyLight: '#e0dedd',
  greyLightest: '#eee8e5',
  greyDark: '#71625d',
  greyDarkest: '#3d251e',
  greyAppBanner: '#808080',
  greyAppBannerLight: '#bfbfbf',
  greyAppBannerLightest: '#f2f2f2',
  greyAppBannerDark: '#3d3d3d',
  blueLight: '#0c71fd',
  white: '#ffffff',
  black: '#3d251e',
  green: '#14a674',
  greenLightest: '#e9f7f2',
  red: '#ad1b3f',
  redError: '#ff0000',
  redDark: '#ad1b3f',
  defaultBlack: '#000000',
  fontWeightLight: 300,
  slowSpeed: '1s',
  mediumSpeed: '.5s',
  fastSpeed: '.25s',
  imageBaseUrl: 'https://kg-static.s3.amazonaws.com/assets/kurtgeiger',
  tabletBp: 768,
  desktopBp: 1024,
  sizeXS: '.8rem',
  sizeS: '1.2rem',
  sizeM: '1.6rem',
  sizeL: '2rem',
  sizeXL: '2.4rem'
};

export const breakpoints = {
  tablet: `${vars.tabletBp}px`,
  desktop: `${vars.desktopBp}px`,
  tabletMax: `${vars.tabletBp - 1}px`,
  desktopMax: `${vars.desktopBp - 1}px`,
};

export const fonts = {
  fontFamilyFuturaNowTextBold: 'FuturaNowTextBold',
  fontFamilyFuturaNowText: 'FuturaNowText',
  fontFamilyFuturaNowTextRegular: 'FuturaNowTextBoldRegular',
  fontFamilyPrimary: 'FuturaNowText',
  fontFamilyPrimaryBold: 'FuturaNowTextBold',
};

export const devices = {
  smallOnly: `(max-width: ${breakpoints.tabletMax})`,
  medium: `(min-width: ${breakpoints.tablet})`,
  mediumOnly: `(min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktopMax})`,
  large: `(min-width: ${breakpoints.desktop})`,
};

export const buttons = {
  primaryHover: {
    textDecoration: 'none',
    background: vars.brandPrimary,
    color: vars.black,
    borderColor: vars.brandPrimary,
  },
  secondary: {
    borderColor: vars.black,
  },
  tertiary: {
    color: vars.black,
  },
};

export const headerColors = {
  backgroundColor: vars.brandPrimary,
  mobileMenuBorder: vars.brandPrimary,
  mobileMenuBackBg: vars.brandPrimary,
};

export const footerColors = {
  backgroundColor: vars.brandPrimary,
  signUpButtonBackgroundColor: vars.black,
  signUpButtonTextColor: vars.white,
  signUpButtonBorderHover: `1px solid ${vars.black}`,
  radioButtonBorderColor: vars.black,
};

export const blockTextColors = {
  default: vars.black,
  dark: vars.brandPrimary,
  light: vars.white,
};

export const blockFonts = {
  titleS: fonts.fontFamilyFuturaNowTextBold,
  titleM: fonts.fontFamilyFuturaNowTextBold,
  titleL: fonts.fontFamilyFuturaNowTextBold,
  subtitle: fonts.fontFamilyFuturaNowTextRegular,
};

export const blockLetterSpacing = {
  headingL: 'normal',
};

export const blockFontSizes = {
  headingS: '2.4rem',
  headingM: '4rem',
  headingL: '4rem',
  headingLMobile: '4rem',
  subtitleS: '1.4rem',
  subtitleM: '1.4rem',
  subtitleL: '1.4rem',
  descriptionCtaS: '1.2rem',
  descriptionCtaM: '1.6rem',
  descriptionCtaL: '1.6rem',
};

export const blockTopMargins = {
  heading: '0.6rem',
  description: '0.8rem',
  cta: '0.8rem',
};

export const plp = {
  filters: {
    dropdownValue: {
      fontSize: '1.4rem',
    },
    textTransform: 'uppercase',
    minWidth: '17rem,',
    indent: '7rem',
  },
  banner: {
    minHeight: '20rem',
    innerLineHeight: '2rem',
  },
  skeleton: {
    startColor: '#f1ebe9',
    endColor: '#f7f3f2',
  },
  dropdownTitle: 'h1Style',
  dropdownTitleSvgMargin: '0',
};

export const productsList = {
  container: {
    width: '100%',
  },
};

export const productCard = {
  brandName: 'h3Style',
  name: {
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: '1.8rem',
  },
  roundelFlag: {
    borderBottom: '#e0e0e0',
    color: vars.black,
  },
  quickBuy: {
    hoverBackground: vars.black,
    hoverColor: vars.white,
    activeBackground: vars.brandPrimary,
    disabledBackground: vars.greyDark,
    position: '6.7rem',
  },
  textMargin: '0.5rem',
};

export const productPrice = {
  sale: {
    colour: vars.black,
  },
  strikethrough: {
    height: '-0.1rem',
  },
};

export const pdpContent = {
  top: '7rem',
  colourSelector: {
    activeHoverColour: vars.brandPrimary,
  },
  colourLabel: {
    textTransform: 'uppercase',
    style: 'h4Style',
  },
  sizeButton: {
    hoverActiveDisabledColour: vars.brandPrimary,
    mobileDisabledColour: vars.greyLight,
  },
  sizeSelected: {
    background: vars.brandPrimary,
    color: vars.black,
  },
  sliderBar: {
    color: vars.greyLightest,
  },
  productName: {
    style: `
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-family: ${fonts.fontFamilyPrimaryBold};
      text-transform: uppercase;
    `,
    margin: '0.8rem 0 0.2rem',
  },
  productBrand: 'h3Style',
  accordionTrigger: {
    textTransform: 'uppercase',
    borderColor: vars.greyLightest,
  },
  imageDots: {
    color: vars.black,
    width: '1.4rem',
    fontSize: '1.2rem',
  },
  sliderImageDots: {
    color: vars.black,
    width: '1rem',
    marginRight: '0.8rem',
  },
  availableSizesMobile: {
    marginTop: '2.2rem',
    style: 'h4Style',
  },
  colourTitle: {
    marginTop: '2rem',
  },
  titles: {
    textTransform: 'uppercase',
  },
  price: {
    margin: '0.6rem 0.5rem 0 0',
  },
  rotationSliderHandle: {
    background: vars.brandPrimary,
  },
  toolTip: {
    background: vars.brandPrimary,
    color: vars.greyDarkest,
  },
  borderColor: vars.greyLightest,
  selectSizeMobile: 'h3Style',
  sizeTypeToggle: 'h3Style',
};

export const search = {
  headings: 'h3Style',
  brandName: 'h4Style',
  productName: 'h4Style',
};

export const minibag = {
  product: {
    fontSize: '1.4rem',
    borderColor: vars.black,
    name: {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    text: {
      marginBottom: '0.6rem',
    },
  },
};

export const countryPicker = {
  change: {
    textTransform: 'uppercase',
  },
};

export const header = {
  mobileLogoMaxW: '16rem',
  desktopLogoMaxW: '24rem',
  bannerLink: {
    textDecoration: 'none',
    fontFamily: fonts.fontFamilyFuturaNowTextRegular,
  },
};

export const footer = {
  fonts: {
    primary: fonts.fontFamilyFuturaNowTextRegular,
  },
  padding: '1.875rem 5%',
  borderTop: 'none',
  accordionTriggerFontSize: '1.4rem',
};

export const upsell = {
  tabTrigger: 'tertiaryLinkStyles',
  tabTriggerQubit: 'h2Style',
  tabTextTransform: 'uppercase',
  textTransform: 'uppercase',
  borderColor: vars.greyLight,
  fontWeight: 400,
};

export const stories = {
  tabs: {
    transform: 'uppercase',
  },
  storyTitle: 'h2Style',
  storyTitleTransform: 'unset',
  titleLinesColor: vars.greyDark,
  titleLinesWidth: `calc(51% - 10rem)`,
  paddingTop: '7rem',
};

export const celebrity = {
  card: {
    asSeenOnTransform: 'uppercase',
    asSeenOnTitle: 'labelStyle',
    celebrity: 'labelSmallStyle',
    shoeName: 'h4Style',
    brandName: 'h4Style',
    color: vars.greyDarkest,
  },
};

export const storeLocator = {
  fontFamily: fonts.fontFamilyFuturaNowTextRegular,
  input: {
    borderColor: vars.greyLight,
  },
  addressList: {
    button: {
      background: vars.greyLightest,
    },
    borderColor: '#D7D7D7',
  },
  markerColour: vars.brandPrimary,
  storeDetailsHeader: {
    textTransform: 'uppercase',
  },
  storeTitle: {
    style: 'h4Style',
    textTransform: 'uppercase',
  },
  storeDistance: {
    style: 'h4Style',
    textTransform: 'uppercase',
  },
  phone: {
    fontWeight: 400,
    textDecoration: 'underline',
  },
  backButton: {
    textTransform: 'uppercase',
  },
};

export const popup = {
  textTransform: 'uppercase',
};

export const welcomePopup = {
  color: vars.greyDarkest,
};

export const wearWithTrigger = {
  background: vars.greyLightest,
};

export const filters = {
  radioButton: {
    borderColour: vars.brandPrimary,
  },
};

export const cmsPages = {
  h1LineHeightFix: '1.2rem',
};

export const linksAnimations = {
  displayType: 'block',
};

export const Variables = {
  vars,
  breakpoints,
  fonts,
  devices,
  buttons,
  headerColors,
  footerColors,
  blockTextColors,
  blockLetterSpacing,
  blockFonts,
  blockFontSizes,
  plp,
  productsList,
  productCard,
  productPrice,
  pdpContent,
  search,
  minibag,
  header,
  footer,
  upsell,
  countryPicker,
  stories,
  celebrity,
  storeLocator,
  popup,
  welcomePopup,
  blockTopMargins,
  wearWithTrigger,
  filters,
  cmsPages,
  linksAnimations
};

export default Variables;
