import gql from 'graphql-tag';

import { PRICE_FRAGMENT } from './basicFragments';

export const MINIBAG_PRODUCT_FRAGMENT = gql`
  fragment MiniBagProduct on CartItemProduct {
    name
    lineNumber
    brand
    colour
    sale
    url
    urlKey
    id
    sizeLabels {
      uk
      eu
      us
    }
    productImage {
      url
      alt
    }
    price {
      ...Price
    }
    sku
  }
  ${PRICE_FRAGMENT}
`;

export const MINIBAG_BUTTON_FRAGMENT = gql`
  fragment MiniBagButton on Cta {
    ctaText
    url
    openInNewTab
  }
`;
