import React, { FC, ReactElement, useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { set } from 'local-storage';

import { ImageV2, NewsletterSignup } from '../../../types/middleware-types';
import FormPopupStructure from './FormPopupStructure';

interface FormPopupProps {
  localStorageKey?: string;
  thanks?: string;
  image?: ImageV2;
  closeButtonClicked: () => void;
  newsletterSignup: NewsletterSignup;
  popupFormIntro?: string;
  source?: string;
}

const FormPopup: FC<FormPopupProps> = ({
  localStorageKey,
  thanks,
  popupFormIntro,
  ...otherProps
}): ReactElement => {
  const [newsletterSubmited, setNewsletterSubmited] = useState(false);

  useEffect(() => {
    if (newsletterSubmited && localStorageKey) {
      const now = new Date().getTime();
      set(localStorageKey, now);
    }
  }, [newsletterSubmited]);

  const componentProps = {
    ...otherProps,
    newsletterSubmited,
    thanks: DOMPurify && thanks ? DOMPurify.sanitize(thanks) : thanks,
    setNewsletterSubmited,
    popupFormIntroHTML:
      DOMPurify && popupFormIntro ? DOMPurify.sanitize(popupFormIntro) : popupFormIntro,
  };

  return <FormPopupStructure {...componentProps} />;
};

export default FormPopup;
