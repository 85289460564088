import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../reduxStore/store';
import { FormattedFilters, InitialFilterDataInterface } from '../../types/custom-types';
import { CategoryActiveFilter, CategoryBanner, CategoryFilter, CategorySortByOption, ContentBlockMediaBanner, Maybe } from '../../types/middleware-types';
import { OpenFilterOptions, PlpDataWithoutItems, ProductListFilterOptionsType } from '../../types/state-types';

// Feeds into applied filters
export const selectPlpDataWithoutItems = (state: RootState): Maybe<PlpDataWithoutItems> =>
  state.filters.plpData;

export const selectCategoriesData = (state: RootState): Maybe<Array<CategoryFilter>> =>
  state.filters.categories;

export const selectActiveCategoriesData = (state: RootState): Maybe<Array<CategoryActiveFilter>> =>
  state.filters.activeCategory;

export const selectCategory = (state: RootState): Maybe<string> =>
  state.filters.selectedCategory;

export const selectPrice = (state: RootState): Maybe<string> =>
  state.filters.selectedPrice;

export const selectGender = (state: RootState): Maybe<string> =>
  state.filters.selectedGender;

export const selectPageView = (state: RootState): string =>
  state.filters.pageView;

// Contains sort by data
export const selectSortByData = (state: RootState): Maybe<Array<CategorySortByOption>> =>
  state.filters.sortBy;
// Contains selectes sort by item (maybe refactor oto pull from above)
export const selectCurrentSortBy = (state: RootState): null | string =>
  state.filters.currentSortBy;

// Contains up to date Filters data
export const selectCurrentPendingFilters = (state: RootState): Array<CategoryFilter> =>
  state.filters.currentPendingFilters;

export const selectOpenFilterDropdown = (state: RootState): OpenFilterOptions =>
  state.filters.openFilterDropdown;

export const formattedFilters = (state: RootState): FormattedFilters =>
  state.filters.formattedFilters;

export const selectMobFiltersOpen = (state: RootState): boolean =>
  state.filters.mobFiltersOpen;

export const selectCloseAllFilterPopups = (state: RootState): boolean =>
  state.filters.closeAllFilterPopups;

export const selectBrandedPlpData = (state: RootState): CategoryBanner | ContentBlockMediaBanner | null =>
  state.filters.brandedPlpData;

export const selectInitialFilterData = (state: RootState): InitialFilterDataInterface =>
  state.filters.initialFilterData;

export const selectFiltersMWData = createSelector(
  [
    selectCategory,
    formattedFilters,
    selectCurrentSortBy
  ],
  (categoryPath: Maybe<string>, filters: FormattedFilters, sortBy: string | null): Maybe<ProductListFilterOptionsType> => ({
    categoryPath,
    filters,
    sortBy
  })
);