import gql from 'graphql-tag';

export const GET_SITE_SWITCHER_DATA = gql`
  query SiteSwitcher($domain: String!) {
    siteSwitcher(domain: $domain) {
      country
      domain
      title
      redirect {
        ctaText
        url
      }
    }
  }
`;
