import React, { FC, KeyboardEvent, memo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledSearchIconContainer } from './SearchIconStyles';

interface SearchIconStructureProps {
  closeNavMenuAndMinibag: () => void;
  isScrolling: boolean;
  searchOpen: boolean;
  isDesktop: boolean;
  handleOpenSearch: (e: KeyboardEvent) => void;
  headerTheme: string;
  headerPosition: string;
  landingSearch: boolean;
  clearSearch: () => void;
  compactHeader: boolean;
  hideNav: boolean;
  isHeaderReverse: boolean;
}

const SearchIconStructure: FC<SearchIconStructureProps> = ({
  closeNavMenuAndMinibag,
  isScrolling,
  searchOpen,
  isDesktop,
  handleOpenSearch,
  headerTheme,
  headerPosition,
  landingSearch,
  clearSearch,
  compactHeader,
  hideNav,
  isHeaderReverse
}) => (
  <>
    {isHeaderReverse ? (
      <StyledSearchIconContainer
        tabIndex={0}
        $pointer={false}
        onKeyPress={(e) => handleOpenSearch(e)}
        $headerTheme={headerTheme}
        $landingSearch={landingSearch}
        $isScrolling={isScrolling}
        $compactHeader={compactHeader}
        $hideNav={hideNav}
        $isHeaderReverse={isHeaderReverse}
        data-hookid={
          landingSearch && !compactHeader
            ? 'globalHeaderMobileLandingSearchIcon'
            : 'globalHeaderSearchIcon'
        }
      >
        {!compactHeader && (isDesktop || landingSearch) && <span>Search Shoeaholics</span>}
        {/* If initial 'landing' state, only show close cross in header */}
        {!(!searchOpen && !landingSearch && !isDesktop && headerPosition === 'absolute') && (
          <FontAwesomeIcon
            onClick={() => {
              closeNavMenuAndMinibag();
              if (!searchOpen) {
                clearSearch();
              }
            }}
            data-hookid='globalHeaderSearchIconSVG'
            icon={searchOpen && !isDesktop ? faTimes as IconProp : faSearch as IconProp}
            className={searchOpen && !isDesktop ? 'search-close-icon' : 'search-icon'}
          />
        )}
      </StyledSearchIconContainer>
    ) : (
      <StyledSearchIconContainer
        tabIndex={0}
        $pointer={true}
        onKeyPress={(e) => handleOpenSearch(e)}
        onClick={() => {
          closeNavMenuAndMinibag();
          if (!searchOpen) {
            clearSearch();
          }
        }}
        $headerTheme={headerTheme}
        $landingSearch={landingSearch}
        $isScrolling={isScrolling}
        $compactHeader={compactHeader}
        $hideNav={hideNav}
        data-hookid={
          landingSearch && !compactHeader
            ? 'globalHeaderMobileLandingSearchIcon'
            : 'globalHeaderSearchIcon'
        }
      >
        {!compactHeader && (isDesktop || landingSearch) && <span>Search Shoeaholics</span>}
        {/* If initial 'landing' state, only show close cross in header */}
        {!(!searchOpen && !landingSearch && !isDesktop && headerPosition === 'absolute') && (
          <FontAwesomeIcon
            icon={searchOpen && !isDesktop ? faTimes as IconProp : faSearch as IconProp}
            className={searchOpen && !isDesktop ? 'search-close-icon' : 'search-icon'}
          />
        )}
        {compactHeader && isDesktop && <span>Search</span>}
      </StyledSearchIconContainer>
    )}
  </>

);

export default memo(SearchIconStructure);
