import Cookies from 'universal-cookie';

import { CustomerData } from '../../../types/middleware-types';
import addToDataLayer from '../addToDataLayer';

interface DatalayerUser {
  guestCheckout: string;
  loggedIn: boolean;
  id?: string;
  fname?: string;
  lname?: string;
  phone?: string;
  email?: string;
}

const formatUserObject = (customerData: CustomerData): void => {
  const OAUTH_COOKIE = 'customer_oauth_token';
  const cookies = new Cookies();
  const authCookie = cookies.get(OAUTH_COOKIE);
  const user: DatalayerUser = {
    guestCheckout: authCookie ? 'No' : 'Yes',
    loggedIn: !!authCookie,
  };

  if (customerData) {
    user.id = customerData.email;
    user.fname = customerData.firstname;
    user.lname = customerData.lastname;
    user.phone = customerData.phone;
    user.email = customerData.emailNotHashed;
  }

  addToDataLayer({
    user,
  });
};

export default formatUserObject;
