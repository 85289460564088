import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrencyData, selectIsCompactHeader, selectShowBrandName, selectToggles } from '../../../features/static';
import {
  ProductSearchKeywordSuggestion,
  ProductSearchProductSuggestion,
} from '../../../types/middleware-types';
import addToDataLayer from '../../../utils/DataLayer/addToDataLayer';
import SearchResultsStructure from './SearchResultsStructure';

export interface SearchResultsProps {
  searchTerm: string;
  searchResults: ProductSearchProductSuggestion[];
  searchSuggestions: ProductSearchKeywordSuggestion[];
  searchUrl: string;
}

const SearchResults: FC<SearchResultsProps> = ({
  searchTerm,
  searchSuggestions,
  searchResults,
  searchUrl,
}) => {
  const [elOffset, setElOffset] = useState(0);
  const currencyData = useSelector(selectCurrencyData);

  const compactHeader = useSelector(selectIsCompactHeader);
  const toggles = useSelector(selectToggles);
  const showBrandName = useSelector(selectShowBrandName);

  useEffect(() => {
    // calc position within window
    const resultsContainer = document.getElementById('search-results');
    if (resultsContainer) {
      setElOffset(resultsContainer.getBoundingClientRect().top);
    }
  }, []);
  const passToDataLayer = (type: string, productName?: string, brand?: string) => {
    addToDataLayer({
      event: 'searchInteraction',
      eventCategory: 'navigation',
      eventAction: 'search interaction',
      eventLabel:
        brand === undefined
          ? `${type} - ${productName?.toLowerCase()}`
          : `${type} - ${brand.toLowerCase()} ${productName?.toLowerCase()}`,
    });
  };

  const componentProps = {
    searchTerm,
    elOffset,
    passToDataLayer,
    searchSuggestions,
    searchResults,
    compactHeader,
    showBrandName,
    searchUrl,
    currencySymbol: currencyData.symbol,
    toggles,
    useFormatted: toggles.featureKgRedesign
  };

  return <SearchResultsStructure {...componentProps} />;
};

export default SearchResults;