/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  updateCurrentOpen,
} from './actions';

export type AccordionState = {
  currentOpen?: string | null;
};

export const initialState: AccordionState = {
  currentOpen: null,
};

export const accordionReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateCurrentOpen, (state, action) => {
      state.currentOpen = action.payload;
    });
});