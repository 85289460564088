import React, { FC, memo } from 'react';
import { InView } from 'react-intersection-observer';
import Link from 'next/link';

import MediaElement from '../../../../basic/MediaElement';
import { ImageHtmlLink, Maybe } from '../../../../types/middleware-types';
import formatPromoEvent from '../../../../utils/DataLayer/formatPromoEvent';
import parseCMSMediaProp from '../../../../utils/formatters/parseCMSMediaProp';
import {
  StyledImageHtmlLink,
  StyledImageHtmlLinkClickableAdWrapper,
  StyledImageHtmlLinkWrapper,
} from './ImageHtmlLinkStyles';

import 'intersection-observer';

export interface ImageHtmlLinkStructureProps {
  content: Maybe<ImageHtmlLink>;
  linkPosition?: string;
  compactHeader: boolean;
  handlePromoView: (isVisible: boolean, content: Maybe<ImageHtmlLink>) => void;
  isLast?: boolean;
  id?: string;
  useReactRoutingLinks: boolean;
}

const ImageHtmlLinkStructure: FC<ImageHtmlLinkStructureProps> = ({
  content,
  linkPosition,
  compactHeader,
  handlePromoView,
  isLast,
  id,
  useReactRoutingLinks,
}) => (
  <StyledImageHtmlLinkWrapper $isLast={isLast}>
    <InView threshold={0.7} onChange={(isVisible) => handlePromoView(isVisible, content)}>
      <StyledImageHtmlLink
        data-hookid="globalHeaderSubMenuImageHtmlLink"
        $compactHeader={compactHeader}
      >
        {!linkPosition || linkPosition === 'center' ? (
          <>
            {content?.link?.url ? (
              <>
                {useReactRoutingLinks ? (
                  <Link href={content.link.url} passHref legacyBehavior>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <a
                      id={id}
                      {...(content.link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                      onClick={() =>
                        formatPromoEvent(
                          content.link?.text,
                          1,
                          content.link?.url,
                          content.image.desktop.src,
                          'meganav',
                          'click',
                        )}
                    >
                      <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
                    </a>
                  </Link>
                ) : (
                  <a
                    id={id}
                    href={content.link.url}
                    {...(content.link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                    onClick={() =>
                      formatPromoEvent(
                        content.link?.text,
                        1,
                        content.link?.url,
                        content.image.desktop.src,
                        'meganav',
                        'click',
                      )}
                  >
                    <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
                  </a>
                )}
              </>
            ) : (
              <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
            )}
          </>
        ) : (
          <StyledImageHtmlLinkClickableAdWrapper>
            {content?.link?.url ? (
              <>
                {useReactRoutingLinks ? (
                  <Link href={content.link.url} passHref legacyBehavior>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      id={id}
                      {...(content.link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                    >
                      <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
                      {content?.subtitle && <h4>{content.subtitle}</h4>}
                      {content.link?.text && <p>{content.link.text}</p>}
                    </a>
                  </Link>
                ) : (
                  <a
                    id={id}
                    href={content.link.url}
                    {...(content.link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                  >
                    <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
                    {content?.subtitle && <h4>{content.subtitle}</h4>}
                    {content.link?.text && <p>{content.link.text}</p>}
                  </a>
                )}
              </>
            ) : (
              <>
                <MediaElement {...parseCMSMediaProp(content?.image)} imageType="intrinsic" />
                {content?.subtitle && <h4>{content.subtitle}</h4>}
                {content?.link?.text && <p>{content.link.text}</p>}
              </>
            )}
          </StyledImageHtmlLinkClickableAdWrapper>
        )}
      </StyledImageHtmlLink>
    </InView>
  </StyledImageHtmlLinkWrapper>
);

export default memo(ImageHtmlLinkStructure);
