import { ImageLoader } from 'next/image';

import { getOriginFromUrl } from '../../getOriginFromUrl';

/**
 * function for generating optimized image src
 * @param serviceUrl string
 * @returns ImageLoader
 */
const imageLoader = (serviceUrl: string): ImageLoader => 
  ({ src, width, quality }) => {
    let tempURL = '';
    if (src?.includes(getOriginFromUrl(process.env.NEXT_PUBLIC_IMAGE_OPTIMISATION_URL) as string)) {
      tempURL =  src.includes('?') ? `${src}&w=${width}` : `${src}?w=${width}`;
    } else {
      tempURL = `${serviceUrl}?src=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;
    }
    return tempURL;
  }

/**
 * function for getting image loader
 * @param src string|undefined
 * @returns object with loader function if conditions are true, empty object if it's not
 */
const getImageLoader = (src?: string): { loader?: ImageLoader } => {
  if (
    process.env.NEXT_PUBLIC_IMAGE_OPTIMISATION_URL
    && src
    && !src.includes('.gif')
  ) {
    return { loader: imageLoader(process.env.NEXT_PUBLIC_IMAGE_OPTIMISATION_URL) };
  }

  return {};
};

export const normaliseImageSrc = (src: string | null | undefined): string => {
  if (!src) { return ''; }
  if (src.startsWith('//')) {
    return `https:${src}`;
  }
  return src;
}

export default getImageLoader;
