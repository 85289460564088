import React, { FC, ReactNode } from 'react';

import LinkItem from '../../basic/LinkItem';
import MobileMenuLinkStructure from './MobileMenuLinkStructure';

interface MobileMenuLinkProps {
  label: string;
  href?: string;
  onClick?: () => void;
  children?: ReactNode;
  compactHeader?: boolean;
}

const MobileMenuLink: FC<MobileMenuLinkProps> = ({
  label,
  href,
  children,
  onClick = () => {},
  compactHeader,
}) => {
  let element;
  if (href) {
    element = <LinkItem href={href}>{label}</LinkItem>;
  } else {
    element = (
      <button type="button" onClick={onClick}>
        {label}
      </button>
    );
  }

  const componentProps = { children, compactHeader, element };

  return <MobileMenuLinkStructure {...componentProps} />;
};

export default MobileMenuLink;
