import React, { FC } from 'react';

import LinkItem from '../../basic/LinkItem';
import { HtmlLink } from '../../types/middleware-types';
import { formate2eId } from '../../utils/formatters/formate2eFooterAccordionId';
import { StyledAccordionItemContainer, StyledFooterLinkList } from './AccordionItemStyles';

interface Props {
  links?: HtmlLink[];
  removeItemLineNumberFromLocalStorage: () => void;
  isHeader?: boolean;
  isOpen?: boolean;
}

export const AccordionItemStructure: FC<Props> = ({
  links,
  isHeader,
  isOpen,
  removeItemLineNumberFromLocalStorage,
}) => {
  return (
    <StyledAccordionItemContainer
      data-hookid="globalFooterAccordionItem"
      className="footerAccordionItem"
    >
      <StyledFooterLinkList $itemOpen={isOpen}>
        {links?.map((link) => (
          <li
            key={`link-${link.url}-${link.text}`}
            data-e2eid={`footer${formate2eId(link.text)}`}
            data-hookid={
              isHeader
                ? `globalHeader${formate2eId(link.text)}`
                : `globalFooter${formate2eId(link.text)}`
            }
          >
            <LinkItem
              formateUrl={false}
              onClick={() => removeItemLineNumberFromLocalStorage()}
              newWindow={link.newWindow}
              href={link.url}
            >
              {link.text}
            </LinkItem>
          </li>
        ))}
      </StyledFooterLinkList>
    </StyledAccordionItemContainer>
  );
};

export default AccordionItemStructure;
