/* eslint-disable */

import React, { FC, memo, Ref } from 'react';

import {
  HeaderNavigation,
  Maybe,
  PromoBanner as PromoBannerType,
} from '../../../types/middleware-types';
import Account from '../../Account';
import BrandLogo from '../../BrandLogo';
import ContinuityBar from '../../ContinuityBar';
import MiniBag from '../../Minibag';
import Search from '../../Search';
import SkipLink from '../../SkipLink';
import {
  StyledDesktopNav,
  StyledMenu,
  StyledMobileNav,
  StyledMobileUtils,
  StyledOverlay,
  StyledSecondarySection,
  StyledTransparentHeader,
  StyledUtilitiesMenu,
} from './TransparentHeaderCompactReverseStyle';
import PromoBanner from '../../PromoBanner';
import CountryPicker from '../../CountryPicker';
import MainMenu from '../../MainMenu';
import MobileMenu from '../../MobileMenu';
import SiteSwitcher from '../../SiteSwitcher';

export interface TransparentHeaderCompactReverseStructureProps {
  toggleSearch: (value?: boolean) => void;
  searchOpen: boolean;
  headerNavigation?: Maybe<HeaderNavigation>;
  headerTheme: string;
  headerBackground: string;
  showSiteSwitcher: boolean;
  getElement: Ref<HTMLDivElement>;
  promoBanner?: Maybe<PromoBannerType>;
  megaNavOpen: boolean;
  finalTopPositionMob: number;
  finalTopPositionDesk: number;
  domain: string;
  showDesktopLayout: boolean | undefined;
  headerPosition: string;
  isAtTop: boolean;
  showContinuityBar: boolean;
  isHomePage: boolean;
  showHomepagePromobanner: boolean;
  isScrolling: boolean;
  pageName: string;
}

const TransparentHeaderCompactReverseStructure: FC<TransparentHeaderCompactReverseStructureProps> = ({
  toggleSearch,
  searchOpen,
  headerNavigation,
  headerTheme,
  headerBackground,
  showSiteSwitcher,
  getElement,
  promoBanner,
  megaNavOpen,
  finalTopPositionMob,
  finalTopPositionDesk,
  domain,
  showDesktopLayout,
  headerPosition,
  isAtTop,
  showContinuityBar,
  isHomePage,
  showHomepagePromobanner,
  isScrolling,
  pageName
}) => (
  // <div>TEST</div>
  <>
    {showSiteSwitcher && domain && (
      <SiteSwitcher
        domain={domain}
      />
    )}

    {!showContinuityBar && (!isHomePage || showHomepagePromobanner) && pageName !== 'M2' && promoBanner && Object.keys(promoBanner).length !== 0 && (
      <PromoBanner data={promoBanner} isReverse={true} />
    )}
    {showContinuityBar && promoBanner && Object.keys(promoBanner).length !== 0 && (
      <ContinuityBar data={promoBanner} isReverse={true} />
    )}
    <SkipLink target="#mainnavigation" label="skip to navigation" />
    <StyledTransparentHeader
      id="header"
      data-hookid="globalHeader"
      ref={getElement}
      $headerTheme={headerTheme}
      $headerBackground={headerBackground}
      $megaNavOpen={megaNavOpen}
      $isAtTop={isAtTop}
      $finalTopPositionMob={finalTopPositionMob}
      $finalTopPositionDesk={finalTopPositionDesk}
      $headerPosition={headerPosition}
      $isScrolling={isScrolling}
    >
      {/* Desktop nav */}
      {showDesktopLayout && (
        <StyledDesktopNav>
          <StyledUtilitiesMenu $searchOpen={searchOpen} data-hookid="desktopGlobalHeader">
            <StyledMenu>
              <CountryPicker />
            </StyledMenu>
            {headerNavigation?.desktopMenu && (
              <MainMenu data={headerNavigation?.desktopMenu} />
            )}
            <StyledMenu>
              <Search landingSearch={false} idName="search" />
              <Account />
              <MiniBag />
            </StyledMenu>
          </StyledUtilitiesMenu>
          <BrandLogo isHomePage={isHomePage} />
          <StyledOverlay
            data-hookid="desktopGlobalHeaderSearchOverlay"
            $searchOpen={searchOpen}
            onClick={() => toggleSearch(false)}
          />
        </StyledDesktopNav>
      )}

      {/* Mobile nav */}
      {!showDesktopLayout && (
        <StyledMobileNav $megaNavOpen={megaNavOpen}>
          <StyledMobileUtils data-hookid="mobileGlobalHeader">
            <StyledSecondarySection data-hookid="mobileHeaderSecondarySectionCompactHeader">
              <MobileMenu
                data={headerNavigation?.mobileMenu}
                layout="MobileMenuCompactReverse"
                finalTopPositionMob={finalTopPositionMob}
              />
              <Search landingSearch={true} idName="mob-search" />
            </StyledSecondarySection>
            <BrandLogo />
            <StyledSecondarySection data-hookid="mobileHeaderSecondarySectionCompactHeader">
              <Account mobileHeader={true} />
              <MiniBag />
            </StyledSecondarySection>
          </StyledMobileUtils>
        </StyledMobileNav>
      )}
    </StyledTransparentHeader>
  </>
);

export default memo(TransparentHeaderCompactReverseStructure);
