import React, { FC, ReactElement } from 'react';

import { ImageV2, NewsletterSignup } from '../../types/middleware-types';
import FormPopup from './FormPopup';
import SignUpPopup from './SignUpPopup';

export interface WelcomeProgramStructureProps {
  thanks?: string;
  image?: ImageV2;
  linkTrigger?: string;
  newsletterSignup: NewsletterSignup | null;
  closeButtonClicked: () => void;
  signUpButtonClicked: () => void;
  showFormPopup: boolean;
  showSignUpPopup: boolean;
  localStorageKey: string;
}

const WelcomeProgramStructure: FC<WelcomeProgramStructureProps> = ({
  thanks,
  image,
  linkTrigger,
  newsletterSignup,
  closeButtonClicked,
  signUpButtonClicked,
  showFormPopup,
  showSignUpPopup,
  localStorageKey
}): ReactElement => {
  return (
    <>
      {showSignUpPopup && (
        <SignUpPopup
          {...{ linkTrigger, showSignUpPopup, signUpButtonClicked, closeButtonClicked }}
        />
      )}
      {showFormPopup && newsletterSignup && (
        <FormPopup
          {...{
            thanks,
            image,
            newsletterSignup,
            localStorageKey,
            closeButtonClicked,
          }}
        />
      )}
    </>
  );
};

export default WelcomeProgramStructure;
