'use client'

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobileSSR } from '../../../features/device';

const useMobileWatcher = (
  breakpoints: Array<string>,
  systemBreakpoints: { tabletBp: number; desktopBp: number },
): {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
} => {
  const isMobileSSR = useSelector(selectIsMobileSSR);
  const [currentWidth, setCurrentWidth] = useState(0)

  const [isMobile, setIsMobile] = useState<boolean | undefined>(!!isMobileSSR);
  const [isTablet, setIsTablet] = useState<boolean | undefined>(false);
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(!isMobileSSR);

  // on screen resize check if device is mobile, desktop or tablet
  useEffect(() => {
    const checkIsMobile = (): void => {
      if (currentWidth !== window.innerWidth) {
        setCurrentWidth(window.innerWidth)

        let isMobileDevice;
        let isTabletDevice;
        let isDesktopDevice;
        if (breakpoints.length > 0) {
          if (breakpoints.includes('mobile')) {
            isMobileDevice = window.innerWidth < systemBreakpoints.tabletBp;
          }
          if (breakpoints.includes('tablet')) {
            isTabletDevice =
              window.innerWidth >= systemBreakpoints.tabletBp &&
              window.innerWidth < systemBreakpoints.desktopBp;
          }
          if (breakpoints.includes('desktop')) {
            isDesktopDevice = window.innerWidth >= systemBreakpoints.desktopBp;
          }
        }
        setIsMobile(isMobileDevice);
        setIsTablet(isTabletDevice);
        setIsDesktop(isDesktopDevice);
      }
    };
    window.addEventListener('resize', checkIsMobile);
    checkIsMobile();
    return () => window.removeEventListener('resize', checkIsMobile);
  }, [breakpoints, systemBreakpoints]);

  return { isMobile, isTablet, isDesktop };
};

export default useMobileWatcher;
