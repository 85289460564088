import React, { ElementType, FC, KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronDown, faChevronUp, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import DOMPurify from 'isomorphic-dompurify';

import { selectCurrentOpen, updateCurrentOpen } from '../../features/accordion';
import { selectIsCompactHeader, selectPageName, selectToggles } from '../../features/static';
import { AccordionValues, CustomMedia } from '../../types/custom-types';
import { HtmlLink, Job, Maybe, StoreGroup, SubMenuLinkList } from '../../types/middleware-types';
import { isNextMediaList } from '../../types/type-checkers';
import cleanHtml from '../../utils/formatters/cleanHtml';
import { Media50SplitAccordionDataType } from '../CMSModules/MediaAndText50PercentSplit/MeadiaAndText50PercentSplitAccordion';
import { ProductInfoData } from '../Product/ProductInformation/ProductInformation.types';
import AccordionStructure from './AccordionStructure';

type AccordionDataType =
  | {
      title: string;
      itemComponent?: ElementType;
      isOpen?: boolean;
      sizes?: HtmlLink[];
      storesData?: StoreGroup[] | Job[];
    }[]
  | SubMenuLinkList[]
  | ProductInfoData[]
  | Media50SplitAccordionDataType[];

export interface Props {
  title?: string;
  children?: ReactNode;
  data?: AccordionDataType;
  openAllOnClick?: boolean;
  allowMultipleOpen?: boolean;
  openByDefault?: string;
  toggleOnHover?: boolean;
  component?: ElementType | null;
  titleComponent?: ElementType | null;
  openByDefaultOnMobile?: boolean;
  plusIcon?: any;
  minusIcon?: any;
  pageName?: string;
  isHeader?: boolean;
  titleImage?: Maybe<CustomMedia>;
  text?: Maybe<string>;
  showCareersPageAccordions?: boolean;
  dataHookId?: string
}

const Accordion: FC<Props> = ({
  title = '',
  children,
  data = [],
  openAllOnClick,
  component,
  titleComponent,
  allowMultipleOpen = true,
  openByDefault,
  toggleOnHover,
  openByDefaultOnMobile,
  titleImage,
  text,
  plusIcon,
  minusIcon,
  isHeader,
  showCareersPageAccordions,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const toggles = useSelector(selectToggles);
  const accordionData = useSelector(selectCurrentOpen);
  const isCompactHeader = useSelector(selectIsCompactHeader);
  const titleImageSrc = isNextMediaList(titleImage?.mediaList)
    ? titleImage?.mediaList?.mobile?.src
    : '';
  const titleImageElement = `<img alt="${titleImage?.alt}" src="${titleImageSrc}">`;

  const accordionTitle = titleImage ? titleImageElement : title;

  const initialActiveAccordion = text ? [{ title: accordionTitle, isOpen: false }] : [];
  const [activeAccordion, setActiveAccordion] = useState<AccordionValues[]>(initialActiveAccordion);
  const [finalData, setFinalData] = useState<
    AccordionValues[] | SubMenuLinkList[] | ProductInfoData[]
  >([]);
  const pageName = useSelector(selectPageName);

  const allowMultipleHandler = (
    acc: AccordionValues,
    items: AccordionValues[],
    selectedTitle: string,
  ) => {
    // find an element and open or close it
    if (DOMPurify.sanitize(acc.title) === DOMPurify.sanitize(selectedTitle)) {
      const arr = items.map((item: AccordionValues) =>
        DOMPurify.sanitize(item.title) === DOMPurify.sanitize(selectedTitle)
          ? { ...item, isOpen: !item.isOpen }
          : item,
      );
      setActiveAccordion(arr);
    }
  };

  const toggleAccordion = (selectedTitle: string) => {
    const items = activeAccordion;
    const titleDropdown = titleImage || text;

    // Case 1 - TitleDropdown
    if (titleDropdown) {
      setActiveAccordion([{ title: selectedTitle, isOpen: !activeAccordion[0].isOpen }]);
    }
    // Case 2 - All open at once
    if (openAllOnClick) {
      const arr = items.map((accordion: AccordionValues) => ({
        ...accordion,
        isOpen: !accordion.isOpen,
      }));
      setActiveAccordion(arr);
      // Case 3 - Multiple open at once
    } else if (allowMultipleOpen) {
      activeAccordion.forEach((acc: AccordionValues) => {
        allowMultipleHandler(acc, items, selectedTitle);
      });
    }
    // Case 4 - Only one open at once, others closed
    if (!allowMultipleOpen) {
      const arr = items.map((item: AccordionValues) => {
        const titlesMatch = item.title === selectedTitle;
        if (item.isOpen) {
          return { ...item, isOpen: false };
        }
        if (titlesMatch) {
          dispatch(updateCurrentOpen(item.title));
          return { ...item, isOpen: true };
        }
        return item;
      });
      setActiveAccordion(arr);
    }
  };

  const onMouseEnter = () => {
    dispatch(updateCurrentOpen(title));
    setActiveAccordion([{ title: DOMPurify.sanitize(title), isOpen: true }]);
  };

  const onMouseLeave = () => {
    dispatch(updateCurrentOpen(null));
    setActiveAccordion([{ title: DOMPurify.sanitize(title), isOpen: false }]);
  };

  const onEnterKeyPressed = (e: KeyboardEvent<HTMLButtonElement>, selectedTitle: string) => {
    if (e.key === 'Enter' && toggleOnHover) {
      e.preventDefault();
      toggleAccordion(selectedTitle);
    }
  };

  useEffect(() => {
    if (data && data.length > 0 && finalData.length === 0) {
      setFinalData(data);
    }
  }, [data, finalData]);

  useEffect(() => {
    if (activeAccordion[0]?.title !== accordionData && activeAccordion[0]?.isOpen) {
      const arr = activeAccordion.map((item) => {
        return { ...item, isOpen: false };
      });
      setActiveAccordion(arr);
    }
  }, [accordionData]);

  useEffect(() => {
    if (finalData.length > 0) {
      const arr: AccordionValues[] = [];
      finalData.forEach((item: AccordionValues | SubMenuLinkList | ProductInfoData) => {
        arr.push({
          title: DOMPurify.sanitize(item.title),
          isOpen: item.openByDefaultOnMobile === true ? true : openByDefault === item.title,
        });
      });
      setActiveAccordion(arr);
    } else if (children) {
      setActiveAccordion([
        {
          title: DOMPurify.sanitize(title),
          isOpen:
            openByDefault === title ||
            accordionData?.replace(/\d/g, '') === title?.replace(/\d/g, ''),
        },
      ]);
    }
  }, [children, openByDefault, finalData]);

  useEffect(() => {
    if (data.length > 0 && !allowMultipleOpen) {
      const arr: AccordionValues[] = [];
      data.forEach((item: AccordionValues | SubMenuLinkList) => {
        arr.push({ title: DOMPurify.sanitize(item.title), isOpen: openByDefaultOnMobile });
      });
      setActiveAccordion(arr);
    }
  }, [openByDefaultOnMobile, allowMultipleOpen]);

  let newData = data;
  if (newData && component) {
    newData = newData.map((item: AccordionValues | SubMenuLinkList) => ({
      ...item,
      itemComponent: component,
    }));
  }

  const finalPlusIcon = toggles?.kgRedesignAssortedChanges ? faChevronDown : faPlus;
  const finalMinusIcon = toggles?.kgRedesignAssortedChanges ? faChevronUp : faMinus;

  const componentProps = {
    componentName: 'Accordion',
    title: cleanHtml(accordionTitle),
    children,
    // TODO: Fix typing to remove this any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: newData as any,
    titleComponent,
    allowMultipleOpen,
    pageName: pageName || 'unknown',
    toggleAccordion,
    toggleOnHover,
    activeAccordion,
    onMouseEnter,
    onMouseLeave,
    onEnterKeyPressed,
    text,
    toggles,
    plusIcon: finalPlusIcon,
    minusIcon: finalMinusIcon,
    colourTriggerRed: !!isHeader && !isCompactHeader && ['clearance'].includes(title.toLowerCase()),
    isHeader,
    showCareersPageAccordions,
    ...otherProps,
  };

  return <AccordionStructure {...componentProps} />;
};

export default Accordion;
