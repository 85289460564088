import styled from 'styled-components';

import { StyledLink } from '../../../basic/LinkItem/LinkItemStyles';
import MediaElement from '../../../basic/MediaElement';
import { Maybe } from '../../../types/middleware-types';
import { StyledCountdownStructure } from '../../Countdown/CountdownStyles';

interface PromoBannerItemContainerProps {
  $height: number;
  $backgroundColourHex: Maybe<string> | undefined;
  $textAlignment?: string;
  $fontColourHex: Maybe<string> | undefined;
  $fontSize: Maybe<string> | undefined;
  borderConfig?: {
    height?: string;
    colour?: string;
  };
}
export const PromoBannerItemContainerLink = styled.a<PromoBannerItemContainerProps>`
  height: ${({ $height }) => $height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  line-height: 1.3;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 400;
  background: ${({ $backgroundColourHex, theme }) => $backgroundColourHex || theme.vars.white};
  text-align: ${({ $textAlignment }) => $textAlignment};
  position: relative;
  flex-grow: 1;
  z-index: 1;
  flex-basis: 0;

  p,
  a,
  span {
    color: ${({ $fontColourHex }) => $fontColourHex};
    font-size: ${({ $fontSize }) => $fontSize};
  }

  strong {
    font-family: ${({ theme }) => theme.header.bannerLink.fontFamily};
  }

  a {
    font-weight: normal;
    text-decoration: ${({ theme }) => theme.header.bannerLink.textDecoration};
  }

  &::before {
    content: "";
    display: ${({ borderConfig }) => (borderConfig ? 'block' : 'none')};
    height: ${({ borderConfig }) =>
  borderConfig && borderConfig.height === 'partial' ? '50%' : '100%'};
    width: 2px;
    background: ${({ borderConfig }) => borderConfig && borderConfig.colour};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    @media ${({ theme }) => theme.devices.smallOnly} {
      display: none;
    }
  }

  .cta-container {
    width: 100%;
    text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};

    .cta {
      text-decoration: underline;
      display: inline-block;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }

    ${StyledLink} {
      &::after {
        content: "";
        display: none;
      }
    }
  }

  .text {
    text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};
    width: 100%;
  }

  ${StyledCountdownStructure} {
    justify-content: ${({ $textAlignment }) => {
    if ($textAlignment === 'left') {
      return 'flex-start';
    }
    if ($textAlignment === 'right') {
      return 'flex-end';
    }
    return 'center';
  }};
  }
`;

export const PromoBannerItemContainer = styled.div<PromoBannerItemContainerProps>`
  height: ${({ $height }) => $height ?? 50}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  line-height: 1.3;
  box-sizing: border-box;
  text-decoration: none;
  background: ${({ $backgroundColourHex }) => $backgroundColourHex};
  text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};
  position: relative;
  flex-grow: 1;
  z-index: 1;
  flex-basis: 0;

  p,
  a,
  span {
    color: ${({ $fontColourHex }) => $fontColourHex};
    font-size: ${({ $fontSize }) => $fontSize};
  }

  &::before {
    content: "";
    display: ${({ borderConfig }) => (borderConfig ? 'block' : 'none')};
    height: ${({ borderConfig }) =>
  borderConfig && borderConfig.height === 'partial' ? '50%' : '100%'};
    width: 2px;
    background: ${({ borderConfig }) => borderConfig && borderConfig.colour};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    @media ${({ theme }) => theme.devices.smallOnly} {
      display: none;
    }
  }

  .cta-container {
    width: 100%;
    text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};

    .cta {
      text-decoration: underline;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }

    ${StyledLink} {
      &::after {
        content: "";
        display: none;
      }
    }
  }

  .text {
    text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};
    width: 100%;
  }

  ${StyledCountdownStructure} {
    justify-content: ${({ $textAlignment }) => {
    if ($textAlignment === 'left') {
      return 'flex-start';
    }
    if ($textAlignment === 'right') {
      return 'flex-end';
    }
    return 'center';
  }};
  }
`;

interface StyledPromoBannerItemSeparatorProps {
  $fontColourHex?: Maybe<string>;
}
export const StyledPromoBannerItemSeparator = styled.div<StyledPromoBannerItemSeparatorProps>`
  position: absolute;
  height: 80%;
  width: 0.2rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: ${({ $fontColourHex }) => $fontColourHex};
`;

export const StyledPromoBannerBackgroundImage = styled(MediaElement)`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  @media ${({ theme }) => theme.devices.medium} {
    width: auto;
  }
`;
