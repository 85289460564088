/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';

import { ImageHtmlLink, Maybe, SubMenuItem } from '../../../types/middleware-types';
import SubNavColumn from '../SubNavColumn';
import {
  BackToMainMenuIcon,
  StyledBackToMainMenuWrapper,
  SubMenuContainer,
  SubmenuImagesContainer,
  SubmenuLinksColumn,
} from './SubMenuCompactStyle';
import { SubMenuCompactProps } from '.'

export interface SubMenuCompactStructureProps extends SubMenuCompactProps {
  submenuLinks: Array<SubMenuItem>;
  submenuImages: Maybe<ImageHtmlLink>[] | Array<SubMenuItem> | undefined;
  maxHeight: number;
  positionLeft?: number;
  stickyHeader: boolean;
  showSingleColumnSubNav?: boolean;
}

const SubMenuCompactStructure: FC<SubMenuCompactStructureProps> = ({
  showSubNav,
  parent,
  submenuLinks,
  submenuImages,
  isDesktop,
  closeSubmenu,
  promoBannerHeight,
  finalTopPositionMob,
  headerHeight,
  maxHeight,
  positionLeft,
  stickyHeader,
  showSingleColumnSubNav,
}) => {
  return (
    <SubMenuContainer
      $showSubNav={showSubNav}
      $isDesktop={isDesktop}
      $promoBannerHeight={promoBannerHeight}
      data-hookid="globalHeaderSubMenu"
      $finalTopPositionMob={finalTopPositionMob}
      $stickyHeader={stickyHeader}
      $headerHeight={headerHeight}
      $maxHeight={maxHeight}
    >
      {!isDesktop && (
        <StyledBackToMainMenuWrapper onClick={closeSubmenu}>
          <BackToMainMenuIcon icon={faAngleLeft as IconProp} />
          <p>back to main menu</p>
        </StyledBackToMainMenuWrapper>
      )}

      <SubmenuLinksColumn $positionLeft={positionLeft} $isDesktop={isDesktop} $showSingleColumnSubNav={showSingleColumnSubNav}>
        {submenuLinks.map((column, index) => (
          <SubNavColumn data={column} parent={parent || ''} key={index} />
        ))}
      </SubmenuLinksColumn>

      <SubmenuImagesContainer>
        {submenuImages && submenuImages.map((column: SubMenuItem | Maybe<ImageHtmlLink>, index: number) => (
          <SubNavColumn data={column} parent={parent || ''} linkPosition="bottom" key={index} />
        ))}
      </SubmenuImagesContainer>
    </SubMenuContainer>
  );
};

export default SubMenuCompactStructure;
