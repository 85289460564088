import React, { FC, ReactElement } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../basic/Buttons';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import { StyledPopupXButton } from '../WelcomePopupStyles';
import { StyledSignUpPopupTrigger } from './SignUpPopupStyles';

interface SignUpPopupStructureProps {
  showSignUpPopup: boolean;
  linkTrigger: string | undefined;
  signUpButtonClicked: () => void;
  closeButtonClicked: () => void;
}

const SignUpPopupStructure: FC<SignUpPopupStructureProps> = ({
  showSignUpPopup,
  linkTrigger,
  signUpButtonClicked,
  closeButtonClicked,
}): ReactElement => (
  <StyledSignUpPopupTrigger $showSignUpPopup={showSignUpPopup}>
    <h3>{linkTrigger}</h3>
    <Button
      data-hookid="WelcomePopupSignUp"
      btnType="primary"
      type="button"
      onClick={signUpButtonClicked}
    >
      SIGN UP
    </Button>
    <StyledPopupXButton onClick={closeButtonClicked} data-hookid="WelcomePopupClose" type="button">
      <FontAwesomeIcon icon={faTimes as IconProp} />
      <ScreenReaderOnlyText text='Close popup' />
    </StyledPopupXButton>
  </StyledSignUpPopupTrigger>
);

export default SignUpPopupStructure;
