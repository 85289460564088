import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { SourceLocation } from 'graphql';
import { sha256 } from 'js-sha256';
import { ParsedUrlQuery } from "querystring";
import Cookies from 'universal-cookie';

import { cookieHeadersLink } from "./apolloLinks/cookieHeaders";
import { noRateLimitsLink } from "./apolloLinks/noRateLimits";
import { previewModeLink, PreviewModeSettings } from "./apolloLinks/previewMode";

type InitialState = NormalizedCacheObject & { query?: ParsedUrlQuery };

let apolloClient: ApolloClient<Record<string, string> | NormalizedCacheObject>;

type SendGraphQLErrorNewRelicType = {
  message: string;
  operationName: string;
  variables: Record<string, any>;
  path?: readonly (string | number)[];
  locations?: ReadonlyArray<SourceLocation>;
};

const sendGraphQLErrorNewRelic = ({
  message,
  operationName,
  variables,
  path,
  locations,
}: SendGraphQLErrorNewRelicType) => {
  if (typeof newrelic === 'object' && typeof window !== 'undefined') {
    newrelic.noticeError(message, {
      errorType: 'GraphQL Error',
      operationName,
      variables: JSON.stringify(variables),
      location: `${path}`,
    });
  }
  // eslint-disable-next-line no-console
  console.error(
    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
  );
};

type SendNetworkErrorNewRelicType = {
  networkError: Error | ServerParseError | ServerError;
  operationName: string;
  variables: Record<string, any>;
};

const sendNetworkErrorNewRelic = ({
  networkError,
  operationName,
  variables,
}: SendNetworkErrorNewRelicType) => {
  // eslint-disable-next-line no-console
  console.error(`[Network error]: ${networkError}`);
  if (typeof newrelic === 'object' && typeof window !== 'undefined') {
    newrelic.noticeError(networkError, {
      errorType: 'Network Error',
      operationName,
      variables: JSON.stringify(variables),
    });
  }
};

const createApolloClient = (clientUrl: string, persistedQueries: boolean, previewSettings?: PreviewModeSettings) => {
  const httpLink = new HttpLink({
    uri: clientUrl,
    headers: {
      siteId: process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID ?? '',
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const { operationName, variables } = operation;

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        sendGraphQLErrorNewRelic({ message, operationName, variables, path, locations });
        return { message, locations, path };

      });
    }

    if (networkError) {
      sendNetworkErrorNewRelic({ networkError, operationName, variables });
    }

    return forward(operation);
  });

  const links: ApolloLink[] = [errorLink, noRateLimitsLink()];

  if (previewSettings) {
    links.push(previewModeLink(previewSettings));
  }

  if (typeof window !== 'undefined') {
    links.push(cookieHeadersLink(new Cookies()));
  }

  if (persistedQueries) {
    const persistedQueriesLink = createPersistedQueryLink({
      useGETForHashedQueries: true,
      sha256,
    });
    // TODO: Look into https://www.apollographql.com/docs/react/api/link/persisted-queries/#build-time-generation
    links.push(persistedQueriesLink);
  }

  // Temporary additional logs to help understand the mass Minibag requests
  links.push(new ApolloLink((operation, forward) => {
    if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_IS_LOCAL === 'false') {
      if (typeof newrelic === 'object' && typeof window !== 'undefined') {
        newrelic.addPageAction('GraphQLRequest', {
          operationName: operation.operationName,
          variables: JSON.stringify(operation.variables),
          headers: JSON.stringify(operation.getContext().headers),
          pageUrl: window.location.href,
        });
      }
    }

    return forward(operation);
  }));

  return new ApolloClient({
    name: `${process.env.NEXT_PUBLIC_PROJECT_NAME}-${process.env.NEXT_PUBLIC_REPOSITORY_NAME}`,
    version: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
    cache: new InMemoryCache(),
    link: from([...links, httpLink]),
    ssrMode: typeof window === 'undefined',
  });
};

export const initializeApollo = (
  initialState: InitialState,
  clientUrl: string,
  persistedQueries: boolean,
): ApolloClient<NormalizedCacheObject> => {
  // Get preview settings from query params
  let clientSearch: URLSearchParams = new URLSearchParams();
  if (typeof window !== 'undefined') {
    clientSearch = new URLSearchParams(window.location.search);
  }

  const cmsPreviewSiteHandle = initialState?.query?.cmsPreviewSiteHandle || clientSearch.get('cmsPreviewSiteHandle');
  const cmsPreviewToken = initialState?.query?.token || clientSearch.get('token');
  const cmsPreviewPageId = initialState?.query?.cmsPreviewPageId || clientSearch.get('cmsPreviewPageId');

  let previewSettings: PreviewModeSettings | undefined;
  if (cmsPreviewSiteHandle && cmsPreviewToken && cmsPreviewPageId) {
    previewSettings = { cmsPreviewSiteHandle, cmsPreviewToken, cmsPreviewPageId };
  }

  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = createApolloClient(clientUrl, persistedQueries, previewSettings);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const getApolloClientConfig = (useGraphCDN = true): {
  apolloClientUrl: string;
  apolloPersistedQueries: boolean;
} => {
  const apolloClientUrl: string = (useGraphCDN
    ? process.env.NEXT_PUBLIC_GRAPHCDN_ENDPOINT
    : process.env.NEXT_PUBLIC_MIDDLEWARE_ENDPOINT) as string;
  const apolloPersistedQueries: boolean = (useGraphCDN
    ? process.env.NEXT_PUBLIC_GRAPHCDN_APOLLO_CLIENT_PERSISTED_QUERIES
    : process.env.NEXT_PUBLIC_APOLLO_CLIENT_PERSISTED_QUERIES) === 'true';

  return {
    apolloClientUrl,
    apolloPersistedQueries,
  };
};

export const useApollo = (
  initialState: InitialState,
  clientUrl: string,
  persistedQueries: boolean,
): ApolloClient<NormalizedCacheObject> => {
  return useMemo(
    () => initializeApollo(initialState, clientUrl, persistedQueries),
    [initialState],
  );
};
