import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { useRouter } from 'next/navigation';
import Cookies from 'universal-cookie';

import { selectToggles } from '../../../features/static';
import SendSplunkReport from '../../senders/SendSplunkReport';

interface CustomParams {
  action_location?: string;
  action_value_id?: string;
  action_value_qty?: number;
  action_value_price?: string;
  action_value_urlkey?: string;
  action_type?: string;
  action_error?: string;
  action_error_reason?: string;
  shopping_cart_id?: string;
  referrer?: string | false;
}

interface UseErrorReturnType {
  sendToSplunk: (customParams?: CustomParams) => Promise<void>;
}

const useError = (
  error?: boolean | ApolloError,
  pushTo404 = false,
  params = {},
  oosError = false,
  pushToOos = false,
  ldToggles: LDFlagSet = {},
): UseErrorReturnType => {
  const router = useRouter();
  const contextLdToggles = useSelector(selectToggles);
  const toggles = ldToggles || contextLdToggles;

  const cookie = new Cookies();
  const shoppingCartId = cookie.get('shopping_cart_id');

  const fullParams = {
    ...params,
    shopping_cart_id: shoppingCartId,
    referrer: typeof window !== 'undefined' && document?.referrer,
  };

  const sendToSplunk = async (customParams?: CustomParams) => {
    if (typeof window === 'undefined') {
      return;
    }

    if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_IS_LOCAL === 'false') {
      // Send splunk report with relevant details
      await SendSplunkReport({ ...fullParams, ...customParams });
    }
  };

  // push to 404 in production build
  const goTo404 = () => {
    if (process.env.NODE_ENV !== 'production' || typeof window === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('Error, will push to 404 in production');
      return;
    }
    if (pushTo404 && router) {
      router.push('/404');
    }
  };

  // push to oos in production build
  const goToOos = () => {
    if (process.env.NODE_ENV !== 'production' || typeof window === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('Error, will push to oos in production');
      return;
    }

    if (pushToOos && router) {
      router.push('/out-of-stock');
    }
  };

  useEffect(() => {
    if (error) {
      sendToSplunk();
      goTo404();
    }
  }, [error]);

  useEffect(() => {
    if (oosError && toggles?.featurePushToOutOfStock) {
      goToOos();
    }
  }, [oosError, toggles]);

  return { sendToSplunk };
};

export default useError;
