'use client'

import React, { FC } from 'react';
import { set } from 'local-storage';
import { usePathname } from 'next/navigation';

import { BasicCarouselProps } from '../../../types/custom-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../utils/customHooks/useAnimationOnScroll';
import CarouselItemListStructure from './CarouselItemListStructure';

const CarouselListItem: FC<BasicCarouselProps> = ({
  items,
  settings,
  component,
  otherProps,
  animationType,
  animate,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  scrollableOnDesktop,
  setCarouselTitle,
  getCarouselInstance = () => { },
  campaign,
  slick,
}) => {
  const path = usePathname();

  // For GTM PDP datalayer.
  const addProductPositionToLocalStorage = (productPosition: number, lineNumber: string): void => {
    set('previous_product_position', {
      position: productPosition.toString(),
      location: `product - ${setCarouselTitle || 'recommended products'}`,
      pageUrl: path,
      lineNum: lineNumber,
      campaignId: campaign,
    });
  };

  const componentProps = {
    items,
    settings,
    component,
    otherProps,
    animationType,
    animate,
    animationStyle,
    scrollableOnDesktop,
    addProductPositionToLocalStorage,
    getCarouselInstance,
    slick,
  };

  return <CarouselItemListStructure {...componentProps} />;
};

export default CarouselListItem;
