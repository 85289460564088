import React, {
  forwardRef,
  MouseEvent,
  ReactNode,
  TouchEvent,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useTheme } from 'styled-components';

import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import { shiftArrayLeft } from '../../utils/formatters/shiftArray';
import { SliderElement, SliderSettings } from './Slider.types';
import SliderStructure from './SliderStructure';

export type SliderProps = SliderSettings & {
  children?: ReactNode;
};

export interface SliderRef {
  nextSlide: () => void;
  prevSlide: () => void;
  scrollToSlide: (num: number) => void;
  currentSlide: () => number;
  totalSlides: () => number;
}

const DIRECTION = {
  next: 'NEXT',
  prev: 'PREV',
};

const Slider = forwardRef<SliderRef, SliderProps>(
  (
    {
      slidesToShow = 1,
      slidesToScroll = 1,
      arrows = false,
      arrowsShowOnHoverOnly = false,
      nextArrow,
      prevArrow,
      arrowsPosition = 'INSIDE',
      arrowsSpaceBetween = 15,
      arrowWidth = 17,
      arrowHeight = 17,
      speed = 500,
      autoplay = false,
      infinite = false,
      dots = false,
      autoplaySpeed = 1500,
      autoplayDirection = DIRECTION.next,
      autoplayPauseOnHover = false,
      dotsPosition = 'BELOW',
      dotsSpaceBetween = 0,
      dotWidth = 0,
      dotHeight = 0,
      dotColour = '',
      dotActiveColour = '',
      beforeChange,
      afterChange,
      lazyLoad = false,
      initialSlide = 0,
      useMouseDrag = true,
      children,
      alignLeft = false,
      forceSwipeEnd = false,
    },
    ref,
  ) => {
    // TODO: fix ts error
    // @ts-ignore
    const array: Array<SliderElement> = React.Children.toArray(children);
    const [needTransition, setNeedTransition] = useState<boolean>(false);
    const [items, setItems] = useState<Array<SliderElement>>(
      shiftArrayLeft<SliderElement>(
        infinite ? [...array, ...array, ...array] : array,
        initialSlide,
      ),
    );
    const [currentPosition, setCurrentPosition] = useState(infinite ? array.length : 0);
    const [currentSlide, setCurrentSlide] = useState<number>(initialSlide);
    const [autoplayPaused, setAutoplayPaused] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState<number>(0);
    const [touchEnd, setTouchEnd] = useState<number>(0);
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const [propagateClick, setPropagateClick] = useState<boolean>(false);
    const [showArrows, setShowArrows] = useState<boolean>(
      arrows && !arrowsShowOnHoverOnly && array.length > slidesToShow,
    );
    const slideWidth: number = 100 / slidesToShow;
    const [translate, setTranslate] = useState<number>(-currentPosition * slideWidth);
    const slideNumberBase = infinite ? array.length : 0;
    const [firstViewedSlide, setFirstViewedSlide] = useState<number>(slideNumberBase - 1);
    const [lastViewedSlide, setLastViewedSlide] = useState<number>(
      slideNumberBase + slidesToShow - 1,
    );

    const theme = useTheme();
    const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);

    const isFirstSlideDisplayed = () => {
      return currentPosition === 0;
    };
    const isLastSlideDisplayed = () => {
      return items.length - currentPosition <= slidesToShow;
    };

    const handleSliderTransitionEnd = () => {
      setNeedTransition(false);
    };

    const nextSlide = () => {
      if (needTransition || (!infinite && isLastSlideDisplayed())) {
        return;
      }
      if (beforeChange) {
        beforeChange(currentSlide);
      }
      const newCurrent = currentPosition + slidesToScroll;
      setNeedTransition(true);
      setCurrentPosition(newCurrent);
      const newCurrentSlide = (currentSlide + slidesToScroll) % array.length;
      setCurrentSlide(newCurrentSlide);
      setLastViewedSlide((prev) => prev + slidesToShow);
      if (afterChange) {
        afterChange(newCurrentSlide);
      }
    };

    const prevSlide = () => {
      if (needTransition || (!infinite && isFirstSlideDisplayed())) {
        return;
      }
      if (beforeChange) {
        beforeChange(currentSlide);
      }
      const newCurrent = currentPosition - slidesToScroll;
      setNeedTransition(true);
      setCurrentPosition(newCurrent);
      let newCurrentSlide = currentSlide - slidesToScroll;
      newCurrentSlide = newCurrentSlide >= 0 ? newCurrentSlide : array.length + newCurrentSlide;
      newCurrentSlide %= array.length;
      setCurrentSlide(newCurrentSlide);
      setFirstViewedSlide((prev) => prev - slidesToShow);
      if (afterChange) {
        afterChange(newCurrentSlide);
      }
    };

    const handleNextSlide = (e: MouseEvent): void => {
      e.preventDefault();
      nextSlide();
    };

    const handlePrevSlide = (e: MouseEvent): void => {
      e.preventDefault();
      prevSlide();
    };

    const getNewMoveBy = (slideNum: number): number => {
      const currentIndex = infinite ? currentSlide : currentPosition;
      const diff = slideNum - currentIndex;
      if (infinite) {
        return diff;
      }
      if (diff < 0) {
        if (diff < -currentIndex) {
          return -currentIndex;
        }
        return diff;
      }
      if (array.length - slidesToShow - currentIndex < diff) {
        return array.length - slidesToShow - currentIndex;
      }
      return diff;
    };

    const scrollToSlide = (num: number): void => {
      if (num === currentSlide || num < 0 || num >= array.length) {
        return;
      }
      if (beforeChange) {
        beforeChange(currentSlide);
      }
      const slideNum = infinite ? num : Math.min(num, array.length - slidesToShow);
      const newMoveBy = getNewMoveBy(slideNum);
      const newCurrent = currentPosition + newMoveBy;
      setNeedTransition(true);
      setCurrentPosition(newCurrent);
      setCurrentSlide(num);
      if (afterChange) {
        afterChange(num);
      }
    };

    const handleSetAutoplay = (value: boolean): void => {
      if (autoplayPauseOnHover) {
        setAutoplayPaused(value);
      }
    };

    const handleSwipeStart = (e: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>): void => {
      // Prevent swiping on multi-touch
      if (e.targetTouches && e.targetTouches.length !== 1) return;

      const target = e.targetTouches ? e.targetTouches[0] : e;
      setTouchStart(target.clientX);
      setTouchEnd(target.clientX);
      setIsSwiping(true);

      if (forceSwipeEnd) {
        // The touch end event will not trigger consistently on iOS Safari, so we have to force the touch end action after some time
        setTimeout(() => {
          handleSwipeEnd();
        }, 1000);
      }
    };

    // On pinch zoom handleSwipeStart is called with both 1 and 2
    // targetTouches - this makes sure that it's called only with 2
    const debouncedHandleSwipeStart = useMemo(() => debounce(handleSwipeStart, 20), []);

    const handleSwipeMove = (e: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>): void => {
      if (isDesktop) {
        e.preventDefault();
        if (!useMouseDrag) {
          return;
        }
      }
      if (isSwiping) {
        const target = e.targetTouches ? e.targetTouches[0] : e;
        setTouchEnd(target.clientX);
      }
    };

    const handleSwipeEnd = () => {
      setIsSwiping(false);
      setTouchStart(0);
      setTouchEnd(0);
      setPropagateClick(touchEnd === touchStart);
      if (Math.abs(touchEnd - touchStart) < 20) {
        return;
      }
      if (touchEnd < touchStart) {
        nextSlide();
        return;
      }
      prevSlide();
    };

    const handleOnClick = (e: MouseEvent): void => {
      if (!propagateClick) {
        e.preventDefault();
      }
    };

    useEffect(() => {
      return () => {
        if (debouncedHandleSwipeStart.cancel) debouncedHandleSwipeStart?.cancel();
      };
    }, []);

    useEffect(() => {
      let handle: NodeJS.Timeout | null = null;
      if (autoplay && infinite && !autoplayPaused && !isSwiping && array.length > slidesToShow) {
        handle = setTimeout(() => {
          if (autoplayDirection === DIRECTION.prev) {
            prevSlide();
          } else {
            nextSlide();
          }
        }, autoplaySpeed);
      }
      return () => {
        if (handle) {
          clearTimeout(handle);
        }
      };
    }, [
      autoplay,
      infinite,
      autoplayPaused,
      currentPosition,
      autoplayDirection,
      autoplaySpeed,
      isSwiping,
    ]);

    useEffect(() => {
      if (needTransition) {
        return;
      }
      let newArray = React.Children.toArray(children);
      const overRideCurrentSlide = initialSlide > 0 ? (initialSlide - 1) : currentSlide;
      const newCurrentSlide = Math.min(overRideCurrentSlide, newArray.length - 1);
      const newCurrentPosition = infinite ? newArray.length : newCurrentSlide;
      setCurrentSlide(newCurrentSlide);
      setCurrentPosition(newCurrentPosition);
      if (infinite && currentSlide > 0) {
        newArray = [...newArray, ...newArray.slice(0, newCurrentSlide)].slice(-newArray.length);
      }
      // TODO: fix type issue
      // @ts-ignore
      setItems(infinite ? [...newArray, ...newArray, ...newArray] : newArray);
      setFirstViewedSlide(slideNumberBase - 1);
      setLastViewedSlide(slideNumberBase + slidesToShow - 1);
    }, [children, infinite, needTransition, slidesToShow]);

    useEffect(() => {
      if (!arrowsShowOnHoverOnly) {
        const newShowArrows = arrows && array.length > slidesToShow;
        if (showArrows !== newShowArrows) {
          setShowArrows(arrows && !arrowsShowOnHoverOnly && array.length > slidesToShow);
        }
      }
    }, [children, arrowsShowOnHoverOnly, slidesToShow]);

    // Keep this as a useLayoutEffect to prevent blinking effect on slide.
    useLayoutEffect(() => {
      if (alignLeft && currentPosition === -1) {
        setTranslate(0);
      } else {
        setTranslate(-currentPosition * slideWidth);
      }
    }, [currentPosition, slideWidth]);

    const sliderStyle = {
      transition: `transform ${needTransition ? speed : 0}ms ease`,
      transform: `translateX(calc(${translate}% + ${touchEnd - touchStart}px)`,
    };

    useImperativeHandle(ref, () => ({
      nextSlide,
      prevSlide,
      scrollToSlide,
      currentSlide: () => currentSlide,
      totalSlides: () => array?.length || 0,
    }));

    const onMouseEnter = () => {
      handleSetAutoplay(true);
      if (arrows && arrowsShowOnHoverOnly) {
        setShowArrows(true);
      }
    };
    const onMouseLeave = () => {
      handleSwipeEnd();
      handleSetAutoplay(false);
      if (arrows && arrowsShowOnHoverOnly) {
        setShowArrows(false);
      }
    };

    const componentProps = {
      items,
      slidesNum: array.length,
      showArrows,
      nextArrow,
      prevArrow,
      arrowsPosition,
      arrowsSpaceBetween,
      arrowWidth,
      arrowHeight,
      dots: dots && array.length > slidesToShow,
      dotsPosition,
      dotsSpaceBetween,
      dotWidth,
      dotHeight,
      dotColour,
      dotActiveColour,
      sliderStyle,
      slideWidth,
      currentSlide,
      slidesToShow,
      handleSliderTransitionEnd,
      handleSetAutoplay,
      handleSwipeEnd,
      handleSwipeStart: (e: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>): void => {
        e.persist();
        debouncedHandleSwipeStart(e);
      },
      handleSwipeMove,
      handleOnClick,
      handleNextSlide,
      handlePrevSlide,
      scrollToSlide,
      onMouseEnter,
      onMouseLeave,
      showSlidesRange: { start: firstViewedSlide, end: lastViewedSlide },
      lazyLoad: lazyLoad && infinite,
    };

    return <SliderStructure {...componentProps} />;
  },
);

export default Slider;
