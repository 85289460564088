import React, { FC } from 'react';
import { Settings } from 'react-slick';

import { BasicCarouselProps } from '../../../types/custom-types';
import SlickCarousel from '../../SlickCarousel';
import Slider from '../../Slider';

export interface CarouselItemListStructureProps extends BasicCarouselProps {
  addProductPositionToLocalStorage: (productPosition: number, lineNumber: string) => void;
}

const CarouselItemListStructure: FC<CarouselItemListStructureProps> = ({
  items,
  settings,
  component: Component,
  otherProps,
  animationType,
  animate,
  animationStyle,
  scrollableOnDesktop,
  addProductPositionToLocalStorage,
  getCarouselInstance,
  slick,
}) => {
  const slickSettings: Settings = {
    ...settings,
    lazyLoad: 'progressive',
  };

  const itemsComponents =
    items.length > 0 &&
    items.map((item, index) => (
      <Component
        key={`${item.id || index}`}
        $animationType={animationType}
        animate={animate}
        animationStyle={animationStyle}
        scrollableOnDesktop={scrollableOnDesktop}
        {...item}
        {...otherProps}
        index={index}
        addProductPositionToLocalStorage={addProductPositionToLocalStorage}
        productIndex={index}
      />
    ));

  if (slick) {
    return (
      <>
        <SlickCarousel {...slickSettings}>{itemsComponents}</SlickCarousel>
      </>
    );
  }

  return (
    <Slider ref={(c) => (getCarouselInstance ? getCarouselInstance(c) : undefined)} {...settings}>
      {itemsComponents}
    </Slider>
  );
};

export default CarouselItemListStructure;
