import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import QubitReact from 'qubit-react/wrapper';

import { selectCurrencyData, selectShowBrandName, selectToggles } from '../../../features/static';
import { ProductInterface, SizesInterface } from '../../../types/custom-types';
import MinibagItemStructure from './MinibagItemStructure';

export interface MinibagItemProps extends ProductInterface {
  quantity: number;
  url?: string;
}

const MinibagItem: FC<MinibagItemProps> = ({
  name,
  sizeLabels,
  quantity,
  price,
  productImage,
  brand,
  colour,
  sale,
  sku,
  url,
}) => {
  const currencyData = useSelector(selectCurrencyData);
  const toggles = useSelector(selectToggles);
  const isRedesign = toggles?.featureKgRedesign;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;
  const showBrandName = useSelector(selectShowBrandName);

  const formatPrice = (num: string): string => Number(num).toFixed(2);

  // Format size labels for different sites
  const formatSizeLabel = (sizes: SizesInterface): string => {
    const { eu, uk, us } = sizes;
    // If site is KG Global, return EU/US size.
    if (
      process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal' ||
      (typeof window !== 'undefined' && window.location.host.includes('global.kurtgeiger'))
    ) {
      if (eu?.toLowerCase() === 'no size') {
        return '';
      }
      if (eu === us) {
        return eu || '';
      }
      return `${eu}/${us}`;
    }
    // If site is KG US, return US size.
    if (
      process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus' ||
      (typeof window !== 'undefined' && window.location.host.includes('kurtgeiger.us'))
    ) {
      if (us && us !== null) {
        return us;
      }
    }
    if (
      process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigermexico' ||
      (typeof window !== 'undefined' && window.location.host.includes('kurtgeiger.mx'))
    ) {
      if (us?.toLowerCase() === 'no size') {
        return '';
      }
      if (us === eu) {
        return us || '';
      }
      return `${us}/${eu}`;
    }
    // If no size, return empty string.
    if (!uk || uk.toLowerCase() === 'no size') {
      return '';
    }
    // If sizes are the same, return UK size.
    if (eu === uk) {
      return uk;
    }
    // Default to UK/EU size.
    return `${uk}/${eu}`;
  };

  const componentProps = {
    name,
    sizeLabels,
    quantity,
    price,
    image: productImage,
    brand,
    colour,
    sale,
    showBrandName,
    formatPrice,
    formatSizeLabel,
    sku,
    url,
    currencySymbol: currencyData?.symbol,
    isRedesign,
    useReactRoutingLinks,
  };

  return (
    <QubitReact
      id="MinibagItem"
      componentTest={MinibagItemStructure}
      componentProps={componentProps}
    >
      <MinibagItemStructure {...componentProps} />
    </QubitReact>
  );
};

export default MinibagItem;