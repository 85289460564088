import { useMemo } from 'react';
import { Action, configureStore, Dispatch, Store, ThunkAction } from '@reduxjs/toolkit';

import { initialState } from '../shared/features';
import { accordionReducer } from '../shared/features/accordion';
import { authReducer } from '../shared/features/auth';
import { bagReducer } from '../shared/features/bag';
import { cartReducer } from '../shared/features/cart';
import { checkoutReducer } from '../shared/features/checkout';
import { currentProductReducer } from '../shared/features/currentProduct';
import { dataCaptureReducer } from '../shared/features/dataCapture';
import { deviceReducer } from '../shared/features/device';
import { elementsReducer } from '../shared/features/elements';
import { filtersReducer } from '../shared/features/filters';
import { findInStoreReducer } from '../shared/features/findInStore';
import { metadataReducer } from '../shared/features/metadata';
import { navReducer } from '../shared/features/nav';
import { notificationReducer } from '../shared/features/notification';
import { plpContentReducer } from '../shared/features/plpContent';
import { plpFilterReducer } from '../shared/features/plpFilter';
import { popUpReducer } from '../shared/features/popUp';
import { radioListReducer } from '../shared/features/radioList';
import { staticReducer } from '../shared/features/static';
import { storiesReducer } from '../shared/features/stories';
import { StoreState } from '../shared/types/redux-types';

export type AppDispatch = Dispatch;
export type RootState = StoreState;

let store: Store | undefined;

export const initStore = (preloadedState: StoreState = initialState): Store =>
  configureStore({
    reducer: {
      accordion: accordionReducer,
      auth: authReducer,
      currentProduct: currentProductReducer,
      nav: navReducer,
      elements: elementsReducer,
      findInStore: findInStoreReducer,
      filters: filtersReducer,
      bag: bagReducer,
      device: deviceReducer,
      plpFilter: plpFilterReducer,
      plpContent: plpContentReducer,
      static: staticReducer,
      stories: storiesReducer,
      popUp: popUpReducer,
      metadata: metadataReducer,
      dataCapture: dataCaptureReducer,
      checkout: checkoutReducer,
      radioList: radioListReducer,
      notification: notificationReducer,
      cart: cartReducer
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['elements/addNewElement', 'plpFilter/setSizeQueryData'],
          ignoreState: true,
          ignoredPaths: ['tabs.tabsData'],
        },
      }),
  });

export const initializeStore = (preloadedState?: StoreState): Store => {
  // eslint-disable-next-line no-underscore-dangle
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initState?: StoreState): Store {
  const storeInit = useMemo(() => initializeStore(initState), [initState]);
  return storeInit;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
