import React, { FC } from 'react';

import constants from '../../../../../sites/constants';
import {
  ImageHtmlLink as ImageHtmlLinkType,
  Maybe,
  SubMenuLinkList,
} from '../../../../types/middleware-types';
import Accordion from '../../../Accordion';
import ImageHtmlLink from '../ImageHtmlLink';
import { StyledImages, StyledLinkListWithImage } from './LinkListWithImageStyles';

interface LinkListWithImageStructureProps {
  links: SubMenuLinkList;
  images?: Maybe<Array<Maybe<ImageHtmlLinkType>>>;
}

const LinkListWithImageStructure: FC<LinkListWithImageStructureProps> = ({ links, images }) => {
  return (
    <StyledLinkListWithImage
      id={links?.menuId || undefined}
      data-hookid="globalHeaderSubMenuLinkListWithImage"
    >
      <Accordion
        openByDefault={links.title}
        data={[links]}
        plusIcon={constants.accordionIcons.plus}
        minusIcon={constants.accordionIcons.minus}
      />
      <StyledImages>
        {images?.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ImageHtmlLink
            id={image?.link?.linkId || undefined}
            key={`${image?.image.desktop.src}-${index}`}
            data={image}
          />
        ))}
      </StyledImages>
    </StyledLinkListWithImage>
  );
};

export default LinkListWithImageStructure;
