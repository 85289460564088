import { Types as CarvelaTypes } from './carvela/styles/resources/typeSnippets';
import { Types as KgTypes } from './kg/styles/resources/typeSnippets';
import { Types as SahTypes } from './sah/styles/resources/typeSnippets';
import getSite from '../shared/utils/customHooks/useSiteChecker';

interface Type {
  [key: string]: string;
}

interface SiteTypes {
  [key: string]: Type;
}

const Types: SiteTypes = {
  carvela: CarvelaTypes,
  kg: KgTypes,
  sah: SahTypes
};

const site = getSite();

const types = Types[site];

export default types;
