import styled from 'styled-components';

interface StyleProps {
  $sale: boolean,
  $kgRedesignToggle: boolean
}

export const StyledMinibagItemContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.vars.black};
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.minibag.product.borderColor};

  p {
    margin: 0;
    line-height: 1.5rem;
  }

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledImageContainer = styled.div<{ $kgRedesignToggle: boolean}>`
  img {
    width: 12rem;
    height: 12rem;
    ${({ $kgRedesignToggle }) =>
  $kgRedesignToggle && `
      height: 15.5rem;
      min-height: 15.5rem;
      width: 15.5rem;
      min-width: 15.5rem;
    `};
  }
`;

export const StyledProductInformation = styled.div``;

export const StyledProductDetailsContainer = styled.div<{ $kgRedesignToggle: boolean}>`
  text-align: left;
  padding-left: 1rem;
  font-size: ${({ theme }) => theme.minibag.product.fontSize};

  p {
    margin-bottom: ${({ theme }) => theme.minibag.product.text.marginBottom};
  }
  ${({ $kgRedesignToggle, theme }) =>
  $kgRedesignToggle && `
      min-height: 15.5rem;
      display: flex;
      flex-direction: column;

      p {
        text-transform: uppercase;
        font-size: 1.1rem;
      }

      @media ${theme.devices.large} {
        .name {
          margin: 1rem 0;
          font-size: 1.2rem;
        }
      }
    `
  };
`;

export const StyledProductName = styled.div`
  .brand {
    ${({ theme }) => theme.types.h4Style};
  }

  .name {
    text-transform: ${({ theme }) => theme.minibag.product.name.textTransform};
    font-weight: ${({ theme }) => theme.minibag.product.name.fontWeight};
  }

  .colour {
    text-transform: capitalize;
  }
`;

export const StyledOrderInfo = styled.div``;

export const StyledPriceBox = styled.div<StyleProps>`
  display: flex;

  .nowPrice { /* stylelint-disable-line selector-class-pattern */
    color: ${({ theme, $sale }) => ($sale ? theme.productPrice.sale.colour : theme.vars.black)};
  }

  .was {
    text-decoration: line-through;
    margin-right: 0.5rem;
  }
  ${({ $kgRedesignToggle }) =>
  $kgRedesignToggle && `
    p {
      font-size: 1.2rem
    }
  `};
`;