import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTheme } from 'styled-components';

import {
  selectHideNav,
  selectMeganavOpen,
  selectSearchOpen,
  selectShowMyBag,
  updateHideNav,
  updateMeganavOpen,
  updateSearchOpen,
  updateShowMyBag,
} from '../../../features/nav';
import { selectIsCompactHeader } from '../../../features/static';
import useMobileWatcher from '../useMobileWatcher';

export interface HeaderNavigationReturn {
  showBag: boolean;
  toggleMiniBag: (value?: boolean) => void;
  toggleSearch: (value?: boolean) => void;
  searchOpen: boolean;
  megaNavOpen: boolean;
  toggleMegaNav: (value?: boolean) => void;
  hideNav: boolean;
}

const useHeaderNavigation = (): HeaderNavigationReturn => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const compactHeader = useSelector(selectIsCompactHeader);
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const isMobileOrTablet = !!isMobile || !!isTablet;
  const hideNav = useSelector(selectHideNav);
  const megaNavOpen = useSelector(selectMeganavOpen);
  const showBag = useSelector(selectShowMyBag);
  const searchOpen = useSelector(selectSearchOpen);

  const closePopup = (values: Array<string>): void => {
    if (isMobileOrTablet) {
      const navigations: { [key: string]: ActionCreatorWithPayload<boolean, string> } = {
        megaNav: updateMeganavOpen,
        search: updateSearchOpen,
        bag: updateShowMyBag,
      };
      values.forEach((value) => {
        dispatch(navigations[value](false));
      });
    }
  };

  useEffect(() => {
    if (megaNavOpen) {
      closePopup(['search', 'bag']);
    }
  }, [megaNavOpen]);

  useEffect(() => {
    if (searchOpen) {
      closePopup(['megaNav', 'bag']);
    }
  }, [searchOpen]);

  useEffect(() => {
    if (showBag) {
      closePopup(['search', 'megaNav']);
    }
  }, [showBag]);

  useEffect(() => {
    // non compact header should not hide the search bar
    if (!compactHeader) {
      dispatch(updateHideNav(isMobileOrTablet && (showBag || megaNavOpen)));
    }
  }, [showBag, megaNavOpen, isMobileOrTablet, compactHeader]);

  useEffect(() => {
    if ((isMobileOrTablet && (showBag || megaNavOpen)) || searchOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [showBag, searchOpen, megaNavOpen, isMobileOrTablet]);

  const toggleMegaNav = (value?: boolean): void => {
    dispatch(updateMeganavOpen(value ?? !megaNavOpen));
  };

  const toggleMiniBag = (value?: boolean): void => {
    dispatch(updateShowMyBag(value ?? !showBag));
  };

  const toggleSearch = (value?: boolean): void => {
    dispatch(updateSearchOpen(value ?? !searchOpen));
    dispatch(updateHideNav(false));
  };

  return { showBag, toggleMiniBag, toggleSearch, searchOpen, megaNavOpen, toggleMegaNav, hideNav };
};

export default useHeaderNavigation;
