import React, { FC, ReactElement } from 'react';

import CountdownItemStructure from './CountdownItemStructure';

interface CountdownItemProps {
  timeValue: number;
  type: string;
  layout: string;
}

const CountdownItem: FC<CountdownItemProps> = ({
  timeValue,
  type,
  layout,
}: CountdownItemProps): ReactElement => {
  const parseNumber = (number: number): string => {
    return `0${number}`.slice(-2);
  };

  const parseType = (text: string): string => {
    return layout === 'page' ? text : text.substring(0, 1);
  };

  const componentProps = {
    timeValue: parseNumber(timeValue),
    type: parseType(type),
    layout,
  };

  return <CountdownItemStructure {...componentProps} />;
};

export default CountdownItem;
