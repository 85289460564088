import gql from 'graphql-tag';

export const COMPETITION_FORM_SUBMISSION = gql`
  mutation competitionFormSubmission($input: WelcomeProgrammeFormInput!, $source: String!) {
    competitionFormSubmission(input: $input, source: $source) {
      ... on WelcomeProgrammeSuccessResponse {
        success
      }
      ... on WelcomeProgrammeErrorResponse {
        status
        statusText
        message
      }
    }
  }
`;
