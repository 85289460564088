import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { BasicCarouselProps } from '../../types/custom-types'
import { DEFAULT_ON_SCROLL_STYLE } from '../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import usePageName from '../../utils/customHooks/usePageName';
import CarouselStructure from './CarouselStructure';


export interface CarouselProps extends BasicCarouselProps {
  title?: string;
  dataHookid?: string;
  mobileOnly?: boolean;
  lsIgnore?: boolean;
}

const Carousel: FC<CarouselProps> = ({
  items = [],
  component,
  settings = {},
  showArrows,
  otherProps,
  scrollableOnMobile,
  scrollableOnDesktop,
  animationType,
  animate,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  getCarouselInstance = () => { },
  slick,
  dataHookid,
  campaign,
  setCarouselTitle,
  mobileOnly,
  lsIgnore,
  idValue
}) => {
  const { pageName } = usePageName();
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);

  const componentProps = {
    items,
    component,
    settings: !scrollableOnDesktop
      ? settings
      : {
        ...settings,
        arrows: false,
        autoplay: false,
        dots: false,
        infinite: false,
        lazyLoad: false,
      },
    pageName,
    showArrows: showArrows && !scrollableOnDesktop,
    scrollableOnMobile,
    scrollableOnDesktop,
    animationType,
    animate,
    animationStyle,
    otherProps,
    getCarouselInstance,
    slick,
    campaign,
    setCarouselTitle,
    dataHookid,
    mobileOnly,
    lsIgnore,
    isDesktop,
    idValue
  };

  return <CarouselStructure {...componentProps} />

};

export default Carousel;
