import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectToggles } from '../../features/static';
import { SocialMedia, ThirdPartyAccount } from '../../types/middleware-types';
import FacebookSvg from '../SVG/Facebook';
import InstagramSvg from '../SVG/Instagram';
import TwitterSvg from '../SVG/Twitter';
import SocialLogosStructure from './SocialLogosStructure';

interface SocialLogosProps {
  data: SocialMedia
}

const SocialLogos: FC<SocialLogosProps> = ({ data }) => {
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const iconChooser = (social: ThirdPartyAccount): ReactNode | null => {
    switch (social.platform) {
      case 'instagram':
        return <InstagramSvg />;
      case 'facebook':
        return <FacebookSvg />;
      case 'twitter':
        return <TwitterSvg />;
      default:
        return null;
    }
  };

  const componentProps = {
    data, iconChooser,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };
  return <SocialLogosStructure {...componentProps} />;
};

export default SocialLogos;
