import React, { FC } from 'react';

import UserIcon from './SVG/UserIcon';
import UserIconFilled from './SVG/UserIconFilled';
import { StyledAccountIcon } from './AccountIconStyles';

interface AccountIconStructureProps {
  isAuth: boolean;
  isHovered?: boolean;
  mobileHeader?: boolean;
  headerTheme: string;
}

const AccountIconStructure: FC<AccountIconStructureProps> = ({
  isAuth,
  headerTheme,
  isHovered,
  mobileHeader,
}: AccountIconStructureProps) => (
  <StyledAccountIcon
    $headerTheme={headerTheme}
    $isHovered={isHovered}
    $isAuth={isAuth}
    $mobileHeader={mobileHeader}
    data-hookid="globalHeaderAccountIcon"
  >
    {isAuth ? <UserIconFilled /> : <UserIcon />}
  </StyledAccountIcon>
);

export default AccountIconStructure;
