import React, { FC, memo } from 'react';
import Link from 'next/link';

import NextImage from '../../../basic/NextImage';
import { SizesInterface } from '../../../types/custom-types';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import {
  StyledImageContainer,
  StyledMinibagItemContainer,
  StyledOrderInfo,
  StyledPriceBox,
  StyledProductDetailsContainer,
  StyledProductInformation,
  StyledProductName,
} from './MinibagItemStyles';
import { MinibagItemProps } from '.';

interface MinibagItemStructureProps extends MinibagItemProps {
  showBrandName: boolean;
  formatPrice: (arg0: string) => string;
  formatSizeLabel: (arg0: SizesInterface) => string;
  currencySymbol: string;
  url?: string;
  isQubit?: boolean;
  isRedesign: boolean;
  useReactRoutingLinks: boolean;
}

const MinibagItemStructure: FC<MinibagItemStructureProps> = ({
  name,
  sizeLabels,
  quantity,
  price,
  image,
  brand,
  showBrandName,
  colour,
  formatPrice,
  sale,
  formatSizeLabel,
  currencySymbol,
  sku,
  url,
  isQubit,
  isRedesign,
  useReactRoutingLinks,
}) => {
  const minibagItem = (
    <StyledMinibagItemContainer
      data-hookid="globalHeaderMinibagItem"
      data-ge-basket-cartitemid={sku ?? ''}
    >
      <StyledImageContainer $kgRedesignToggle={isRedesign}>
        {image && (
          <NextImage
            mediaList={createMediaList({
              src: image.url,
              width: isRedesign ? 155 : 120,
              height: isRedesign ? 155 : 120,
            })}
            imageType='fixed'
            alt={image.alt || `image of ${name.toLowerCase()}`}
          />
        )}
      </StyledImageContainer>
      <StyledProductDetailsContainer data-hookid="minibagProductDetails" $kgRedesignToggle={isRedesign}>
        <StyledProductInformation>
          <StyledProductName>
            {brand !== null && showBrandName && !isRedesign && <p className="brand">{brand}</p>}
            <p className="name">{name.toLowerCase()}</p>
            <p>Quantity: {quantity}</p>
            {colour && <ScreenReaderOnlyText text={`Colour: ${colour.toLowerCase()}`} />}
          </StyledProductName>
          <StyledOrderInfo>
            {sizeLabels && (
              <p data-hookid="globalHeaderMinibagItemSize">{formatSizeLabel(sizeLabels)}</p>
            )}
          </StyledOrderInfo>
        </StyledProductInformation>
        {isRedesign && price !== null && (
          <StyledPriceBox $sale={sale} $kgRedesignToggle={isRedesign}>
            {price.was && (
              <p className="was" data-ge-basket-productlistprice>
                {price.wasFormatted}
              </p>
            )}
            {price?.nowFormatted && (
              <p
                className="nowPrice"
                data-ge-basket-productsaleprice
              >
                {price.nowFormatted}
              </p>
            )}
          </StyledPriceBox>
        )}
        {!isRedesign && price !== null && (
          <StyledPriceBox $sale={sale} $kgRedesignToggle={isRedesign}>
            {price.was && (
              <p className="was" data-ge-basket-productlistprice>
                {currencySymbol}
                {formatPrice(price.was)}
              </p>
            )}
            {price.now && (
              <p
                className="nowPrice"
                data-ge-basket-productsaleprice
              >
                {currencySymbol}
                {formatPrice(price.now)}
              </p>
            )}
          </StyledPriceBox>
        )}
      </StyledProductDetailsContainer>
    </StyledMinibagItemContainer>
  );

  return (
    <>
      {isQubit ? (
        <>
          {useReactRoutingLinks ? (
            <Link href={url as string} passHref legacyBehavior>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a data-hookid="globalHeaderQubitLinkedMinibagItem">
                {minibagItem}
              </a>
            </Link>
          ) : (
            <a href={url} data-hookid="globalHeaderQubitLinkedMinibagItem">
              {minibagItem}
            </a>
          )}
        </>
      ) : (
        minibagItem
      )}
    </>
  )
};

export default memo(MinibagItemStructure);