import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsCompactHeader, selectToggles } from '../../features/static';
import { PromoBanner as PromoBannerType, PromoBannerItem } from '../../types/middleware-types';
import useElementPush from '../../utils/customHooks/useElementPush';
import { useHeaderCompactPosition } from '../../utils/customHooks/useHeaderCompactPosition';
import useHeaderTheme from '../../utils/customHooks/useHeaderTheme';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import PromoBannerStructure from './PromoBannerStructure';
import settings from './settings';

export const DEFAULT_PROMO_BANNER_HEIGHT = 50;

export interface PromoBannerProps {
  data: PromoBannerType;
  isReverse?: boolean;
}

const PromoBanner: FC<PromoBannerProps> = ({ data, isReverse = false }) => {
  const theme = useTheme();
  const toggles = useSelector(selectToggles);
  const compactHeader = useSelector(selectIsCompactHeader);
  const { headerPosition } = useHeaderTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const getElement = useElementPush<HTMLDivElement>('promoBanner');

  const { promoBannerTop } = useHeaderCompactPosition();

  const height = !isDesktop ? data?.mobileHeightPixels : data?.desktopHeightPixels;

  // Needed for SSR of the search icon.
  const SSRDesktopCheck = isDesktop ?? false;

  const getItemsWithContent = (items: Array<PromoBannerItem>) => {
    if (!items) {
      return [];
    }
    return items.filter(
      ({ textHtml, expiryDate, textLink, backgroundImage }) =>
        textHtml || expiryDate || textLink?.text || backgroundImage,
    );
  };


  const componentProps = {
    desktopItems: getItemsWithContent(data.desktopItems),
    mobileItems: getItemsWithContent(data.mobileItems),
    height,
    settings,
    isDesktop: SSRDesktopCheck,
    getElement,
    hasSeparator: data.hasSeparator,
    stickyCompactHeader: toggles?.featureStickyHeader,
    compactHeader,
    headerPosition,
    isReverse,
    top: promoBannerTop,
  };

  return <PromoBannerStructure {...componentProps} />;
};

export default PromoBanner;