// Added ignore as any type of data can be passed
type targetArrayProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

/**
 * FilterArray first sets query to lowercase. It then checks if the filterTargets is an array. If so
 * it loops(filter) around each filterTarget and checks if the query exists in the target. If so it
 *  sets the hasConditions to true and returns the item. If not it returns false. It then returns
 * the array.
 *
 * If the filterTarget is just one item it just filters and checks for the indexOf and returns
 * the filterArray
 *
 * @param targetArray - Array to filter by
 * @param filterTargets - What key you want to filter by
 * @param query - the value you're looking for
 */
const filterArray = <Type>(targetArray: Type[] | null | undefined, filterTargets: string[], query: string): Type[] | undefined | null => {
  let filteredArray;
  const queryLower = query.toLowerCase();
  const filterTargesIsArray = Array.isArray(filterTargets);
  const targetArrayIsArray = Array.isArray(targetArray);

  if (targetArray === null) {
    return targetArray;
  }

  if (filterTargesIsArray && targetArrayIsArray) {
    // @ts-ignore
    // TODO: fix this type error
    filteredArray = targetArray.filter((arrayItem: targetArrayProps) => {
      let hasConditions = false;

      filterTargets.forEach((value) => {
        const arrayItemValue = arrayItem[value].toLowerCase();

        if (arrayItemValue.indexOf(queryLower) !== -1) {
          hasConditions = true;
        }
      });

      if (hasConditions) {
        return arrayItem;
      }

      return false;
    });

    return filteredArray;
  }

  return filteredArray;
};

export default filterArray;
