import { NextMediaList } from "../../../types/custom-types";
import { Maybe } from "../../../types/middleware-types";

interface CreateMediaListProps {
  src: string
  width?: Maybe<number>;
  height?: Maybe<number>;
}

export const createMediaList = (image?: CreateMediaListProps | undefined | null): NextMediaList => {
  return {
    mobile: image,
    tablet: image,
    desktop: image,
  }
}