import styled from 'styled-components';

interface StyledAmazonPayWrapperProps {
  $showAmazonButton?: boolean;
}

export const StyledAmazonPayWrapper = styled.div<StyledAmazonPayWrapperProps>`
  width: calc(100% - 3.2rem) !important;
  height: ${({ $showAmazonButton }) => ($showAmazonButton ? 3.8 : 0)}rem !important;
  margin: 1rem 1.6rem;

  @media ${({ theme }) => theme.devices.large} {
    width: calc(100% - 0.8rem) !important;
    margin: 1rem 0.4rem 0;
  }
`;
