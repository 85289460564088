import { createReducer } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
import { FiltersReduxState } from '../../types/state-types';
import {setActiveCategoriesData,
  setBrandedPlpData,
  setCategoriesData,
  setCurrentPageView,
  setCurrentPendingFilters,
  setCurrentSortBy,
  setFormattedFilters,
  setInitialFilterData,
  setOpenFilterDropdown,
  setPlpDataWithoutItems,
  setSelectedCategory,
  setSelectedGender,
  setSelectedPrice,
  setSortByData,
  updateCloseAllFilterPopups,
  updateMobFiltersOpen} from './actions';

export const initialState: FiltersReduxState = {
  plpData: null,
  categories: null,
  pageView: "large_view",
  sortBy: null,
  currentSortBy: null,
  currentPendingFilters: [],
  selectedCategory: null,
  selectedPrice: null,
  selectedGender: null,
  activeCategory: null,
  openFilterDropdown: '',
  formattedFilters: {},
  mobFiltersOpen: false,
  closeAllFilterPopups: false,
  brandedPlpData: null,
  initialFilterData: {
    categoryPath : null,
    filterOptions: null,
    sortBy: null
  }
};

export const filtersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPlpDataWithoutItems, (state, action) => {
      state.plpData = action.payload;
    })
    .addCase(setSelectedCategory, (state, action) => {
      state.selectedCategory = action.payload;
    })
    .addCase(setSelectedPrice, (state, action) => {
      state.selectedPrice = action.payload;
    })
    .addCase(setSelectedGender, (state, action) => {
      state.selectedGender = action.payload;
    })
    .addCase(setActiveCategoriesData, (state, action) => {
      state.activeCategory = action.payload;
    })
    .addCase(setCategoriesData, (state, action) => {
      state.categories = action.payload;
    })
    .addCase(setCurrentPageView, (state, action) => {
      state.pageView = action.payload;
    })
    .addCase(setSortByData, (state, action) => {
      state.sortBy = action.payload;
    })
    .addCase(setCurrentSortBy, (state, action) => {
      state.currentSortBy = action.payload;
    })
    .addCase(setCurrentPendingFilters, (state, action) => {
      state.currentPendingFilters = action.payload;
    })
    .addCase(setOpenFilterDropdown, (state, action) => {
      state.openFilterDropdown = action.payload;
    })
    .addCase(setFormattedFilters, (state, action) => {
      state.formattedFilters = action.payload;
    })
    .addCase(setBrandedPlpData, (state, action) => {
      state.brandedPlpData = action.payload;
    })
    .addCase(updateMobFiltersOpen, (state, action) => {
      state.mobFiltersOpen = action.payload;
    })
    .addCase(setInitialFilterData, (state, action) => {
      state.initialFilterData = action.payload;
    })
    .addCase(updateCloseAllFilterPopups, (state, action) => {
      state.closeAllFilterPopups = action.payload;
    });
});
