import React, { FC } from 'react';

import { StyledAmazonPayWrapper } from './AmazonPayStyles';

interface AmazonPayStructureProps {
  parentId?: string;
  amazonRef: (node: HTMLDivElement) => void;
  showAmazonButton?: boolean;
  location: string;
}

const AmazonPayStructure: FC<AmazonPayStructureProps> = ({
  parentId,
  amazonRef,
  showAmazonButton,
  location,
}) => (
  <StyledAmazonPayWrapper
    data-hookid={`${location}AmazonPay`}
    id={`AmazonPayButton${parentId}`}
    ref={amazonRef}
    className="amazonPayButton"
    $showAmazonButton={showAmazonButton}
  />
);

export default AmazonPayStructure;
