import styled from 'styled-components';

const iconDimensions = {
  width: '2.5rem',
  height: '1.8rem',
};

export const StyledShippingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.vars.black};

  & > div {
    display: flex;
    justify-content: space-between;
  }

  @media ${({ theme }) => theme.devices.large} {
    color: inherit;
  }

  table {
    width: 100%;

    tr {
      td {
        padding-bottom: ${({ theme }) => theme.vars.sizeM};

        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export const StyledCurrentLocation = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: ${iconDimensions.width};
    height: ${iconDimensions.height};
    margin-right: 1.1rem;
  }

  > span + span {
    &::before {
      content: " ";
      display: inline-block;
      margin-left: 1.1rem;
    }
  }

  > div {
    margin-right: 1.1rem !important;
  }

  span {
    ${({ theme }) => theme.types.labelSmallStyle};
  }
`;

export const StyledCountryPickerButton = styled.button<{ $listVisible?: boolean }>`
  background: none;
  border: none;
  line-height: 1.2;
  padding: 0;
  font-weight: 400;
  text-transform: ${({ theme }) => theme.countryPicker.change.textTransform};
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  ${({ theme }) => theme.types.tertiaryLinkStyles};
  display: ${({ $listVisible }) => ($listVisible ? 'none' : 'block')};
  color: ${({ theme }) => theme.vars.black};

  @media ${({ theme }) => theme.devices.large} {
    color: inherit;
  }
`;

export const StyledCountryPickerButtonMobile = styled(StyledCountryPickerButton)`
  display: block;
  text-align: left;
  margin-left: 1rem;
  margin-bottom: ${({ theme }) => theme.vars.sizeL};
  width: 100%;
  outline: none;
  text-decoration: none;

  img {
    margin-right: 1rem;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledShippingOptions = styled.div`
  p {
    line-height: 1rem;
    font-size: 1.2rem;
  }
`;
