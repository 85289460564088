/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { AuthState } from '../../types/state-types';
import {
  updateAuthStatus,
} from './actions';

export const initialState: AuthState = {
  isAuth: false,
};

export const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateAuthStatus, (state, action) => {
      state.isAuth = action.payload;
    });
});
