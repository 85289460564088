import styled from 'styled-components';

import { StyledMobileCarousel } from './MobileCarousel/MobileCarouselStyles';
import { StyledSlider, StyledSliderWrapper } from '../Slider/SliderStyles';

interface StyledCarouselContainerProps {
  $scrollableOnMobile?: boolean;
  $scrollableOnDesktop?: boolean;
}

export const StyledCarouselContainer = styled.div<StyledCarouselContainerProps>`
  height: 100%;
  ${StyledSlider} {
    padding: 0 ${({ theme }) => theme.vars.sizeL};
  }
  ${({ $scrollableOnMobile, theme }) =>
  $scrollableOnMobile === true &&
    `
    @media ${theme.devices.smallOnly} {
      ${StyledSlider} {
        display: none;
      }
      ${StyledMobileCarousel} {
        display: flex;
      }
    }
  `}
  ${({ $scrollableOnDesktop, theme }) =>
      $scrollableOnDesktop === true &&
    `
    @media ${theme.devices.large} {
      ${StyledSlider} {
        ${StyledSliderWrapper} {
          overflow: auto;

          &::-webkit-scrollbar {
            height: 0.8rem;
          }

          &::-webkit-scrollbar:vertical {
            -webkit-appearance: none;
          }

          &::-webkit-scrollbar-thumb {
            background: ${theme.vars.brandPrimary};
            height: 0.5rem;
            border-radius: 4rem;
          }

          &::-webkit-scrollbar-track {
            background: ${theme.vars.greyLightest};
            margin: 0 11.2rem;
          }
        }
      }
    }
  `}

  .slick-slider {
    touch-action: unset;
  }

  .slick-dots {
    bottom: -3rem;
    
    li {
      margin: 0;
      
      &.slick-active {
        button::before {
          color: ${({ theme }) => theme.pdpContent.sliderImageDots.color};
        }
      }

      button::before {
        font-size: 1.2rem;
      }
    }
  }
`;
