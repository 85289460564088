import Cookies from 'universal-cookie';

interface CustomerHeaders {
  customer_oauth_token?: string;
}

const getCustomerHeaders = (cookie: string | null | undefined): CustomerHeaders => {
  const cookies = new Cookies(cookie);
  const customerOauthToken = cookies.get('customer_oauth_token');

  const customerHeaders: CustomerHeaders = {};
  if (customerOauthToken && customerOauthToken !== 'null') {
    customerHeaders.customer_oauth_token = customerOauthToken;
  }

  return customerHeaders;
};

export default getCustomerHeaders;
