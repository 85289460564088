/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  setIsMobileSSR, setIsSmartAppBannerClosed,
} from './actions';

export type DeviceState = {
  isMobileSSR: boolean;
  isSmartAppBannerClosed: boolean;
};

export const initialState: DeviceState = {
  isMobileSSR: false,
  isSmartAppBannerClosed: true,
};

export const deviceReducer = createReducer(initialState, builder => {
  builder
    .addCase(setIsMobileSSR, (state, action) => {
      state.isMobileSSR = action.payload;
    })
    .addCase(setIsSmartAppBannerClosed, (state, action) => {
      state.isSmartAppBannerClosed = action.payload;
    });
});
