import styled from 'styled-components';

export const StyledAccordionItemContainer = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.vars.sizeL} 0;

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ theme }) => theme.vars.sizeL};
    padding-top: 0;
  }

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: 100%;
    padding: ${({ theme }) => theme.vars.sizeS} 0;
  }
`;

export const StyledAccordionTrigger = styled.button<{ $red: boolean }>`
  background: transparent;
  border: none;
  text-transform: uppercase;
  ${({ theme }) => theme.types.h3Style};
  color: ${({ theme, $red }) => ($red ? theme.vars.red : theme.vars.black)};
  font-size: ${({ theme }) => theme.vars.sizeM};
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.vars.black};
  }
`;

type StyledFooterLinkListProps = {
  $itemOpen?: boolean;
};

export const StyledFooterLinkList = styled.ul<StyledFooterLinkListProps>`
  display: ${({ $itemOpen }) => ($itemOpen ? 'block' : 'none')};
  list-style: none;
  padding: 0;
  margin-left: 3.2rem;

  li {
    margin: 1.6rem 0;

    a {
      font-weight: 400;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;
  }
`;
