/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import getSite from '../../utils/customHooks/useSiteChecker';
import {
  updateHideNav,
  updateIsAtTop,
  updateIsSiteSwitcherVisible,
  updateMediaBannerColourTheme,
  updateMeganavOpen,
  updateSearchOpen,
  updateShowMobileCountryPicker,
  updateShowMyBag,
  updateShowSiteSwitcher,
  updateSubmenuCompactPosition,
} from './actions';

export interface NavState {
  showBag: boolean;
  megaNavOpen: boolean;
  hideNav: boolean;
  mediaBannerColourTheme: string | null;
  isSiteSwitcherVisible: boolean;
  showMobileCountryPicker: boolean;
  searchOpen: boolean;
  showSiteSwitcher: boolean;
  submenuCompactPosition: number;
  isAtTop: boolean
}

interface localStates {
  [key: string]: {
    [key: string]: boolean;
  };
}

const localNavState: localStates = {
  carvela: {
    isSiteSwitcherVisible: false,
  },
  kg: {
    isSiteSwitcherVisible: true,
  },
  kgus: {
    isSiteSwitcherVisible: true,
  },
  kgglobal: {
    isSiteSwitcherVisible: true,
  },
  sah: {
    isSiteSwitcherVisible: false,
  },
};

const getLocalSite = getSite();

export const initialState: NavState = {
  showBag: false,
  megaNavOpen: false,
  hideNav: false,
  mediaBannerColourTheme: null,
  isSiteSwitcherVisible: localNavState[getLocalSite].isSiteSwitcherVisible,
  showMobileCountryPicker: false,
  searchOpen: false,
  showSiteSwitcher: false,
  submenuCompactPosition: 0,
  isAtTop: false
};

export const navReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateShowMyBag, (state, action) => {
      state.showBag = action.payload;
    })
    .addCase(updateHideNav, (state, action) => {
      state.hideNav = action.payload;
    })
    .addCase(updateMeganavOpen, (state, action) => {
      state.megaNavOpen = action.payload;
    })
    .addCase(updateMediaBannerColourTheme, (state, action) => {
      state.mediaBannerColourTheme = action.payload;
    })
    .addCase(updateIsSiteSwitcherVisible, (state, action) => {
      state.isSiteSwitcherVisible = action.payload;
    })
    .addCase(updateShowMobileCountryPicker, (state, action) => {
      state.showMobileCountryPicker = action.payload;
    })
    .addCase(updateSearchOpen, (state, action) => {
      state.searchOpen = action.payload;
    })
    .addCase(updateShowSiteSwitcher, (state, action) => {
      state.showSiteSwitcher = action.payload;
    })
    .addCase(updateSubmenuCompactPosition, (state, action) => {
      state.submenuCompactPosition = action.payload;
    })
    .addCase(updateIsAtTop, (state, action) => {
      state.isAtTop = action.payload;
    });
});
