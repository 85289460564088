import styled from 'styled-components';

import { StyledMinibagDropdownContainer } from './MinibagDropdown/MinibagDropdownStyles';
import { StyledMinibagIconContainer } from './MinibagIcon/MinibagIconStyles';

interface BagProps {
  $showBag: boolean;
  $isKG: boolean;
}

export const StyledMinibagContainer = styled.div<BagProps>`
  height: ${({ $isKG }) => ($isKG ? '4.5rem;' : '4.3rem')};
  padding: 0 1rem;
  margin: ${({ $isKG }) => ($isKG && '0 0 0 0.6rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $showBag, theme }) =>
  $showBag ? theme.vars.white : 'transparent'};
  box-shadow: ${({ $showBag }) => ($showBag ? '0 0.4rem 0.3rem 0 rgba(0, 0, 0, 0.2)' : 'none')};
  ${StyledMinibagIconContainer} {
    span {
      ${({ $showBag, theme }) => $showBag ? `color: ${theme.vars.white}` : ''};
    }
  }

  &:hover {
    ${StyledMinibagDropdownContainer} {
      display: block;
    }

    ${StyledMinibagIconContainer} {
      @media ${({ theme }) => theme.devices.large} {
        path {
          stroke: ${({ theme }) => theme.vars.black};
          stroke-width: 0.5px;
        }

        span {
          color: ${({ theme }) => theme.vars.white};
        }
      }
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    margin: ${({ $isKG }) => ($isKG && '0')};
  }
`;
