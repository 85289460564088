import React, { FC, memo, Ref } from 'react';

import constants from '../../../../sites/constants';
import LinkItem from '../../../basic/LinkItem';
import {
  HeaderNavigation,
  Maybe,
  PromoBanner as PromoBannerType,
} from '../../../types/middleware-types';
import Account from '../../Account';
import BrandLogo from '../../BrandLogo';
import ContinuityBar from '../../ContinuityBar';
import CountryPicker from '../../CountryPicker';
import MainMenu from '../../MainMenu';
import MiniBag from '../../Minibag';
import MobileMenu from '../../MobileMenu';
import PromoBanner from '../../PromoBanner';
import Search from '../../Search';
import SiteSwitcher from '../../SiteSwitcher';
import SkipLink from '../../SkipLink';
import StoresIcon from '../../SVG/StoresIcon';
import {
  GlobalMainStyle,
  StyledDesktopNav,
  StyledMenu,
  StyledMobileNav,
  StyledMobileUtils,
  StyledOverlay,
  StyledSecondarySection,
  StyledStoresContainer,
  StyledStoresLink,
  StyledTransparentHeader,
  StyledUtilitiesMenu,
} from './TransparentHeaderCompactStyle';

const { storeLocator } = constants;

export interface TransparentHeaderCompactStructureProps {
  toggleSearch: (value?: boolean) => void;
  searchOpen: boolean;
  headerNavigation?: Maybe<HeaderNavigation>;
  headerTheme: string;
  headerBackground: string;
  showSiteSwitcher: boolean;
  getElement: Ref<HTMLDivElement>;
  promoBanner?: Maybe<PromoBannerType>;
  megaNavOpen: boolean;
  storesIcon: boolean;
  finalTopPositionMob: number;
  finalTopPositionDesk: number;
  domain: string;
  showDesktopLayout: boolean | undefined;
  headerPosition: string;
  headerTop: number;
  promoBannerHeight: number;
  stickyCompactHeader: boolean;
  isAtTop: boolean;
  showContinuityBar: boolean;
  showOpenSearchBar: boolean;
}

const TransparentHeaderCompactStructure: FC<TransparentHeaderCompactStructureProps> = ({
  toggleSearch,
  searchOpen,
  headerNavigation,
  headerTheme,
  headerBackground,
  showSiteSwitcher,
  getElement,
  promoBanner,
  megaNavOpen,
  storesIcon,
  finalTopPositionMob,
  finalTopPositionDesk,
  domain,
  showDesktopLayout,
  headerPosition,
  headerTop,
  promoBannerHeight,
  stickyCompactHeader,
  isAtTop,
  showContinuityBar,
  showOpenSearchBar
}) => (
  <>
    {stickyCompactHeader && <GlobalMainStyle paddingTop={showOpenSearchBar ? 0 : promoBannerHeight} />}
    {showSiteSwitcher && domain && (
      <SiteSwitcher
        domain={domain}
      />
    )}
    {!showContinuityBar && promoBanner && Object.keys(promoBanner).length !== 0 && (
      <PromoBanner data={promoBanner} />
    )}
    {showContinuityBar && promoBanner && Object.keys(promoBanner).length !== 0 && (
      <ContinuityBar data={promoBanner} />
    )}
    <SkipLink target="#mainnavigation" label="skip to navigation" />
   
    <StyledTransparentHeader
      id="header"
      data-hookid="globalHeader"
      ref={getElement}
      $headerTheme={headerTheme}
      $headerBackground={headerBackground}
      $megaNavOpen={megaNavOpen}
      $isAtTop={isAtTop}
      $finalTopPositionMob={stickyCompactHeader ? headerTop : finalTopPositionMob}
      $finalTopPositionDesk={stickyCompactHeader ? headerTop : finalTopPositionDesk}
      $headerPosition={headerPosition}
    >
      {/* Desktop nav */}
      {showDesktopLayout && (
        <StyledDesktopNav>
          <StyledUtilitiesMenu $searchOpen={searchOpen} data-hookid="desktopGlobalHeader">
            <StyledMenu>
              <CountryPicker />
              {storesIcon && (
                <StyledStoresLink $headerTheme={headerTheme}>
                  <LinkItem href={storeLocator.linkHref}>
                    <StyledStoresContainer $headerTheme={headerTheme}>
                      <StoresIcon />
                      <span>Stores</span>
                    </StyledStoresContainer>
                  </LinkItem>
                </StyledStoresLink>
              )}
            </StyledMenu>
            <BrandLogo />
            <StyledMenu>
              <Search landingSearch={false} idName="search" />
              <Account />
              <MiniBag />
            </StyledMenu>
          </StyledUtilitiesMenu>
          {headerNavigation?.desktopMenu && (
            <MainMenu data={headerNavigation?.desktopMenu} />
          )}
          <StyledOverlay
            data-hookid="desktopGlobalHeaderSearchOverlay"
            $searchOpen={searchOpen}
            onClick={() => toggleSearch(false)}
          />
        </StyledDesktopNav>
      )}

      {/* Mobile nav */}
      {!showDesktopLayout && (
        <StyledMobileNav $showOpenSearchBar={showOpenSearchBar}>
          <StyledMobileUtils $showOpenSearchBar={showOpenSearchBar} data-hookid="mobileGlobalHeader">
            <StyledSecondarySection data-hookid="mobileHeaderSecondarySectionCompactHeader">
              <MobileMenu
                data={headerNavigation?.mobileMenu}
                layout="MobileMenuCompact"
                finalTopPositionMob={finalTopPositionMob}
              />
              <Search showOpenSearchBar={showOpenSearchBar} landingSearch={!showOpenSearchBar} idName="mob-search" />
            </StyledSecondarySection>
            <BrandLogo />
            <StyledSecondarySection data-hookid="mobileHeaderSecondarySectionCompactHeader">
              <Account mobileHeader={true} />
              <MiniBag />
            </StyledSecondarySection>
          </StyledMobileUtils>
          {showOpenSearchBar && headerPosition === 'absolute' && <Search showOpenSearchBar={showOpenSearchBar} landingSearch={true} idName="search-abs" />}
        </StyledMobileNav>
      )}
    </StyledTransparentHeader>
  </>
);

export default memo(TransparentHeaderCompactStructure);
