/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectSubmenuCompactPosition } from '../../../features/nav';
import { selectPageName, selectToggles } from '../../../features/static';
import { SubMenuData } from '../../../types/custom-types';
import {
  ImageHtmlLink,
  Maybe,
  SubMenuItem
} from '../../../types/middleware-types';
import { isSubMenuArray } from '../../../types/type-checkers';
import SubmenuCompactStructure from './SubMenuColumnStructure';

export interface SubMenuColumnProps {
  data?: SubMenuData;
  showSubNav?: boolean;
  parent: string;
  closeSubmenu: () => void;
  promoBannerHeight: number;
  finalTopPositionMob: number;
  headerHeight: number;
  isDesktop?: boolean;
  mobileSubmenuImages?: Maybe<ImageHtmlLink>[];
}

type sortSubmenuDataReturn = {
  submenuLinks: Array<SubMenuItem>;
  submenuImages: Maybe<ImageHtmlLink>[] | Array<SubMenuItem> | undefined;
};

const SubMenuColumn: FC<SubMenuColumnProps> = ({
  data,
  showSubNav,
  parent,
  isDesktop,
  closeSubmenu,
  promoBannerHeight,
  finalTopPositionMob,
  headerHeight,
  mobileSubmenuImages,
}) => {
  const positionLeft = useSelector(selectSubmenuCompactPosition);
  const [subnavOffset, setSubnavOffset] = useState(0);
  const [subnavPosition, setSubnavPosition] = useState(0);
  const toggles = useSelector(selectToggles);
  const engs13232ScrollIssue = toggles?.engs13232ScrollIssue;
  const pageName = useSelector(selectPageName);
  const isHomepage = pageName === 'HomePage';

  const sortSubmenuData = (submenuData: SubMenuData): sortSubmenuDataReturn => {
    const submenuLinksArray: Array<SubMenuItem> = [];
    const submenuImagesArray: Array<SubMenuItem> = [];

    isSubMenuArray(submenuData) &&
      submenuData?.forEach((item) => {
        if (item) {
          item.items.forEach((submenuItem) => {
            if (submenuItem.__typename === 'SubMenuLinkList') {
              submenuLinksArray.push(submenuItem);
            } else {
              submenuImagesArray.push(submenuItem);
            }
          });
        }
      });

    if (mobileSubmenuImages) {
      return {
        submenuLinks: submenuLinksArray,
        submenuImages: mobileSubmenuImages,
      };
    }
    return {
      submenuLinks: submenuLinksArray,
      submenuImages: submenuImagesArray,
    };
  };

  useEffect(() => {
    const elementTop = document.querySelector('[data-hookid="globalHeaderSubMenu"]')?.getBoundingClientRect()?.top;
    if (elementTop) {
      setSubnavOffset(elementTop)
    }

    const SubnavPositionCalc = document.querySelector('[data-hookid="globalHeaderMobileNavigationContainer"]')?.getBoundingClientRect()?.top;
    if (SubnavPositionCalc) {
      setSubnavPosition(SubnavPositionCalc)
    }
  }, []);

  // This is needed for mobile to allow extra space to scroll because of the promoBanner
  const hpMobilePromoBanner = toggles?.featureShowKgHomepagePromobanner === 'mobile' || toggles?.featureShowKgHomepagePromobanner === 'true';
  const bannerSpace = (!isHomepage || hpMobilePromoBanner) ? 60 : 20;

  const componentProps = {
    ...sortSubmenuData(data),
    showSubNav,
    parent,
    isDesktop,
    closeSubmenu,
    promoBannerHeight,
    headerHeight,
    positionLeft,
    subnavOffset,
    maxHeight:
      typeof window === 'undefined' ? 0 : window.innerHeight - finalTopPositionMob - headerHeight,
    stickyHeader: toggles?.featureStickyHeader,
    subnavPosition,
    engs13232ScrollIssue,
    bannerSpace
  };

  return componentProps.submenuLinks?.length > 0 ||
    (componentProps.submenuImages && componentProps.submenuImages.length > 0) ? (
      <SubmenuCompactStructure {...componentProps} />
    ) : null;
};

export default SubMenuColumn;
