/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';

import { ImageHtmlLink, IndexSubMenu, Maybe, SubMenuItem } from '../../../types/middleware-types';
import SubNavItem from '../SubMenuItem';
import { StyledSubNavColumn } from './SubNavColumnStyles';

export interface SubNavColumnStructureProps {
  data: SubMenuItem | Maybe<ImageHtmlLink> | SubMenuItem[] | IndexSubMenu;
  parent: string;
  linkPosition?: string;
}

const SubNavColumnStructure: FC<SubNavColumnStructureProps> = ({ data, parent, linkPosition }) => {
  return (
    <StyledSubNavColumn data-hookid={`globalHeaderSubNavColumn${parent.split(' ').join('')}`}>
      {Array.isArray(data) ? (
        data.map((item, index) => (
          <SubNavItem
            key={`${item.__typename}-${index}`}
            layout={item.__typename}
            data={item}
            linkPosition={linkPosition}
            isLast={index === data.length -1 && item.__typename === 'ImageHtmlLink'}
          />
        ))
      ) : (
        <SubNavItem
          key={data?.__typename}
          layout={data?.__typename}
          data={data}
          linkPosition={linkPosition}
        />
      )}
    </StyledSubNavColumn>
  );
};

export default SubNavColumnStructure;
