import styled from 'styled-components';

import { StyledLink } from '../../../../basic/LinkItem/LinkItemStyles';

export const StyledImageLinkList = styled.div`
  display: flex;
  text-align: center;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.devices.mediumOnly} {
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      margin: 1rem;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.devices.large} {
      margin: 1%;
      width: 23%;
      min-width: 18.4rem;

      &:first-child {
        margin-left: 0;
      }

      ${StyledLink} {
        font-size: ${({ theme }) => theme.vars.sizeS};
        line-height: ${({ theme }) => theme.vars.sizeL};
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @media ${({ theme }) => theme.devices.smallOnly} {
    text-align: left;
    flex-flow: column nowrap;

    & > div {
      position: relative;
      margin: 1rem 0;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    ${StyledLink} {
      position: absolute;
      top: 50%;
      left: 10%;
    }
  }
`;
