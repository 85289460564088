import styled from 'styled-components';

export const StyledSocialLogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;

  @media ${({ theme }) => theme.devices.smallOnly} {
    margin: 1.6rem 0 0;
  }

  @media ${({ theme }) => theme.devices.mediumOnly} {
    margin: 1.6rem 0 0;
  }

  @media ${({ theme }) => theme.devices.large} {
    width: 50%;
    justify-content: flex-start;
  }
`;

export const IconListStyles = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3.2rem 0.8em;

  li {
    path {
      fill: ${({ theme }) => theme.vars.black};
    }

    margin-right: 5.1rem;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 0;
    padding: 0;

    li {
      margin-right: 2.6rem;
      margin-bottom: 0;
    }

    li:nth-child(n + 2) {
      margin: 0 2.6rem;
    }
  }

  a {
    display: block;

    svg {
      transform: scale(1.5);

      &:hover {
        path {
          stroke: ${({ theme }) => theme.vars.black};
          stroke-width: 0.5px;
        }
      }
    }
  }
`;
