import React, { FC } from 'react';

import constants from '../../../sites/constants';
import LinkItem from '../../basic/LinkItem';
import { SubMenuLinkList } from '../../types/middleware-types';
import { formate2eId } from '../../utils/formatters/formate2eFooterAccordionId';
import removeItemLineNumberFromLocalStorage from '../../utils/helpers/removeItemLineNumberFromLocalStorage';
import Accordion from '../Accordion';
import {
  StyledFooterLinkList,
  StyledFooterLinksContainer,
  StyledLinksList,
  StyledLinksListsContainer,
  StyledLinksTitle,
} from './FooterLinkStyles';

interface FooterLinkStructureProps {
  footerNavigation: SubMenuLinkList[];
  isMobile?: boolean;
  isTablet?: boolean;
  newLayout?: boolean;
  kgRedesignToggle: boolean;
}

const FooterLinkStructure: FC<FooterLinkStructureProps> = ({
  footerNavigation,
  isMobile,
  isTablet,
  newLayout,
  kgRedesignToggle,
}) => (
  <StyledFooterLinksContainer data-hookid="globalFooterFooterLinks" $newLayout={newLayout}>
    {newLayout && !isMobile && !isTablet ? (
      <StyledLinksListsContainer>
        {footerNavigation.map((nav) => (
          <StyledLinksList key={nav.title}>
            <StyledLinksTitle data-hookid="globalFooterAccordionItem" $kgRedesignToggle={kgRedesignToggle}>
              {nav.title}
            </StyledLinksTitle>
            <StyledFooterLinkList $kgRedesignToggle={kgRedesignToggle}>
              {nav.links.map((link) => (
                <li
                  key={`link-${link.url}-${link.text}`}
                  data-e2eid={`footer${formate2eId(link.text)}`}
                  data-hookid={`globalFooter${formate2eId(link.text)}`}
                >
                  <LinkItem
                    onClick={() => removeItemLineNumberFromLocalStorage()}
                    newWindow={link.newWindow}
                    href={link.url}
                  >
                    {link.text}
                  </LinkItem>
                </li>
              ))}
            </StyledFooterLinkList>
          </StyledLinksList>
        ))}
      </StyledLinksListsContainer>
    ) : (
      <Accordion
        openAllOnClick={!isMobile}
        data={footerNavigation}
        plusIcon={constants.accordionIcons.plus}
        minusIcon={constants.accordionIcons.minus}
      />
    )}
  </StyledFooterLinksContainer>
);

export default FooterLinkStructure;
