import gql from 'graphql-tag';

export const GET_CUSTOMER_DATA = gql`
  query Customer {
    customerData {
      firstname
      lastname
      email
      emailNotHashed
      phone
    }
  }
`;

export const GET_CUSTOMER_DATA_FLAT = gql`
  query Customer {
    customer {
      prefix
      firstname
      lastname
      email
      birthday
      isSubscribed
      createdAt
      currentTier
      currentPoints
      pendingPoints
    }
  }
`;