import React, { FC } from 'react';
import { ImageProps } from 'next/image';

import { MediaList, NextMediaList } from '../../types/custom-types';
import { Video } from '../../types/middleware-types';
import MediaElementStructure from './MediaElementStructure';

export interface MediaElementProps {
  mediaList?: MediaList | NextMediaList;
  alt?: string;
  type?: string;
  video?: Video;
  label?: string;
  imageType?: ImageProps['layout'];
  imgPriority?: boolean;
  sizes?: string;
}

const MediaElement: FC<MediaElementProps> = ({
  alt,
  mediaList,
  type = 'next-image',
  video,
  label,
  imageType = 'fill',
  imgPriority,
  sizes,
}) => {
  const componentProps = {
    alt,
    type,
    video,
    label,
    mediaList,
    imageType,
    imgPriority,
    sizes
  };

  return <MediaElementStructure {...componentProps} />;
};

export default MediaElement;
