import React, { FC } from 'react';

const MyBagCompact: FC = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.11929 6.76758H14.062M14.062 6.76758V6.15756C14.062 3.30906 11.835 1 9.08934 1C6.34368 1 4.11929 3.30906 4.11929 6.15756V6.76758H0.99946V19.8455C0.991099 20.142 1.10303 20.4297 1.31087 20.646C1.51871 20.8622 1.80561 20.9895 2.10907 21H15.8864C16.1903 20.9901 16.4779 20.8632 16.6863 20.6468C16.8946 20.4305 17.0069 20.1424 16.9986 19.8455V6.76758H14.062Z"
      stroke="black"
      strokeMiterlimit="10"
    />
  </svg>
);

export default MyBagCompact;
