import styled from 'styled-components';

import { StyledNextImageWrapper } from '../../basic/NextImage/NextImageStyles';
import { SmartAppBannerDevices, Theme } from '.';

interface StyledBannerProps {
  $bannerTheme?: Theme;
}

export const StyledBannerWrapper = styled.div`
  min-height: 7.8rem;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`

export const StyledSmartBanner = styled.div<StyledBannerProps>`
  display: flex;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;
  flex-direction: row;
  max-width: 100%;
  min-height: 6.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem 0.7rem;
  box-shadow: ${({ theme }) => `0 0.1rem 0.1rem ${theme.vars.greyDark}`};
  color: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.greyAppBanner : theme.vars.greyAppBannerLight)};
  background: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.greyAppBannerLightest : theme.vars.greyAppBannerDark)};

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`

export const StyledSmartBannerCloseButton = styled.button<StyledBannerProps>`
  border: 0;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  margin-right: 1rem;
  background: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.greyAppBannerLightest : theme.vars.greyAppBannerDark)};
  text-shadow: ${({ theme }) => `0 0.1rem 0 ${theme.vars.white}`};
  
  &:active,
  &:hover {
    svg {
      color: ${({ theme }) => theme.vars.greyAppBanner};
    }
  }

  svg {
    color: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.defaultBlack : theme.vars.greenLightest)};
  }
`

export const StyledSmartBannerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  text-shadow: ${({ theme }) => `0 0.1rem 0 ${theme.vars.greyAppBannerLightest}`};

  img {
    border-radius: 20%;
    padding-left: 0.4rem;
  }

  ${StyledNextImageWrapper} {
    width: 6.4rem;
  }
`

export const StyledSmartBannerColumn = styled.div<StyledBannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  margin-left: 1rem;
  text-shadow: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? `0 0.1rem 0 ${theme.vars.greyAppBannerLightest}` : `0 0.1rem 0.2rem ${theme.vars.defaultBlack}`)};
`

export const StyledSmartBannerTitle = styled.p<StyledBannerProps>`
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 0;
  margin-bottom: 0.3rem;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: ${({ $bannerTheme, theme}) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.greyAppBanner : theme.vars.white)};
`

export const StyledSmartBannerInfo = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
`

export const StyledSmartBannerLink = styled.a<StyledBannerProps>`
  margin-right: 0.6rem;
  font-size: 1.7rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  text-shadow: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? `0 0.1rem 0 ${theme.vars.greyAppBannerLightest}` : `0 0.1rem 0.2rem ${theme.vars.defaultBlack}`)};
  color: ${({ $bannerTheme, theme }) => ($bannerTheme === SmartAppBannerDevices.IOS ? theme.vars.blueLight : theme.vars.greyLightest)};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.vars.greyLight};
  }
`
