import styled from 'styled-components';

interface HeaderCompactProps {
  $headerTheme: string,
  $headerCompact?: boolean
}

interface MiniBagIconProps extends HeaderCompactProps {
  $showBag: boolean;
  $bagEmpty: boolean;
  $kgRedesign: boolean;
  $isKG: boolean;
  $finalIconMargin: string;
}

export const StyledMinibagIconContainer = styled.div<MiniBagIconProps>`
  position: relative;
  cursor: pointer;
  z-index: 10;

  svg {
    margin-bottom: ${({ $isKG, $finalIconMargin }) => ($isKG ? $finalIconMargin : '0px')};

    @media ${({ theme }) => theme.devices.large} {
      margin-bottom: ${({ $isKG }) => ($isKG ? '3px' : '0px')};
    }
  }

  svg path {
    stroke: ${({ $showBag, $headerTheme, theme }) => ($showBag ? theme.vars.black : $headerTheme)};

    &#filled-minibag-main-path {
      fill: ${({ $headerTheme, $showBag, $bagEmpty, theme }) =>
  $showBag && !$bagEmpty ? theme.vars.black : $headerTheme};
    }
  }

  background: ${({ $showBag, theme }) =>
    $showBag ? theme.vars.white : 'transparent'};

  @media ${({ theme }) => theme.devices.large} {
    padding: 1rem;
  }
`;

export const StyledMinibagCount = styled.span<HeaderCompactProps>`
  position: absolute;
  top: ${({ $headerCompact }) => (!$headerCompact ? '0.6rem' : '30%')};
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: ${({ $headerTheme, theme }) =>
  $headerTheme === theme.vars.black ? theme.vars.white : theme.vars.black};
  line-height: 1.15rem;
  font-size: 1.1rem;

  @media ${({ theme }) => theme.devices.large} {
    top: ${({ $headerCompact }) => (!$headerCompact ? '1.6rem' : '38%')};
  }
`;

export const StyledMinibagPlus = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.vars.black};
`;
