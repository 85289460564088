import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectElements } from '../../../features/elements';
import { selectToggles } from '../../../features/static';
import { SubMenuData } from '../../../types/custom-types';
import { ImageHtmlLink, Maybe, MobileMultiItemSubMenu } from '../../../types/middleware-types';
import getCustomerServicesUrl from '../../../utils/getters/getCustomerServicesUrl';
import { MobileMenuBasicProps } from '../MobileMenu.types';
import MobileMenuCompactStructure from './MobileMenuCompactStructure';

export interface MobileMenuCompactProps extends MobileMenuBasicProps {
  showSubMenu: boolean;
  setShowSubMenu: (arg0: boolean) => void;
  promoBannerHeight: number;
  finalTopPositionMob?: number;
  showCountryPickerButton?: boolean;
  hideCountryPickerOnGlobalESite?: boolean;
}

const MobileMenuCompact: FC<MobileMenuCompactProps> = ({
  isMenuOpen,
  isScrolling,
  headerTheme,
  toggleMobileMenu,
  data,
  handleDoubleClick,
  isAuth,
  accountLabel,
  accountHref,
  currentCountry,
  showCountryPicker,
  toggleCountryPicker,
  showSubMenu,
  setShowSubMenu,
  compactHeader,
  promoBannerHeight,
  finalTopPositionMob = 0,
  showCountryPickerButton,
  hideCountryPickerOnGlobalESite,
}) => {
  const [mobileSubmenuData, setMobileSubmenuData] = useState<SubMenuData>();
  const [mobileSubmenuImages, setMobileSubmenuImages] = useState<Maybe<ImageHtmlLink>[]>();
  const toggles = useSelector(selectToggles);
  const { header } = useSelector(selectElements);
  const storesIcon = toggles?.featureStoresIcon;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  const openSubmenu = (mobileData: MobileMultiItemSubMenu) => {
    setShowSubMenu(true);
    setMobileSubmenuData([{ ...mobileData }]);
    if (mobileData.images !== null && mobileData.images !== undefined) {
      setMobileSubmenuImages(mobileData?.images);
    }
  };

  const closeSubmenu = () => {
    setShowSubMenu(false);
  };
  const buffer = 43;
  const updatedTop =
    typeof window !== 'undefined'
      ? finalTopPositionMob + header?.clientHeight - window?.scrollY - buffer
      : 0;

  const componentProps = {
    isMenuOpen,
    isScrolling,
    headerTheme,
    toggleMobileMenu,
    data,
    mobileSubmenuImages,
    handleDoubleClick,
    isAuth,
    accountLabel,
    accountHref,
    currentCountry,
    showCountryPicker,
    showCountryPickerButton,
    toggleCountryPicker,
    showSubMenu,
    openSubmenu,
    closeSubmenu,
    mobileSubmenuData,
    compactHeader,
    promoBannerHeight,
    headerHeight: header?.clientHeight,
    customerServicesUrl: getCustomerServicesUrl(),
    storesIcon,
    finalTopPositionMob: updatedTop,
    maxHeight:
    typeof window !== 'undefined'
      ? window?.innerHeight - finalTopPositionMob - header?.clientHeight
      : 0,
    hideCountryPickerOnGlobalESite,
    useReactRoutingLinks,
  };

  return <MobileMenuCompactStructure {...componentProps} />;
};

export default MobileMenuCompact;
