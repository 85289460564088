import styled from 'styled-components';

interface StyledButtonProps {
  $btnType: string;
  $currentSite?: string;
  effect?: string;
  active?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme }) => theme.types.ctaTextStyles};
  color: ${({ theme }) => theme.vars.white};
  background: ${({ theme }) => theme.vars.black};
  border: 1px solid ${({ theme }) => theme.vars.black};
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  height: 3.8rem;

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.vars.greyLightest};
    border: none;
    color: ${({ theme }) => theme.vars.black};
    pointer-events: none;
  }

  &:hover,
  &:focus {
    text-decoration: ${({ theme }) => theme.buttons.primaryHover.textDecoration};
    background: ${({ theme }) => theme.buttons.primaryHover.background};
    color: ${({ theme }) => theme.buttons.primaryHover.color};
    border-color: ${({ theme }) => theme.buttons.primaryHover.borderColor};
  }
  ${({ $btnType, theme, $currentSite }) =>
  $btnType === 'secondary' &&
    `
    background: ${theme.vars.white};
    color: ${theme.vars.black};
    border-color: ${theme.buttons.secondary.borderColor};
    &:hover,
    &:focus {
      ${
        $currentSite === 'kg'
          ? `
        color: ${theme.vars.white};
        background: ${theme.vars.black};
        text-decoration: none;
      `
          : `
        background: ${theme.vars.black};
        color: ${theme.vars.white};
        border-color: ${theme.vars.black};
        text-decoration: none;
      `
      };
  `}
  ${({ $btnType, theme }) =>
        $btnType === 'tertiary' &&
    `
    border: none;
    background: transparent;
    color: ${theme.buttons.tertiary.color};
    text-decoration: underline;
    &:hover,
    &:focus {
      background: none;
      border: none;
      color: ${theme.vars.black};
      text-decoration: none;
    }
  `}
  ${({ $btnType, theme }) =>
      $btnType === 'unstyled' &&
    `
    border: none;
    background: transparent;
    color: ${theme.vars.black};
    width: unset;
    height: unset;
    line-height: unset;
    font-size: unset;
    &:hover,
    &:focus {
      background: none;
      color: ${theme.vars.brandPrimary};
      text-decoration: none;
    }
  `}
  ${({ effect, theme }) =>
      effect === 'clear' &&
    `
    background: transparent;
    color: ${theme.vars.brandPrimary};
    border-color: ${theme.vars.brandPrimary};
  `}
  ${({ effect, theme }) =>
      effect === 'short' &&
    `
    @media ${theme.devices.medium} {
      width: auto;
      padding: 0 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  `}
  ${({ active, $btnType, theme }) =>
      active &&
    `
    background: ${$btnType === 'primary' ? theme.vars.greyDark : theme.vars.greyLightest};
  `}
`;
