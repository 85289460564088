import styled from 'styled-components';

import { StyledAccountContainer, StyledAccountDropdown } from '../../Account/AccountStyles';
import { StyledMinibagDropdownContainer } from '../../Minibag/MinibagDropdown/MinibagDropdownStyles';
import { StyledSearchIconContainer } from '../../Search/SearchIcon/SearchIconStyles';

interface StyledTransparentHeaderProps {
  $headerTheme: string;
  $headerBackground: string;
  $megaNavOpen: boolean;
  $finalTopPositionMob: number;
  $finalTopPositionDesk: number;
  $headerPosition: string;
  $isAtTop: boolean;
  $isScrolling: boolean;
}

export const StyledTransparentHeader = styled.header<StyledTransparentHeaderProps>`
  z-index: 10;
  width: 100%;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: ${({ $headerTheme }) => $headerTheme};
  font-weight: unset;
  background: ${({ $headerBackground, $megaNavOpen, theme }) => ($megaNavOpen ? theme.headerColors.backgroundColor : $headerBackground)
  };
  position: ${({ $headerPosition }) => $headerPosition};
  top: ${({ $finalTopPositionMob, $isScrolling }) => $isScrolling === false ? $finalTopPositionMob : '0'}px;

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    top: ${({ $finalTopPositionDesk, $isScrolling }) => !$isScrolling ? $finalTopPositionDesk : '0'}px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    background-color: ${({ theme, $megaNavOpen, $headerPosition, $isAtTop }) => {
    if ($headerPosition === 'fixed' && !$isAtTop) return theme.vars.white
    return $megaNavOpen ? theme.headerColors.backgroundColor : 'transparent';
  }}
  }
`;

export const StyledUtilitiesMenu = styled.div<{ $searchOpen: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  flex-direction: row-reverse;
  align-items: center;
  pointer-events: all;

  @media ${({ theme }) => theme.devices.large} {
    pointer-events: ${({ $searchOpen }) => $searchOpen && 'none'};
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const StyledOverlay = styled.div<{ $searchOpen: boolean }>`
  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $searchOpen }) => ($searchOpen ? 'block' : 'none')};
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 20;
    background-color: rgb(0 0 0 / 50%);
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    margin-left: 2rem;
    justify-content: flex-start;
  }

  &:last-of-type {
    margin-right: 1rem;
    justify-content: flex-end;

    @media ${({ theme }) => theme.devices.large} {
      margin-right: 2rem;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    ${StyledMinibagDropdownContainer} {
      z-index: 12;
    }
    ${StyledAccountDropdown} {
      left: auto;
      right: 0;
    }
  }
`;

export const StyledSecondarySection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  ${StyledSearchIconContainer} {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }
  ${StyledAccountContainer} {
    margin-left: 0.7rem;
  }
`;

export const StyledDesktopNav = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
    width: 100%;
    padding: 0.8rem 0 0;
  }

  ${StyledAccountContainer} {
    margin-right: 0.7rem;
  }
`;

export const StyledMobileNav = styled.div<{ $megaNavOpen: boolean }>`
  display: block;
  width: 100%;
  height: ${({ $megaNavOpen }) => $megaNavOpen && '100vh'};
  padding: 0 1rem 0 1.6rem;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledMobileUtils = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledStoresContainer = styled.div<{ $headerTheme: string }>`
  display: flex;
  align-items: center;

  span {
    color: ${({ $headerTheme }) => $headerTheme};
    margin: 0.5rem;
  }

  path {
    stroke: ${({ $headerTheme }) => $headerTheme};
  }
`;

export const StyledStoresLink = styled.div<{ $headerTheme: string }>`
  @media ${({ theme }) => theme.devices.large} {
    a::after {
      background: ${({ $headerTheme }) => $headerTheme};
    }
  }
`;
