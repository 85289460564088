import styled from 'styled-components';

import { StyledLink } from '../../basic/LinkItem/LinkItemStyles';

export const StyledSvgContainer = styled.div`
  ${StyledLink} {
    &::after {
      display: none;
    }
  }
`;

export const StyledAccountDropdown = styled.div<{ $isHovered: boolean; $isAuth: boolean }>`
  display: ${({ $isHovered, $isAuth }) => ($isHovered && $isAuth ? 'flex' : 'none')};
  position: absolute;
  top: 2.3rem;
  left: 0;
  width: 22.4rem;
  padding: 1.5rem;
  flex-direction: column;
  text-align: left;
  color: ${({ theme }) => theme.vars.black};
  background: ${({ theme }) => theme.vars.white};
  box-shadow: -0.3rem 0.5rem 0.5rem 0 rgb(138 138 138 / 50%);
  z-index: 10;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0.4rem;

      a {
        font-size: ${({ theme }) => theme.vars.sizeS};
        font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
        line-height: 2rem;
        text-decoration: none;
        color: inherit;
      }
    }
  }
`;

export const StyledAccountContainer = styled.div<{ $compactHeaderSelected: boolean }>`
  display: ${({ $compactHeaderSelected }) => ($compactHeaderSelected ? 'flex' : 'none')};
  width: ${({ theme }) => theme.vars.sizeXL};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;

  @media ${(props) => props.theme.devices.large} {
    display: flex;
  }

  span {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* is authenticated styles */
  &.is-auth {
    &:hover {
      box-shadow: -0.5rem -0.2rem 0.5rem rgb(138 138 138 / 25%);
    }
  }
`;

export const StyledSignOutButton = styled.li`
  ${({ theme }) => theme.types.linkStyles};
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: 1.2rem;
  display: inline-block;
  text-decoration: none;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0.1rem;
    background: ${({ theme }) => theme.vars.black};
    transition: width 0.4s ease-in-out;
    margin-top: 0.3rem;
  }

  &:hover,
  &:focus {
    &::after {
      width: 100%;
    }
  }
`;
