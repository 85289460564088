import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ConstantsState, HeaderLayout } from '../../../shared/types/state-types';

const Constants: ConstantsState = {
  datalayerAddToBasketSizesType: 'uk',
  brandName: 'Carvela',
  plpSearch: {
    showHeader: false,
    noResults: ["Oops! We're sorry - there are no matches for '{{searchTerm}}'."],
    searchedResults: ["It's a hit! We found {{results}} that matched your search."],
    recommendedResults: [
      "There are no matches for '{{searchTerm}}'.",
      "But we did find some for '{{returnedSearch}}'.",
    ],
  },
  searchPlaceholder: 'Search Carvela...',
  defaultMetaData: {
    title: "Women's Shoes & Bags | Boots, Trainers & Heels | Carvela",
    description:
      "See the new collection at Carvela for the latest styles In women's shoes, boots, bags, accessories and more",
  },
  plpProductsPerPage: 36,
  storyTypes: [
    { title: 'ALL STORIES', value: 'all' },
    { title: 'STEAL THEIR STYLE', value: 'as-seen-on' },
    { title: 'TRENDING NOW', value: 'seasonal-trend' },
    { title: 'INSIDE CARVELA', value: 'gallery' },
    { title: 'WATCH', value: 'video' },
  ],
  storyTitle: 'STORIES',
  pdpProductInformation: {
    accordionTitles: {
      productDetails: 'Product Details',
      productDescription: 'Product Description',
      delivery: 'Delivery & Returns',
    },
  },
  storeLocator: {
    showDistanceLabel: false,
    linkHref: '/stores',
  },
  headerImageDimensions: {
    width: 1140,
    height: 480,
  },
  headerLayout: HeaderLayout.TransparentHeaderCompact,
  showSignUpFormSuccessSvg: false,
  mobileNewsletterAccordion: true,
  searchResultsProductNameTitleCase: false,
  accordionIcons: {
    plus: faPlus as IconProp,
    minus: faMinus as IconProp,
  },
  newsletterButtonType: 'primary',
  plpProductCardSizes: {
    small_view: '(max-width: 1024px) 50vw, 33vw',
    large_view: '(max-width: 1024px) 50vw, 25vw',
    default: '(max-width: 1024px) 50vw, 25vw',
  },
  initialsId: 'cv',
  // TODO: clean up after react router is complete
  plpRoutes: [
    '/sale',
    '/new-in',
    '/women/shoes',
    '/trends/holiday',
    '/women/bags',
    '/women/accessories',
  ],
  loadingGif: 'CARVELA-Load.gif',
};

export default Constants;
