import React, { FC, memo, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import constants from '../../../../sites/constants';
import Button from '../../../basic/Buttons';
import NextImage from '../../../basic/NextImage';
import { SubMenuData } from '../../../types/custom-types';
import { ImageHtmlLink, Maybe, MobileMultiItemSubMenu } from '../../../types/middleware-types';
import { getCountryFlag } from '../../../utils/getters/getCountryFlag';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import AccountIcon from '../../Account/AccountIcon';
import CountryPicker from '../../CountryPicker';
import MobileMenuLink from '../../MobileMenuLink';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import SubMenu from '../../SubMenu';
import ServicesIcon from '../../SVG/ServicesIcon';
import StoresIcon from '../../SVG/StoresIcon';
import { MobileMenuBasicProps } from '../MobileMenu.types';
import {
  StyledArrowIcon,
  StyledCountryFlag,
  StyledCountryPickerMobile,
  StyledLinksContainer,
  StyledMobileMenuContainer,
  StyledMobileMenuTrigger,
  StyledMobileNavigationContainer,
  StyledStoresSection,
  StyledSubMenuContainer,
  StyledUtilLinks,
} from './MobileMenuCompactStyles';

const { storeLocator } = constants;

export interface MobileMenuCompactStructureProps extends MobileMenuBasicProps {
  mobileSubmenuImages: Maybe<ImageHtmlLink>[] | undefined;
  showSubMenu: boolean;
  openSubmenu: (arg0: MobileMultiItemSubMenu) => void;
  closeSubmenu: () => void;
  mobileSubmenuData: SubMenuData;
  promoBannerHeight: number;
  customerServicesUrl?: Maybe<string>;
  storesIcon: ReactNode;
  finalTopPositionMob: number;
  headerHeight: number;
  maxHeight: number;
  showCountryPickerButton?: boolean;
  hideCountryPickerOnGlobalESite?: boolean;
  useReactRoutingLinks: boolean;
}

const MobileMenuCompactStructure: FC<MobileMenuCompactStructureProps> = ({
  isMenuOpen,
  headerTheme,
  toggleMobileMenu,
  data,
  mobileSubmenuImages,
  handleDoubleClick,
  isAuth,
  accountLabel,
  accountHref,
  currentCountry,
  toggleCountryPicker,
  showSubMenu,
  openSubmenu,
  closeSubmenu,
  showCountryPicker,
  mobileSubmenuData,
  compactHeader,
  promoBannerHeight,
  customerServicesUrl,
  storesIcon,
  finalTopPositionMob,
  headerHeight,
  maxHeight,
  showCountryPickerButton,
  hideCountryPickerOnGlobalESite,
  useReactRoutingLinks,
}) => (
  <>
    {isMenuOpen && (
      <SubMenu
        layout="SubMenuCompact"
        data={mobileSubmenuData}
        showSubNav={showSubMenu}
        closeSubmenu={closeSubmenu}
        promoBannerHeight={promoBannerHeight}
        finalTopPositionMob={finalTopPositionMob}
        headerHeight={headerHeight}
        mobileSubmenuImages={mobileSubmenuImages}
      />

    )}
    <StyledMobileMenuContainer data-hookid="globalHeaderMobileMenu">
      <StyledMobileMenuTrigger
        type="button"
        data-hookid="globalHeaderMobileMenuButton"
        onClick={toggleMobileMenu}
      >
        <ScreenReaderOnlyText text="Open or close menu" />
        <FontAwesomeIcon color={headerTheme} icon={isMenuOpen ? faTimes as IconProp : faBars as IconProp} size="2x" />
      </StyledMobileMenuTrigger>
      <StyledMobileNavigationContainer
        $isMenuOpen={isMenuOpen}
        $finalTopPositionMob={finalTopPositionMob}
        $headerHeight={headerHeight}
        $maxHeight={maxHeight}
        data-hookid="globalHeaderMobileNavigationContainer"
      >
        {!showCountryPicker ? (
          <>
            <StyledLinksContainer data-hookid="globalHeaderMobileMenuLinks">
              <ul>
                {data &&
                  data.length > 0 &&
                  data?.map((menuData) => {
                    return (
                      <li
                        id={menuData?.menuId || undefined}
                        data-hookid={`globalHeader${menuData?.link?.text?.replace(/'|,|\s/g, '')}`}
                        key={`${menuData?.link?.text}}`}
                      >
                        <Button
                          btnType="unstyled"
                          dataHook={menuData?.items?.length ? 'mobileMenuLinkWithChildren' : 'mobileMenuLink'}
                          onDoubleClick={() => handleDoubleClick(menuData?.link?.url)}
                          onClick={() => menuData?.items?.length > 0 && openSubmenu(menuData)}
                        >
                          {menuData?.items?.length > 0 ? (
                            <>
                              {menuData?.link?.text}
                              <StyledArrowIcon icon={faAngleRight as IconProp} />
                            </>
                          ) : (
                            <>
                              {useReactRoutingLinks ? (
                                <Link href={menuData?.link?.url as string} passHref legacyBehavior>
                                  <a
                                    {...(menuData?.link?.newWindow
                                      ? { target: '_blank', rel: 'noreferrer' }
                                      : {})}
                                  >
                                    {menuData?.link?.text}
                                  </a>
                                </Link>
                              ) : (
                                <a
                                  href={menuData?.link?.url}
                                  {...(menuData?.link?.newWindow
                                    ? { target: '_blank', rel: 'noreferrer' }
                                    : {})}
                                >
                                  {menuData?.link?.text}
                                </a>
                              )}
                            </>
                          )}
                        </Button>
                      </li>
                    );
                  })}
              </ul>
            </StyledLinksContainer>
            <StyledSubMenuContainer data-hookid="SubMenuContainer" $showSubMenu={showSubMenu}>
              <StyledUtilLinks>
                {storesIcon && (
                  <MobileMenuLink
                    label="Store Locator"
                    href={storeLocator.linkHref}
                    compactHeader={compactHeader}
                  >
                    <StyledStoresSection>
                      <StoresIcon />
                    </StyledStoresSection>
                  </MobileMenuLink>
                )}
                <MobileMenuLink
                  label={accountLabel}
                  href={accountHref}
                  compactHeader={compactHeader}
                >
                  <AccountIcon isAuth={isAuth} />
                </MobileMenuLink>
                {customerServicesUrl && (
                  <MobileMenuLink
                    label="Help Centre"
                    href={customerServicesUrl}
                    compactHeader={compactHeader}
                  >
                    <ServicesIcon />
                  </MobileMenuLink>
                )}
                {!hideCountryPickerOnGlobalESite &&
                  showCountryPickerButton &&
                  currentCountry?.name && (
                  <MobileMenuLink
                    label="Delivery Information"
                    onClick={toggleCountryPicker}
                    compactHeader={compactHeader}
                  >
                    <StyledCountryFlag>
                      <NextImage
                        mediaList={createMediaList({
                          src: getCountryFlag(currentCountry.name),
                          width: 20,
                          height: 16,
                        })}
                        alt={currentCountry.name}
                        imageType="fixed"
                      />
                    </StyledCountryFlag>
                  </MobileMenuLink>
                )}
                {hideCountryPickerOnGlobalESite && <div data-ge-shippingswitcher-flag />}
              </StyledUtilLinks>
            </StyledSubMenuContainer>
          </>
        ) : (
          <StyledCountryPickerMobile>
            <CountryPicker />
          </StyledCountryPickerMobile>
        )}
      </StyledMobileNavigationContainer>
    </StyledMobileMenuContainer>
  </>
);

export default memo(MobileMenuCompactStructure);
