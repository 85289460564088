import React, { FC } from 'react';

import LinkItem from '../../../../basic/LinkItem';
import { HtmlLink } from '../../../../types/middleware-types';

const HtmlLinkStructure: FC<HtmlLink> = ({ text, url, newWindow, linkId }) => {
  return (
    <LinkItem className="subnav-link" href={url} newWindow={newWindow} id={linkId || undefined}>
      {text}
    </LinkItem>
  );
};

export default HtmlLinkStructure;
