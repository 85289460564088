/* Packages */
import React, { FC, useEffect, useState } from 'react';

import { ImageV2, NewsletterSignup } from '../../../types/middleware-types';
import OrganicPopUpStructure from './OrganicPopUpStructure';

export interface OrganicPopUpProps {
  signUpButtonClicked: () => void;
  popUpthanks?: string | null;
  title?: string | null;
  image?: ImageV2;
  newsletterSignup: NewsletterSignup;
  signUpButton?: string | null;
  showOrganicPopUp: boolean;
  showThanksInfo: boolean;
  closeDelayAmount: number;
  delayAmount: number
}

const OrganicPopUp: FC<OrganicPopUpProps> = ({
  popUpthanks,
  title,
  image,
  signUpButtonClicked,
  newsletterSignup,
  signUpButton,
  showOrganicPopUp,
  showThanksInfo,
  closeDelayAmount,
  delayAmount
}) => {
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [localShowOrganicPopup, setLocalShowOrganicPopup] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (showOrganicPopUp) {
        setLocalShowOrganicPopup(true);
      }
    }, delayAmount);
  }, []);

  const closeButtonClicked = () => {
    setLocalShowOrganicPopup(false)
  }

  const componentProps = {
    popUpthanks,
    title,
    image,
    closeButtonClicked,
    signUpButtonClicked,
    newsletterSignup,
    signUpButton,
    showCloseBtn,
    showThanksInfo,
  };

  useEffect(() => {
    // hide the page scroll when showing the popup
    if (localShowOrganicPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [localShowOrganicPopup]);

  useEffect(() => {
    // show the close button after 3 sec
    const timeoutId = setTimeout(() => {
      setShowCloseBtn(true);
    }, closeDelayAmount);
    return () => clearTimeout(timeoutId);
  }, []);

  return <>{localShowOrganicPopup && <OrganicPopUpStructure {...componentProps} />}</>;
};

export default OrganicPopUp;
