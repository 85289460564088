import React, { FC, ReactElement } from 'react';

import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import AccountIconStructure from './AccountIconStructure';

interface AccountIconProps {
  isAuth: boolean;
  isHovered?: boolean;
  mobileHeader?: boolean;
}

const AccountIcon: FC<AccountIconProps> = ({
  isAuth,
  isHovered,
  mobileHeader,
}): ReactElement => {
  const { headerTheme } = useHeaderTheme();
  const componentProps = { isAuth, headerTheme, isHovered, mobileHeader };

  return <>{headerTheme && <AccountIconStructure {...componentProps} />}</>;
};

export default AccountIcon;
