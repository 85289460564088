import { RootState } from '../../../reduxStore/store';

export const selectShowMyBag = (state: RootState): boolean => state.nav.showBag;
export const selectMeganavOpen = (state: RootState): boolean => state.nav.megaNavOpen;
export const selectHideNav = (state: RootState): boolean => state.nav.hideNav;
export const selectMediaBannerColourTheme = (state: RootState): string | null => state.nav.mediaBannerColourTheme;
export const selectIsSiteSwitcherVisible = (state: RootState): boolean => state.nav.isSiteSwitcherVisible;
export const selectShowMobileCountryPicker = (state: RootState): boolean => state.nav.showMobileCountryPicker;
export const selectSearchOpen = (state: RootState): boolean => state.nav.searchOpen;
export const selectShowSiteSwitcher = (state: RootState): boolean => state.nav.showSiteSwitcher;
export const selectSubmenuCompactPosition = (state: RootState): number => state.nav.submenuCompactPosition;
export const selectIsAtTop = (state: RootState): boolean => state.nav.isAtTop;
