/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import {
  MobileSubMenu,
  SubMenuColumn,
  SubMenuItem,
} from '../../../types/middleware-types';
import { isSubMenuItem, isSubNavItem } from '../../../types/type-checkers';
import SubNavColumn from '../SubNavColumn';
import { StyledSubMenuContainer, StyledSubNavContent } from './SubMenuStyles';

export interface SubMenuStructureProps {
  data: Array<SubMenuColumn | MobileSubMenu | SubMenuItem>;
  showSubNav?: boolean;
  parent: string;
  imageLinkList: boolean;
}

const SubMenuStructure: FC<SubMenuStructureProps> = ({
  showSubNav,
  data,
  imageLinkList,
  parent,
}) => (
  <StyledSubMenuContainer $showSubNav={showSubNav} data-hookid="globalHeaderSubMenu">
    <StyledSubNavContent $imageLinkList={imageLinkList}>
      {data.length > 0 &&
        data.map((item, index) => (
          <SubNavColumn
            key={index}
            data={
              isSubNavItem(item) && item.items
                ? item.items
                : isSubMenuItem(item)
            }
            parent={parent}
          /> 
        ))}
    </StyledSubNavContent>
  </StyledSubMenuContainer>
);

export default SubMenuStructure;
