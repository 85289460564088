/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import { MobileMultiItemSubMenu } from '../../../../types/middleware-types';
import SubMenuItem from '../index';

interface MobileMultiItemSubMenuStructureProps {
  data: MobileMultiItemSubMenu;
}

const MobileMultiItemSubMenuStructure: FC<MobileMultiItemSubMenuStructureProps> = ({ data }) => {
  return (
    <>
      {data &&
        data.items?.map((item, index) => (
          <SubMenuItem key={`${item.__typename}-${index}`} layout={item.__typename} data={item} />
        ))}
    </>
  );
};

export default MobileMultiItemSubMenuStructure;
