import { Ref, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addNewElement } from '../../../features/elements';

const useElementPush = <T>(name: string): Ref<T> => {
  const dispatch = useDispatch();

  const getElement = useCallback((node: any) => {
    if (node !== null) {
      dispatch(addNewElement({ nodeName: name, node }));
    }
  }, []);

  return getElement;
};

export default useElementPush;
