'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';

import { selectToggles } from "../../features/static";
import GlobalEStructure from './GlobalEStructure';

const GlobalE: FC = () => {
  const toggles = useSelector(selectToggles);
  const globalEDomain = process.env.NEXT_PUBLIC_GLOBAL_E_BASEURL;
  const useToggledCode = toggles.engs13790GlobaleId;
  const path = usePathname() ?? '';
  const is404 = path.includes('/404');
  const [loadGlobalE, setLoadGlobalE] = useState(!is404 && !path.includes('cmsPreviewSiteHandle')
    && (toggles?.featureGlobalECountryPickerAndSiteSwitcherOnly
      || toggles?.featureGlobalE)
  );

  const [strategy, setStrategy] = useState<'afterInteractive' | 'beforeInteractive'>(
    'beforeInteractive',
  );

  useEffect(() => {
    if (is404 && Object.keys(toggles).length !== 0) {
      setLoadGlobalE(!path.includes('cmsPreviewSiteHandle')
        && (toggles?.featureGlobalECountryPickerAndSiteSwitcherOnly
          || toggles?.featureGlobalE)
      );
      setStrategy('afterInteractive');
    }
  }, [toggles]);

  return (
    <>
      {globalEDomain && loadGlobalE && (
        <GlobalEStructure domain={globalEDomain} strategy={strategy} useToggledCode={useToggledCode} />
      )}
    </>
  );
};

export default GlobalE;
