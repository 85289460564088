import window from 'global/window';
// Temporary m2 check while we don't have .env vars set up.
const useSiteChecker = (): 'sah' | 'kg' | 'carvela' => {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp(/:\/\/([^\/]+)/);
  const regexArray = regex.exec(window?.location?.href);
  const domain = regexArray && regexArray[1];

  if (
    process.env.REACT_APP_SITE === 'sah' ||
    process.env.NEXT_PUBLIC_SITE === 'sah' ||
    domain?.includes('shoeaholics')
  ) {
    return 'sah';
  }
  if (
    process.env.REACT_APP_SITE === 'carvela' ||
    process.env.NEXT_PUBLIC_SITE === 'carvela' ||
    domain?.includes('carvela')
  ) {
    return 'carvela';
  }
  return 'kg';
};

export default useSiteChecker;
