/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { Maybe } from '../../types/middleware-types';
import { clearAllPopups, updateOpenPopUp } from './actions';

export type PopUpData = Maybe<{
  [key: string]: {
    open: boolean;
    position?: number;
    imageId?: number;
  };
}>;

export type PopUpState = {
  popup: PopUpData;
};

export const initialState: PopUpState = {
  popup: null
};

export const popUpReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateOpenPopUp, (state, action) => {
      state.popup = action.payload && {
        ...state.popup,
        [action.payload.target]: { ...action.payload },
      };
    })
    .addCase(clearAllPopups, (state) => {
      state.popup = null;
    });
});
