import React, { FC } from 'react';

import {
  StyledCountdownItemContainer,
  StyledCountdownItemType,
  StyledCountdownItemValue,
} from './CountdownItemStyles';

interface CountdownItemStructureProps {
  timeValue: string;
  type: string;
  layout: string;
}

const CountdownItemStructure: FC<CountdownItemStructureProps> = ({
  timeValue,
  type,
  layout,
}) => (
  <StyledCountdownItemContainer data-hookid="CountdownItem" $layout={layout}>
    <StyledCountdownItemValue data-hookid={`CountdownItemValue${type.charAt(0)?.toUpperCase()}${type.slice(1)}`} $layout={layout}>
      {timeValue}
    </StyledCountdownItemValue>
    <StyledCountdownItemType $layout={layout}>{type}</StyledCountdownItemType>
  </StyledCountdownItemContainer>
);

export default CountdownItemStructure;
