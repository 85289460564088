import React, { FC, memo } from 'react';

import constants from '../../../../sites/constants';
import LinkItem from '../../../basic/LinkItem';
import NextImage from '../../../basic/NextImage';
import {
  ProductSearchKeywordSuggestion,
  ProductSearchProductSuggestion,
} from '../../../types/middleware-types';
import { titleCase } from '../../../utils/formatters/capitalize';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import { normaliseImageSrc } from '../../../utils/helpers/imageLoader';
import {
  StyledImageContainer,
  StyledPrices,
  StyledProductInfo,
  StyledProductLink,
  StyledProductSearchResult,
  StyledSearchHistoryContainer,
  StyledSearchHistoryText,
  StyledSearchResultsContainer,
  StyledSearchTermsContainer,
  StyledViewMoreResultsBtn,
} from './SearchResultsStyles';

const { searchResultsProductNameTitleCase } = constants;

interface SearchResultsStructureProps {
  searchTerm: string;
  elOffset: number;
  passToDataLayer: (type: string, productName?: string, brand?: string) => void;
  searchResults: ProductSearchProductSuggestion[];
  searchSuggestions: ProductSearchKeywordSuggestion[];
  compactHeader: boolean;
  showBrandName: boolean;
  searchUrl: string;
  currencySymbol: string;
  useFormatted: boolean
}

const SearchResultsStructure: FC<SearchResultsStructureProps> = ({
  searchTerm,
  elOffset,
  passToDataLayer,
  searchSuggestions,
  searchResults,
  compactHeader,
  showBrandName,
  searchUrl,
  currencySymbol,
  useFormatted
}) => (
  <StyledSearchResultsContainer
    id="search-results"
    data-hookid="globalHeaderSearchResults"
    $elOffset={elOffset}
    $compactHeader={compactHeader}
  >
    {searchSuggestions.length > 0 && (
      <StyledSearchHistoryContainer>
        <ul>
          <StyledSearchHistoryText>Suggested Results</StyledSearchHistoryText>
          {searchSuggestions.map(({ query, totalItems }) => (
            <li className="suggestedSearchTerms" key={`${query}-${totalItems}`}>
              <LinkItem
                onClick={() => passToDataLayer('text', query)}
                href={`${searchUrl}?q=${query}`}
                label={query}
              />
            </li>
          ))}
        </ul>
      </StyledSearchHistoryContainer>
    )}
    <StyledSearchTermsContainer>
      <StyledSearchHistoryText>Top Matches</StyledSearchHistoryText>
      {searchResults.length > 0 &&
        searchResults.map(({ url, thumbnail, name, brand, price, lineNumber }) => (
          <StyledProductLink
            onClick={() => passToDataLayer('image', name, brand)}
            key={lineNumber}
            href={url}
            data-hookid="globalHeaderSearchResultsProduct"
          >
            <StyledProductSearchResult>
              <StyledImageContainer>
                <NextImage
                  mediaList={createMediaList({
                    src: normaliseImageSrc(thumbnail),
                    width: 90,
                    height: 90,
                  })}
                  alt={name}
                />
              </StyledImageContainer>
              <StyledProductInfo>
                {showBrandName && <p className="brand">{brand}</p>}
                <p className="name">{searchResultsProductNameTitleCase ? titleCase(name) : name}</p>
                <StyledPrices $wasPrice={price.was}>
                  <p className="price" data-bp={price?.now} data-hookid="globalHeaderSearchResultsPriceNow">
                    {useFormatted ? (
                      <>{price?.nowFormatted}</>
                    ) : (
                      <>
                        {currencySymbol}
                        {price?.now}
                      </>
                    )}
                  </p>
                  {price?.was && (
                    <p className="price" data-hookid="globalHeaderSearchResultsPriceWas">
                      {useFormatted ? (
                        <>{price?.wasFormatted}</>
                      ) : (
                        <>
                          {currencySymbol}
                          {price?.was}
                        </>
                      )}
                    </p>
                  )}
                </StyledPrices>
              </StyledProductInfo>
            </StyledProductSearchResult>
          </StyledProductLink>
        ))}
      <StyledViewMoreResultsBtn>
        <LinkItem className="view_more" href={`${searchUrl}?q=${searchTerm}`}>
          View more results
        </LinkItem>
      </StyledViewMoreResultsBtn>
    </StyledSearchTermsContainer>
  </StyledSearchResultsContainer>
);

export default memo(SearchResultsStructure);