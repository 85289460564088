'use client'

import React, { ComponentType, FC, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

import { selectAuth } from '../../features/auth';
import { selectShowMobileCountryPicker, updateShowMobileCountryPicker } from '../../features/nav';
import {
  selectCountryPicker,
  selectIsCompactHeader,
  selectPromoBanner,
  selectToggles,
} from '../../features/static';
import { CountryPickerItem, MobileSubMenu } from '../../types/middleware-types';
import { isCountryPickerItem, isMobileMultiItemSubMenu } from '../../types/type-checkers';
import useHeaderNavigation from '../../utils/customHooks/useHeaderNavigation';
import useHeaderTheme from '../../utils/customHooks/useHeaderTheme';
import MobileMenuComponent, { MobileMenuProps as MobileMenuLayoutProps } from './MobileMenu';
import MobileMenuCompact, { MobileMenuCompactProps } from './MobileMenuCompact';
import MobileMenuCompactReverse from './MobileMenuCompactReverse'

interface MobileMenuProps {
  data?: Array<MobileSubMenu>;
  layout: string;
  finalTopPositionMob?: number;
}

const LAYOUTS: {
  [key: string]: ComponentType<MobileMenuLayoutProps> | ComponentType<MobileMenuCompactProps>;
} = {
  MobileMenu: MobileMenuComponent,
  MobileMenuCompact,
  MobileMenuCompactReverse,
};

const MobileMenu: FC<MobileMenuProps> = ({ data, layout, finalTopPositionMob }) => {
  const dispatch = useDispatch();
  const { headerTheme, headerBackground, isScrolling } = useHeaderTheme();
  const isAuth = useSelector(selectAuth);
  const toggles = useSelector(selectToggles);
  const showMobileCountryPicker = useSelector(selectShowMobileCountryPicker);
  const currentCountryData = useSelector(selectCountryPicker);

  const countryPicker = useSelector(selectCountryPicker);

  const promoBanner = useSelector(selectPromoBanner);
  const compactHeader = useSelector(selectIsCompactHeader);
  const router = useRouter();
  const [currentTab, setCurrentTab] = useState('women');
  const countryPickerItem = countryPicker;
  const [currentCountry, setCurrentCountry] = useState<CountryPickerItem | null>(
    isCountryPickerItem(countryPickerItem) ? countryPickerItem : null,
  );
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [promoBannerHeight, setPromoBannerHeight] = useState(0);

  const accountLabel = isAuth ? 'My Account' : 'Sign In/Register';
  const accountHref = '/customer/account';

  const showCountryPicker = showMobileCountryPicker;
  const { megaNavOpen, toggleMegaNav } = useHeaderNavigation();

  const showCountryPickerButton = toggles?.featureCountryPicker;
  const hideCountryPickerOnGlobalESite =
    toggles?.featureGlobalE || toggles?.featureGlobalECountryPickerAndSiteSwitcherOnly;

  const forceCompactHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu ? true : compactHeader;

  const openSubNavOnKeyPress = (e: KeyboardEvent<HTMLLIElement>, title?: string): void => {
    e.preventDefault();
    if (title) {
      setCurrentTab(title.toLowerCase());
    }
  };

  const handleDoubleClick = (url?: string): void => {
    if (url) {
      router.push(url);
    }
  };

  const toggleCountryPicker = (): void => {
    dispatch(updateShowMobileCountryPicker(!showCountryPicker));
  };

  useEffect(() => {
    if (promoBanner?.mobileItems !== undefined && promoBanner?.mobileItems?.length > 0) {
      setPromoBannerHeight(promoBanner.mobileHeightPixels || 50);
    }
  }, [promoBanner]);

  useEffect(() => {
    if (currentCountryData && isCountryPickerItem(currentCountryData)) {
      setCurrentCountry({ ...currentCountry, ...currentCountryData });
    }
  }, [currentCountryData]);

  const toggleMobileMenu = (): void => {
    setShowSubMenu(false);
    toggleMegaNav();
  };

  const componentProps = {
    showSubMenu,
    setShowSubMenu,
    isMenuOpen: megaNavOpen,
    isScrolling,
    headerBackground,
    headerTheme,
    toggleMobileMenu,
    data: isMobileMultiItemSubMenu(data) ? data : [],
    setCurrentTab,
    currentTab,
    openSubNavOnKeyPress,
    handleDoubleClick,
    isAuth,
    accountLabel,
    accountHref,
    currentCountry,
    showCountryPicker,
    toggleCountryPicker,
    compactHeader: forceCompactHeader,
    promoBannerHeight,
    finalTopPositionMob,
    showCountryPickerButton,
    hideCountryPickerOnGlobalESite,
  };

  const Component = LAYOUTS[layout];

  return Component && <Component {...componentProps} />;
};

export default MobileMenu;
