import React, { FC, ReactElement, useEffect, useState } from 'react';
import { get, remove, set } from 'local-storage';

import {
  NewsletterSignup,
} from '../../types/middleware-types';
import WelcomeProgramStructure from './WelcomeProgramStructure';

interface WelcomePopupProps {
  welcomeData: NewsletterSignup;
}

const WelcomePopup: FC<WelcomePopupProps> = ({ welcomeData }): ReactElement => {
  const userOptOutExpireInMonths = 6;
  const localStorageKey = 'user-opted-out-of-wp';
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  // check expiry date of welcome popup
  useEffect(() => {
    const now = new Date().getTime();
    const optOutExpireDate = userOptOutExpireInMonths * 4 * 7 * 24 * 60 * 60 * 1000;
    const dateOfUserOptOut = get<number>(localStorageKey);
    if (!dateOfUserOptOut || now - dateOfUserOptOut > optOutExpireDate) {
      remove(localStorageKey);
      setShowSignUpPopup(true);
    }
  }, []);

  const closeButtonClicked = (): void => {
    const now = new Date().getTime();
    set(localStorageKey, now);
    setShowFormPopup(false);
    setShowSignUpPopup(false);
  };

  const signUpButtonClicked = (): void => {
    setShowSignUpPopup(false);
    setShowFormPopup(true);
  };

  const componentProps = {
    ...welcomeData?.popups?.welcome,
    newsletterSignup: welcomeData,
    closeButtonClicked,
    signUpButtonClicked,
    showFormPopup,
    showSignUpPopup,
    localStorageKey
  };
  return <>{welcomeData && <WelcomeProgramStructure {...componentProps} />}</>;
};

export default WelcomePopup;
