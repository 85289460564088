import React, { FC, ReactNode } from 'react';
import Link from 'next/link';

import { SocialMedia, ThirdPartyAccount } from '../../types/middleware-types';
import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import { IconListStyles, StyledSocialLogoContainer } from './SocialLogosStyles';

interface SocialLogosStructureProp {
  data: SocialMedia,
  iconChooser: (arg0: ThirdPartyAccount) => ReactNode | null,
  useReactRoutingLinks: boolean,
}

const SocialLogosStructure: FC<SocialLogosStructureProp> = ({ data, iconChooser, useReactRoutingLinks }) => (
  <StyledSocialLogoContainer
    data-hookid="globalFooterSocialLogos"
  >
    <IconListStyles>
      {data?.accounts?.map((social) => {
        return (
          <li key={social.platform}>
            {useReactRoutingLinks ? (
              <Link href={social.url} passHref legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a title={`${data.title} on ${social.platform}`}>
                  <ScreenReaderOnlyText text={social.platform} />
                  {iconChooser(social)}
                </a>
              </Link>
            ) : (
              <a href={social.url} title={`${data.title} on ${social.platform}`}>
                <ScreenReaderOnlyText text={social.platform} />
                {iconChooser(social)}
              </a>
            )}
          </li>
        );
      })}
    </IconListStyles>
  </StyledSocialLogoContainer>
);

export default SocialLogosStructure;
