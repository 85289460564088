import React, { FC, memo } from 'react';

import constants from '../../../../../sites/constants';
import LinkItem from '../../../../basic/LinkItem';
import { SubMenuLinkList as LinkListTypes } from '../../../../types/middleware-types';
import Accordion from '../../../Accordion';
import { StyledLinkList, StyledLinkListContainer } from './LinkListStyles';

interface LinkListStructureProps {
  data: LinkListTypes;
  compactHeader: boolean;
  isMobile?: boolean;
}

const LinkListStructure: FC<LinkListStructureProps> = ({ data, compactHeader, isMobile }) => {
  return (
    <StyledLinkListContainer
      id={data?.menuId || undefined}
      data-hookid="globalHeaderSubMenuLinkList"
    >
      {!isMobile && (
        <>
          {data.title && <h3>{data.title}</h3>}
          <StyledLinkList $compactHeader={compactHeader}>
            {data.links.map((link) => (
              <li key={`${link.text}`}>
                <LinkItem
                  dataHook="subNavLink"
                  id={link?.linkId || undefined}
                  key={link.text}
                  newWindow={link.newWindow}
                  href={link.url}
                >
                  {link.text}
                </LinkItem>
              </li>
            ))}
          </StyledLinkList>
        </>
      )}
      {isMobile &&
        (!compactHeader ? (
          <Accordion
            isHeader={true}
            title={data.title}
            data={[data]}
            allowMultipleOpen={false}
            plusIcon={constants.accordionIcons.plus}
            minusIcon={constants.accordionIcons.minus}
          />
        ) : (
          <>
            {data.title && <h3>{data.title}</h3>}
            <StyledLinkList $compactHeader={compactHeader}>
              {data.links.map((link) => (
                <li
                  data-hookid={`globalHeader${link.text.replace(/'|,|\s/g, '')}`}
                  key={`${link.text}`}
                >
                  <LinkItem
                    dataHook="subNavLink"
                    formateUrl={true}
                    id={link?.linkId || undefined}
                    key={link.text}
                    newWindow={link.newWindow}
                    href={link.url}
                  >
                    {link.text}
                  </LinkItem>
                </li>
              ))}
            </StyledLinkList>
          </>
        ))}
    </StyledLinkListContainer>
  );
};

export default memo(LinkListStructure);
