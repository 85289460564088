import styled from 'styled-components';

import { StyledAccordionTrigger } from '../../../Accordion/AccordionStyles';

export const StyledShopBySize = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    min-height: 19.2rem;

    &:last-child {
      min-height: auto;
    }
  }

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  ul {
    @media ${({ theme }) => theme.devices.mediumOnly} {
      flex-wrap: nowrap;
      max-width: 100%;
    }

    max-width: 32rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
      list-style: none;
      border: 1px solid ${({ theme }) => theme.vars.greyDarkest};
      width: 30%;
      cursor: pointer;
      margin: 2%;
      transition: all 0.3s ease-in;
      height: 4rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1),
      &:nth-child(3n + 4) {
        margin-left: 0;
      }

      a {
        ${({ theme }) => theme.types.h4Style};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-decoration: none;
        height: 100%;
        color: ${({ theme }) => theme.vars.brandPrimary};
        transition: all 0.3s ease-in;

        &:active,
        &:hover,
        &:focus {
          background: ${({ theme }) => theme.vars.brandPrimary};
          color: ${({ theme }) => theme.vars.white};
        }
      }

      @media ${({ theme }) => theme.devices.medium} {
        height: 3.2rem;
      }
    }
  }
`;

export const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.vars.sizeXS};
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
    color: ${({ theme }) => theme.vars.black};
  }
`;

export const StyledAccordion = styled.div`
  ${StyledAccordionTrigger} {
    text-transform: none;
    font-size: 1.6rem;
    padding: 2rem 0;

    span {
      font-size: inherit;
    }

    color: ${({ theme }) => theme.vars.black};
  }
`;
