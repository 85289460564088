import styled from 'styled-components';

import StyledFormLabel from '../FormLabel/FormLabelStyles';

export const StyledRadioButton = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  & + ${StyledFormLabel} {
    cursor: pointer;
    display: flex;
    align-items: center;

    &::before {
      content: "''";
      position: relative;
      overflow: hidden;
      margin-right: 1rem;
      display: inline-block;
      width: ${({ theme }) => theme.vars.sizeL};
      height: ${({ theme }) => theme.vars.sizeL};
      border-radius: ${({ theme }) => theme.vars.sizeS};
      border: 1px solid ${({ theme }) => theme.vars.greyDark};
      background: ${({ theme }) => theme.vars.white};
      text-indent: -3em;
      box-sizing: border-box;
      vertical-align: bottom;
    }
  }

  &:focus {
    & + ${StyledFormLabel} {
      &::before {
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }
  &:checked + ${StyledFormLabel} {
    &::before {
      content: "''";
      border: 1px solid ${({ theme }) => theme.vars.greyLightest};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.vars.white} inset;
      background: ${({ theme }) => theme.vars.black};
      border-radius: 50%;
      top: 1rem;
      left: 1rem;
      transform: translate(-50%, -50%);
    }
  }
`;
