import React, { FC } from 'react';

import { ScreenReaderOnlyTextProps } from './ScreenReaderOnlyText.types';
import { StyledSRText } from './ScreenReaderOnlyTextStyles';

const ScreenReaderOnlyText: FC<ScreenReaderOnlyTextProps> = ({ text, element, attrs }) => {
  return (
    <StyledSRText {...attrs} as={element} data-hookid={attrs?.['data-hookid'] || 'screenReaderOnlyText'}>
      {text}
    </StyledSRText>
  );
};

export default ScreenReaderOnlyText;
