import { fonts, vars } from './resources';

interface TypesInterface {
  [key: string]: string;
}

export const Types: TypesInterface = {
  h1Style: `
    font-size: 20px;
    font-family: ${fonts.fontFamilyPrimary};
    font-weight: 400;
    text-transform: uppercase;
  `,
  h2Style: `
    font-size: 16px;
    font-family: ${fonts.fontFamilyPrimary};
    margin: 0;
    text-transform: uppercase;
  `,
  h3Style: `
    font-size: 11px;
    font-family: ${fonts.fontFamilyPrimary};
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
  `,
  h4Style: `
    font-size: 11px;
    font-family: ${fonts.fontFamilyPrimary};
    font-weight: 400;
    text-transform: uppercase;
  `,
  h5Style: `
    font-size: 18px;
    font-family: ${fonts.fontFamilyPrimary};
    text-transform: uppercase;
  `,
  h6Style: `
    font-size: 35px;
    font-family: ${fonts.fontFamilyPrimary};
    text-transform: uppercase;
  `,
  ctaTextStyles: `
    font-family: ${fonts.fontFamilyPrimaryBold};
    text-transform: uppercase;
    font-size: 12px;
  `,
  linkStyles: `
  text-decoration: underline;
  font-size: 12px;
  font-family: ${fonts.fontFamilyPrimary};
  font-weight: 700;
  cursor: pointer;
  color: ${vars.black};
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
  `,
  tertiaryLinkStyles: `
  text-decoration: underline;
  font-size: 11px;
  font-family: ${fonts.fontFamilyPrimary};
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  `,
  labelStyle: `
    font-family: ${fonts.fontFamilyPrimary};
    font-size: 12px;
    font-weight: normal;
  `,
  labelSmallStyle: `
    font-family: ${fonts.fontFamilyPrimary};
    font-size: 10px;
    font-weight: normal;
  `,
  boldStyle: `
  font-family: ${fonts.fontFamilyPrimaryBold};
  font-size: inherit;
  text-transform: uppercase;
`,
};

export default Types;
