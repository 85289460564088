import gql from 'graphql-tag';

import {
  BRAND_LETTERS_LINKS_LIST_FRAGMENT,
  CTA_FRAGMENT,
  NEXT_IMAGE_FRAGMENT,
  PRODUCT_FRAGMENT,
  SUB_MENU_INDEX_LINK_FRAGMENT,
} from '../fragments/basicFragments';
import { FOOTER_DATA, HEADER_DATA, HEADER_NAVIGATION_DATA } from '../fragments/headerConsolidated';

export const GET_HEADER_CONSOLIDATED_DATA = gql`
  query HeaderConsolidated(
    $code: String
    $includeCountryPicker: Boolean!
    $includeWelcomePopup: Boolean!
  ) {
    headerConsolidated(CountryCode: $code) {
      headerNavigation {
        ...HeaderNavigationData
      }
      headerTrendingSearch {
        trendingProducts {
          title
          products {
            ...Product
          }
        }
        trendingMenu {
          title
          links {
            text
            url
            newWindow
          }
        }
      }
      brandIndex {
        index {
          ...SubMenuIndexLink
        }
        brandListLinks {
          ...BrandLetterLinkList
        }
      }
      countryPicker @include(if: $includeCountryPicker) {
        ... on CountryPickerList {
          instructions
          searchLabel
          items {
            code
            name
          }
          cantFindAction {
            ...Cta
          }
        }
        ... on CountryPickerItem {
          code
          name
          instructions
          deliveryPrices {
            label
            value
          }
          moreInfoAction {
            ...Cta
          }
        }
      }
      header {
        ...HeaderData
      }
      footer {
        ...FooterData
        newsletterSignup {
          title
          description
          formTitle
          emailPlaceholder
          genderCodes {
            code
            label
          }
          legal
          thanks {
            messagePrimary
            messageSecondary
            offer
          }
          popups {
            __typename
            welcome @include(if: $includeWelcomePopup) {
              __typename
              linkTrigger
              thanks
              image {
                ...NextImage
              }
            }
            entrance {
              description
              signUpButton
              signUpSuccess
              organicSearch {
                title
                description
              }
            }
          }
        }
      }
    }
  }
  ${HEADER_NAVIGATION_DATA}
  ${HEADER_DATA}
  ${FOOTER_DATA}
  ${CTA_FRAGMENT}
  ${SUB_MENU_INDEX_LINK_FRAGMENT}
  ${BRAND_LETTERS_LINKS_LIST_FRAGMENT}
  ${NEXT_IMAGE_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`;

export const GET_HEADER_CONSOLIDATED_DATA_FLAT = gql`
  query HeaderConsolidated(
    $code: String
    $includeCountryPicker: Boolean!
    $includeWelcomePopup: Boolean!
  ) {
    headerConsolidated(CountryCode: $code) {
      headerNavigation {
        desktopMenu {
          ... on DesktopMultiColumnSubMenu {
            __typename
            menuId
            link {
              __typename
              text
              url
              newWindow
              linkId
            }
            columns {
              __typename
              items {
                __typename
                ... on SubMenuLinkList {
                  __typename
                  menuId
                  title
                  links {
                    text
                    url
                    newWindow
                    linkId
                  }
                }
                ... on SubMenuImageLinkList {
                  __typename
                  menuId
                  links {
                    image {
                      desktop {
                        src
                        width
                        height
                      }
                      tablet {
                        src
                        width
                        height
                      }
                      mobile {
                        src
                        width
                        height
                      }
                      alt
                    }
                    link {
                      text
                      url
                      newWindow
                      linkId
                    }
                    subtitle
                  }
                }
                ... on ImageHtmlLink {
                  __typename
                  image {
                    __typename
                    desktop {
                      src
                      width
                      height
                    }
                    tablet {
                      src
                      width
                      height
                    }
                    mobile {
                      src
                      width
                      height
                    }
                    alt
                  }
                  link {
                    text
                    url
                    newWindow
                    linkId
                  }
                  subtitle
                }
                ... on SubMenuShopBySize {
                  __typename
                  title
                  sizes {
                    text
                    url
                    newWindow
                    linkId
                  }
                }
              }
            }
          }
          ... on DesktopSingleColumnSubMenu {
            __typename
            menuId
            link {
              text
              url
              newWindow
              linkId
            }
            column {
              __typename
              items {
                ... on SubMenuLinkList {
                  menuId
                  title
                  links {
                    text
                    url
                    newWindow
                    linkId
                  }
                }
                ... on SubMenuImageLinkList {
                  menuId
                  links {
                    image {
                      desktop {
                        src
                        width
                        height
                      }
                      tablet {
                        src
                        width
                        height
                      }
                      mobile {
                        src
                        width
                        height
                      }
                      alt
                    }
                    link {
                      text
                      url
                      newWindow
                      linkId
                    }
                    subtitle
                  }
                }
                ... on ImageHtmlLink {
                  image {
                    desktop {
                      src
                      width
                      height
                    }
                    tablet {
                      src
                      width
                      height
                    }
                    mobile {
                      src
                      width
                      height
                    }
                    alt
                  }
                  link {
                    text
                    url
                    newWindow
                    linkId
                  }
                  subtitle
                }
                ... on SubMenuShopBySize {
                  title
                  sizes {
                    text
                    url
                    newWindow
                    linkId
                  }
                }
              }
            }
          }
          ... on IndexSubMenu {
            menuId
            link {
              text
              url
              newWindow
              linkId
            }
            index {
              title
              allUrl
            }
            links {
              title
              links {
                text
                url
                newWindow
                linkId
              }
            }
            images {
              image {
                desktop {
                  src
                  width
                  height
                }
                tablet {
                  src
                  width
                  height
                }
                mobile {
                  src
                  width
                  height
                }
                alt
              }
              link {
                text
                url
                newWindow
                linkId
              }
            }
          }
          ... on SubMenu {
            menuId
            link {
              text
              url
              newWindow
              linkId
            }
          }
        }
        mobileMenu {
          ... on MobileMultiItemSubMenu {
            __typename
            menuId
            link {
              text
              url
              newWindow
              linkId
            }
            items {
              ... on SubMenuLinkList {
                __typename
                menuId
                title
                links {
                  text
                  url
                  newWindow
                  linkId
                }
                openByDefaultOnMobile
              }
              ... on SubMenuImageLinkList {
                menuId
                links {
                  image {
                    desktop {
                      src
                      width
                      height
                    }
                    tablet {
                      src
                      width
                      height
                    }
                    mobile {
                      src
                      width
                      height
                    }
                    alt
                  }
                  link {
                    text
                    url
                    newWindow
                    linkId
                  }
                  subtitle
                }
              }
              ... on ImageHtmlLink {
                image {
                  desktop {
                    src
                    width
                    height
                  }
                  tablet {
                    src
                    width
                    height
                  }
                  mobile {
                    src
                    width
                    height
                  }
                  alt
                  alt
                }
                link {
                  text
                  url
                  newWindow
                  linkId
                }
                subtitle
              }
              ... on SubMenuShopBySize {
                title
                sizes {
                  text
                  url
                  newWindow
                  linkId
                }
              }
            }
            images {
              __typename
              image {
                __typename
                desktop {
                  src
                  width
                  height
                }
                tablet {
                  src
                  width
                  height
                }
                mobile {
                  src
                  width
                  height
                }
                alt
              }
              link {
                text
                url
                newWindow
                linkId
              }
              subtitle
            }
          }
          ... on IndexSubMenu {
            menuId
            link {
              text
              url
              newWindow
              linkId
            }
            images {
              __typename
              image {
                desktop {
                  src
                  width
                  height
                }
                tablet {
                  src
                  width
                  height
                }
                mobile {
                  src
                  width
                  height
                }
                alt
              }
              link {
                text
                url
                newWindow
                linkId
              }
            }
            links {
              title
              links {
                text
                url
                newWindow
                linkId
              }
            }
            index {
              title
              allUrl
            }
          }
        }
      }
      headerTrendingSearch {
        trendingProducts {
          title
          products {
            name
            lineNumber
            url
            brand
            brandCode
            productGenderCode
            colour
            material
            media {
              productImages {
                url
                shotType
                alt
                placeholder
              }
            }
            price {
              now
              nowFormatted
              was
              wasFormatted
              discount
              discountPercentage
            }
            newIn
            sale
            offer
            exclusive
            clearance
            priceDrop
            imageRoundel
          }
        }
        trendingMenu {
          title
          links {
            text
            url
            newWindow
          }
        }
      }
      brandIndex {
        index {
          letter
          enabled
          url
        }
        brandListLinks {
          letter
          links {
            text
            url
            newWindow
            linkId
          }
        }
      }
      countryPicker @include(if: $includeCountryPicker) {
        ... on CountryPickerList {
          instructions
          searchLabel
          items {
            code
            name
          }
          cantFindAction {
            text
            url
            newWindow
            linkId
          }
        }
        ... on CountryPickerItem {
          code
          name
          instructions
          deliveryPrices {
            label
            value
          }
          moreInfoAction {
            text
            url
            newWindow
            linkId
          }
        }
      }
      header {
        logo {
          dark {
            __typename
            desktop {
              __typename
              src
              width
              height
            }
            tablet {
              __typename
              src
              width
              height
            }
            mobile {
              __typename
              src
              width
              height
            }
            alt
          }
          light {
            __typename
            desktop {
              __typename
              src
              width
              height
            }
            tablet {
              __typename
              src
              width
              height
            }
            mobile {
              __typename
              src
              width
              height
            }
            alt
          }
        }
        promoBanner {
          desktopHeightPixels
          mobileHeightPixels
          desktopItems {
            backgroundImage {
              desktop {
                src
                width
                height
              }
              tablet {
                src
                width
                height
              }
              mobile {
                src
                width
                height
              }
              alt
            }
            backgroundColourHex
            textAlignment
            fontSize
            fontColourHex
            expiryDate
            textHtml
            textLink {
              text
              url
              newWindow
              linkId
            }
            linkWrapsWholeItem
          }
          mobileItems {
            backgroundImage {
              desktop {
                src
                width
                height
              }
              tablet {
                src
                width
                height
              }
              mobile {
                src
                width
                height
              }
              alt
            }
            backgroundColourHex
            textAlignment
            fontSize
            fontColourHex
            expiryDate
            textHtml
            textLink {
              text
              url
              newWindow
              linkId
            }
            linkWrapsWholeItem
          }
          hasSeparator
          continuityBar
        }
      }
      footer {
        menu {
          title
          links {
            text
            url
            newWindow
            linkId
          }
        }
        socialMedia {
          title
          accounts {
            platform
            url
          }
        }
        appStores {
          accounts {
            platform
            url
          }
        }
        cookiePolicy
        copyright
        newsletterSignup {
          title
          description
          formTitle
          emailPlaceholder
          genderCodes {
            code
            label
          }
          legal
          thanks {
            messagePrimary
            messageSecondary
            offer
          }
          popups {
            __typename
            welcome @include(if: $includeWelcomePopup) {
              __typename
              linkTrigger
              thanks
              image {
                desktop {
                  src
                  width
                  height
                }
                tablet {
                  src
                  width
                  height
                }
                mobile {
                  src
                  width
                  height
                }
                alt
              }
            }
            entrance {
              description
              signUpButton
              signUpSuccess
              organicSearch {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;
