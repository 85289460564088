import { CustomMedia } from '../../../types/custom-types';
import { Maybe, Media, Video } from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';

// Pass CMS Media object which can be video or image
const parseCMSMediaProp = (media?: Maybe<Media> | Record<string, never>): CustomMedia => {
  if (!media) {
    return null;
  }

  if ((media as Video).videoIdDesktop || (media as Video).videoIdMobile) {
    return {
      video: {
        ...(media as Video),
      },
      type: 'video',
    };
  }

  const image = isImageV2(media) ? media : null;
  if (!image) {
    return null;
  }

  if (!(image.desktop || image.mobile || image.tablet)) {
    return null;
  }

  const { desktop, mobile, tablet } = image;
  return {
    alt: image.alt || '',
    mediaList: {
      desktop,
      mobile,
      tablet,
    },
    defaultImageUrl: (image.desktop || image.tablet || image.mobile).src,
    type: 'next-image',
  };
};

export default parseCMSMediaProp;
