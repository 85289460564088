/* eslint-disable react/no-danger */
import React, { ChangeEvent, FC, FormEvent } from 'react';

import constants from '../../../sites/constants';
import Button from '../../basic/Buttons';
import RadioButton from '../../basic/RadioButton';
import TextInput from '../../basic/TextInput';
import { NewsletterSignup } from '../../types/middleware-types';
import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import SignUpFormSuccessSvg from '../SVG/SignUpFormSuccessSvg';
import {
  Container,
  StyledPrivacyMessage,
  StyledPromoMessage,
  StyledRadioButtonContainer,
  StyledSignUpConfirmation,
  StyledSignUpConfirmationHtml,
  StyledSignUpForm,
  StyledSignUpMain,
} from './NewsletterSignUpStyles';
import { SignUpFormData, SignUpFormError } from '.';

export interface NewsletterSignUpStructureProps extends NewsletterSignup {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: SignUpFormError;
  formData: SignUpFormData;
  showSignUpFormSuccessSvg: boolean | undefined;
  submitted: boolean;
  showSignUpDescription?: boolean;
  thanksHtml?: string | null;
  newLayout?: boolean;
  idPrefix: string;
  setSignUpButton?: string | null;
  labelTxt: string;
  kgRedesignToggle: boolean;
}

const NewsletterSignUpStructure: FC<NewsletterSignUpStructureProps> = ({
  handleSubmit,
  handleChange,
  error,
  formData,
  submitted,
  thanks,
  description,
  emailPlaceholder,
  legal,
  genderCodes,
  showSignUpDescription = true,
  showSignUpFormSuccessSvg,
  thanksHtml,
  newLayout,
  idPrefix,
  setSignUpButton,
  labelTxt,
  kgRedesignToggle,
}) => (
  <Container data-hookid="globalFooterSubscribed" $newLayout={newLayout}>
    {description && showSignUpDescription && <StyledPromoMessage $kgRedesignToggle={kgRedesignToggle}>{description}</StyledPromoMessage>}
    {submitted ? (
      <>
        {thanksHtml ? (
          <StyledSignUpConfirmationHtml
            data-hookid="globalFooterMessageThanksMsg"
            dangerouslySetInnerHTML={{ __html: thanksHtml }}
          />
        ) : (
          <StyledSignUpConfirmation data-hookid="globalFooterMessageSubscribed">
            {showSignUpFormSuccessSvg && <SignUpFormSuccessSvg />}
            <p>{thanks.messagePrimary}</p>
            <p>{thanks.messageSecondary}</p>
            <p className="offer">{thanks.offer}</p>
          </StyledSignUpConfirmation>
        )}
      </>
    ) : (
      <>
        <StyledSignUpForm onSubmit={(e) => handleSubmit(e)} data-hookid="globalFooterSignupBox">
          <StyledSignUpMain>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`${idPrefix}-email`}>
              <ScreenReaderOnlyText text={labelTxt} />
            </label>
            <TextInput
              data-hookid="globalFooterSignUpTextInput"
              name="email"
              id={`${idPrefix}-email`}
              error={error.error}
              errorMsg={error.errorMsg}
              value={formData.email}
              onChange={handleChange}
              type="text"
              placeholder={emailPlaceholder}
            />
            <Button
              className="desktop"
              data-hookid="globalFooterSignUpButton"
              type="submit"
              btnType={constants.newsletterButtonType}
            >
              {setSignUpButton || `sign up`}
            </Button>
          </StyledSignUpMain>
          <StyledRadioButtonContainer $newLayout={newLayout} data-hookid="globalFooterSignUpRadioButtons">
            {genderCodes?.length > 1 &&
              genderCodes?.map((option, index) => (
                <RadioButton
                  isDefault={index === 0}
                  key={option.code}
                  id={`${idPrefix}${option.label.toLowerCase()}`}
                  className="input"
                  onChange={handleChange}
                  name="option"
                  label={option.label}
                  value={option.code === '02' ? 'm' : 'f'}
                />
              ))}
          </StyledRadioButtonContainer>
          <StyledPrivacyMessage $kgRedesignToggle={kgRedesignToggle}>
            <div dangerouslySetInnerHTML={{ __html: legal }} />
          </StyledPrivacyMessage>
          <Button
            data-hookid="globalFooterSignUpButton"
            className="mobile"
            type="submit"
            btnType={constants.newsletterButtonType}
          >
            {setSignUpButton || `sign up`}
          </Button>
        </StyledSignUpForm>
      </>
    )}
  </Container>
);

export default NewsletterSignUpStructure;
