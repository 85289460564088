import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { StyledImageHtmlLink } from '../SubMenuItem/ImageHtmlLink/ImageHtmlLinkStyles';
import {
  StyledLinkList,
  StyledLinkListContainer,
} from '../SubMenuItem/SubMenuLinkList/LinkListStyles';
import { StyledSubNavColumn } from '../SubNavColumn/SubNavColumnStyles';

export interface SubMenuContainerProps {
  $promoBannerHeight: number;
  $finalTopPositionMob: number;
  $headerHeight: number;
  $maxHeight: number;
  $showSubNav?: boolean;
  $isDesktop?: boolean;
  $stickyHeader: boolean;
  $toggleSAHUpdate?: boolean;
}

interface SubMenuLinksColumnProps {
  $positionLeft?: number;
  $isDesktop?: boolean;
  $showSingleColumnSubNav?: boolean;
}

export const SubMenuContainer = styled.div<SubMenuContainerProps>`
  position: fixed;
  top: ${({ $finalTopPositionMob, $headerHeight, $stickyHeader }) => $stickyHeader ? $finalTopPositionMob : $finalTopPositionMob + $headerHeight}px;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  left: 100%;
  background: ${({ theme }) => theme.headerColors.backgroundColor};
  width: 100%;
  cursor: default;
  overflow: scroll;
  box-sizing: border-box;
  box-shadow: 1px 7px 9px -3px hsl(0deg 0% 54.1% / 50%);
  z-index: 30;
  transform: ${({ $showSubNav }) => ($showSubNav ? 'translateX(-100%)' : 'translateX(0)')};
  transition: transform 600ms ease-in;
  margin-top: 0;
  padding-top: 0;
  ${({ $toggleSAHUpdate }) => $toggleSAHUpdate && `
    padding-bottom: 93px;
  `}

  @media ${({ theme }) => theme.devices.large} {
    display: ${({ $showSubNav }) => ($showSubNav ? 'flex' : 'none')};
    flex-direction: row;
    position: absolute;
    top: unset;
    left: 0;
    z-index: 10;
    margin-top: 1.2rem;
    padding-top: 1.8rem;
    min-height: 33rem;
    height: auto;
    transform: none;
    transition: none;
    overflow: hidden;
    padding-bottom: 0;
  }
`;

export const SubmenuLinksColumn = styled.div<SubMenuLinksColumnProps>`
  display: ${({ $showSingleColumnSubNav }) => ($showSingleColumnSubNav ? 'block' : 'flex')};
  justify-content: flex-starts;
  min-width: 26%;
  margin-left: 1rem;

  @media ${({ theme }) => theme.devices.large} {
    margin-left: ${({ $positionLeft }) => $positionLeft}rem;
  }

  ${StyledSubNavColumn} {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  ${StyledLinkListContainer} {
    margin-bottom: 0.8rem;
    min-height: unset;
  }

  ${StyledLinkList} {
    margin: 0;

    li {
      margin: ${({ $isDesktop }) => ($isDesktop ? '1rem 0.7rem' : '0')};

      @media ${({ theme }) => theme.devices.large} {
        margin: 0 0 1rem;
      }

      a {
        font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
        padding: ${({ $isDesktop }) => ($isDesktop ? '0' : '1rem 0.7rem')};
        width: ${({ $isDesktop }) => ($isDesktop ? 'auto' : 'calc(100% - 2rem)')};
      }
    }
  }
`;

export const SubmenuImagesContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin: 0 0.8rem 1rem;

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;
    width: 38%;
    max-width: 55rem;
    ${StyledNextImageWrapper} {
      > div {
        width: unset;
        max-height: 24rem;
        max-width: 100%;

        img {
          max-height: 24rem !important;
        }
      }
    }
    ${StyledSubNavColumn} {
      width: unset;
      ${StyledImageHtmlLink} {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const StyledBackToMainMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.headerColors.mobileMenuBackBg};
  border-bottom: 1px solid ${({ theme }) => theme.vars.warmGray || 'transparent'};
  padding: 0.8rem 0;
  margin-bottom: 2rem;

  p {
    font-size: ${({ theme }) => theme.vars.sizeS};
    line-height: 1.25rem;
    text-transform: uppercase;
  }
`;

export const BackToMainMenuIcon = styled(FontAwesomeIcon)`
  min-width: 1.5rem;
  height: 2.5rem;
  margin: 0 0.8rem;
`;
