/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';

import { ImageHtmlLink, Maybe, SubMenuItem } from '../../../types/middleware-types';
import SubNavColumn from '../SubNavColumn';
import {
  BackToMainMenuIcon,
  StyledBackToMainMenuWrapper,
  SubMenuContainer,
  SubmenuImagesContainer,
  SubmenuLinksColumn,
} from './SubMenuColumnStyle';

export interface SubMenuColumnStructureProps {
  submenuLinks: Array<SubMenuItem>;
  submenuImages: Maybe<ImageHtmlLink>[] | Array<SubMenuItem> | undefined;
  maxHeight: number;
  positionLeft?: number;
  stickyHeader: boolean;
  subnavOffset: number;
  subnavPosition: number;
  showSubNav?: boolean;
  parent: string;
  closeSubmenu: () => void;
  promoBannerHeight: number;
  headerHeight: number;
  isDesktop?: boolean;
  engs13232ScrollIssue: boolean;
  bannerSpace: number;
}

const SubMenuColumnStructure: FC<SubMenuColumnStructureProps> = ({
  showSubNav,
  parent,
  submenuLinks,
  submenuImages,
  isDesktop,
  closeSubmenu,
  promoBannerHeight,
  headerHeight,
  maxHeight,
  positionLeft,
  stickyHeader,
  subnavOffset,
  subnavPosition,
  engs13232ScrollIssue,
  bannerSpace
}) => {
  return (
    <SubMenuContainer
      $subnavOffset={subnavOffset}
      $showSubNav={showSubNav}
      $isDesktop={isDesktop}
      $promoBannerHeight={promoBannerHeight}
      data-hookid="globalHeaderSubMenu"
      $stickyHeader={stickyHeader}
      $headerHeight={headerHeight}
      $maxHeight={maxHeight}
      $subnavPosition={subnavPosition}
      $engs13232ScrollIssue={engs13232ScrollIssue}
      $bannerSpace={bannerSpace}
    >
      {!isDesktop && (
        <StyledBackToMainMenuWrapper onClick={closeSubmenu}>
          <BackToMainMenuIcon icon={faAngleLeft as IconProp} />
          <p>back</p>
        </StyledBackToMainMenuWrapper>
      )}

      <SubmenuLinksColumn $positionLeft={positionLeft} $isDesktop={isDesktop}>
        {submenuLinks.map((column, index) => (
          <SubNavColumn data={column} parent={parent || ''} key={index} />
        ))}
      </SubmenuLinksColumn>
      <SubmenuImagesContainer>
        {submenuImages && submenuImages.map((column: SubMenuItem | Maybe<ImageHtmlLink>, index: number) => (
          <SubNavColumn data={column} parent={parent || ''} linkPosition="bottom" key={index} />
        ))}
      </SubmenuImagesContainer>
    </SubMenuContainer>
  );
};

export default SubMenuColumnStructure;
