import React, { ChangeEvent, FC, FormEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import { useTheme } from 'styled-components';

import constants from '../../../sites/constants';
import { selectElements } from '../../features/elements';
import { selectIsCompactHeader, selectToggles } from '../../features/static';
import { GET_SEARCH_RESULTS } from '../../graphQl/queries/search';
import {
  ProductSearchKeywordSuggestion,
  ProductSearchProductSuggestion,
  ProductSearchSuggestions,
} from '../../types/middleware-types';
import useHeaderNavigation from '../../utils/customHooks/useHeaderNavigation';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import SearchStructure from './SearchStructure';

interface SearchProps {
  landingSearch: boolean;
  idName?: string;
  showOpenSearchBar?: boolean;
}

const Search: FC<SearchProps> = ({ landingSearch, idName, showOpenSearchBar }) => {
  const compactHeader = useSelector(selectIsCompactHeader);
  const { searchPlaceholder } = constants;
  const toggles = useSelector(selectToggles);
  const { header } = useSelector(selectElements);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState<ProductSearchKeywordSuggestion[]>([]);
  const [searchResults, setSearchResults] = useState<ProductSearchProductSuggestion[]>([]);
  const [getSearchResults, { data, error }] =
    useLazyQuery<{ productSearchSuggestions: ProductSearchSuggestions }>(GET_SEARCH_RESULTS);
  const { searchOpen, toggleSearch } = useHeaderNavigation();
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const [finalHeaderHeight, setFinalHeaderHeight] = useState(header?.clientHeight);

  const searchUrl =
    process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_IS_LOCAL === 'true'
      ? '/plp/enrichmentdata/search/index'
      : '/enrichmentdata/search/index';

  const getSearchResultsDebounced = useCallback(debounce(getSearchResults, 200), []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 2) {
      getSearchResultsDebounced({
        variables: {
          query: term,
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      getSearchResultsDebounced.cancel();
    }
  }, []);

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  useEffect(() => {
    if (data) {
      const { productSearchSuggestions } = data;
      setSearchSuggestions(productSearchSuggestions?.keywords || []);
      setSearchResults(productSearchSuggestions?.products || []);
    }
  }, [data]);

  const sendToSearchPlp = () => {
    if (searchTerm.length > 2) {
      const url = `${window.location.protocol}//${window.location.host
        }${searchUrl}?q=${encodeURIComponent(searchTerm)}`;
      window.open(url, '_self');
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      sendToSearchPlp();
    }
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    sendToSearchPlp();
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchClose = () => {
    toggleSearch(false);
    clearSearch();
    setSearchResults([]);
    setSearchSuggestions([]);
  };

  useEffect(() => {
    if (header === undefined) {
      const headerElement = document.getElementById('header');
      setFinalHeaderHeight(headerElement?.clientHeight)
    }
  }, [header])

  const componentProps = {
    searchOpen,
    handleChange,
    searchTerm,
    clearSearch,
    landingSearch,
    handleSearch,
    handleKeyPress,
    searchSuggestions,
    searchResults,
    handleSearchClose,
    compactHeader,
    searchUrl,
    headerHeight: finalHeaderHeight,
    searchPlaceholder,
    idName,
    showOpenSearchBar,
    showTrendingData: toggles.featureTrendingSearchResults && isDesktop
  };

  return <SearchStructure {...componentProps} />;
};

export default Search;
