import React, { FC, memo, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import Button from '../../../basic/Buttons';
import { SubMenuData } from '../../../types/custom-types';
import { ImageHtmlLink, Maybe, MobileMultiItemSubMenu } from '../../../types/middleware-types';
import CountryPicker from '../../CountryPicker';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import SubMenu from '../../SubMenu';
import { MobileMenuBasicProps } from '../MobileMenu.types';
import {
  StyledArrowIcon,
  StyledCountryPickerMobile,
  StyledLinksContainer,
  StyledMobileMenuContainer,
  StyledMobileMenuTrigger,
  StyledMobileNavigationContainer,
  StyledSubMenuContainer,
  StyledUtilLinks,
} from './MobileMenuCompactReverseStyles';

export interface MobileMenuCompactStructureProps extends MobileMenuBasicProps {
  mobileSubmenuImages: Maybe<ImageHtmlLink>[] | undefined;
  showSubMenu: boolean;
  openSubmenu: (arg0: MobileMultiItemSubMenu) => void;
  closeSubmenu: () => void;
  mobileSubmenuData: SubMenuData;
  promoBannerHeight: number;
  customerServicesUrl?: Maybe<string>;
  storesIcon: ReactNode;
  finalTopPositionMob: number;
  headerHeight: number;
  showCountryPickerButton?: boolean;
  hideCountryPickerOnGlobalESite?: boolean;
  headerOffset: number;
  bannerSpace: number;
  engs13232ScrollIssue: boolean;
  mobileLinkList: { url: string | null | undefined, name: string | undefined }[];
  useReactRoutingLinks: boolean;
}

const MobileMenuCompactStructure: FC<MobileMenuCompactStructureProps> = ({
  isMenuOpen,
  headerTheme,
  toggleMobileMenu,
  data,
  mobileSubmenuImages,
  handleDoubleClick,
  showSubMenu,
  openSubmenu,
  closeSubmenu,
  showCountryPicker,
  mobileSubmenuData,
  promoBannerHeight,
  finalTopPositionMob,
  headerHeight,
  hideCountryPickerOnGlobalESite,
  headerOffset,
  bannerSpace,
  engs13232ScrollIssue,
  mobileLinkList,
  useReactRoutingLinks,
}) => (
  <>
    {isMenuOpen && (
      <SubMenu
        layout="SubMenuColumn"
        data={mobileSubmenuData}
        showSubNav={showSubMenu}
        closeSubmenu={closeSubmenu}
        promoBannerHeight={promoBannerHeight}
        finalTopPositionMob={finalTopPositionMob}
        headerHeight={headerHeight}
        mobileSubmenuImages={mobileSubmenuImages}
      />

    )}
    <StyledMobileMenuContainer data-hookid="globalHeaderMobileMenu">
      <StyledMobileMenuTrigger
        type="button"
        data-hookid="globalHeaderMobileMenuButton"
        onClick={toggleMobileMenu}
      >
        <ScreenReaderOnlyText text="Open or close menu" />
        <FontAwesomeIcon color={headerTheme} icon={isMenuOpen ? faTimes as IconProp : faBars as IconProp} size="2x" />
      </StyledMobileMenuTrigger>
      <StyledMobileNavigationContainer
        $isMenuOpen={isMenuOpen}
        $finalTopPositionMob={finalTopPositionMob}
        $headerHeight={headerHeight}
        $headerOffset={headerOffset}
        $bannerSpace={bannerSpace}
        $engs13232ScrollIssue={engs13232ScrollIssue}
        data-hookid="globalHeaderMobileNavigationContainer"
      >
        {!showCountryPicker ? (
          <>
            <StyledLinksContainer data-hookid="globalHeaderMobileMenuLinks">
              <ul>
                {data &&
                  data.length > 0 &&
                  data?.map((menuData) => {
                    return (
                      <li
                        id={menuData?.menuId || undefined}
                        data-hookid={`globalHeader${menuData?.link?.text?.replace(/'|,|\s/g, '')}`}
                        key={`${menuData?.link?.text}}`}
                      >
                        <Button
                          btnType="unstyled"
                          dataHook={menuData?.items?.length ? 'mobileMenuLinkWithChildren' : 'mobileMenuLink'}
                          onDoubleClick={() => handleDoubleClick(menuData?.link?.url)}
                          onClick={() => menuData?.items?.length > 0 && openSubmenu(menuData)}
                        >
                          {menuData?.items?.length > 0 ? (
                            <>
                              {menuData?.link?.text}
                              <StyledArrowIcon icon={faAngleRight as IconProp} />
                            </>
                          ) : (
                            <>
                              {useReactRoutingLinks ? (
                                <Link href={menuData?.link?.url as string} passHref legacyBehavior>
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    {...(menuData?.link?.newWindow
                                      ? { target: '_blank', rel: 'noreferrer' }
                                      : {})}
                                  >
                                    {menuData?.link?.text}
                                  </a>
                                </Link>
                              ) : (
                                <a
                                  href={menuData?.link?.url}
                                  {...(menuData?.link?.newWindow
                                    ? { target: '_blank', rel: 'noreferrer' }
                                    : {})}
                                >
                                  {menuData?.link?.text}
                                </a>
                              )}
                            </>

                          )}
                        </Button>
                      </li>
                    );
                  })}
              </ul>
            </StyledLinksContainer>
            <StyledSubMenuContainer data-hookid="SubMenuContainer">
              <StyledUtilLinks>
                {mobileLinkList && mobileLinkList.map((linkItem) => {
                  if (linkItem?.url) {
                    return (
                      <li key={linkItem.name}>
                        {useReactRoutingLinks ? (
                          <Link href={linkItem.url} passHref>
                            {linkItem.name}
                          </Link>
                        ) : (
                          <a href={linkItem.url}>{linkItem.name}</a>
                        )}
                      </li>
                    )
                  }
                  return null
                })}
                {hideCountryPickerOnGlobalESite && <li data-ge-shippingswitcher-flag />}
              </StyledUtilLinks>
            </StyledSubMenuContainer>
          </>
        ) : (
          <StyledCountryPickerMobile>
            <CountryPicker />
          </StyledCountryPickerMobile>
        )}
      </StyledMobileNavigationContainer>
    </StyledMobileMenuContainer>
  </>
);

export default memo(MobileMenuCompactStructure);
