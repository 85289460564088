import React, { FC } from 'react';
import Link from 'next/link';

import LinkItem from '../../../../basic/LinkItem';
import MediaElement from '../../../../basic/MediaElement';
import { ImageHtmlLink } from '../../../../types/middleware-types';
import formatPromoEvent from '../../../../utils/DataLayer/formatPromoEvent';
import parseCMSMediaProp from '../../../../utils/formatters/parseCMSMediaProp';
import { StyledImageLinkList } from './ImageLinkListStyles';

interface SubMenuImageLinkListStructureProps {
  data: Array<ImageHtmlLink>;
  useReactRoutingLinks: boolean;
}
const SubMenuImageLinkListStructure: FC<SubMenuImageLinkListStructureProps> = ({ data, useReactRoutingLinks }) => (
  <StyledImageLinkList data-hookid="globalHeaderSubMenuImageLink">
    {data.length > 0 &&
      data.map(({ image, link }, index) => (
        <div data-hookid="globalHeaderSubMenuImageLinkListItem" key={`${link?.text}-${index + 2}`}>
          {useReactRoutingLinks ? (
            <Link href={link?.url as string} passHref legacyBehavior>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
              <a
                id={link?.linkId || undefined}
                {...(link?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                onClick={() =>
                  formatPromoEvent(
                    link?.text,
                    index + 1,
                    link?.url,
                    image.desktop.src,
                    'meganav',
                    'click',
                  )}
              >
                <MediaElement {...parseCMSMediaProp(image)} imageType="intrinsic" />
              </a>
            </Link>
          ) : (
            <a
              id={link?.linkId || undefined}
              href={link?.url}
              {...(link?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
              onClick={() =>
                formatPromoEvent(
                  link?.text,
                  index + 1,
                  link?.url,
                  image.desktop.src,
                  'meganav',
                  'click',
                )}
            >
              <MediaElement {...parseCMSMediaProp(image)} imageType="intrinsic" />
            </a>
          )}
          {link && (
            <LinkItem id={link?.linkId || undefined} href={link.url} newWindow={link.newWindow}>
              {link.text}
            </LinkItem>
          )}
        </div>
      ))}
  </StyledImageLinkList>
);

export default SubMenuImageLinkListStructure;
