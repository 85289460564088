import addToDataLayer from '../addToDataLayer';

const formatUserLocationObject = (location: string): void => {
  const userLocation = {
    user_location: location,
  };
  addToDataLayer(userLocation);
};

export default formatUserLocationObject;
