import styled from 'styled-components';

import { StyledLink } from '../../../basic/LinkItem/LinkItemStyles';

interface StyledMainMenuItemProps {
  $compactHeader: boolean;
  $headerTheme: string;
  $currentTab: string;
  $isCurrentTab: boolean;
  $red: boolean;
  $isHeaderReverse: boolean;
}

export const StyledMainMenuItem = styled.li<StyledMainMenuItemProps>`
  list-style: none;
  padding: ${({ $isHeaderReverse }) => ($isHeaderReverse ? '1.6rem 1.6rem 1.2rem' : '1.2rem 1.6rem')};
  cursor: pointer;
  display: inline-block;
  ${StyledLink} {
    ${({ theme, $compactHeader }) => ($compactHeader ? theme.types.linkStyles : theme.types.h3Style)};
    color: ${({ $headerTheme, $currentTab, $isCurrentTab, $red, theme }) => {
  if ($red) return theme.vars.red;
  return $currentTab === '' || !$isCurrentTab ? $headerTheme : theme.vars.black }};
    font-size: ${({ $compactHeader, $isHeaderReverse }) => {
    if($compactHeader && !$isHeaderReverse) {
      return '1.2rem'
    }
    if ($compactHeader && $isHeaderReverse) {
      return '1.4rem'
    }
    return null
  }};
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    text-transform: none;
  }

  .active {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.vars.black};
      margin-top: 3px;
    }
  }

  .active,
  &:hover {
    background: ${({ theme }) => theme.headerColors.backgroundColor};
  }
`;
