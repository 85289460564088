import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface StyledTextInputProps {
  $error?: boolean;
  $hasIcon?: boolean;
  ref: (input: HTMLInputElement) => false | void | undefined
}

export const StyledTextInput = styled.input<StyledTextInputProps>`
  padding: 1rem 1.5rem;
  border: 1px solid ${(props) => (props.$error ? props.theme.vars.red : props.theme.vars.greyDark)};
  border-radius: 0;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  padding-left: ${({ $hasIcon }) => ($hasIcon ? '3.2rem' : '1rem')};

  &::placeholder {
    font-size: 1.4rem;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  }
`;

interface StyledErrorMsgProps {
  $darkBg?: boolean;
  $hasIcon?: boolean;
}

export const StyledErrorMsg = styled.div<StyledErrorMsgProps>`
  color: ${(props) => (props.$darkBg ? props.theme.vars.white : props.theme.vars.red)};
  font-size: 1.1rem;
  padding: 1rem ${(props) => (props.$darkBg ? '1rem' : '0')};
  background: ${(props) => (props.$darkBg ? props.theme.vars.red : '')};
  font-family: ${(props) => props.theme.fonts.fontFamilyPrimary};
`;

export const StyledIconContainer = styled.span`
  position: absolute;
  top: 52%;
  left: 3px;
  padding-left: 5px;

  svg {
    font-size: ${({ theme }) => theme.vars.sizeM};
  }
`;
