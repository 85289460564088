const capitalize = (str: string): string => str[0].toUpperCase() + str.substring(1);

export const titleCase = (str: string): string => {
  const splitStr = str.toLowerCase().split(" ");

  if (splitStr) {
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
    }
    return splitStr.join(" ");
  }

  return "";
}

export default capitalize;
