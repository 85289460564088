import { ApolloLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import type Cookies from "universal-cookie";
import { v4 as uuidv4 } from 'uuid';

export const cookieHeadersLink = (cookies: Cookies) => new ApolloLink((operation, forward) => {
  let operationHeaders = operation.getContext().headers;

  let cookieKGSession = cookies.get('kg_session');
  if (!cookieKGSession) {
    cookieKGSession = uuidv4();
    cookies.set('kg_session', cookieKGSession, {
      path: '/',
      secure: process.env.NODE_ENV === 'production',
    });
  }
  if (cookieKGSession) {
    operationHeaders = {
      ...operationHeaders,
      'kg_session': cookieKGSession,
    };
  }

  const cookieCustomerAuthToken = cookies.get('customer_oauth_token');
  if (cookieCustomerAuthToken) {
    operationHeaders = {
      ...operationHeaders,
      'customer_oauth_token': cookieCustomerAuthToken,
    };
  }

  const cookieShoppingCartId = cookies.get('shopping_cart_id');
  if (cookieShoppingCartId) {
    operationHeaders = {
      ...operationHeaders,
      'shopping_cart_id': cookieShoppingCartId,
    };
  }

  operation.setContext({
    headers: {
      ...operationHeaders,
    },
  });

  return forward(operation).map((response) => {
    if (response.data) {
      const responseShoppingCartId = response.data?.minibag?.bag?.id || response.data?.addProductToBag?.bag?.id;
      if (responseShoppingCartId) {
        const expireTime = 72 * 3600 * 1000; // 72 hours
        const expireDate = new Date(Date.now() + expireTime);
        cookies.set('shopping_cart_id', responseShoppingCartId, {
          path: '/',
          secure: process.env.NODE_ENV === 'production',
          expires: expireDate,
        });
      }
    }

    return response;
  });
}).concat(
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const operationHeaders = operation.getContext().headers;
      graphQLErrors.map(({ extensions }) => {
        const code = extensions?.code;
        if (code === 'CUSTOMER_SESSION_EXPIRED') {
          cookies.remove('customer_oauth_token', { path: '/' });
          cookies.remove('shopping_cart_id', { path: '/' });
          delete operationHeaders.customer_oauth_token;
          delete operationHeaders.shopping_cart_id;
          operation.setContext({ headers: { ...operationHeaders } });
          return forward(operation);
        }

        if (code === 'GUEST_CART_NOT_FOUND') {
          cookies.remove('shopping_cart_id', { path: '/' });
          delete operationHeaders.shopping_cart_id;
          operation.setContext({ headers: { ...operationHeaders } });
          return forward(operation);
        }
        return null
      });
    }
  })
);
