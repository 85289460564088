import React, { FC } from 'react';

import { StyledSkipLink } from './SkipLinksStyle';
import { SkipLinksProps } from '.';

const SkipLinksStructure: FC<SkipLinksProps> = ({ target, label }) => (
  <StyledSkipLink href={target} tabIndex={0}>
    {label}
  </StyledSkipLink>
);

export default SkipLinksStructure;
