import { createAction } from '@reduxjs/toolkit';

import { CategoryActiveFilter, Maybe } from '../../types/middleware-types';
import {
  CurrentFilterType,
  CurrentMobileFiltersType,
  ProductListFilterOptionsType,
} from '../../types/state-types';

export const updatePlpFilterOptionsData = createAction<Maybe<ProductListFilterOptionsType>>(
  'plpFilter/updatePlpFilterOptionsData',
);
export const updatePlpCurrentFilter = createAction<Maybe<CurrentFilterType>>(
  'plpFilter/updateNearbyStoresData',
);
export const updatePlpCurentMobileFilters = createAction<Maybe<CurrentMobileFiltersType>>(
  'plpFilter/updatePlpCurentMobileFilters',
);
export const updatePlpActiveFilters = createAction<Maybe<Array<CategoryActiveFilter>> | undefined>(
  'plpFilter/updatePlpActiveFilters',
);
export const updatePlpCurrentSortBy = createAction<string>(
  'plpFilter/updatePlpCurrentSortBy',
);
export const updatePlpSlideFilters = createAction<boolean>('plpFilter/updatePlpSlideFilters');
export const updatePLpCurrentSelectedFilters = createAction<Record<string, Array<string>>>(
  'plpFilter/updatePlpSlideFilter',
);
export const setPLpCurrentSelectedFilters = createAction<Record<string, Array<string>>>(
  'plpFilter/setPlpSlideFilter',
);
export const setPLpApplyFilterEnabled = createAction<boolean>('plpFilter/setPLpApplyFilterEnabled');
export const setPLpFilterMenuOpenMobile = createAction<boolean>(
  'plpFilter/setPLpFilterMenuOpenMobile',
);
export const setSizeQueryData = createAction<
  string | null | undefined
>('plpFilter/setSizeQueryData');
