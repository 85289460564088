/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectSubmenuCompactPosition } from '../../../features/nav';
import { selectToggles } from '../../../features/static';
import { SubMenuData } from '../../../types/custom-types';
import {
  ImageHtmlLink,
  Maybe,
  SubMenuItem
} from '../../../types/middleware-types';
import { isSubMenuArray } from '../../../types/type-checkers';
import { useHeaderCompactPosition } from '../../../utils/customHooks/useHeaderCompactPosition';
import SubmenuCompactStructure from './SubMenuCompactStructure';

export interface SubMenuCompactProps {
  data?: SubMenuData;
  showSubNav?: boolean;
  parent: string;
  closeSubmenu: () => void;
  promoBannerHeight: number;
  finalTopPositionMob: number;
  headerHeight: number;
  isDesktop?: boolean;
  mobileSubmenuImages?: Maybe<ImageHtmlLink>[];
}

type sortSubmenuDataReturn = {
  submenuLinks: Array<SubMenuItem>;
  submenuImages: Maybe<ImageHtmlLink>[] | Array<SubMenuItem> | undefined;
};

const SubMenuCompact: FC<SubMenuCompactProps> = ({
  data,
  showSubNav,
  parent,
  isDesktop,
  closeSubmenu,
  promoBannerHeight,
  finalTopPositionMob,
  headerHeight,
  mobileSubmenuImages,
}) => {
  const positionLeft = useSelector(selectSubmenuCompactPosition);
  const { totalHeaderHeight } = useHeaderCompactPosition();
  const toggles = useSelector(selectToggles);
  const showSingleColumnSubNav = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu;

  const sortSubmenuData = (submenuData: SubMenuData): sortSubmenuDataReturn => {
    const submenuLinksArray: Array<SubMenuItem> = [];
    const submenuImagesArray: Array<SubMenuItem> = [];

    isSubMenuArray(submenuData) &&
      submenuData?.forEach((item) => {
        if (item) {
          item.items.forEach((submenuItem) => {
            if (submenuItem.__typename === 'SubMenuLinkList') {
              submenuLinksArray.push(submenuItem);
            } else {
              submenuImagesArray.push(submenuItem);
            }
          });
        }
      });

    if (mobileSubmenuImages) {
      return {
        submenuLinks: submenuLinksArray,
        submenuImages: mobileSubmenuImages,
      };
    }
    return {
      submenuLinks: submenuLinksArray,
      submenuImages: submenuImagesArray,
    };
  };

  const componentProps = {
    ...sortSubmenuData(data),
    showSubNav,
    parent,
    isDesktop,
    closeSubmenu,
    promoBannerHeight,
    finalTopPositionMob: toggles?.featureStickyHeader ? totalHeaderHeight : finalTopPositionMob,
    headerHeight,
    positionLeft,
    maxHeight:
      typeof window === 'undefined' ? 0 : window.innerHeight - finalTopPositionMob - headerHeight,
    stickyHeader: toggles?.featureStickyHeader,
    showSingleColumnSubNav,
  };

  return componentProps.submenuLinks?.length > 0 ||
    (componentProps.submenuImages && componentProps.submenuImages.length > 0) ? (
      <SubmenuCompactStructure {...componentProps} />
    ) : null;
};

export default SubMenuCompact;
