import React, { FC } from 'react';

import { BasicCarouselProps } from '../../../types/custom-types';
import { StyledMobileCarousel } from './MobileCarouselStyles';

interface MobileCarouselStructureProps
  extends Omit<BasicCarouselProps, "getCarouselInstance"> {
  addProductPositionToLocalStorage: (productPosition: number, lineNumber: string) => void;
}

const MobileCarouselStructure: FC<MobileCarouselStructureProps> = ({
  items,
  component: Component,
  otherProps,
  addProductPositionToLocalStorage,
}) => (
  <StyledMobileCarousel>
    {items.length > 0 &&
      items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Component
          key={`${item.id}-${index}`}
          {...item}
          {...otherProps}
          index={index}
          addProductPositionToLocalStorage={addProductPositionToLocalStorage}
        />
      ))}
  </StyledMobileCarousel>
);

export default MobileCarouselStructure;
