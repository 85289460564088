import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../reduxStore/store';
import {
  ContentBlockCelebrityStyleCarousel,
  Product,
  ProductMedia,
} from '../../types/middleware-types';

export const selectCelebrityStyleCarousel = (
  state: RootState,
): ContentBlockCelebrityStyleCarousel | null => state.currentProduct.celebrityStyleCarousel;
export const selectProductColours = (state: RootState): Product[] | null | undefined =>
  state.currentProduct.productColours;
export const selectCurrentProduct = (state: RootState): Product | null =>
  state.currentProduct.currentProduct;
export const selectProductMedia = (state: RootState): ProductMedia | null | undefined =>
  state.currentProduct.productMedia;
export const selectHasRotationImages = createSelector(
  selectProductMedia,
  (state) => !!state && state?.productRotation?.length > 0,
);
export const selectProductLineNumber = (state: RootState): string =>
  state.currentProduct.currentProduct?.lineNumber;

export const selectProductUrl = (state: RootState): string | undefined => state.currentProduct.currentProduct?.url;