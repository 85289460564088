import styled from 'styled-components';

import Button from '../../../basic/Buttons';
import {
  StyledInputContainer,
} from '../../../basic/TextInput/TextInputStyles';
import {
  Container,
  StyledPrivacyMessage,
  StyledPromoMessage,
  StyledRadioButtonContainer,
  StyledSignUpConfirmationHtml,
  StyledSignUpForm,
} from '../../NewsletterSignUp/NewsletterSignUpStyles';
import { StyledSRText } from '../../ScreenReaderOnlyText/ScreenReaderOnlyTextStyles';

export const StyledPopupCover = styled.div`
  background: ${({ theme }) => theme.vars.black};
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
`;

export const StyledPopupBtnWrapper = styled.div`
  margin: 1rem 2rem;
  text-align: center;

  button {
    max-width: 32rem;
    text-transform: none;
  }

  .continue-shopping {
    margin-top: 3rem;
  }
`;

export const StyledPopupWrapper = styled.div`
  background: ${({ theme }) => theme.vars.white};
  position: fixed;
  right: 0;
  width: calc(100% - 1rem);
  height: calc(100% - 2rem);
  z-index: 13;
  overflow-y: auto;
  margin: 1rem 0.5rem;

  @media ${({ theme }) => theme.devices.smallOnly} {
    top: 0;
    left: 0;
    max-width: 100%;
    width: calc(100% - 5rem);
    height: calc(100% - 3rem);
    margin: 1.5rem auto;
  }

  @media ${({ theme }) => theme.devices.mediumOnly} {
    top: 0;
    max-width: 40rem;
  }

  @media ${({ theme }) => theme.devices.large} {
    width: 100%;
    bottom: 0;
    height: 32rem;
    max-width: 100%;
    margin: 0;
  }

  h3 {
    text-transform: none;
    ${({ theme }) => theme.types.h1Style}
    margin-top: 1rem;
    text-align: center;

    @media ${({ theme }) => theme.devices.smallOnly} {
      margin-top: 3rem;
    }

    @media ${({ theme }) => theme.devices.mediumOnly} {
      margin-top: 4rem;
    }
  }

  ${Container} {
    margin: 3rem 2rem 1rem;

    h3 {
      margin-top: initial;
      ${({ theme }) => theme.types.labelStyle};
    }

    @media (height <= 600px) {
      padding-top: 4rem;
    }

    label {
      position: relative;
    }

    strong {
      ${({ theme }) => theme.types.boldStyle};
    }
  }
  ${StyledSRText} {
    left: 0;
    top: -2rem;
    width: auto;
    height: auto;
    overflow: hidden;
    ${({ theme }) => theme.types.labelStyle};
  }

  ${StyledRadioButtonContainer} {
    justify-content: center;

    @media ${({ theme }) => theme.devices.smallOnly} {
      margin-top: 4rem;
    }
  }

  ${StyledPromoMessage} {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  
  ${StyledSignUpConfirmationHtml} {
    h3 {
      ${({ theme }) => theme.types.h1Style}
    }
  }

  ${StyledSignUpForm} {
    button {
      background-color: ${({ theme }) => theme.welcomePopup.color};
      color: ${({ theme }) => theme.vars.white};
      width: 100%;
      max-width: 32rem;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }

    ${StyledPrivacyMessage} {
      text-align: center;
      padding: 0.5rem 0;
      margin-top: initial;

      @media ${({ theme }) => theme.devices.smallOnly} {
        p {
          ${({ theme }) => theme.types.labelSmallStyle};
        }
      }
    }
    
    ${StyledInputContainer} {
      width: 100%;

      input {
        ${({ theme }) => theme.types.labelStyle};
        color: ${({ theme }) => theme.welcomePopup.color};
        border: 0.1rem solid ${({ theme }) => theme.welcomePopup.color};

        &::placeholder {
          ${({ theme }) => theme.types.labelStyle};
          color: ${({ theme }) => theme.welcomePopup.color};
        }
      }
    }
  }
`;

export const StyledPopupInner = styled.div`
  padding-bottom: 2rem;
  margin: 0;
  position: relative;

  @media ${({ theme }) => theme.devices.large} {
    padding-bottom: 0;
    margin: 0 6rem;
  }
`;

export const StyledCloseBtn = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
  z-index: 3;

  @media ${({ theme }) => theme.devices.smallOnly} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  font-size: ${({ theme }) => theme.vars.sizeXL};
  background: ${({ theme }) => theme.vars.white};
`;

export const StyledNoThanksButton = styled(Button)`
  color: ${({ theme }) => theme.vars.black};
`;

export const StyledPopupTitle = styled.h2`
  text-align: center;
`;

export const StyledPopupIntro = styled.p`
  text-align: center;
  margin: 1rem 0;
`;

export const StyledPopupTerms = styled.p`
  text-align: center;
`;

export const StyledImageWrapper = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }

  img {
    width: 100%;
  }
`;
