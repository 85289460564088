import { createGlobalStyle } from 'styled-components';

import { fonts } from '../resources/resources';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${fonts.fontKudryashevHeadline};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/KudryashevHeadline.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/KudryashevHeadline.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: ${fonts.fontFamilyPrimary};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ProximaNova-Regular.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ProximaNova-Regular.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: ${fonts.fontShapiroBase55Middle};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ShapiroBase-55Middle.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ShapiroBase-55Middle.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: ${fonts.fontShapiroBase63LightHeavy};
    src: url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ShapiroBase-63LightHeavy.woff2") format("woff2"), url("${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/fonts/ShapiroBase-63LightHeavy.woff") format("woff");
    font-display: swap;
  }

  html {
    font-size: 10px;

    &.smartbanner-show.smartbanner-margin-top {
      margin-top: 0 !important;
    }

    &.smartbanner-show {
      main {
        position: relative;
      }
    }

    body {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      font-size: 10px;
      font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};

      /* To assist mobile minibag styling */
      overflow-x: hidden;

      @media ${({ theme }) => theme.devices.large} {
        overflow-x: initial;
      }

      &.no-scroll {
        overflow: hidden !important;
        width: 100vw;
        height: 100vh;
      }

      .smartbanner {
        position: sticky;
        top: 0;
      }
    }

    /* removes focus ring for mouse & touch interactions */
    /* stylelint-disable selector-max-universal */
    &[data-whatintent= "mouse"],
    &[data-whatintent= "touch"] {
      *:focus {
        outline: none;

        /* Necessary for styled radios */
        & + label::before {
          outline: none !important;
        }
      }
    }
  }
`;