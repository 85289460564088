import styled from 'styled-components';

import { StyledCountryPicker } from '../CountryPicker/CountryPickerStyles';

interface MainMenuProps {
  $isHeaderReverse: boolean;
}

export const StyledMainMenuContainer = styled.div`
  width: 100%;
  ${StyledCountryPicker} {
    display: none;

    @media ${({ theme }) => theme.devices.large} {
      display: block;
    }
  }
`;

export const StyledMainMenuLinksContainer = styled.div<MainMenuProps>`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    margin-top: ${({ $isHeaderReverse }) => ($isHeaderReverse ? '0' : '2rem')};
  }
`;

export const StyledLinks = styled.ul<MainMenuProps>`
  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: ${({ $isHeaderReverse }) => ($isHeaderReverse ? 'static' : 'relative')};
    margin: 0;
  }
`;
