'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImageProps } from 'next/image';
import { useTheme } from 'styled-components';

import { selectToggles } from '../../features/static';
import { NextMediaList } from '../../types/custom-types';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import NextImageStructure from './NextImageStructure';

export interface NextImageProps {
  mediaList: NextMediaList;
  alt?: string;
  imageType?: ImageProps['layout'];
  priority?: boolean;
  sizes?: string;
  tabIndex?: number;
  className?: string;
  dataHookId?: string;
}

const NextImage: FC<NextImageProps> = ({
  mediaList: { mobile, tablet, desktop },
  alt = '',
  imageType = 'responsive' as ImageProps['layout'],
  priority,
  sizes,
  tabIndex,
  className,
  dataHookId,
}) => {
  const toggles = useSelector(selectToggles);

  const theme = useTheme();
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const [image, setImage] = useState(
    (isMobile && mobile) || (isTablet && (tablet || mobile)) || desktop,
  );

  useEffect(() => {
    if (isMobile) {
      setImage(mobile);
    } else if (isTablet) {
      const imageTablet = tablet || mobile;
      setImage(imageTablet);
    } else {
      setImage(desktop);
    }
  }, [isMobile, isTablet, mobile, tablet, desktop]);

  const defaultSizes = (imageType === 'responsive') ?
    "(max-width: 1024px) 100vw, 50vw" : undefined;

  const useFill = imageType === "fill" && image?.width === undefined && image?.height === undefined;

  const props = {
    ...image,
    alt,
    imageType,
    priority,
    quality: toggles?.featureNextImageQuality || 75,
    sizes: sizes || defaultSizes,
    tabIndex,
    className,
    dataHookId,
    useFill
  };

  return <NextImageStructure {...props} />;
};
export default NextImage;
