import React, { FC } from 'react';

import { HtmlLink as HtmlLinkTypes } from '../../../../types/middleware-types';
import HtmlLinkStructure from './HtmlLinkStructure';

export interface HtmlLinkProps {
  data: HtmlLinkTypes
}

const HtmlLink: FC<HtmlLinkProps> = ({ data }) => {
  const componentProps = { ...data };
  return <HtmlLinkStructure {...componentProps} />;
};

export default HtmlLink;
