import styled, { keyframes } from 'styled-components';


const loader = keyframes`
    50% {
      opacity: 0;
    }
  `;

export const StyledLoading = styled.div`
  text-indent: -9999px;
  margin: auto;
  display: flex;
  width: 3.6rem;

  span {
    display: block;
    background: ${({ theme }) => theme.vars.greyDark};
    height: ${({ theme }) => theme.vars.sizeXS};
    margin: 2px;
    width: ${({ theme }) => theme.vars.sizeXS};
    opacity: 1;
    animation: ${loader} 1.1675s normal infinite;
    border-radius: 100%;

    &:nth-of-type(1) {
      animation-delay: 0.2295s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.5455s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.6985s;
    }
  }
`;