/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { FindInStoreState } from '../../types/state-types';
import {
  updateCurrentFisProduct,
  updateFisSelectedSizeData,
  updateNearbyStoresData,
  updateSelectedStore,
} from './actions';

export const initialState: FindInStoreState = {
  currentFindInStoreProduct: null,
  nearbyStoresData: {
    stores: null,
    loading: false,
    fetchError: false,
    locationDecodingError: '',
    searchLocation: '',
    coords: { lat: null, lng: null },
  },
  selectedStore: null,
  selectedFisSize: null,
};

export const findInStoreReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateCurrentFisProduct, (state, action) => {
      state.currentFindInStoreProduct = action.payload;
    })
    .addCase(updateNearbyStoresData, (state, action) => {
      state.nearbyStoresData = action.payload;
    })
    .addCase(updateFisSelectedSizeData, (state, action) => {
      state.selectedFisSize = action.payload;
    })
    .addCase(updateSelectedStore, (state, action) => {
      state.selectedStore = action.payload;
    });
});
