// Subnav data could be array or object
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';
import { useTheme } from 'styled-components';

import constants from '../../../../sites/constants';
import { selectElements } from '../../../features/elements';
import { selectMeganavOpen, updateSubmenuCompactPosition } from '../../../features/nav';
import { selectIsCompactHeader, selectIsHeaderReverse } from '../../../features/static';
import { SubMenuData } from '../../../types/custom-types';
import { HtmlLink, Maybe } from '../../../types/middleware-types';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import getResizeObserver from '../../../utils/getters/getResizeObserver/getResizeObserver';
import removeItemLineNumberFromLocalStorage from '../../../utils/helpers/removeItemLineNumberFromLocalStorage';
import MainMenuItemStructure from './MainMenuItemStructure';

export interface MainMenuItemProps {
  link?: Maybe<HtmlLink>;
  currentTab: string;
  toggleSubNav: (value: string) => void;
  subNavData: SubMenuData;
  id?: string;
  index?: number;
}

const MainMenuItem: FC<MainMenuItemProps> = ({
  link,
  currentTab,
  toggleSubNav,
  subNavData,
  id,
  index = 0,
}) => {
  const theme = useTheme();
  const { headerTheme } = useHeaderTheme();
  const isCurrentTab = currentTab === link?.text;
  const isHeaderReverse = useSelector(selectIsHeaderReverse);
  const megaNavOpen = useSelector(selectMeganavOpen);
  const pathname = usePathname();

  const compactHeader = useSelector(selectIsCompactHeader);
  const { header } = useSelector(selectElements);
  const dispatch = useDispatch();
  const [firstItem, setFirstItem] = useState<HTMLLIElement | null>(null);
  const itemRef = useCallback((node: HTMLLIElement) => {
    setFirstItem(node);
  }, []);

  // updates left position to align SubMenus with first MenuItem.
  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null;
    if (compactHeader && index === 0 && firstItem) {
      const itemPadding = firstItem ? window
        ?.getComputedStyle(firstItem)
        .getPropertyValue('padding-left')
        .replace(/\D/g, '') : '0';

      const updatePositionLeft = () => {
        if (firstItem && itemPadding) {
          const position = +((firstItem?.getBoundingClientRect().left + + itemPadding) / 10).toFixed(2);

          dispatch(updateSubmenuCompactPosition(position));
        }
      };

      // Keep updatePositionLeft outside of header check, for initial position in M2 to be set correctly.
      updatePositionLeft();

      if (header) {
        resizeObserver = getResizeObserver(updatePositionLeft);
        resizeObserver.observe(header);
      }
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(header);
      }
    }

  }, [header, compactHeader, index, firstItem, pathname])

  const formateUrl = link ? constants.plpRoutes.includes(link?.url) : false;

  const componentProps = {
    link: link || { url: '', text: '', newWindow: false },
    isCurrentTab,
    toggleSubNav,
    subNavData,
    headerTheme: megaNavOpen && isHeaderReverse ? theme.vars.black : headerTheme,
    compactHeader,
    currentTab,
    removeItemLineNumberFromLocalStorage,
    id,
    itemRef,
    formateUrl,
    isHeaderReverse
  };
  return <>{headerTheme && <MainMenuItemStructure {...componentProps} />}</>;
};

export default MainMenuItem;
