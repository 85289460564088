import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectHasFilledForm } from '../../../features/dataCapture';
import { selectHeaderNavigationData, selectPromoBanner, selectToggles } from '../../../features/static';
import useDataCaptureCookies from '../../../utils/customHooks/useDataCaptureCookies';
import useElementPush from '../../../utils/customHooks/useElementPush';
import useHeaderNavigation from '../../../utils/customHooks/useHeaderNavigation';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import TransparentHeaderStructure from './TransparentHeaderStructure';

interface HeaderProps {
  showDesktopLayout: boolean;
  hasSmartAppBanner?: boolean;
}

const TransparentHeader: FC<HeaderProps> = ({ showDesktopLayout, hasSmartAppBanner }) => {
  const toggles = useSelector(selectToggles);
  const { headerTheme, headerBackground, headerPosition } = useHeaderTheme();
  const getElement = useElementPush<HTMLDivElement>('header');
  const { searchOpen, toggleSearch } = useHeaderNavigation();

  const headerNavigation = useSelector(selectHeaderNavigationData);
  const promoBanner = useSelector(selectPromoBanner);

  const { showContiunity = false } = useDataCaptureCookies();
  const acceptedRedux = useSelector(selectHasFilledForm);
  const [showContinuityBar, setShowContinuityBar] = useState(false);

  useEffect(() => {
    const hasAcceptCookie = showContiunity || acceptedRedux;
    setShowContinuityBar(toggles.featureDataCapture && hasAcceptCookie);
  }, [acceptedRedux, toggles])

  const componentProps = {
    searchOpen,
    toggleSearch,
    headerPosition,
    headerTheme,
    headerBackground,
    promoBanner,
    getElement,
    showDesktopLayout,
    hasSmartAppBanner,
    headerNavigation,
    showContinuityBar
  };

  return <>{headerTheme && <TransparentHeaderStructure {...componentProps} />}</>;
};

export default TransparentHeader;