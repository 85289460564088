import React, { FC } from 'react';

const ServicesIcon: FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" maskUnits="userSpaceOnUse" x="6" y="2" width="8" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5906 14.7826H8.22466V10.4891H7.5906C7.24041 10.4891 6.95654 10.223 6.95654 9.8947V8.47487C6.95654 8.14657 7.24041 7.88044 7.5906 7.88044H11.1413C11.4915 7.88044 11.7754 8.14657 11.7754 8.47487V14.7826H12.4094C12.7596 14.7826 13.0435 15.0488 13.0435 15.3771V16.7969C13.0435 17.1252 12.7596 17.3913 12.4094 17.3913H7.5906C7.24041 17.3913 6.95654 17.1252 6.95654 16.7969V15.3771C6.95654 15.0488 7.24041 14.7826 7.5906 14.7826ZM10 2.17392C8.73935 2.17392 7.71741 3.13199 7.71741 4.31387C7.71741 5.49574 8.73935 6.45381 10 6.45381C11.2607 6.45381 12.2826 5.49574 12.2826 4.31387C12.2826 3.13199 11.2607 2.17392 10 2.17392Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.434753 -0.434784H20.4348V20.4348H-0.434753V-0.434784Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default ServicesIcon;
