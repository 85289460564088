import { createGlobalStyle } from 'styled-components';

import { fonts,vars } from '../resources/resources';
import SahTypes from '../resources/typeSnippets';

export const GlobalType = createGlobalStyle`
  body {
    font-family: ${fonts.fontFamilyPrimary};
    font-weight: ${vars.fontWeightLight};
  }

  h1,
  .h1-style {
    ${SahTypes.h1Style}
  }

  h2,
  .h2-style {
    ${SahTypes.h2Style}
  }

  h3,
  .h3-style {
    ${SahTypes.h3Style}
  }

  h4,
  .h4-style {
    ${SahTypes.h4Style}
  }

  h5,
  .h5-style {
    ${SahTypes.h5Style}
  }

  h6,
  .h6-style {
    ${SahTypes.h6Style}
  }

  p,
  .p-style {
    ${SahTypes.labelStyle}
  }

  ul li,
  ol li {
    ${SahTypes.labelStyle}
  }

  a {
    ${SahTypes.linkStyles}
  }

  button {
    ${SahTypes.ctaTextStyles}
  }
`;
