/* eslint-disable no-nested-ternary */
import React, { FC, Ref } from 'react';

import { Maybe } from '../../types/middleware-types';
import { StyledContinuityBar } from './ContinuityBarStyles';

interface PromoBannerStructureProps {
  height?: Maybe<number>;
  getElement: Ref<HTMLDivElement>;
  compactHeader: boolean;
  headerPosition: string;
  top: number;
  stickyCompactHeader: boolean;
  continuityBarData?: Maybe<string> | undefined;
  isReverse: boolean;
}

const PromoBannerStructure: FC<PromoBannerStructureProps> = ({
  height,
  getElement,
  compactHeader,
  headerPosition,
  top,
  stickyCompactHeader,
  continuityBarData,
  isReverse
}) => (
  <StyledContinuityBar
    $isReverse={isReverse}
    $height={height}
    data-hookid="globalContinuityBar"
    ref={getElement}
    $stickyCompactHeader={stickyCompactHeader}
    $compactHeader={compactHeader}
    $headerPosition={headerPosition}
    $top={top}
  >
    {continuityBarData && <div className="text" dangerouslySetInnerHTML={{ __html: continuityBarData }} />}
  </StyledContinuityBar>
);

export default PromoBannerStructure;
