import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectBagData } from '../../../features/bag';
import { selectCurrencyData, selectIsCompactHeader, selectToggles } from '../../../features/static';
import { MinibagOptionsInterface } from '../../../types/custom-types';
import { AmazonPayButton, CartItem } from '../../../types/middleware-types';
import { useHeaderCompactPosition } from '../../../utils/customHooks/useHeaderCompactPosition';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import getResizeObserver from '../../../utils/getters/getResizeObserver/getResizeObserver';
import MinibagDropdownStructure from './MinibagDropdownStructure';

export interface MinibagDropdownProps extends MinibagOptionsInterface {
  loading: boolean;
}

const MinibagDropdown: FC<MinibagDropdownProps> = ({
  toggleBag,
  showBag,
  loading,
  isMobileOrTablet,
}: MinibagDropdownProps) => {
  const [topPosition, setTopPosition] = useState(0);
  const bagData = useSelector(selectBagData);
  const [remainingItems, setRemainingItems] = useState(0);
  const [itemsToShow, setItemsToShow] = useState<Array<CartItem>>([]);
  const { isScrolling, scrollDirection } = useHeaderTheme();
  const [amazonPayData, setAmazonPayData] = useState<AmazonPayButton | null>(null);

  const toggles = useSelector(selectToggles);
  const engs13172CheckoutButton = toggles?.engs13172CheckoutButton;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;
  const showReactCheckout = toggles?.featureReactCheckout;
  const isRedesign = toggles?.featureKgRedesign;
  const currencyData = useSelector(selectCurrencyData);
  const compactHeader = useSelector(selectIsCompactHeader);
  const forceCompactHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu ? true : compactHeader;
  const sahNewHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu;

  const { headerTop } = useHeaderCompactPosition();
  const formatTotalPrice = (price: string) => Number(price).toFixed(2);
  const MAX_ITEMS_TO_SHOW = 2;

  // Show MAX_ITEMS_TO_SHOW items and show amazon pay button
  useEffect(() => {
    if (bagData?.bag?.items) {
      setItemsToShow(bagData.bag.items.slice(0, MAX_ITEMS_TO_SHOW));
      setRemainingItems(bagData.bag.items.length - MAX_ITEMS_TO_SHOW);
    }
    if (bagData?.amazonPayButton) {
      setAmazonPayData(bagData.amazonPayButton);
    }
  }, [bagData]);

  const setDropdownPosition = () => {
    const node = document.querySelector(
      isMobileOrTablet
        ? '[data-hookid="mobileGlobalHeader"]'
        : '[data-hookid="globalHeaderMinibagIcon"]',
    );
    if (node) {
      const { height, top } = node.getBoundingClientRect();

      if (height + top !== 0) {
        setTopPosition(height + top);
      }
    }
  };

  // observe changes in smartBanner, size switcher and header/minibagIcon and update bag top positon
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const smartBanner = document.querySelectorAll('[data-hookid="smartbanner"]');
    const resizeObserver = getResizeObserver(setDropdownPosition);

    if (smartBanner && smartBanner.length) {
      resizeObserver.observe(smartBanner[0]);
    }

    const siteSwitcher = document.querySelector('[data-hookid="siteSwitcher"]');
    if (siteSwitcher) {
      resizeObserver.observe(siteSwitcher);
    }

    const node = document.querySelector(
      isMobileOrTablet
        ? '[data-hookid="mobileGlobalHeader"]'
        : '[data-hookid="globalHeaderMinibagIcon"]',
    );
    if (node) {
      setDropdownPosition();
      resizeObserver.observe(node);
      return () => resizeObserver.unobserve(node);
    }

  }, [setDropdownPosition]);

  const buffer = isRedesign ? topPosition : headerTop + 40;
  let tempTopPosition = topPosition;
  if (sahNewHeader && scrollDirection > 0) {
    tempTopPosition = topPosition;
  } else if (forceCompactHeader && scrollDirection > 0 && toggles?.featureStickyHeader) {
    tempTopPosition =  buffer;
  }

  const componentProps = {
    itemsToShow,
    showBag,
    toggleBag,
    bagData,
    formatTotalPrice,
    loading,
    isScrolling,
    compactHeader: forceCompactHeader,
    isMobileOrTablet,
    topPosition: tempTopPosition,
    remainingItems,
    currencySymbol: currencyData?.symbol,
    amazonPayData,
    linkAttr: bagData?.bagButton?.openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {},
    checkoutButtonLinkAttr: bagData?.checkoutButton?.openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {},
    isRedesign,
    engs13172CheckoutButton,
    useReactRoutingLinks,
    showReactCheckout,
  };

  return <MinibagDropdownStructure {...componentProps} />;
};

export default MinibagDropdown;
