import { StoreState } from '../types/redux-types';
import { initialState as accordionInitialState } from './accordion';
import { initialState as authInitialState } from './auth';
import { initialState as bagInitialState } from './bag';
import { initialState as cartInitialState } from './cart';
import { initialState as checkoutInitialState } from './checkout'
import { initialState as currentProductInitialState } from './currentProduct';
import { initialState as dataCaptureInitialState } from './dataCapture';
import { initialState as deviceInitialState } from './device';
import { initialState as elementsInitialState } from './elements';
import { initialState as filtersInitialState } from './filters';
import { initialState as findInStoreInitialState } from './findInStore';
import { initialState as metadataInitialState } from './metadata';
import { initialState as navInitialState } from './nav';
import { initialState as notificationInitialState } from './notification'
import { initialState as plpContentInitialState } from './plpContent';
import { initialState as plpFilterInitialState } from './plpFilter';
import { initialState as popUpInitialState } from './popUp';
import { initialState as radioListInitialState } from './radioList';
import { initialState as staticInitialState } from './static';
import { initialState as storiesInitialState } from './stories';

export const initialState: StoreState = {
  accordion: accordionInitialState,
  currentProduct: currentProductInitialState,
  elements: elementsInitialState,
  device: deviceInitialState,
  plpContent: plpContentInitialState,
  static: staticInitialState,
  stories: storiesInitialState,
  nav: navInitialState,
  popUp: popUpInitialState,
  findInStore: findInStoreInitialState,
  bag: bagInitialState,
  plpFilter: plpFilterInitialState,
  auth: authInitialState,
  metadata: metadataInitialState,
  filters: filtersInitialState,
  dataCapture: dataCaptureInitialState,
  checkout: checkoutInitialState,
  notification: notificationInitialState,
  radioList: radioListInitialState,
  cart: cartInitialState
};

export const initialStateNext14: StoreState = {
  accordion: accordionInitialState,
  static: staticInitialState,
  currentProduct: currentProductInitialState,
  elements: elementsInitialState,
  device: deviceInitialState,
  plpContent: plpContentInitialState,
  stories: storiesInitialState,
  nav: navInitialState,
  popUp: popUpInitialState,
  findInStore: findInStoreInitialState,
  bag: bagInitialState,
  plpFilter: plpFilterInitialState,
  auth: authInitialState,
  metadata: metadataInitialState,
  filters: filtersInitialState,
  dataCapture: dataCaptureInitialState,
  checkout: checkoutInitialState,
  radioList: radioListInitialState,
  notification: notificationInitialState,
  cart: cartInitialState
};
