import React, { FC, ReactElement } from 'react';

import CountdownItem from './CountdownItem';
import { StyledCountdownStructure } from './CountdownStyles';
import { CountdownValues } from '.';

interface CountdownStructureProps {
  countdownValues: CountdownValues;
  layout: string;
  pageName: string | null;
}

const CountdownStructure: FC<CountdownStructureProps> = ({
  countdownValues: { days, hours, mins, secs },
  layout,
  pageName,
}: CountdownStructureProps): ReactElement => (
  <StyledCountdownStructure $layout={layout} data-hookid={`${pageName}Countdown`}>
    <CountdownItem timeValue={days} type="days" layout={layout} />
    <CountdownItem timeValue={hours} type="hours" layout={layout} />
    <CountdownItem timeValue={mins} type="mins" layout={layout} />
    <CountdownItem timeValue={secs} type="secs" layout={layout} />
  </StyledCountdownStructure>
);

export default CountdownStructure;
